import NotificationAlert from '../../components/notifications-alert/NotificationAlert';
import {CommandExecutor} from '../core/command-executor';
import {stores} from '../../store';
import Api from '../core/axios-service';

const commandExecutor = new CommandExecutor();

const MeetingApi = {
  leaveSession(executorId, eventId, sessionId, message) {
    return commandExecutor.execute(
      executorId,
      Api.post('/event/schedule/leave', {
        eventId,
        sessionId,
        message,
      })
        .then(resp => resp.data)
        .catch(err => {
          NotificationAlert.error(err.message);
          stores.errorStore.addError(err);
          return err;
        }),
    );
  },
  joinSession(executorId, eventId, sessionId, message) {
    return commandExecutor.execute(
      executorId,
      Api.post('/event/schedule/join', {
        eventId,
        toType: 'session',
        sessionId,
        message,
      })
        .then(resp => resp.data)
        .catch(err => {
          NotificationAlert.error(err.message);
          stores.errorStore.addError(err);
          return err;
        }),
    );
  },
  cancelMeeting(executorUserId, eventId, sessionId, message, invitationId) {
    return commandExecutor.execute(
      executorUserId,
      Api.post('/event/schedule/leave', {eventId, message, sessionId, invitationId})
        .then(resp => resp.data)
        .catch(err => {
          stores.errorStore.addError(err);
          return err;
        }),
    );
  },

  preBookMeeting(executorUserId, eventId, sessionId, fromParticipantId, toParticipantId, message) {
    return commandExecutor.execute(
      executorUserId,
      Api.post('/event/schedule/prebook', {eventId, message, sessionId, fromParticipantId, toParticipantId})
      .then(resp => resp.data)
      .catch(err => {
        stores.errorStore.addError(err);
        return err;
      }),
    );
  },
  pingSession(executorUserId, eventId, sessionId) {
    return commandExecutor.execute(
      executorUserId,
      Api.post('/event/schedule/ping', {eventId, sessionId})
        .then(resp => resp.data)
        .catch(err => {
          stores.errorStore.addError(err);
          return err;
        }),
    );
  },
  addParticipantToMeeting(executorId, eventId, selectedSessionId, message, participantId, behalfOf = null) {
    const params = {eventId, sessionId: selectedSessionId, message, participantId};
    if (behalfOf) {
      params.behalfOf = behalfOf;
      params.block = false;
    }

    return commandExecutor.execute(
      executorId,
      Api.post('/event/schedule/addToSession', params)
        .then(resp => resp.data)
        .catch(err => {
          console.log('e', err.response.data.message);
          NotificationAlert.error(err.response.data.message);
          stores.errorStore.addError(err);
          return err;
        }),
    );
  },
  getCalendarLink(eventId) {
    return Api.post('/event/participant/calendar', {eventId})
      .then(resp => resp.data)
      .catch(err => {
        stores.errorStore.addError(err);
        return Promise.reject(err.response.data);
      });
  },
};

export default MeetingApi;
