import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Label, Popup} from 'semantic-ui-react';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import './UserEmails.scss';

const UserEmails = observer(() => {
  const {userStore, modalStore} = React.useContext(AppContext);
  const {connectedEmails, user} = userStore;
  const primaryEmail = connectedEmails ? connectedEmails.find(email => email === user.username) : null;
  const sortedConnectedEmails = connectedEmails.sort(a => {
    if (a === primaryEmail) {
      return -1;
    }
    return 0;
  });

  const updateUsername = value => {
    userStore
      .updateUsername(value)
      .then(() => {
        NotificationAlert.success(<FormattedMessage id="user.emails.success.update" />);
      })
      .catch(err => {
        NotificationAlert.error(err.message);
      });
  };

  const emailsConnected = sortedConnectedEmails.map(email => (
    <div className="account-emails__row" key={email}>
      <div className="account-emails__email">{email}</div>
      {email !== userStore.user.username ? (
        <>
          <Label className="account-emails__email-label" aria-hidden color="grey">
            <FormattedMessage id="user.emails.status.secondary" />
          </Label>
          <Popup
            trigger={<i className="account-email__btn-more ellipsis horizontal icon button" />}
            className="account-emails__remove-popup"
            basic
            position="bottom right"
            on="click"
            content={
              <div role="list" className="account-emails__more-list">
                <div
                  aria-hidden
                  className="item"
                  onClick={() => {
                    updateUsername(email);
                  }}
                >
                  <div className="content">
                    <FormattedMessage id="user.emails.make.primary.btn" />
                  </div>
                </div>
                <div
                  aria-hidden
                  className="item"
                  onClick={e => {
                    e.currentTarget.closest('body')?.click();
                    modalStore.open('removeEmailConfirmationModal', {email});
                  }}
                >
                  <div className="content">
                    <FormattedMessage id="user.emails.remove.btn" />
                  </div>
                </div>
              </div>
            }
          />
        </>
      ) : (
        <Label aria-hidden color="green">
          <FormattedMessage id="user.emails.status.primary" />
        </Label>
      )}
    </div>
  ));

  return (
    <div className="user-emails">
      <React.Fragment>
        {emailsConnected ? (
          <div className="user-emails__emails">
            <p>
              <FormattedMessage id="user.emails.text.emails.2" />
            </p>
            <div className="account-emails">{emailsConnected}</div>
          </div>
        ) : null}
      </React.Fragment>
    </div>
  );
});

export default injectIntl(UserEmails);
