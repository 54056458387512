import Api from '../core/axios-service';
import {stores} from '../../store';

const CheckInApi = {
  checkIn(body) {
    const postBody = {
      entityType: body.entityType, // area/session/event
      entityId: body.entityId, // eventId/areaId/sessionId
      checkIn: body.checkIn,
      eventId: body.eventId,
      printer: body.printerLabel,
    };
    body.ticketId ? (postBody.ticketId = body.ticketId) : (postBody.participantId = body.participantId);

    return Api.post('event/participant/checkIn', postBody).then(
      resp => {
        if (resp.data?.errorMessage) {
          return Promise.reject(resp.data);
        }
        return Promise.resolve(resp);
      },
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },

  // "type" can be ticketId or participantId
  getTXTTemplate(id, eventId, type = 'ticketId') {
    const postBody = {eventId};
    postBody[type] = id;

    return Api.post('digest/template', postBody).then(
      resp => {
        if (resp.data?.errorMessage) {
          return Promise.reject(resp.data);
        }
        return Promise.resolve(resp);
      },
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
};

export default CheckInApi;
