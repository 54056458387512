import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage} from 'react-intl';
import Modal from '../../../../ui/Modal';
import {AppContext} from '../../../../store';
import MeetingFormAddGuest from '../../../meeting/MeetingFormAddGuest';
import './AddGuestToMeetingModal.scss';

const AddGuestToMeetingModal = observer(() => {
  const {modalStore, participantStore, eventStore, userStore} = React.useContext(AppContext);
  const {open, meeting} = modalStore.modals.addGuestToMeetingModal;

  React.useEffect(() => {
    if (meeting) {
      eventStore.getEventDetails(meeting.eventId).then(eventDetails =>
        userStore.setQueryUser(eventDetails, eventDetails.me).then(() => {
          const query = {query: '*:*', initialLength: 10, length: 10};
          participantStore.setQuery(query, true);
          return participantStore.getParticipants(eventDetails, true, true);
        }),
      );
    }
  }, [meeting]);

  return (
    <Modal
      className="add-guest-to-meeting-modal"
      open={open}
      onClose={() => modalStore.close('addGuestToMeetingModal')}
      size="tiny"
    >
      <h3>
        <FormattedMessage id="meeting.add.guest.modal.title" />
      </h3>
      <MeetingFormAddGuest meeting={meeting} />
    </Modal>
  );
});

export default AddGuestToMeetingModal;
