import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/database';
import {AppContext, stores} from '../../../store';
import UtilsService from '../../../services/core/utils-service';
import ImageLazy from '../../../ui/ImageLazy';
import attendeeImg from '../../../assets/images/defaultImages/attendee.png';
import './PopupTable.scss';

const PopupTable = props => {
  const {popupMeeting} = props;
  const {participantStore} = React.useContext(AppContext);
  const [onlineParticipants, setOnlineParticipants] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const seatConfig = [
    ['top-view'],
    ['right-view'],
    ['left-view'],
    ['bottom-view'],
    ['top-view'],
    ['right-view'],
    ['left-view'],
    ['bottom-view'],
  ];

  const openConference = popupMeeting => {
    window.open(
      UtilsService.createConferenceUrl(stores.eventStore.meParticipant, popupMeeting.meetingId, false, false, true),
      'drconferenceexternal',
    );
  };

  useEffect(() => {
    firebase
      .database()
      .ref(`/videoRooms/${popupMeeting.roomId}/participantState`)
      .on('value', res => {
        let states = res.val();
        let onlineParticipants = [];
        let now = Date.now();
        if (states != null) {
          for (let pid in states) {
            let presenceInfo = states[pid];
            if (presenceInfo.presence) {
              if (now - presenceInfo.presence.date <= 60000) {
                onlineParticipants.push(participantStore.getParticipantDetails(pid));
              }
            }
          }
        }
        if (onlineParticipants.length === 0) {
          setOnlineParticipants([]);
        } else {
          Promise.all(onlineParticipants).then(allresults => {
            setOnlineParticipants(allresults);
          });
        }
      });
  }, []);

  const seats = seatConfig.map((seatConfig, index) => {
    return {
      class: seatConfig[0],
      comp: (
        <div className={'seat ' + seatConfig[0]}>
          {onlineParticipants[index] ? (
            <div className="user-img">
              <ImageLazy
                onClick={() => {
                  history.push({
                    pathname: `/event/${onlineParticipants[index].eventId}/attendees/${onlineParticipants[index].id}`,
                    state: {
                      background: location,
                      page: 'popupTable',
                      title: onlineParticipants[index].name,
                    },
                  });
                }}
                src={onlineParticipants[index]?.profilePhoto}
                errorSrc={attendeeImg}
                alt={onlineParticipants[index]?.name}
              />
            </div>
          ) : null}
        </div>
      ),
    };
  });

  const areas = ['top', 'right', 'bottom', 'left'].map(area => {
    return (
      <div key={area} className={area + '-area seat-container'}>
        {seats
          .filter(seat => seat.class === area + '-view')
          .map(seat => {
            return seat.comp;
          })}
      </div>
    );
  });

  return (
    <div className="popup-table">
      {areas}
      <div className="table-center">
        <div className="table-title">{popupMeeting.name}</div>
      </div>
      <div className="button-wrapper">
        <button
          onClick={() => {
            openConference(popupMeeting);
          }}
          className="ui teal icon button"
        >
          Grab a Seat
        </button>
      </div>
    </div>
  );
};

export default PopupTable;
