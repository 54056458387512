import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Dropdown, Form, Button, Message} from 'semantic-ui-react';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import ButtonLoading from '../../../ui/ButtonLoading';
import MeetingApi from '../../../services/api/meetingApi';
import './AgendaLinkCalendar.scss';

const AgendaLinkCalendar = observer(props => {
  const {intl, goBack} = props;
  const {eventStore, modalStore} = React.useContext(AppContext);
  const {activeEvents} = eventStore;
  const [eventId, setEventId] = React.useState(null);

  const handleSubmit = () =>
    MeetingApi.getCalendarLink(eventId)
      .then(data => {
        if (data.error) {
          NotificationAlert.error(data.error);
        } else {
          NotificationAlert.success(<FormattedMessage id="agenda.calendar.success" />);
          modalStore.close('exportAgendaModal');
        }
      })
      .catch(error => NotificationAlert.error(error.message));

  const options = activeEvents.map(event => {
    const obj = {};
    obj.key = event.id;
    obj.text = event.name;
    obj.value = event.id;
    return obj;
  });

  return (
    <div className="agenda-calendar">
      <Message info>Send agenda to your email</Message>
      <div className="form-group input-floating" id="start">
        <div className="agenda-calendar__select">
          <Form.Field>
            <label>
              <FormattedMessage id="agenda.calendar.select.name" />
            </label>
            <Dropdown
              selection
              options={options}
              onChange={(e, {value}) => {
                setEventId(value);
              }}
              placeholder={intl.formatMessage({id: 'agenda.calendar.placeholder.dropdown'})}
            />
          </Form.Field>
        </div>
        <div className="agenda-calendar__action">
          <Button color="blue" onClick={goBack}>
            <FormattedMessage id="agenda.calendar.btn.back" />
          </Button>
          <ButtonLoading color="blue" onClick={() => handleSubmit()} disabled={!eventId}>
            <FormattedMessage id="agenda.calendar.btn.confirm" />
          </ButtonLoading>
        </div>
      </div>
    </div>
  );
});

export default injectIntl(AgendaLinkCalendar);
