import React from 'react';
import {observer} from 'mobx-react-lite';
import {useLocation, useHistory} from 'react-router-dom';
import {Button, Form} from 'semantic-ui-react';
import {useForm} from 'react-hook-form';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import classNames from 'classnames';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import searchParse from '../../../utils/searchParse';
import './ForgotPasswordConfirmForm.scss';

const ForgotPasswordConfirmForm = observer(() => {
  const intl = useIntl();
  const {handleSubmit, register, errors} = useForm();
  const [showPassword, setShowPassword] = React.useState(false);
  const {userStore} = React.useContext(AppContext);
  const [error, setError] = React.useState(null);
  const location = useLocation();
  const history = useHistory();

  const onTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const classesPassword = classNames('link icon', {
    eye: showPassword,
    'eye slash': !showPassword,
  });

  const setValue = value => {
    const {username, code} = searchParse(location.search);
    userStore
      .passwordReset(username, code, value.password)
      .then(() => {
        NotificationAlert.success(intl.formatMessage({id: 'forgot.password.confirm.success'}));
        history.push('/');
      })
      .catch(err => {
        NotificationAlert.error(err.message);
        setError(err);
      });
  };

  return (
    <div className="forgot-password-confirm-form">
      <div className="forgot-password-confirm-form__header">
        <h2 className="forgot-password-confirm-form__title">
          <FormattedMessage id="forgot.password.confirm.title" />
        </h2>
      </div>
      <p className="forgot-password-confirm-form__desc">
        <FormattedMessage id="forgot.password.confirm.desc" />
      </p>
      <Form onSubmit={handleSubmit(setValue)}>
        <Form.Field error={!!errors.password}>
          <div className="ui left icon input">
            <i className="lock icon" />
            <input
              name="password"
              placeholder={intl.formatMessage({id: 'forgot.password.confirm.placeholder'})}
              type={showPassword ? 'text' : 'password'}
              ref={register({
                required: intl.formatMessage({id: 'validation.error.required'}, {name: 'Password'}),
                minLength: {
                  value: 8,
                  message: intl.formatMessage({id: 'validation.error.minlength'}, {number: 8}),
                },
              })}
            />
            <i aria-hidden="true" className={classesPassword} onClick={onTogglePassword} />
          </div>
          {error ? <div className="error">{error.message}</div> : null}
        </Form.Field>
        <Button className="forgot-password-confirm-form__reset">
          <FormattedMessage id="forgot.password.confirm.btn" />
        </Button>
      </Form>
    </div>
  );
});

export default injectIntl(ForgotPasswordConfirmForm);
