import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {runInAction} from 'mobx';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import {Controller, useForm} from 'react-hook-form';
import {Form, Button, Message} from 'semantic-ui-react';
import {useDropzone} from 'react-dropzone';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import UtilsService from '../../../services/core/utils-service';
import {AppContext} from '../../../store';
import closeImg from '../../../assets/images/icons/X-dark.svg';
import HtmlEditor from '../../../ui/HtmlEditor';
import {useFetch} from '../../../hooks/useFetch';
import AnnouncementApi from '../../../services/api/announcementApi';
import './AnnouncementAddForm.scss';

const AnnouncementAddForm = observer(props => {
  const {announcement, eventDetails} = props;
  const {modalStore} = React.useContext(AppContext);
  const {handleSubmit, errors, setValue, control} = useForm();
  const intl = useIntl();
  const [files, setFiles] = React.useState([]);
  const [error, setError] = React.useState(null);
  let imgdata = '';

  const update = useFetch({
    fetchFn: AnnouncementApi.updateAnnouncement,
    immediate: false,
  });

  const createAnnouncement = useFetch({
    fetchFn: AnnouncementApi.createAnnouncement,
    immediate: false,
  });

  const closeModal = () => {
    setValue('announcement', '');
    runInAction(() => {
      modalStore.modals.announcementCreate.announcement = null;
    });
    modalStore.close('announcementCreate');
  };

  const {getRootProps, getInputProps, open} = useDropzone({
    accept: 'image/jpeg, image/png',
    maxSize: 2097152,
    maxFiles: 1,
    multiple: false,
    onDrop: acceptedFiles => {
      if (error) {
        setError(null);
      }
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
    onDropRejected: errs => {
      if (errs[0].errors[0].code === 'file-too-large') {
        setError('File is larger than 2 Mb.');
      } else {
        setError(errs[0].errors[0].message);
      }
    },
  });

  React.useEffect(() => {
    files.forEach(file => {
      URL.revokeObjectURL(file.preview);
    });
  }, [files]);

  const thumbs = () => {
    if (files.length > 0) {
      return (
        <div className="announcement-create__images">
          {files.map(file => {
            if (file.preview) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = e => {
                imgdata = e.target.result;
              };
              return (
                <div key={file} className="announcement-create__review-img">
                  <img className="announcement-create__image-preview" src={file.preview} alt="media" width="100%" />
                </div>
              );
            }
            return (
              <div key={file} className="announcement-create__img">
                <img
                  aria-hidden
                  className="announcement-create__image-remove"
                  src={closeImg}
                  alt="remove"
                  onClick={() => setFiles([])}
                />
                <img
                  className="announcement-create__image-announcement"
                  src={`${UtilsService.fixPublicBucketUrl(file)}?rand=${Math.random()}`}
                  alt=""
                />
              </div>
            );
          })}
        </div>
      );
    }
    if (announcement && announcement.files && announcement.files.length > 0) {
      return (
        <div className="announcement-create__images">
          {announcement.files.map(file => (
            <div key={file} className="announcement-create__img">
              <img
                aria-hidden
                className="announcement-create__image-remove"
                src={closeImg}
                alt="remove"
                onClick={handleSubmit(e => updateAnnouncement(e, announcement, true))}
              />
              <img
                className="announcement-create__image-announcement"
                src={`${UtilsService.fixPublicBucketUrl(file)}?rand=${Math.random()}`}
                alt=""
              />
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const addAnnouncement = value => {
    const postBody = {
      type: 'announcement',
      eventId: eventDetails.id,
      text: value.announcement,
      files: files[0],
    };

    if (!isEmpty(files)) {
      postBody.files = {
        announcement: {
          contentType: files[0].type,
          data: imgdata,
        },
      };
    }

    createAnnouncement
      .makeRequest(postBody)
      .then(() => {
        closeModal();
        NotificationAlert.success(<FormattedMessage id="announcement.add-form.add.success" />);
      })
      .catch(() => {
        NotificationAlert.error(<FormattedMessage id="announcement.add-form.add.error" />);
      });
  };

  const updateAnnouncement = (value, a, removeImg = false) => {
    const postBody = {
      type: 'announcement',
      id: a.id,
      eventId: a.eventId,
      text: value.announcement,
      files: files[0],
    };

    if (removeImg) {
      postBody.deleteFiles = a.files;
      delete postBody.files;
    } else if (!isEmpty(files)) {
      postBody.deleteFiles = a.files;
      postBody.files = {
        [`announcement_${Date.now()}`]: {
          contentType: files[0].type,
          data: imgdata,
        },
      };
    }

    update
      .makeRequest(postBody)
      .then(resp => {
        if (!removeImg) {
          closeModal();
        } else {
          runInAction(() => {
            modalStore.modals.announcementCreate.announcement = resp.data;
          });
        }
        NotificationAlert.success(<FormattedMessage id="announcement.add-form.update.success" />);
      })
      .catch(() => {
        NotificationAlert.error(<FormattedMessage id="announcement.add-form.update.error" />);
      });
  };

  const classes = classNames('announcement-create__action', {
    update: announcement,
    create: !announcement,
  });

  return eventDetails && Object.keys(eventDetails).length > 0 ? (
    <div className="announcement-create">
      <Form unstackable>
        <Form.Field className="field__textarea" error={!!errors.announcement}>
          <Controller
            as={<HtmlEditor placeholder={props.intl.formatMessage({id: 'announcement.add-form.text-placeholder'})} />}
            control={control}
            name="announcement"
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({id: 'validation.error.required'}, {name: 'announcement'}),
              },
            }}
            defaultValue={(announcement && announcement.text) || ''}
          />
          {errors.announcement ? <div className="error">{errors.announcement.message}</div> : null}
        </Form.Field>
        <div className="announcement-create__preview">
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
          </div>
          <aside>{thumbs()}</aside>
        </div>
        {error ? <Message negative>{error}</Message> : null}
        <div className={classes}>
          {announcement ? (
            <Fragment>
              <div className="announcement-create__upload-wrapper">
                <Button onClick={open} className="btn-upload-announcement">
                  <FormattedMessage id="announcement.add-form.btn.update.upload" />
                </Button>
              </div>
              <Button
                primary
                loading={update.loading}
                disabled={update.loading}
                onClick={handleSubmit(e => updateAnnouncement(e, announcement))}
                type="submit"
                className="btn-update-announcement"
              >
                <FormattedMessage id="announcement.add-form.btn.update" />
              </Button>
              <Button onClick={closeModal} type="submit" className="btn-update-announcement">
                <FormattedMessage id="announcement.add-form.btn.cancel" />
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button onClick={open} className="btn-upload-announcement">
                <FormattedMessage id="announcement.add-form.btn.upload" />
              </Button>
              <Button
                primary
                loading={createAnnouncement.loading}
                disabled={createAnnouncement.loading}
                onClick={handleSubmit(addAnnouncement)}
                type="submit"
                className="btn-add-announcement"
              >
                <FormattedMessage id="announcement.add-form.btn.create" />
              </Button>
            </Fragment>
          )}
        </div>
      </Form>
    </div>
  ) : null;
});

AnnouncementAddForm.propTypes = {
  eventDetails: PropTypes.objectOf(PropTypes.any),
};

export default injectIntl(AnnouncementAddForm);
