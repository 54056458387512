import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useHistory, useLocation} from 'react-router-dom';
import {Dimmer, Loader, Message, Segment} from 'semantic-ui-react';
import {FormattedMessage, useIntl} from 'react-intl';
import NotificationAlert from '../../components/notifications-alert/NotificationAlert';
import {AppContext} from '../../store';
import Header from '../../components/header/Header';
import Footer from '../../components/shared/Footer';
import searchParse from '../../utils/searchParse';
import './ConfirmEmail.scss';

const ConfirmEmail = observer(() => {
  const {userStore} = React.useContext(AppContext);
  const {newEmail} = userStore;
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState(null);
  const intl = useIntl();

  useEffect(() => {
    const {email, code} = searchParse(location.search);

    if (code) {
      setLoading(true);
      userStore
        .confirmConnectNewEmail(code, email)
        .then(() => {
          setLoading(false);
          NotificationAlert.success(intl.formatMessage({id: 'confirm.email.success'}));
          history.push(`/event/list`);
        })
        .catch(err => {
          setLoading(false);
          setError(err.message);
        });
    } else {
      history.push(`/event/list`);
    }
  }, [location]);

  const content = () => {
    if (loading) {
      return (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        </Segment>
      );
    }
    if (error) {
      return <Message error>{error}</Message>;
    }
    return null;
  };

  return (
    <div className="main main--confirm">
      <Header />
      <div className="container">
        <div className="main-confirm">
          <div className="main-confirm__content">
            <h3>
              <FormattedMessage
                id="confirm.email.content"
                values={{
                  newEmail,
                }}
              />
            </h3>
            {content()}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
});

export default ConfirmEmail;
