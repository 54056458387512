import React from 'react';
import {observer} from 'mobx-react-lite';
import PreRegistrationForm from '../../components/auth/PreRegistrationForm';

const PreRegistrationFormPage = observer(() => {
  return (
    <div className="registration-form-page">
      <PreRegistrationForm />
    </div>
  );
});

export default PreRegistrationFormPage;
