import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button} from 'semantic-ui-react';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import InvitationApi from '../../../services/api/invitationApi';
import AttendeePreview from '../../attendee/AttendeePreview';
import AttendeeOpenChatBtn from '../../attendee/AttendeeOpenChatBtn';
import InvitationHeaderCard from '../InvitationHeaderCard';
import InvitationDateSection from '../InvitationDateSection';
import './InvitationCardSent.scss';

const InvitationCardSent = observer(props => {
  const {invitation} = props;
  const {userStore, eventStore, modalStore, invitationStore} = React.useContext(AppContext);
  const {user} = userStore;
  const isRescheduled = invitation && invitation.lastInvitationDetails.type === 'reschedule';
  const {eventList} = eventStore;
  const eventDetails = eventList.filter(e => e.id === invitation.eid)[0];
  const [loadingRevoke, setLoadingRevoke] = React.useState(false);

  const classes = classNames('invitation-card invitation-card--sent', {
    'invitation-card--reschedule': isRescheduled,
    'invitation-card--past': invitation?.status === 'past',
  });

  return invitation ? (
    <div className={classes}>
      <InvitationHeaderCard invitation={invitation} type="sent" />
      <InvitationDateSection invitation={invitation} />
      <div className="invitation-card__top">
        <AttendeePreview attendee={invitation.opponentParticipant} />
      </div>
      <div className="invitation-card__bottom-sent">
        <AttendeePreview attendee={invitation.opponentParticipantReceived} />
        <div
          className="invitation-card__message"
          dangerouslySetInnerHTML={{__html: invitation.lastInvitationDetails?.message?.replaceAll('\n', '<br>')}}
        ></div>
      </div>
      {invitation.status === 'live' ? (
        <div className="invitation-card__action">
          <Button
            className="attendee-card__btn attendee-card__btn--revoke"
            color="red"
            loading={loadingRevoke}
            onClick={() => {
              setLoadingRevoke(true);
              let res;
              if (isRescheduled) {
                res = InvitationApi.revokeRescheduleInvitation(
                  user.id,
                  eventDetails.id,
                  invitation.lastInvitationDetails.id,
                );
              } else {
                res = InvitationApi.revokeInvitation(
                  user.id,
                  invitation.lastInvitationDetails.eid,
                  invitation.lastInvitationDetails.id,
                );
              }
              res.then(() => {
                modalStore.close('attendeeMeet');
                setLoadingRevoke(false);
              });
            }}
          >
            <FormattedMessage id="invite.card.sent.btn.revoke" />
          </Button>
          <AttendeeOpenChatBtn
            color="blue"
            basic
            attendee={invitation.opponentParticipant}
            openCallback={() => {
              invitationStore.showInvitation(false);
            }}
          />
          {/*
          <Button className="attendee-card__btn attendee-card__btn--edit" basic color="blue">
            <FormattedMessage id="invite.card.sent.btn.edit" />
          </Button>
          */}
        </div>
      ) : null}
    </div>
  ) : null;
});

InvitationCardSent.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.object]),
  past: PropTypes.bool,
};

InvitationCardSent.defaultProps = {
  past: false,
};

export default injectIntl(InvitationCardSent);
