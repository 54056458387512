import React from 'react';
import PropTypes from 'prop-types';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './ImageLazy.scss';

const ImageLazy = props => {
  const {src, errorSrc, className, alt, width, height, visibleByDefault} = props;

  return (
    <LazyLoadImage
      className={className}
      width={width}
      height={height}
      src={src || errorSrc}
      alt={alt}
      title={alt}
      visibleByDefault={visibleByDefault}
      onClick={props.onClick}
      onError={e => {
        e.target.src = errorSrc;
      }}
    />
  );
};

ImageLazy.propTypes = {
  src: PropTypes.string.isRequired,
  errorSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  visibleByDefault: PropTypes.bool,
};

ImageLazy.defaultProps = {
  onClick: () => {},
  className: '',
  alt: '',
  height: null,
  width: null,
  visibleByDefault: false,
};

export default ImageLazy;
