export const PreRegistrationField = [
  {
    type: 'input',
    fieldName: 'name',
    placeholder: 'John',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'surname',
    placeholder: 'Doe',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'email',
    placeholder: 'user@email.com',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'phone',
    placeholder: '+11111111',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'company',
    placeholder: 'My company',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'jobTitle',
    placeholder: 'Job title',
    required: true,
  },
];
