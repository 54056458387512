import firebase from 'firebase/app';
import 'firebase/database';

export const updateMessage = (roomId, messageId, type = null, msg = null) => {
  const ref = firebase.database().ref(`rooms/${roomId}/messages/${messageId}`);

  return ref
    .update({type, msg})
    .then(r => r)
    .catch(e => {
      console.log('e', e);
    });
};
