// eslint-disable-next-line max-classes-per-file
import {observable, runInAction} from 'mobx';

export class StorageEntry {
  @observable.shallow data;
  invalidated = true;

  set(value) {
    runInAction(() => {
      this.data = value;
    });
  }

  remove() {
    runInAction(() => {
      this.data = null;
    });
  }
}

export class CacheService {
  storage = {};

  clearAll() {
    for (const keys in this.storage) {
      const res = this.storage[keys];
      setTimeout(() => {
        res.remove();
      }, 500);
    }
    this.storage = {};
  }

  get(key) {
    const keyStr = this.getkeyStr(key);
    return this.storage[keyStr].data;
  }

  getOrCreate(key, func, invalidate = false) {
    const keyStr = this.getkeyStr(key);
    let entry = this.storage[keyStr];

    if (entry == null) {
      entry = new StorageEntry();
      this.storage[keyStr] = entry;
    } else if (invalidate) {
      entry.invalidated = true;
    }
    if (entry.invalidated) {
      entry.invalidated = false;
      return func(key, entry);
    }
    return Promise.resolve(entry.data);
  }

  // eslint-disable-next-line class-methods-use-this
  getkeyStr(key) {
    let keyStr;
    if (key instanceof Array) {
      keyStr = key.join('_');
    } else {
      keyStr = `${key}`;
    }
    return keyStr;
  }

  set(key, value) {
    const keyStr = this.getkeyStr(key);
    let data = this.storage[keyStr];
    if (data == null) {
      data = new StorageEntry();
      this.storage[keyStr] = data;
    }
    data.data = value;
  }
}

class ServiceCacheService {
  caches = [];

  destroyAll() {
    this.caches.forEach(val => {
      val.clearAll();
    });
  }

  create() {
    const sc = new CacheService();
    this.caches.push(sc);
    return sc;
  }
}

export default new ServiceCacheService();
