import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {injectIntl, FormattedMessage, useIntl} from 'react-intl';
import {Form, Input, Label, Checkbox, Dropdown, TextArea, Radio, Icon} from 'semantic-ui-react';
import {Controller} from 'react-hook-form';
import classNames from 'classnames';
import TimeService from '../../../services/core/time-service';
import HtmlEditor from '../../../ui/HtmlEditor';
import PhoneNumberField from '../../../ui/PhoneNumberField';
import {AppContext} from '../../../store';

const UniversalField = observer(props => {
  const {
    name,
    type,
    dataList,
    required,
    fields,
    className,
    multiSelect,
    requiredLabel,
    placeholder,
    maxSelectableOptionCount,
    ...fieldProps
  } = props;
  const {label, error, hint, disabled, hidden, ...inputProps} = fieldProps;
  const {modalStore} = React.useContext(AppContext);
  const [showPassword, setShowPassword] = React.useState(false);
  const intl = useIntl();

  if (hidden) {
    return null;
  }

  const getMessageTextById = (messageKey, messageValue, formatted = false) => {
    const id = messageKey + '.' + messageValue;

    if (intl.messages[id]) {
      return formatted ? id : intl.formatMessage({id: id});
    }
    return messageValue;
  };

  const fieldOptional = req => {
    if (req && requiredLabel) {
      return <span className="optional">(required)</span>;
    }
    if ((!req && requiredLabel) || (req && !requiredLabel)) {
      return '';
    }
    return <span className="optional">(optional)</span>;
  };

  if (type === 'checkbox') {
    return (
      <Form.Field className="field__checkbox" error={!!error} hint={hint} disabled={disabled}>
        <Controller
          name={name}
          render={({onChange: onValueChange}) => (
            <Checkbox
              label={
                <label>
                  <FormattedMessage
                    id={getMessageTextById('app.field', label, true)}
                    values={{
                      termsOfServiceLink: (
                        <span className="link" aria-hidden onClick={() => modalStore.open('termsModal')}>
                          {intl.formatMessage({id: 'app.field.terms'})}
                        </span>
                      ),
                      privacyPolicyLink: (
                        <span className="link" aria-hidden onClick={() => modalStore.open('privacyModal')}>
                          {intl.formatMessage({id: 'app.field.policy'})}
                        </span>
                      ),
                    }}
                  />
                </label>
              }
              onChange={(_, data) => onValueChange(data.checked)}
              {...inputProps}
            />
          )}
          rules={{
            required: {
              value: required,
              message: intl.formatMessage({id: 'validation.error.required'}),
            },
          }}
          defaultValue={props.defaultValue || false}
        />
      </Form.Field>
    );
  }

  if (name === 'timezone') {
    const [localTimezone, setLocalTimezone] = React.useState(props.defaultValue);

    return (
      <Form.Field className="field__select field__timezone" error={!!error} hint={hint} disabled={disabled}>
        {label ? (
          <label htmlFor={name}>
            {getMessageTextById('app.field', label)}
            {fieldOptional(required)}
          </label>
        ) : null}
        <Controller
          name={name}
          render={({onChange: onValueChange, ref}) => (
            <>
              <Dropdown
                ref={ref}
                selection
                search
                placeholder={getMessageTextById('app.field.placeholder', placeholder)}
                options={dataList}
                onChange={(_, data) => {
                  onValueChange(data.value);
                  setLocalTimezone(data.value);
                }}
                {...inputProps}
              />
              {!localTimezone ? (
                <span
                  className="field__timezone-helper"
                  aria-hidden
                  onClick={() => {
                    ref.current.state.value = TimeService.getMyLocalTimezone();
                    onValueChange(TimeService.getMyLocalTimezone());
                    setLocalTimezone(TimeService.getMyLocalTimezone());
                  }}
                >
                  (Suggestion: {TimeService.getMyLocalTimezone()} {TimeService.getMyLocalTimezoneOffset()})
                </span>
              ) : null}
            </>
          )}
          defaultValue={props.defaultValue || localTimezone}
        />
      </Form.Field>
    );
  }

  if (type === 'select') {
    return multiSelect ? (
      <Form.Field className="field__select" error={!!error} hint={hint} disabled={disabled}>
        {label ? (
          <label htmlFor={name}>
            {getMessageTextById('app.field', label)}
            {fieldOptional(required)}
          </label>
        ) : null}
        <Controller
          name={name}
          render={({onChange: onValueChange}) => (
            <Dropdown
              multiple
              search
              placeholder={getMessageTextById('app.field.placeholder', placeholder)}
              selection
              options={dataList}
              onChange={(_, data) => onValueChange(data.value)}
              {...inputProps}
            />
          )}
          rules={{
            validate: {
              value: value => {
                const isCategories = name.includes('categories');
                let val;

                if (isCategories) {
                  val = value && value.selectedCategoryIds;
                } else {
                  val = value;
                }
                if (val && val.length > maxSelectableOptionCount) {
                  return intl.formatMessage(
                    {id: 'validation.error.maxItemsLength'},
                    {number: maxSelectableOptionCount},
                  );
                }
                if (!required) {
                  return null;
                }
                if (!val) {
                  return intl.formatMessage({id: 'validation.error.required'});
                }
                return null;
              },
            },
          }}
          defaultValue={props.defaultValue ? [props.defaultValue] : null}
        />
        {error ? <div className="error">{error.message}</div> : null}
      </Form.Field>
    ) : (
      <Form.Field className="field__select" error={!!error} hint={hint} disabled={disabled}>
        {label ? (
          <label htmlFor={name}>
            {getMessageTextById('app.field', label)}
            {fieldOptional(required)}
          </label>
        ) : null}
        <Controller
          name={name}
          render={({onChange: onValueChange}) => (
            <Dropdown
              selection
              search
              placeholder={getMessageTextById('app.field.placeholder', placeholder)}
              options={dataList}
              onChange={(_, data) => onValueChange(data.value)}
              {...inputProps}
            />
          )}
          rules={{
            validate: {
              value: value => {
                let val;
                if (!required) {
                  return null;
                }
                if (name.includes('categories')) {
                  val = value && value.selectedCategoryIds;
                } else {
                  val = value;
                }
                if (val && val.length > 0) {
                  return null;
                }
                return intl.formatMessage({id: 'validation.error.required'});
              },
            },
          }}
          defaultValue={props.defaultValue}
        />
        {error ? <div className="error">{error.message}</div> : null}
      </Form.Field>
    );
  }

  if (type === 'textarea') {
    return (
      <Form.Field className="field__textarea" error={!!error} hint={hint} disabled={disabled}>
        {label ? (
          <label htmlFor={name}>
            {getMessageTextById('app.field', label)}
            {fieldOptional(required)}
          </label>
        ) : null}
        <Controller
          as={<TextArea />}
          name={name}
          rules={{
            required: {
              value: required,
              message: intl.formatMessage({id: 'validation.error.required'}, {name}),
              placeholder: getMessageTextById('app.field.placeholder', placeholder),
            },
          }}
          defaultValue={props.defaultValue}
          {...inputProps}
        />
        {error ? <div className="error">{error.message}</div> : null}
      </Form.Field>
    );
  }

  if (type === 'inputGroup') {
    return (
      <Form.Field
        className={`field__inputGroup ${className}`}
        error={!!error}
        hint={hint}
        disabled={disabled}
        required={required}
      >
        {label ? (
          <label htmlFor={name}>
            {getMessageTextById('app.field', label)}
            {fieldOptional(required)}
          </label>
        ) : null}
        <Form.Group grouped>
          {fields.map(field => (
            <Form.Field key={field.fieldName}>
              <Controller
                as={
                  <Input
                    labelPosition="left"
                    type="text"
                    placeholder={getMessageTextById('app.field.placeholder', field.placeholder)}
                  >
                    {<Icon name={field.icon} /> ? (
                      <Label basic>
                        <Icon name={field.icon} />
                      </Label>
                    ) : null}
                    <input />
                  </Input>
                }
                name={`${name}[${field.fieldName}]`}
                rules={{
                  required: {
                    value: field.required,
                    message: intl.formatMessage({id: 'validation.error.required'}, {name}),
                  },
                }}
                defaultValue={(props.defaultValue && props.defaultValue[field.fieldName]) || ''}
              />
              {error ? <div className="error">{error.message}</div> : null}
            </Form.Field>
          ))}
        </Form.Group>
      </Form.Field>
    );
  }

  if (type === 'radioGroup') {
    const [typeRadio, setTypeRadio] = React.useState(props.defaultValue);

    return (
      <Form.Field className="field__radioGroup" error={!!error} hint={hint} disabled={disabled} required={required}>
        {label ? (
          <label htmlFor={name}>
            {getMessageTextById('app.field', label)}
            {fieldOptional(required)}
          </label>
        ) : null}
        <Form.Group grouped>
          {fields.map(field => (
            <Controller
              key={field.label}
              render={({onChange: onValueChange}) => (
                <Form.Field>
                  <Radio
                    name={name}
                    label={field.label}
                    checked={typeRadio === field.value}
                    onChange={_ => {
                      onValueChange(field.value);
                      setTypeRadio(field.value);
                    }}
                    {...inputProps}
                  />
                </Form.Field>
              )}
              rules={{
                required: {
                  value: required,
                  message: intl.formatMessage({id: 'validation.error.required'}, {name}),
                },
              }}
              name={name}
              defaultValue={props.defaultValue || ''}
            />
          ))}
          {error ? <div className="error">{error.message}</div> : null}
        </Form.Group>
      </Form.Field>
    );
  }

  if (name === 'phone') {
    return (
      <PhoneNumberField
        name={name}
        label={getMessageTextById('app.field', label)}
        placeholder={getMessageTextById('app.field.placeholder', placeholder)}
        error={error}
        disabled={disabled}
        defaultValue={props.defaultValue}
        requiredLabel={requiredLabel}
        required={required}
      />
    );
  }

  if (name === 'password') {
    const classesPassword = classNames('link icon', {
      eye: showPassword,
      'eye slash': !showPassword,
    });

    const onTogglePassword = () => {
      setShowPassword(!showPassword);
    };

    return (
      <Form.Field error={!!error} hint={hint} disabled={disabled}>
        {label ? (
          <label htmlFor={name}>
            {getMessageTextById('app.field', label)}
            {fieldOptional(required)}
          </label>
        ) : null}
        <div className="ui left icon input">
          <i className="lock icon" />
          <Controller
            name={name}
            render={({onChange: onValueChange}) => (
              <Input
                name="password"
                placeholder={getMessageTextById('app.field.placeholder', placeholder)}
                className="password-input"
                type={showPassword ? 'text' : 'password'}
                onChange={(_, data) => onValueChange(data.value)}
                {...inputProps}
              />
            )}
            rules={{
              required: {
                value: required,
                message: intl.formatMessage({id: 'validation.error.required'}, {name: 'Password'}),
              },
              minLength: {
                value: 8,
                message: intl.formatMessage({id: 'validation.error.minlength'}, {number: 8}),
              },
            }}
            defaultValue={props.defaultValue}
          />
          <i aria-hidden="true" className={classesPassword} onClick={onTogglePassword} />
        </div>
        {error ? <div className="error">{error.message}</div> : null}
      </Form.Field>
    );
  }

  if (type === 'editor') {
    const [valueMde, setValueMde] = React.useState('');

    return (
      <Form.Field className="field__editor" error={!!error} hint={hint} disabled={disabled}>
        {label ? (
          <label htmlFor={name}>
            {getMessageTextById('app.field', label)}
            {fieldOptional(required)}
          </label>
        ) : null}
        <Controller
          name={name}
          render={({onChange: onValueChange}) => (
            <HtmlEditor
              value={valueMde || inputProps.defaultValue || ''}
              onChange={data => {
                setValueMde(data);
                onValueChange(data);
              }}
              placeholder={getMessageTextById('app.field.placeholder', placeholder)}
            />
          )}
          rules={{
            required: {
              value: required,
              message: intl.formatMessage({id: 'validation.error.required'}),
            },
          }}
          defaultValue={props.defaultValue || ''}
        />
        {error ? <div className="error">{error.message}</div> : null}
      </Form.Field>
    );
  }

  return (
    <Form.Field className="field__input" error={!!error} hint={hint} disabled={disabled}>
      {label ? (
        <label htmlFor={name}>
          {getMessageTextById('app.field', label)}
          {fieldOptional(required)}
        </label>
      ) : null}
      <Controller
        name={name}
        render={({onChange: onValueChange}) => (
          <Input
            onChange={(_, data) => onValueChange(data.value)}
            placeholder={getMessageTextById('app.field.placeholder', placeholder)}
            {...inputProps}
          />
        )}
        rules={{
          required: {
            value: required,
            message: intl.formatMessage({id: 'validation.error.required'}),
          },
        }}
        defaultValue={props.defaultValue}
      />
      {error ? <div className="error">{error.message}</div> : null}
    </Form.Field>
  );
});

UniversalField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  dataList: PropTypes.oneOfType([PropTypes.array]),
  shrink: PropTypes.bool,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  children: PropTypes.node,
  multiSelect: PropTypes.bool,
  requiredLabel: PropTypes.bool,
};

UniversalField.defaultProps = {
  multiSelect: false,
  requiredLabel: false,
};

export default injectIntl(UniversalField);
