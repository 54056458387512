import {observable, action, when} from 'mobx';
import * as Cookies from 'js-cookie';

export class UiStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    when(
      () => this.rootStore.windowSizeStore.windowWidth,
      () => {
        this.isMenuCollapsed =
          this.rootStore.windowSizeStore.windowSizes === 'tablet' ||
          this.rootStore.windowSizeStore.windowSizes === 'mobile';
      },
    );
  }

  @observable isOpenMobileMenu = false;
  @observable isMenuCollapsed = false;

  @observable profileSidebarShow = false;
  @observable profileSidebarTabIndex = 0;
  @observable pageTitle = false;
  @observable appNative = false;

  @action
  triggerMobileMenu() {
    this.isOpenMobileMenu = !this.isOpenMobileMenu;
  }

  @action
  closeMobileMenu() {
    this.isOpenMobileMenu = false;
  }

  @action
  triggerMainMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }

  @action
  showProfile(show, index = 0) {
    this.profileSidebarShow = show;
    this.profileSidebarTabIndex = index;
  }

  @action
  setPageTitle(title) {
    this.pageTitle = title;
  }

  @action
  setAppNative() {
    this.appNative = true;
    Cookies.set('cookie_consent_status', 'allow', {expires: 360});
    localStorage.setItem('appNative', true);
  }
}
