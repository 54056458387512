import htmlClass from './htmlClass';

export default function addMainClasses(stores) {
  const {userStore, windowSizeStore} = stores;
  const isMobile = windowSizeStore.windowSizes === 'mobile';
  const isTablet = windowSizeStore.windowSizes === 'tablet';
  const isDesktop = windowSizeStore.windowSizes === 'desktop';

  if (isMobile) {
    htmlClass.remove(document.body, 'tablet');
    htmlClass.remove(document.body, 'desktop');
    htmlClass.add(document.body, windowSizeStore.windowSizes);
  }
  if (isTablet) {
    htmlClass.remove(document.body, 'mobile');
    htmlClass.remove(document.body, 'desktop');
    htmlClass.add(document.body, windowSizeStore.windowSizes);
  }
  if (isDesktop) {
    htmlClass.remove(document.body, 'mobile');
    htmlClass.remove(document.body, 'tablet');
    htmlClass.add(document.body, windowSizeStore.windowSizes);
  }

  if (userStore.isUserLogged) {
    htmlClass.add(document.body, 'is-logged-in');
    htmlClass.remove(document.body, 'is-logged-out');
  } else {
    htmlClass.add(document.body, 'is-logged-out');
    htmlClass.remove(document.body, 'is-logged-in');
  }

  const {userAgent} = window.navigator;
  if (userAgent.match(/iPhone/i) || userAgent.match(/iPad/i)) {
    htmlClass.add(document.body, 'is-ios');
  } else {
    htmlClass.remove(document.body, 'is-ios');
  }

  return null;
}
