class CrispService {
  eventName;
  version;
  name;
  surname;
  email;

  setUsername(name, surname, email) {
    this.name = name;
    this.surname = surname;
    this.email = email;
    this.updateCrispUsername();
  }

  updateCrispUsername() {
    if (window.$crisp) {
      if (this.name != null) {
        if (this.eventName != null) {
          window.$crisp.push([
            'set',
            'user:nickname',
            // eslint-disable-next-line no-useless-concat
            `${this.name} ${this.surname} [${this.eventName}]` + ` [${this.version}]`,
          ]);
        } else {
          window.$crisp.push(['set', 'user:nickname', `${this.name} ${this.surname} [${this.version}]`]);
        }
        window.$crisp.push(['set', 'user:email', `${this.email}`]);
      }
    }
  }

  setEventName(eventName) {
    this.eventName = eventName;
    if (this.eventName != null) {
      if (window.$crisp) {
        window.$crisp.push(['set', 'session:data', [[['EventName', this.eventName]]]]);
      }
    }
    this.updateCrispUsername();
  }

  setVersion(version) {
    this.version = version;
    if (this.version != null) {
      if (window.$crisp) {
        window.$crisp.push(['set', 'session:data', [[['Version', this.version]]]]);
      }
    }
    this.updateCrispUsername();
  }
}

export default new CrispService();
