import firebase from 'firebase/app';
import differenceBy from 'lodash/differenceBy';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import conforms from 'lodash/conforms';
import escapeRegExp from 'lodash/escapeRegExp';
import last from 'lodash/last';
import {action, observable, extendObservable, runInAction, computed} from 'mobx';
import UtilsService from '../../../services/core/utils-service';
import TimeService from '../../../services/core/time-service';
import {CommandExecutor} from '../../../services/core/command-executor';
import ServiceCacheService from '../../../services/core/cache-service';
import {processInvitation} from '../../../services/helpers/invitationHelper';

export class InvitationStore {
  @observable.shallow invitations = [];
  @observable.shallow invitationMap = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.commandExecutor = new CommandExecutor();
    this.invitationCache = ServiceCacheService.create();

    extendObservable(this, {
      loadingActiveInvitations: false,
      loadedInvitations: false,
      filteredInvitations: [],
      filterCriteria: ['attendeeFullName', 'eventName', 'state'],
      filterOptions: {},
      filterValues: {},
      filters: {},
      countMeetingsByEvents: {},
      totalMeetingsForEvent: {},
      stepLoadPastInvitations: 5,
      loadingPastInvitations: false,
      typeCommandByUser: {},
      invitationSidebarShow: false,
    });
  }

  @action
  loadInvitationsForActiveEvents() {
    this.loadingActiveInvitations = true;
    const events = this.rootStore.eventStore.activeEvents;
    const invitationsObservables = [];

    events.forEach(event => {
      invitationsObservables.push(
        this.rootStore.participantStore
          .getParticipantDetails(event.participantId)
          .then(data =>
            this.rootStore.participantStore
              .getStates(event.id, event.participantId)
              .then(() => this.getInvitations(event, data)),
          ),
      );
    });

    if (events.length === 0) {
      this.loadingActiveInvitations = false;
      this.loadedInvitations = true;
      return Promise.resolve();
    }

    return Promise.all([Promise.all(invitationsObservables)])
      .then(results => {
        runInAction(() => {
          this.loadingActiveInvitations = false;
          this.loadedInvitations = true;
        });
        return results;
      })
      .catch(() => {
        runInAction(() => {
          this.loadingActiveInvitations = false;
          this.loadedInvitations = true;
        });
      });
  }

  @action
  loadInvitationsForPatsEvents() {
    this.loadingPastInvitations = true;
    const events = this.rootStore.eventStore.pastEvents;
    const invitationsObservables = [];

    events.slice(this.stepLoadPastInvitations - 5, this.stepLoadPastInvitations).forEach(event => {
      invitationsObservables.push(
        this.rootStore.participantStore
          .getParticipantDetails(event.participantId)
          .then(data =>
            this.rootStore.participantStore
              .getStates(event.id, event.participantId)
              .then(() => this.getInvitations(event, data)),
          ),
      );
    });

    if (events.length === 0) {
      this.loadingPastInvitations = false;
      this.loadedInvitations = true;
      return Promise.resolve();
    }

    return Promise.all([events, Promise.all(invitationsObservables)]).then(results => {
      runInAction(() => {
        this.stepLoadPastInvitations += 5;
        this.loadingPastInvitations = false;
        this.loadedInvitations = true;
      });
      return results;
    });
  }

  getInvitations(event, participantDetails) {
    return this.invitationCache.getOrCreate(event.id, (key, subject) =>
      this.getFromFirebase(event, participantDetails).then(data => {
        runInAction(() => subject.set(data));
        return data;
      }),
    );
  }

  getFromFirebase(event, participantDetails) {
    const poolId = UtilsService.getPoolId(event.id, participantDetails);
    const ref = firebase.database().ref(`/newEvents/${event.id}/invitations/${poolId}`);

    return new Promise((resolve, reject) => {
      ref.once(
        'value',
        res =>
          this.rootStore.eventStore.getEventDetails(event.id, true).then(eventDetails => {
            let invitations = res.val();

            // Set limit for 1-1meetings
            if (eventDetails.participantTypes[eventDetails.me.typeId]?.maxMeetingCount) {
              this.getCountMeetingsByEvents(invitations, event.id);
            }

            if (invitations) {
              const invList = Object.values(invitations);
              const invitationObservables = [];
              for (const inviteId in invitations) {
                const invite = invitations[inviteId];
                if (invite.id) {
                  invitationObservables.push(processInvitation(eventDetails, invList, invite, participantDetails));
                }
              }

              return Promise.all([Promise.all(invitationObservables)]).then(results => {
                runInAction(() => {
                  if (results[0]) {
                    if (this.invitations.length === 0) {
                      // eslint-disable-next-line prefer-destructuring
                      this.invitations = results[0];
                    } else {
                      this.invitations = this.invitations.concat(results[0]);
                    }
                  }

                  this.invitations.forEach(invite => {
                    this.invitationMap[invite.fpid] = invite;
                    this.invitationMap[invite.tpid] = invite;
                  });

                  if (this.invitations) {
                    this.loadingActiveInvitations = false;
                  }
                });
                return resolve(results[0]);
              });
            }
            return resolve([]);
          }),
        errorObject => {
          console.log('error', errorObject);
          return reject(errorObject);
        },
      );

      ref
        .orderByChild('cdt')
        .startAt(TimeService.serverNow)
        .on('child_added', newInvite => {
          // TODO important!!! Hotfix
          ref.once(
            'value',
            res =>
              this.rootStore.eventStore.getEventDetails(event.id, true).then(eventDetails => {
                let invitations = res.val();

                // Set limit for 1-1meetings
                if (eventDetails.participantTypes[eventDetails.me.typeId]?.maxMeetingCount) {
                  this.getCountMeetingsByEvents(invitations, event.id);
                }

                if (invitations) {
                  const invList = Object.values(invitations);
                  const invitationObservables = [];
                  for (const inviteId in invitations) {
                    const invite = invitations[inviteId];
                    if (invite.id) {
                      invitationObservables.push(processInvitation(eventDetails, invList, invite, participantDetails));
                    }
                  }

                  return Promise.all([Promise.all(invitationObservables)]).then(results => {
                    runInAction(() => {
                      if (results[0]) {
                        if (this.invitations.length === 0) {
                          // eslint-disable-next-line prefer-destructuring
                          this.invitations = results[0];
                        } else {
                          this.invitations = differenceBy(uniqBy(this.invitations, 'id'), results[0], 'id');
                          this.invitations = this.invitations.concat(results[0]);
                        }
                      }
                      this.setTypeCommandByUser(newInvite.val().oppId, null);

                      this.invitations.forEach(invite => {
                        this.invitationMap[invite.fpid] = invite;
                        this.invitationMap[invite.tpid] = invite;
                      });

                      if (this.invitations) {
                        this.loadingActiveInvitations = false;
                      }
                    });
                    return resolve(results[0]);
                  });
                }
                return resolve([]);
              }),
            errorObject => {
              console.log('error', errorObject);
              return reject(errorObject);
            },
          );
        });

      ref.on('child_removed', res => {
        runInAction(() => {
          this.invitations = differenceBy(uniqBy(this.invitations, 'id'), [res.val()], 'id');
        });
      });

      ref.on('child_changed', changeInvite => {
        if (
          !last(changeInvite.val().meetingDetails) ||
          last(changeInvite.val().meetingDetails).status === 'cancelled'
        ) {
          runInAction(() => {
            this.invitations = differenceBy(uniqBy(this.invitations, 'id'), [changeInvite.val()], 'id');
          });
          return this.rootStore.eventStore.getEventDetails(event.id, true).then(eventDetails => {
            processInvitation(eventDetails, this.invitations, changeInvite.val(), participantDetails).then(inv => {
              runInAction(() => {
                this.invitations.push(inv);
              });
            });
          });
        }
        this.rootStore.agendaStore.data.subscribePrivateMeeting(
          event.id,
          participantDetails.id,
          last(changeInvite.val().meetingDetails).sessionId,
          () => {
            runInAction(() => {
              this.invitations = differenceBy(this.invitations, [changeInvite.val()], 'id');
            });
            this.rootStore.eventStore.getEventDetails(event.id, true).then(eventDetails => {
              processInvitation(eventDetails, this.invitations, changeInvite.val(), participantDetails).then(inv => {
                runInAction(() => {
                  this.invitations.push(inv);
                });
              });
            });
          },
        );
      });
    });
  }

  // TODO need to improve
  @action
  removeInvitation(inv) {
    this.invitations = differenceBy(this.invitations, [inv], 'id');
  }

  @action
  clean() {
    this.invitations = [];
    this.invitationMap = {};
  }

  @action
  setOptionsForFilters(invitations) {
    if (Object.keys(invitations).length > 0) {
      Object.keys(invitations).forEach(id => {
        const invitation = invitations[id];

        this.filterCriteria.forEach(criteria => {
          if (invitation[criteria]) {
            if (!this.filterOptions[criteria]) {
              this.filterOptions[criteria] = [invitation[criteria]];
            } else if (!this.filterOptions[criteria].includes(invitation[criteria])) {
              this.filterOptions[criteria].push(invitation[criteria]);
            }
          }
        });
        if (!this.filterOptions.eventName.includes('All events')) {
          this.filterOptions.eventName.unshift('All events');
        }
      });
    }
  }

  @action
  applyFilter() {
    this.filteredInvitations = filter(this.invitations, conforms(this.filters));
  }

  @action
  filterExact(property, rule) {
    if (!rule) {
      this.removeFilter(property);
      return;
    }
    this.filters[property] = val => val === rule;
    this.filterValues[property] = rule;
    this.applyFilter();
  }

  @action
  filterArray(property, array) {
    if (!array) {
      this.removeFilter(property);
      return;
    }
    this.filters[property] = val => val.some(el => array.includes(el));
    this.filterValues[property] = array;
    this.applyFilter();
  }

  @action
  filterSearch(property, rule) {
    const re = new RegExp(escapeRegExp(rule), 'i');
    if (!rule) {
      this.removeFilter(property);
      return;
    }
    this.filters[property] = val => re.test(val);
    this.filterValues[property] = rule;
    this.applyFilter();
  }

  @computed
  get countFilters() {
    return Object.keys(this.filterValues).length;
  }

  @action
  removeFilter(property) {
    delete this.filters[property];
    delete this.filterValues[property];
    this[property] = null;
    this.applyFilter();
  }

  @action
  cleanFilter() {
    this.filters = {};
    this.filterOptions = {};
    this.filterValues = {};
  }

  @action
  getCountMeetingsByEvents(invitations, eventId) {
    let countReceived = 0;
    let countSent = 0;
    let countCompany = 0;

    this.countMeetingsByEvents[eventId] = {};
    this.countMeetingsByEvents[eventId].received = 0;
    this.countMeetingsByEvents[eventId].sent = 0;
    this.countMeetingsByEvents[eventId].company = 0;

    if (invitations && Object.keys(invitations).length > 0) {
      Object.keys(invitations).forEach(inv => {
        const invitation = invitations[inv];
        invitation.state = invitation.state == null ? 'waiting' : invitation.state;
        invitation.segment = invitation.sent ? 'sent' : 'received';

        // received
        if (
          invitation.type === 'join' &&
          invitation.eid === eventId &&
          (((invitation.edt == null || invitation.edt + 24 * 3600 * 1000 > TimeService.now()) &&
            invitation.state !== 'revoked' &&
            invitation.state !== 'waiting') ||
            (invitation.state != null && invitation.state !== 'waiting'))
        ) {
          countReceived += 1;
          this.countMeetingsByEvents[eventId].received = countReceived;
          return;
        }

        // sent
        if (
          invitation.segment === 'sent' &&
          invitation.eid === eventId &&
          (invitation.state == null || invitation.state === 'waiting') &&
          invitation.fpid != null &&
          invitation.tpid != null &&
          (invitation.edt == null || invitation.edt + 24 * 3600 * 1000 > Date.now())
        ) {
          countSent += 1;
          this.countMeetingsByEvents[eventId].sent = countSent;
          return;
        }
        if (
          invitation.type === 'join' &&
          invitation.eid === eventId &&
          (((invitation.edt == null || invitation.edt + 24 * 3600 * 1000 > TimeService.now()) &&
            invitation.state !== 'revoked' &&
            invitation.state !== 'waiting') ||
            (invitation.state != null && invitation.state !== 'waiting'))
        ) {
          countSent += 1;
          this.countMeetingsByEvents[eventId].sent = countSent;
          return;
        }

        // company
        if (invitation.segment === 'company' && invitation.eid === eventId) {
          countCompany += 1;
          this.countMeetingsByEvents[eventId].company = countCompany;
        }
      });
    }

    this.setLimitMeetingsByEvent(eventId);
    return this.countMeetingsByEvents;
  }

  @action
  setLimitMeetingsByEvent(eventId) {
    const countMeetings = this.countMeetingsByEvents[eventId];
    this.totalMeetingsForEvent[eventId] = countMeetings.sent + countMeetings.received;
  }

  @action
  setTypeCommandByUser(userId, value) {
    this.typeCommandByUser[userId] = value;
  }

  @action
  showInvitation(show) {
    this.invitationSidebarShow = show;
  }
}
