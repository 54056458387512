import {createIntl} from 'react-intl';
import messages from '../components/account/AccountNotificationSettings/AccountNotificationSettings.messages';

const intl = createIntl({
  locale: 'en',
});

export default [
  {
    name: 'participant_meet_invitation',
    channels: {email: true},
    label: intl.formatMessage(messages.accountConfNotification1),
  },
  {
    name: 'confirm_participant_invitation',
    channels: {email: true},
    label: intl.formatMessage(messages.accountConfNotification2),
  },
  {
    name: 'reject_participant_invite',
    channels: {email: true},
    label: intl.formatMessage(messages.accountConfNotification3),
  },
  {
    name: 'session_reschedule_invitation',
    channels: {email: true},
    label: intl.formatMessage(messages.accountConfNotification4),
  },
  {
    name: 'confirm_reschedule_invitation',
    channels: {email: true},
    label: intl.formatMessage(messages.accountConfNotification5),
  },
  {
    name: 'reject_invite_reschedule',
    channels: {email: true},
    label: intl.formatMessage(messages.accountConfNotification6),
  },
  {name: 'cancel_meeting', channels: {email: true}, label: intl.formatMessage(messages.accountConfNotification7)},
  {name: 'cancel_session', channels: {email: true}, label: intl.formatMessage(messages.accountConfNotification8)},
  {
    name: 'meeting_reminder',
    channels: {email: true, sms: true},
    label: intl.formatMessage(messages.accountConfNotification9),
  },
];
