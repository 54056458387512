import React from 'react';
import {observer} from 'mobx-react-lite';
import {injectIntl, useIntl} from 'react-intl';
import {Form, Button} from 'semantic-ui-react';
import Search from '../../../ui/Search';
import {AppContext} from '../../../store';
import {ReactComponent as Close} from '../../../assets/images/icons/close.svg';
import {ReactComponent as Filter} from '../../../assets/images/icons/filter.svg';
import './AgendaSearch.scss';

const AgendaSearch = observer(props => {
  const {isOpen} = props;
  const intl = useIntl();
  const {agendaStore} = React.useContext(AppContext);
  const {myAgendaList} = agendaStore.data;

  React.useEffect(() => {
    if (myAgendaList) {
      agendaStore.filter.setOptionsForFilters(myAgendaList.filter(el => el.type !== 'dealroom'));
    }
  }, [agendaStore.filter, myAgendaList]);

  return (
    <Form className="agenda-search">
      <Search
        placeholder={intl.formatMessage({id: 'global.search'})}
        onSearchChange={value => agendaStore.filter.filterSearch('name', value, true)}
      />
      <Button circular basic className="agenda-search__btn" icon onClick={props.collapseFilter}>
        {isOpen ? <Close style={{height: '15px', width: '15px'}} /> : <Filter />}
      </Button>
    </Form>
  );
});

export default injectIntl(AgendaSearch);
