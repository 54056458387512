import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Form, Message} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {useForm} from 'react-hook-form';
import {FormattedMessage, injectIntl} from 'react-intl';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import './UserEmailConnectForm.scss';

const UserEmailConnectForm = observer(props => {
  const {intl} = props;
  const {userStore} = React.useContext(AppContext);
  const {connectedEmails} = userStore;
  const {handleSubmit, register, errors} = useForm();
  const [loading, setLoading] = React.useState(false);

  // eslint-disable-next-line consistent-return
  const onSubmit = value => {
    setLoading(true);

    if (connectedEmails.includes(value.email)) {
      setLoading(false);
      return NotificationAlert.error(<FormattedMessage id="user.emails.error.email.connected" />);
    }
    userStore
      .connectNewEmail(value)
      .then(() => {
        NotificationAlert.success(<FormattedMessage id="user.emails.error.confirmation" />);
        setLoading(false);
      })
      .catch(err => {
        NotificationAlert.error(err.message);
        setLoading(false);
      });
  };

  return (
    <Form
      className="user-email-connect"
      error={!isEmpty(errors) || !!userStore.error}
      onSubmit={handleSubmit(onSubmit)}
    >
      <p>
        <FormattedMessage
          id="user.emails.text.emails.1"
          values={{
            br: <br />,
            strong: chunks => <strong>{chunks}</strong>,
          }}
        />
      </p>
      <Form.Field error={!!errors.email}>
        <input
          name="email"
          placeholder={intl.formatMessage({id: 'user.emails.placeholder.email'})}
          ref={register({
            required: intl.formatMessage({id: 'validation.error.required'}, {name: 'E-mail'}),
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: intl.formatMessage({id: 'validation.error.email'}),
            },
          })}
        />
        {errors.email ? <div className="error">{errors.email.message}</div> : null}
      </Form.Field>
      <Button color="teal" disabled={loading} loading={loading}>
        <FormattedMessage id="user.emails.btn.add" />
      </Button>
      <Message error>{userStore.error ? userStore.error : null}</Message>
    </Form>
  );
});

export default injectIntl(UserEmailConnectForm);
