import {useEffect} from 'react';

export function useEventOutsideElement(ref, handler, events = [], exclusions = () => {}) {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || exclusions(event)) {
        return;
      }
      handler(event);
    };

    events.forEach(event => {
      document.addEventListener(event, listener);
    });

    return () => {
      events.forEach(event => {
        document.removeEventListener(event, listener);
      });
    };
  }, [ref, handler]);
}
