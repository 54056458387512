export const RegistrationFields = [
  {
    type: 'input',
    fieldName: 'name',
    placeholder: 'John',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'surname',
    placeholder: 'Doe',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'email',
    placeholder: 'user@email.com',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'password',
    placeholder: '8+ characters',
    required: true,
  },
  {
    type: 'checkbox',
    fieldName: 'consent',
    placeholder: '',
    required: true,
  },
];

export const TicketRegistrationFieldsTicket = [
  {
    type: 'input',
    fieldName: 'ticketRegistrationUsername',
    placeholder: 'Email or ticketId',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'name',
    placeholder: 'John',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'surname',
    placeholder: 'Doe',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'password',
    placeholder: '8+ characters',
    required: true,
  },
  {
    type: 'checkbox',
    fieldName: 'consent',
    placeholder: 'Email your ticket ID or your active email',
    required: true,
  },
];
