/* eslint class-methods-use-this: ["error", {"exceptMethods": ["tag", "untag", "boothsList", "markAsSeen", "checkProfileFields"]}] */
import {action, computed, extendObservable, runInAction} from 'mobx';
import firebase from 'firebase/app';
import 'firebase/database';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import Api from '../../../services/core/axios-service';
import {processParticipant, filterAttendees} from '../../../services/helpers/attendeesHelper';
import {processBooth} from '../../../services/helpers/expoHelper';
import UtilsService from '../../../services/core/utils-service';
import TimeService from '../../../services/core/time-service';
import ServiceCacheService from '../../../services/core/cache-service';
import {LocalStore} from '../../../services/core/storage-service';

// TODO need to improve

export class ParticipantStore {
  static PARTICIPANT_TABLE_RESERVER = (eventId)=>{
    if(eventId == null) eventId = "null"
    return {id:eventId+"tablereserver"}
  }
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.participantCache = ServiceCacheService.create();

    extendObservable(this, {
      participants: [],
      participant: null,
      participantData: null,
      matchesCount: 0,
      isLoading: false,
      participantsMap: {},
      offset: 0,
      query: '',
      length: null,
      initialLength: null,
      segment: 'all',
      tags: {},
      tagCount: null,
      tagCounts: {},
      orderBy: null,
      view: 'list',
      filters: [],
      defaultFilters: [],
      moreFilters: [],
      selectedFilters: {},
      selectedFiltersArray: [],
      hasFilters: false,
      latestQueryText: '',
      latestFilters: null,
      selectedMoreFilterCount: 0,
      states: {},
      statesByEvent: {},
      statesByEvents: {},
      session: null,
      availableSessions: null,
      availableSessionDayIndex: 0,
      selectedSessionId: null,
      selectedWizardStep: null,
      stepsForWizard: [],
      profileActionBeforeChange: {},
      categoriesList: [],
      selectedParticipantType: null,
      selectedCategories: {},
      preselectSelectWizardSteps: true,
      disabledToChangeParticipantType: false,
    });
  }

  @action
  getTags(eventId, participantId) {
    return new Promise(resolve => {
      firebase
        .database()
        .ref(`newEvents/${eventId}/participants/${participantId}/tags`)
        .on('value', data => {
          const tags = data.val();

          if (tags === null) {
            runInAction(() => {
              this.tags = {};
              this.tagCounts = {};
            });

            return resolve();
          }

          const modifiedTags = {};
          let tagCount = 1;

          for (const partId in tags) {
            if (Object.prototype.hasOwnProperty.call(tags, partId)) {
              const ptagsmap = {};
              const ptags = tags[partId];
              tagCount += 1;
              for (const ind in ptags) {
                if (Object.prototype.hasOwnProperty.call(ptags, ind)) {
                  ptagsmap[ptags[ind]] = true;
                }
              }
              modifiedTags[partId] = ptagsmap;
            }
          }
          runInAction(() => {
            this.tagCounts = {};
          });

          // TODO
          for (const pId in modifiedTags) {
            if (Object.prototype.hasOwnProperty.call(modifiedTags, pId)) {
              tagCount += 1;
              // eslint-disable-next-line no-shadow
              const tags = modifiedTags[pId];
              if (tags) {
                for (const tag in tags) {
                  if (Object.prototype.hasOwnProperty.call(tags, tag)) {
                    if (tags[tag]) {
                      let count = this.tagCounts[tag];
                      count = count == null ? 0 : count;
                      runInAction(() => {
                        this.tagCounts[tag] = count + 1;
                      });
                    }
                  }
                }
              }
            }
          }

          runInAction(() => {
            this.tags = modifiedTags;
            this.tagCount = tagCount;
          });

          return resolve(modifiedTags);
        });
    });
  }

  @action
  setQuery(queryParams, search) {
    return new Promise(resolve => {
      if (queryParams.query === undefined || queryParams.query === null || queryParams.query.trim() === '') {
        this.query = '*:*';
      } else {
        this.query = queryParams.query;
      }
      if (queryParams.filters != null && queryParams.filters.length > 0) {
        const filtersQueries = [];
        queryParams.filters.forEach(filter => {
          const allSubFilters = [];

          for (const subItemId in filter.items) {
            allSubFilters.push(subItemId);
          }
          if (allSubFilters.length > 0) {
            let filterQuery = '';
            if (filter.groupId === 'participantPresent') {
              filterQuery = `present:("${allSubFilters.join('" OR "')}")`;
            } else if (filter.groupId === 'participantType') {
              filterQuery = `typeId.exact:("${allSubFilters.join('" OR "')}")`;
            } else {
              filterQuery = `categories.selectedCategoryIds.exact:("${allSubFilters.join('" OR "')}")`;
            }
            filtersQueries.push(filterQuery);
          }
        });

        if (filtersQueries.length > 0) {
          this.query = `(${this.query}) AND (${filtersQueries.join(') AND (')})`;
        }
      }
      this.query = this.query.trim();
      if (this.query.length == 0) {
        this.query = '*:*';
      }
      let userQuery = this.rootStore.userStore.user.query;
      this.length = queryParams.length ? queryParams.length : 60;
      this.initialLength = queryParams.initialLength ? queryParams.initialLength : 60;

      this.query =
        userQuery && userQuery.length > 0 ? `((${userQuery}) AND ${this.query}) OR (${this.query})` : `(${this.query})`;
      this.asc = queryParams.asc;
      search ? (this.rootStore.agendaStore.data.participants = []) : (this.participants = []);
      this.orderBy = queryParams.orderBy;
      this.loading = false;
      this.offset = 0;
      return resolve();
    });
  }

  @action
  getParticipants(eDetails, update, search) {
    this.isLoading = !update;
    const eventDetails = eDetails || this.rootStore.eventStore.eventDetails;
    const me = eDetails?.me || this.rootStore.eventStore.meParticipant;

    const params = {
      eventId: eventDetails.id,
      query: this.query,
      eventKey: eventDetails.eventKey,
      length: this.offset === 0 ? this.initialLength : this.length,
      after: this.offset,
    };

    if (eventDetails.restricted) {
      params.restricted = eventDetails.restricted;
      params.organizer = me.organizer;
      params.segmentationQuery = eventDetails.segmentationQuery;
    } else if (me.organizer === true) {
      params.organizer = me.organizer;
    }
    if (this.orderBy) {
      params.orderBy = this.orderBy;
    }
    if (this.asc) {
      params.asc = this.asc;
    }

    return Api.post('event/participant/search2', params).then(
      resp => {
        runInAction(() => {
          const participants = resp.data;
          this.isLoading = false;
          this.matchesCount = resp.data.matches;
          const {props} = participants;
          const allPartsFromServer = participants.results;
          const participantsObjects = [];

          for (const k in allPartsFromServer) {
            const participant = {};
            const partArrayEntry = allPartsFromServer[k];
            for (let i = 0; i < props.length; i++) {
              participant[props[i]] = partArrayEntry[i];
            }
            if (participant.profilePhoto) {
              participant.profilePhoto = UtilsService.fixPublicBucketUrl(participant.profilePhoto);
            }

            if (participant.some != null) {
              const some = {};

              for (const key in participant.some) {
                const tag = participant.some[key];
                some[key] = UtilsService.getSomeLink(key, tag);
              }
              participant.someLinks = some;
            }

            participant.fullName = `${participant.name} ${participant.surname}`;
            participant.eventId = eventDetails.id;
            participant.website = UtilsService.getUrlHttpPref(participant.website ? participant.website.trim() : '');
            participant.websiteDisplay = UtilsService.getDomain(participant.website);
            participant.canInvite =
              me.organizer ||
              !eventDetails.restricted ||
              (eventDetails.restricted &&
                me.participantTypeConf != null &&
                me.participantTypeConf.invitationRights != null &&
                me.participantTypeConf.invitationRights[participant.typeId]);

            if (this.participantsMap[participant.id] == null) {
              this.participantsMap[participant.id] = participant;
            }
            if (participant.typeId) {
              const typeObject = eventDetails.participantTypes[participant.typeId];
              if (typeObject) {
                participant.participantType = typeObject.texts[eventDetails.defaultLanguage];
                if (participant.participantType === null) {
                  // eslint-disable-next-line prefer-destructuring
                  participant.participantType = Object.values(typeObject.texts)[0];
                }
                participant.participantTypeConf = typeObject;
              }
            }

            this.offset += 1;
            participant.state !== 'deleted' && participantsObjects.push(participant);
          }

          if (search) {
            this.rootStore.agendaStore.data.participants =
              this.rootStore.agendaStore.data.participants.concat(participantsObjects);
          } else {
            this.participants = this.participants.concat(participantsObjects);
          }
        });

        return Promise.resolve(search ? this.rootStore.agendaStore.data.participants : this.participants);
      },
      err => {
        this.rootStore.errorStore.addError(err);
        runInAction(() => {
          this.isLoading = false;
        });

        return Promise.reject();
      },
    );
  }

  @computed
  get hasMore() {
    return this.matchesCount < 0 || this.matchesCount > this.offset;
  }

  @action
  loadParticipants(eventDetails, queryParams) {
    return this.rootStore.userStore.setQueryUser(eventDetails, eventDetails.me).then(() =>
      this.getTags(eventDetails.id, eventDetails.me.id).then(() => {
        const query = queryParams || {query: '*:*', initialLength: this.tagCount + 60, length: 60};
        this.setQuery(query);
        return this.getParticipants(eventDetails);
      }),
    );
  }

  @action
  selectSegment(segmentP, eventId) {
    this.segment = segmentP;
    this.search({query: this.latestQueryText, filters: this.latestFilters}, eventId);
  }

  @action
  selectSort(sortField) {
    this.selectedSort = sortField;
    this.search({query: this.latestQueryText, filters: this.latestFilters});
  }

  @action
  selectView(view) {
    this.view = view;
  }

  @action
  search(event, eventId, search = false) {
    this.latestFilters = event.filters ? event.filters : null;
    let filters = event.filters ? event.filters : null;

    let stateQuery = null;
    if (filters) {
      let stateFilter = filters.filter(filterItem => filterItem.groupId === 'contactState');
      filters = filters.filter(filterItem => filterItem.groupId !== 'contactState');
      if (stateFilter.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        stateFilter = stateFilter[0];
        const allSubFilters = [];
        for (const subItemId in stateFilter.items) {
          const partsToBeIgnored = [];
          if (subItemId === 'contactStateFresh') {
            for (const partId in this.states) {
              if (this.states[partId] != null && this.states[partId] !== 'none') {
                partsToBeIgnored.push(partId);
              }
            }
          } else if (subItemId === 'contactStateAccepted') {
            for (const partId in this.states) {
              if (this.states[partId] === 'meeting' || this.states[partId] === 'reschedule') {
                partsToBeIgnored.push(partId);
              }
            }
          } else if (subItemId === 'contactStateRejected') {
            for (const partId in this.states) {
              if (this.states[partId] === 'rejected') {
                partsToBeIgnored.push(partId);
              }
            }
          } else if (subItemId === 'contactStateReceived') {
            for (const invId in this.rootStore.invitationStore.invitations) {
              const inv = this.rootStore.invitationStore.invitations[invId];
              if (!inv.sent && inv.myInvitation) {
                partsToBeIgnored.push(inv.oppId);
              }
            }
          } else if (subItemId === 'contactStateSent') {
            for (const invId in this.rootStore.invitationStore.invitations) {
              const inv = this.rootStore.invitationStore.invitations[invId];
              if (inv.sent && inv.myInvitation && inv.status === 'live') {
                partsToBeIgnored.push(inv.oppId);
              }
            }
          }
          if (partsToBeIgnored.length === 0) {
            partsToBeIgnored.push('nonexistantpart');
          }
          let query = `(id:("${partsToBeIgnored.join('" OR "')}"))`;
          if (subItemId === 'fresh') {
            query = `(NOT${query})`;
          }
          allSubFilters.push(query);
        }
        if (allSubFilters.length > 0) {
          stateQuery = `(${allSubFilters.join(' OR ')})`;
        }
      }
    }
    this.latestQueryText = event.queryText;
    let query = event.queryText;
    if (this.segment !== 'all') {
      const tags = this.segment === 'meeting' || this.segment === 'invitation' ? this.connectionTags : this.tags;
      if (query === null || query === undefined) {
        query = '*:*';
      }
      const partIds = [];
      for (const partId in tags) {
        const tag = tags[partId];
        if (tag != null && tag[this.segment] === true) {
          partIds.push(partId);
        }
      }
      let partQuery = '';
      if (partIds.length > 0) {
        partQuery = partIds.join('" OR "');
        partQuery = `id:("${partQuery}")`;
      } else {
        partQuery = 'id:none';
      }
      query = `(${partQuery}) AND (${query})`;
    }
    const sortField =
      this.selectedSort === 'relevance' || this.selectedSort == null ? null : `${this.selectedSort}.exact`;
    let asc = false;
    if (sortField === 'name' || sortField === 'company') {
      asc = true;
    }
    if (query != null) {
      query += '*';
    }

    if (stateQuery != null) {
      if (query != null) {
        query = `(${query}) AND ${stateQuery}`;
      } else {
        query = stateQuery;
      }
    }

    const eventDetails = this.rootStore.eventStore.eventDetails;
    return this.setQuery({query, filters, orderBy: sortField, asc}, search).then(() =>
      this.rootStore.eventStore
        .getEventDetails(eventId || eventDetails.id, true)
        .then(eventDetails => this.getParticipants(eventDetails, search, search)),
    );
  }

  markAsIgnored(participant) {
    const eventId = this.rootStore.eventStore.eventDetails.id;
    const participantId = this.rootStore.eventStore.eventDetails.me.id;

    if (this.tags[participant.id] != null && this.tags[participant.id].ignored) {
      return this.untag(eventId, participantId, participant.id);
    }
    return this.tag(eventId, participantId, participant.id, 'ignored');
  }

  markAsFav(participant) {
    const eventId = this.rootStore.eventStore.eventDetails.id;
    const participantId = this.rootStore.eventStore.eventDetails.me.id;

    if (this.tags[participant.id] != null && this.tags[participant.id].fav) {
      return this.untag(eventId, participantId, participant.id);
    }
    return this.tag(eventId, participantId, participant.id, 'fav');
  }

  tag(eventId, participantId, taggedParticipantId, tag) {
    return firebase
      .database()
      .ref(`newEvents/${eventId}/participants/${participantId}/tags/${taggedParticipantId}`)
      .set([tag]);
  }

  untag(eventId, participantId, taggedParticipantId) {
    return firebase
      .database()
      .ref(`newEvents/${eventId}/participants/${participantId}/tags/${taggedParticipantId}`)
      .remove();
  }

  @action
  ngOnChanges() {
    this.defaultFilters = [];
    this.moreFilters = [];
    let filters1 = this.filters.filter(ent => ent.order != null);
    let filters2 = this.filters.filter(ent => ent.order == null);
    filters1 = filters1.sort((o1, o2) => (o1.order > o2.order ? 1 : -1));
    filters2 = filters2.sort((o1, o2) => (`${o1.label}`.toLowerCase() > `${o2.label}`.toLowerCase() ? 1 : -1));
    this.filters = filters1.concat(filters2);
    this.filters.forEach(filter => {
      filter.items = filter.items.slice().sort((o1, o2) =>
        // eslint-disable-next-line no-nested-ternary
        o1.order ? (o1.order > o2.order ? 1 : -1) : `${o1.label}`.toLowerCase() > `${o2.label}`.toLowerCase() ? 1 : -1,
      );

      if (filter.default === true) {
        if (filter.items != null && filter.items.length > 0) {
          this.defaultFilters.push(filter);
        }
      } else if (filter.items != null && filter.items.length > 0) {
        this.moreFilters.push(filter);
      }
    });
  }

  @action
  initFilter(value) {
    this.filters = [];
    const pTypePresent = {id: 'participantPresent', default: false, order: 1, items: []};
    value.presentTypes.forEach(presentType => {
      pTypePresent.items.push({label: presentType, id: presentType});
    });
    this.filters.push(pTypePresent);

    const pTypeFilter = {id: 'participantType', default: false, order: 1, items: []};
    for (const partTypeId in value.participantTypes) {
      const participantType = value.participantTypes[partTypeId];
      pTypeFilter.items.push({label: participantType.texts[value.defaultLanguage], id: partTypeId});
    }
    this.filters.push(pTypeFilter);

    const pStateFilter = {
      id: 'contactState',
      default: false,
      items: [
        {id: 'contactStateReceived', order: 1},
        {id: 'contactStateSent', order: 2},
        {id: 'contactStateAccepted', order: 3},
        {id: 'contactStateRejected', order: 4},
        {id: 'contactStateFresh', order: 5},
      ],
      order: 2,
    };
    this.filters.push(pStateFilter);

    const categoryFilters = [];
    for (const categoryId in value.categories) {
      const category = value.categories[categoryId];
      if (category.type === 'select' && category.searchable !== false && category.matchingCategoryId == null) {
        const catFilter = {
          label: category.texts.label[value.defaultLanguage] || category.name,
          id: categoryId,
          items: [],
        };

        for (const subCategoryId in category.subCategories) {
          const subCategory = category.subCategories[subCategoryId];
          catFilter.items.push({label: subCategory.texts[value.defaultLanguage], id: subCategoryId});
        }
        categoryFilters.push(catFilter);
      }
    }

    categoryFilters.sort((o1, o2) => (o1.label > o2.label ? 1 : -1));
    categoryFilters.forEach(item => this.filters.push(item));

    this.ngOnChanges();
  }

  @action
  updateMoreFilterCount() {
    const isMobile = this.rootStore.windowSizeStore.windowSizes === 'mobile';
    this.selectedMoreFilterCount = 0;
    this.moreFilters.forEach(filterConf => {
      const selConf = this.selectedFilters[filterConf.id];
      if (selConf != null) {
        if (selConf.id === 'contactState' && !isMobile) return;
        this.selectedMoreFilterCount += selConf.selectedCount;
      }
    });
  }

  @action
  emitQuery() {
    const queryFilters = [];

    for (const selectedFilterGroupId in this.selectedFilters) {
      const selectedFilterConfig = this.selectedFilters[selectedFilterGroupId];
      queryFilters.push({groupId: selectedFilterGroupId, items: selectedFilterConfig.items});
    }
    this.search({queryText: this.latestQueryText, filters: queryFilters});
  }

  @action
  setMultiFilter(event, data, filterConfig) {
    const lastValue = data.value[data.value.length - 1];
    let selectFilter = [];

    if (!lastValue) {
      const removeItem = Object.keys(this.selectedFilters[filterConfig.id].items);
      selectFilter = data.options.filter(o => o.key === removeItem[0]);
    } else if (
      this.selectedFilters[filterConfig.id] != null &&
      this.selectedFilters[filterConfig.id].items[lastValue]
    ) {
      const removeItem = difference(Object.keys(this.selectedFilters[filterConfig.id].items), data.value);
      selectFilter = data.options.filter(o => o.key === removeItem[0]);
    } else {
      selectFilter = data.options.filter(o => o.key === lastValue);
    }

    this.setFilter(event, filterConfig, {
      id: selectFilter[0].key,
      label: selectFilter[0].text,
    });
  }

  @action
  setFilter(event, filterGroup, filter) {
    let filterGroupConfig = this.selectedFilters[filterGroup.id];
    if (filterGroupConfig == null) {
      filterGroupConfig = {items: {}, text: '', label: filterGroup.label, id: filterGroup.id};
      this.selectedFilters[filterGroup.id] = filterGroupConfig;
      this.selectedFiltersArray.push(filterGroupConfig);
    }
    this.hasFilters = this.selectedFiltersArray.length > 0;
    if (filterGroupConfig.items[filter.id] != null) {
      delete filterGroupConfig.items[filter.id];
    } else {
      filterGroupConfig.items[filter.id] = filter;
    }
    const text = [];
    let selectedCount = 0;
    for (const itemId in filterGroupConfig.items) {
      const item = filterGroupConfig.items[itemId];
      text.push(item.label);
      selectedCount += 1;
    }
    filterGroupConfig.text = `${filterGroup.label} : ${text.join(', ')}`;
    filterGroupConfig.selectedCount = selectedCount;

    this.selectedFilters[filterGroup.id] = filterGroupConfig;

    this.emitQuery();
    this.updateMoreFilterCount();
    event.preventDefault();
  }

  @action
  clearFilter(filter) {
    this.removeFilter(filter.id);
    this.emitQuery();
  }

  @action
  clearMore() {
    this.moreFilters.forEach(filter => {
      this.removeFilter(filter.id);
    });
    this.updateMoreFilterCount();
    this.emitQuery();
  }

  @action
  clear() {
    this.selectedFilters = {};
    this.selectedFiltersArray = [];
    this.hasFilters = false;
  }

  @action
  removeFilter(id) {
    delete this.selectedFilters[id];
    this.selectedFiltersArray = this.selectedFiltersArray.filter(val => val.id !== id);
    this.hasFilters = this.selectedFiltersArray.length > 0;
  }

  /*
  async getParticipantDetails(participantId) {
    return this.participantCache.getOrCreate(participantId, (key, subject) =>
      firebase
        .database()
        .ref(`profiles/${participantId}/public`)
        .once('value')
        .then(details =>
          processParticipant(details.val()).then(data => {
            runInAction(() => {
              this.participantData = data;
              this.participantData.state = this.states[data.id];
              subject.set(details.val());
            });
            return Promise.resolve(data);
          }),
        )
        .catch(err => {
          console.log('err', err);
        }),
    );
  }
  */

  @action
  getParticipantDetails(participantId) {
    return firebase
      .database()
      .ref(`profiles/${participantId}/public`)
      .once('value')
      .then(details =>
        processParticipant(details.val()).then(data => {
          runInAction(() => {
            // TODO
            this.participantData = data;
            this.participantData.state = this.states[data.id];
          });
          return data;
        }),
      );
  }

  getStates(eventId, userId) {
    const ref = firebase.database().ref(`newEvents/${eventId}/states/${userId}`);

    return new Promise((resolve, reject) => {
      ref.on(
        'value',
        snapshot => {
          runInAction(() => {
            this.statesByEvent = [];
            this.statesByEvent = snapshot.val();
            this.statesByEvents[eventId] = this.statesByEvent;
            Object.keys(this.statesByEvents).forEach(s => {
              const statesEvent = this.statesByEvents[s];
              if (statesEvent) {
                Object.keys(statesEvent).forEach(state => {
                  this.states[state] = statesEvent[state];
                });
              }
            });
          });
          return resolve(snapshot.val());
        },
        errorObject => {
          this.rootStore.errorStore.addError(errorObject.code);
          return reject(errorObject.code);
        },
      );

      ref.on('child_removed', res => {
        runInAction(() => {
          delete this.states[res.key];
        });
      });

      ref.on('child_changed', res => {
        runInAction(() => {
          this.states[res.key] = res.val();
        });
      });
    });
  }

  getParticipantPublicSessions(eventId, participantId) {
    const participantDetails = this.participantData;
    if(participantId === ParticipantStore.PARTICIPANT_TABLE_RESERVER(eventId).id){
      return Promise.resolve({});
    }
    return firebase
      .database()
      .ref(`newEvents/${eventId}/public_schedules/${participantId}`)
      .once('value')
      .then(s =>
        this.rootStore.eventStore.getEventDetails(eventId, true).then(eventDetails => {
          const sessions = s.val();

          for (const sesId in sessions) {
            const ses = sessions[sesId];
            TimeService.sessionDatePopulate(eventDetails, participantDetails, ses);
          }

          return sessions;
        }),
      );
  }

  @action
  async availableSessionsForParticipants(participant1, participant2) {
    const availableSessions = await this.rootStore.eventStore
      .getEventDetails(participant1.eventId)
      .then(eventDetails => {
        if (participant1.boothId != null || participant2.boothId != null) {
          const seacrhedBootId = participant1.boothId != null ? participant1.boothId : participant2.boothId;
          return this.boothsList(participant1.eventId).then(booths => {
            const booth = booths.filter(val => val.id === seacrhedBootId);
            if (booth.length === 0) return console.error('No booth found');
            return this.getAvailableSessions(
              eventDetails,
              booth[0].ownerParticipantId,
              participant1,
              participant2,
            ).then(res => {
              if (res.noDealRoom) {
                return this.getAvailableSessions(eventDetails, eventDetails.organizer, participant1, participant2);
              } else {
                return res;
              }
            });
          });
        } else {
          return this.getAvailableSessions(eventDetails, eventDetails.organizer, participant1, participant2);
        }
      });
    runInAction(() => {
      this.availableSessions = availableSessions;
    });
  }

  // TODO will move in new store
  @action
  boothsList(eventId) {
    const ref = firebase.database().ref(`/newEvents/${eventId}/booths`);

    return new Promise(resolve => {
      ref.on('value', res => {
        const next = res.val();
        const booths = [];
        for (const boothId in next) {
          const boothDetails = next[boothId].details;
          booths.push(processBooth(eventId, boothDetails));
        }
        return resolve(booths);
      });
    });
  }

  @action
  getAvailableSessions(eventDetails, ownerParticiantId, participant1, participant2) {
    const participantId1 = participant1.id;
    const participantId2 = participant2.id;
    this.availableSessionDayIndex = 0;
    // eslint-disable-next-line
    const me = this;
    this.noOpponentSpace = false;
    this.noDealRoom = false;
    this.noSelfSpace = false;
    return Promise.all([
      me.getParticipantPublicSessions(eventDetails.id, participantId2),
      me.getParticipantPublicSessions(eventDetails.id, participantId1),
      this.rootStore.sessionsNewStore.data.getPublicSessions(eventDetails.id, ownerParticiantId),
    ]).then(results => {
      const opponetSesions = results[0];
      const mySesions = results[1];
      const publicDefinitions = results[2];
      const dealroomSessions = [];
      me.noOpponentSpace = false;
      me.noDealRoom = false;
      me.noSelfSpace = false;
      const availableSlots = [];
      const restrictedSessionIds = [];
      for (const defId in publicDefinitions) {
        const publicDef = publicDefinitions[defId];
        if (publicDef.type === 'dealroom') {
          if (
            publicDef.apid != null &&
            publicDef.apid.find(item => item === participant1.typeId || item === participant2.typeId)
          ) {
            restrictedSessionIds.push(defId);
          }
        }
      }

      for (const defId in publicDefinitions) {
        const publicDef = publicDefinitions[defId];
        if (publicDef.type === 'dealroom') {
          if (
            (restrictedSessionIds.length === 0 && (publicDef.apid == null || publicDef.apid.length === 0)) ||
            (restrictedSessionIds.length !== 0 && restrictedSessionIds.indexOf(defId) >= 0)
          ) {
            for (const subSesId in publicDef.ses) {
              if (this.session == null || this.session.id !== subSesId) {
                const subSes = publicDef.ses[subSesId];
                if (subSes.state === 'available' && subSes.startsAt > TimeService.now()) {
                  dealroomSessions.push(subSes);
                }
              }
            }
          }
        }
      }

      me.noDealRoom = dealroomSessions.length === 0;
      if (!me.noDealRoom) {
        const freeDealRoomFromOpponent = [];
        dealroomSessions.forEach(dealroomSession => {
          let collides = false;
          for (const oppSesId in opponetSesions) {
            const opponentSession = opponetSesions[oppSesId];

            if (
              TimeService.collides(opponentSession.sdt, opponentSession.edt, dealroomSession.sdt, dealroomSession.edt)
            ) {
              collides = true;
              break;
            }
          }
          if (!collides) {
            freeDealRoomFromOpponent.push(dealroomSession);
          }
        });

        me.noOpponentSpace = freeDealRoomFromOpponent.length === 0;
        if (!me.noOpponentSpace) {
          freeDealRoomFromOpponent.forEach(dealroomSession => {
            let collides = false;
            for (const mySessionId in mySesions) {
              const mySession = mySesions[mySessionId];

              if (TimeService.collides(mySession.sdt, mySession.edt, dealroomSession.sdt, dealroomSession.edt)) {
                collides = true;
                break;
              }
            }
            if (!collides) {
              availableSlots.push(dealroomSession);
            }
          });

          me.noSelfSpace = availableSlots.length === 0;
        }
      }

      availableSlots.sort((o1, o2) => (o1.sdt > o2.sdt ? 1 : -1));
      const slotsByDate = {};

      availableSlots.forEach(availableSlot => {
        const dateStr = availableSlot.sdts.date;
        let slots = slotsByDate[dateStr];
        if (slots == null) {
          slots = {date: dateStr, slots: new Set([])};
          slotsByDate[dateStr] = slots;
        }
        slots.slots.add(availableSlot);
      });

      return {
        availableSessions: Object.values(slotsByDate),
        days: Object.keys(slotsByDate),
        noDealRoom: this.noDealRoom,
        noOpponentSpace: this.noOpponentSpace,
        noSelfSpace: this.noSelfSpace,
      };
    });
  }

  @action
  selectMeetingDay(data) {
    this.availableSessionDayIndex = findIndex(this.availableSessions.availableSessions, d => d.date === data.value);
  }

  @action
  selectSession(selectedSession) {
    this.selectedSessionId = selectedSession.id;
  }

  @computed
  get filterList() {
    const {tags} = this;
    const {segment} = this;
    return filterAttendees(this.participants, {tags, segment});
  }

  markAsSeen(eventId, userId, otherPId) {
    firebase
      .database()
      .ref(`newEvents/${eventId}/profileStats/${userId}/${otherPId}/seen`)
      .set(true)
      .then(el => el)
      .catch(error => {
        console.log('error', error);
      });
  }

  @action
  checkProfileFields(eventDetails, profile) {
    let completedStepCount = 0;
    const nonCompletedSteps = [];
    completedStepCount = 0;

    if (profile.visibleSetByUser !== true && eventDetails.showVisibilityQuestion === true) {
      completedStepCount += 1;
      nonCompletedSteps.push('visibility');
    }
    if (profile.visible) {
      if (profile.company == null || profile.jobTitle == null || profile.company === '' || profile.jobTitle === '') {
        completedStepCount += 1;
        nonCompletedSteps.push('company');
      }
      if (!profile.offer || !profile.seek) {
        completedStepCount += 1;
        nonCompletedSteps.push('offerSeek');
      }
      if (eventDetails.participantTypesArray != null && eventDetails.participantTypesArray.length > 0) {
        if (
          eventDetails.participantTypeAtRegistration !== false &&
          (!profile.typeId || eventDetails.participantTypes[profile.typeId] == null)
        ) {
          completedStepCount += 1;
          nonCompletedSteps.push('participantType');
        }
        let hasCategories = false;
        if (eventDetails.categoriesArray != null) {
          const participantType = eventDetails.participantTypes[profile.typeId];
          eventDetails.categoriesArray.forEach(val => {
            if (
              val.visibleToAll ||
              val.visibleParticipants[profile.typeId] != null ||
              (participantType != null && participantType.categories[val.categoryId] != null)
            ) {
              hasCategories = true;
            }
          });
        }
        if (hasCategories) {
          let categoriesSpecified = false;
          for (const catId in profile.categories) {
            const selectedCategoryDet = profile.categories[catId];
            if (selectedCategoryDet.selectedCategoryIds != null && selectedCategoryDet.selectedCategoryIds.length > 0) {
              completedStepCount += 1;
              categoriesSpecified = true;
              break;
            }
          }

          if (!categoriesSpecified) {
            if (eventDetails.participantTypeAtRegistration !== false || profile.typeId != null) {
              if (
                nonCompletedSteps.indexOf('participantType') < 0 &&
                eventDetails.participantTypeAtRegistration !== false
              ) {
                nonCompletedSteps.push('participantType');
              }
              nonCompletedSteps.push('categories');
            }
          }
        } else {
          completedStepCount += 1;
        }
      }
    }

    return {completed: completedStepCount >= 5, nonCompletedSteps};
  }

  @action
  loadProfileData(eventDetails, currentParticipant) {
    this.loadParticipantProfileData(eventDetails);
    this.generateStepsForWizard(eventDetails);
    this.selectedWizardStep = this.stepsForWizard[0];

    return currentParticipant;
  }

  @action
  generateStepsForWizard(eventDetails) {
    const selectedParticipantType = eventDetails.participantTypes[eventDetails.me.typeId];
    const checkVisibleCategories = this.categoriesList.some(cat => !!cat.visible);
    const pTypes = Object.keys(eventDetails.participantTypes).filter(type => {
      // eslint-disable-next-line no-prototype-builtins
      if (eventDetails.participantTypes[type].hasOwnProperty('allowedToModify')) {
        return eventDetails.participantTypes[type].allowedToModify;
      }
      return true;
    });

    if (
      !isEmpty(eventDetails.categories) &&
      !isEmpty(eventDetails.participantTypes) &&
      (pTypes.length > 0 || selectedParticipantType) &&
      checkVisibleCategories
    ) {
      this.stepsForWizard = ['company', 'offerSeek', 'participantType', 'categories'];
    } else if (!isEmpty(eventDetails.categories) && isEmpty(eventDetails.participantTypes) && checkVisibleCategories) {
      this.stepsForWizard = ['company', 'offerSeek', 'categories'];
    } else if (!isEmpty(eventDetails.participantTypes) && (pTypes.length > 0 || selectedParticipantType)) {
      this.stepsForWizard = ['company', 'offerSeek', 'participantType'];
    } else {
      this.stepsForWizard = ['company', 'offerSeek'];
    }
  }

  @action.bound
  profileStepChanged(currentStep, nextStep, finish, compareData, history) {
    if (currentStep === 'participantType') {
      if (
        compareData.typeId == null &&
        (nextStep == null || this.stepsForWizard.indexOf(nextStep) > this.stepsForWizard.indexOf(currentStep))
      ) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Please select your participant type');
      }
    }
    if (currentStep === 'categories') {
      let {maxOptionCount, selectedCategoriesCount} = 0;
      const inValid = compareData.categories.some(cat => {
        if (cat.type === 'select') {
          selectedCategoriesCount = cat.selectedCategoryIds.length;
          maxOptionCount = Number(cat.maxSelectableOptionCount);
          return cat && cat.selectedCategoryIds && selectedCategoriesCount > maxOptionCount;
        }
        return false;
      });
      if (inValid) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(
          `You already added ${selectedCategoriesCount} options. Unfortunately the system is not allow you to add more than ${maxOptionCount} options.`,
        );
      }
    }

    const eventDetails = this.rootStore.eventStore.eventDetails;
    const lastStep = currentStep === this.stepsForWizard[this.stepsForWizard.length - 1];

    const setFilledStatus = eventDetails => {
      const filledEventProfileForEvents = LocalStore.getObject('filledEventProfileForEvents');
      const checkEventProfileIsFilled = Object.keys(filledEventProfileForEvents).some(
        eventId => eventId === eventDetails.id,
      );
      if (!checkEventProfileIsFilled) {
        LocalStore.addToObject('filledEventProfileForEvents', {[eventDetails.id]: TimeService.now()});
      }
    };

    if (
      eventDetails &&
      eventDetails.isFinished &&
      ((nextStep && lastStep) ||
        (nextStep && nextStep === this.stepsForWizard[this.stepsForWizard.indexOf(currentStep) + 1]))
    ) {
      this.setPreselectSelectWizardSteps(true);
      runInAction(() => {
        this.selectedWizardStep = nextStep || currentStep;
      });

      return Promise.resolve({});
    }
    if (eventDetails && eventDetails.isFinished && lastStep && !nextStep) {
      setFilledStatus(eventDetails);

      runInAction(() => {
        this.selectedWizardStep = nextStep || currentStep;
      });

      if (
        eventDetails.id === '05ffd47e-b4db-4f54-b0b6-a55d0605bbe7' ||
        eventDetails.id === '54b237f9-6bc3-42fc-800e-a2d46eace54e' ||
        eventDetails.id === 'abfdae24-05e1-470b-a032-93987d36d3a9' ||
        eventDetails.id === '79afb04c-9463-413e-a8dd-9e87fbdff66f' ||
        eventDetails.id === '5ec0f6a2-ab85-4cb7-9c49-b27af7fc43c7'
      ) {
        history.push(`/event/${eventDetails.id}/attendees`);
      } else if (eventDetails.id === '21e15529-927a-41f7-8ead-02ea01b92309') {
        history.push(`/event/${eventDetails.id}/sessions`);
      } else {
        history.push(`/event/${eventDetails.id}/` + eventDetails.indexPagePath);
      }

      return Promise.resolve({});
    }

    return this.saveAll(lastStep, compareData).then(next => {
      setTimeout(() => {
        if (finish && eventDetails) {
          setFilledStatus(eventDetails);
          if (
            eventDetails.id === '05ffd47e-b4db-4f54-b0b6-a55d0605bbe7' ||
            eventDetails.id === '54b237f9-6bc3-42fc-800e-a2d46eace54e' ||
            eventDetails.id === 'abfdae24-05e1-470b-a032-93987d36d3a9' ||
            eventDetails.id === '79afb04c-9463-413e-a8dd-9e87fbdff66f' ||
            eventDetails.id === '5ec0f6a2-ab85-4cb7-9c49-b27af7fc43c7'
          ) {
            history.push(`/event/${eventDetails.id}/attendees`);
          } else if (eventDetails.id === '21e15529-927a-41f7-8ead-02ea01b92309') {
            history.push(`/event/${eventDetails.id}/sessions`);
          } else {
            history.push(`/event/${eventDetails.id}/` + eventDetails.indexPagePath);
          }
        }
      });

      runInAction(() => {
        this.selectedWizardStep = nextStep || currentStep;
      });

      return next;
    });
  }

  @action
  saveAll(forced = false, pprofile) {
    if (!forced && UtilsService.checkAllSame(pprofile, this.profileactionBeforeChange)) {
      return Promise.resolve({});
    }
    return this.rootStore.eventStore.updateParticipantDetails(pprofile).then(() => {
      this.rootStore.userStore.getDetails(true);
    });
  }

  @action
  setParticipantType(typeId) {
    // if (!this.disabledToChangeParticipantType) {
    // eslint-disable-next-line no-underscore-dangle
    this._setParticipantType(typeId);
    // }
  }

  // eslint-disable-next-line no-underscore-dangle
  @action
  _setParticipantType(typeId) {
    if (typeId != null) {
      this.selectedParticipantType = this.rootStore.eventStore.eventDetails.participantTypes[typeId];
      this.categoriesList.forEach(val => {
        if (this.selectedParticipantType && this.selectedParticipantType.categories) {
          const category = this.selectedParticipantType.categories[val.categoryId];
          if (category && category.title && val.texts && val.texts.title) {
            val.texts.title.name = category.title;
          }

          if (category && category.isMulti) {
            val.isMulti = category.isMulti;
          }
        }
        val.visible =
          val.category.visibleToAll ||
          val.category.visibleParticipants[typeId] != null ||
          this.selectedParticipantType?.categories[val.category.categoryId];
      });
    } else {
      this.categoriesList.forEach(val => {
        val.visible = false;
      });
    }
  }

  @action
  loadParticipantProfileData(eventDetails) {
    const {meParticipant} = this.rootStore.eventStore;

    this.profileactionBeforeChange = {
      action: 'all',
      categories: meParticipant.categories || [],
      company: meParticipant.company || '',
      eventId: meParticipant.eventId,
      id: meParticipant.id,
      jobTitle: meParticipant.jobTitle || '',
      name: meParticipant.name,
      offer: meParticipant.offer || '',
      seek: meParticipant.seek || '',
      biography: meParticipant.biography || '',
      some: {
        facebook: meParticipant.some?.facebook || '',
        twitter: meParticipant.some?.twitter || '',
        instagram: meParticipant.some?.instagram || '',
        linkedin: meParticipant.some?.linkedin || '',
      },
      surname: meParticipant.surname,
      timezone: meParticipant.timezone,
      typeId: meParticipant.typeId,
      website: meParticipant.website || '',
      present: meParticipant.present || '',
    };

    this.categoriesList = [];

    this.disabledToChangeParticipantType =
      eventDetails.participantTypeAtRegistration === false ||
      (meParticipant.typeId != null &&
        eventDetails.participantTypes[meParticipant.typeId] != null &&
        eventDetails.participantTypes[meParticipant.typeId].allowedToModify === false);
    eventDetails.categoriesArray.forEach(val => {
      this.categoriesList.push({visible: false, category: UtilsService.cloneData(val)});
    });

    if (meParticipant.categories) {
      meParticipant.categories.forEach(category => {
        const ecategory = eventDetails.categories[category.categoryId];
        if (ecategory) {
          if (ecategory.type === 'select') {
            let selectedSubCts = [];

            if (category.selectedCategoryIds) {
              category.selectedCategoryIds.forEach(val => {
                const eventCategory = ecategory.subCategories[val];
                if (eventCategory) {
                  selectedSubCts.push(eventCategory.id);
                }
              });
            }
            if (ecategory.isMulti === false && selectedSubCts !== null && selectedSubCts.length > 0) {
              // eslint-disable-next-line prefer-destructuring
              selectedSubCts = selectedSubCts[0];
            }
            this.selectedCategories[category.categoryId] = {
              type: category.type,
              selectedCategoryIds: selectedSubCts,
              categoryId: category.categoryId,
            };
          } else {
            this.selectedCategories[category.categoryId] = {type: category.type, value: category.value};
          }
        }
      });
    }
    this.setParticipantType(meParticipant.typeId);
    this.setPreselectSelectWizardSteps(true);
    return this.profileactionBeforeChange;
  }

  @action
  resetWizardSteps() {
    // eslint-disable-next-line prefer-destructuring
    this.selectedWizardStep = this.stepsForWizard[0];
  }

  @action
  setPreselectSelectWizardSteps(value) {
    this.preselectSelectWizardSteps = value;
  }
}
