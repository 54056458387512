/* eslint class-methods-use-this: ["error", {"exceptMethods": ["pinRoom"]}] */
import {action, computed, extendObservable, runInAction} from 'mobx';
import firebase from 'firebase/app';
import filter from 'lodash/filter';
import values from 'lodash/values';
import conforms from 'lodash/conforms';
import escapeRegExp from 'lodash/escapeRegExp';
import NotificationAlert from '../../../components/notifications-alert/NotificationAlert';
import ChatApi from '../../../services/api/chatApi';
import {processParticipant} from '../../../services/helpers/attendeesHelper';
import TimeService from '../../../services/core/time-service';
import htmlClass from '../../../utils/htmlClass';

export const COUNT_MESSAGES = 20;

export class ChatStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    extendObservable(this, {
      userRooms: {},
      roomsDetails: {},
      selectedRoom: null,
      floatingSelectedRoom: null,
      allMembers: {},
      floatingOpen: false,
      isFloatingChat: false,
      loading: false,
      filteredRoom: [],
      filterCriteria: ['searchName'],
      filterOptions: {},
      filters: {},
      startAtNext: {},
      errorRoomLoad: false,
      errorRoomDetailsLoad: false,
    });
  }

  @action
  loadChatRooms(userId) {
    this.loading = true;
    const chatRoomsObservables = [];

    firebase
      .database()
      .ref(`/userRooms/${userId}`)
      .once('value')
      .then(
        snapshot => {
          if (snapshot.val()) {
            runInAction(() => {
              this.userRooms = snapshot.val();
              Object.keys(snapshot.val()).forEach(el => {
                chatRoomsObservables.push(this.getRoomsDetails(el, snapshot.val()[el]));
              });
            });
          }

          return Promise.all(chatRoomsObservables)
            .then(results => {
              runInAction(() => {
                this.loading = false;
                this.loaded = true;
              });
              setTimeout(() => this.setOptionsForFilters(results));
              return results;
            })
            .catch(e => {
              console.log('e', e);
              runInAction(() => {
                this.loading = false;
                this.loaded = true;
              });
            });
        },
        errorObject => {
          this.rootStore.errorStore.addError(errorObject.code);
          runInAction(() => {
            this.loading = false;
            this.loaded = true;
          });
        },
      )
      .catch(e => {
        runInAction(() => {
          this.errorRoomLoad = e;
        });
      });

    firebase
      .database()
      .ref(`/userRooms/${userId}`)
      .orderByChild('seen')
      .startAt(`${TimeService.now()}`)
      .on(
        'child_added',
        snapshot => {
          if (snapshot.val()) {
            runInAction(() => {
              this.userRooms[snapshot.key] = snapshot.val();
              setTimeout(() => {
                chatRoomsObservables.push(this.getRoomsDetails(snapshot.key, snapshot.val()));
              }, 1000);
            });
          }
        },
        errorObject => {
          runInAction(() => {
            this.errorRoomLoad = errorObject;
          });
        },
      );

    firebase
      .database()
      .ref(`/userRooms/${userId}`)
      .on('child_changed', snapshot => {
        runInAction(() => {
          this.roomsDetails[snapshot.key].pin = snapshot.val().pin;
        });
      });

    firebase
      .database()
      .ref(`/userRooms/${userId}`)
      .on(
        'child_removed',
        snapshot => {
          if (snapshot.val()) {
            runInAction(() => {
              Object.keys(this.roomsDetails).forEach(roomId => {
                if (roomId === snapshot.key) {
                  this.selectedRoom = null;
                  this.floatingSelectedRoom = null;
                  delete this.allMembers[userId];
                  delete this.allMembers[this.roomsDetails[roomId]['oppositeMember']['userId']];
                  delete this.allMembers[this.roomsDetails[roomId]['oppositeMember']['id']];
                  delete this.userRooms[roomId];
                  delete this.roomsDetails[roomId];
                }
              });
            });
          }
        },
        e => {
          runInAction(() => {
            this.errorRoomLoad = e;
          });
        },
      );
  }

  @action
  getRoomsDetails(roomId, details, firstRoomId) {
    const currentUserId = this.rootStore.userStore.user.id;
    const chatMembersObservables = [];
    let messagesRoom;
    const ref = firebase.database().ref(`rooms/${roomId}/meta`);

    return new Promise((resolve, reject) => {
      ref
        .once('value')
        .then(snapshot => {
          const roomDetails = snapshot.val();
          runInAction(() => {
            if (firstRoomId && firstRoomId !== roomId) {
              delete this.roomsDetails[firstRoomId];
            }
            if (!this.roomsDetails[roomId]) {
              this.roomsDetails[roomId] = roomDetails;
            }
            this.roomsDetails[roomId].pin = details.pin;
          });

          messagesRoom = this.getRoomsFirstMessages(roomId);

          Object.keys(roomDetails.members)
            .filter(id => id !== currentUserId)
            .forEach(el => {
              chatMembersObservables.push(this.getInterlocutorData(el, roomId));

              runInAction(() => {
                this.allMembers[el] = roomId;
              });
            });

          return Promise.all([chatMembersObservables, messagesRoom]).then(results => {
            runInAction(() => {
              // eslint-disable-next-line prefer-destructuring
              this.roomsDetails[roomId].messages = results[1];
            });
            this.computedUnreadMessagesByRoom(roomId);

            return resolve(snapshot.val());
          });
        })
        .catch(e => {
          runInAction(() => {
            console.log('e', e);
            this.errorRoomDetailsLoad = e;
          });
          return reject(e);
        });

      ref.on('child_changed', snapshot => {
        const data = snapshot.val();
        if (typeof data === 'object' && snapshot.hasChild(currentUserId) && data[currentUserId]['eventInfo']) {
          const interlocutorsArray = Object.keys(data).filter(member => member !== currentUserId);
          const interlocutor = interlocutorsArray.length === 1 ? interlocutorsArray[0] : '';

          runInAction(() => {
            this.roomsDetails[roomId]['members'][interlocutor]['eventInfo'] = data[interlocutor]['eventInfo'];
          });
        }
      });
    });
  }

  @action
  getRoomsFirstMessages(roomId) {
    return firebase
      .database()
      .ref(`rooms/${roomId}/messages`)
      .limitToLast(COUNT_MESSAGES)
      .once('value')
      .then(snapshot => {
        const messages = snapshot.val();
        if (snapshot.val()) {
          runInAction(() => {
            this.startAtNext[roomId] = messages[Object.keys(messages)[0]]?.dt;
          });
        }
        return messages;
      });
  }

  @action
  getRoomsNextMessages(roomId) {
    return firebase
      .database()
      .ref(`rooms/${roomId}/messages`)
      .orderByChild('dt')
      .endAt(this.startAtNext[roomId])
      .limitToLast(COUNT_MESSAGES)
      .once('value')
      .then(snapshot => {
        const newMessages = snapshot.val();
        runInAction(() => {
          this.startAtNext[roomId] = newMessages[Object.keys(newMessages)[0]]?.dt;
          this.roomsDetails[roomId].messages = Object.assign(newMessages, this.roomsDetails[roomId].messages);
          this.roomsDetails[roomId].loaded = Object.keys(snapshot.val()).length < COUNT_MESSAGES;
        });
        return newMessages;
      });
  }

  @action
  getInterlocutorData(participantId, roomId) {
    return firebase
      .database()
      .ref(`profiles/${participantId}/public`)
      .once('value')
      .then(details =>
        details.val()
          ? processParticipant(details.val()).then(data => {
              runInAction(() => {
                this.roomsDetails[roomId].members[participantId] = Object.assign(
                  this.roomsDetails[roomId].members[participantId],
                  data,
                );
                this.roomsDetails[roomId].searchName = data.fullName;
                if (!this.roomsDetails[roomId].oppositeMember) {
                  this.roomsDetails[roomId].oppositeMember = data;
                }
              });
              return data;
            })
          : null,
      );
  }

  @action
  createRoom(members, type, eventId, name) {
    return ChatApi.createRoom(members, type, eventId, name)
      .then(data => {
        if (data) {
          const roomId = data.data.object || data.data.data;
          const firstRoomId = `${members[1].id}-${this.rootStore.userStore.user.id}`;

          runInAction(() => {
            this.floatingSelectedRoom = roomId;
            this.allMembers[members[1].id] = roomId;
          });

          return this.getRoomsDetails(roomId, data.data, firstRoomId).then(() => {
            this.trackMessage(roomId, this.rootStore.userStore.user.id, eventId);
            return data;
          });
        }
        return null;
      })
      .catch(e => {
        NotificationAlert.error(e.message);
        this.rootStore.errorStore.addError(e);
        return e;
      });
  }

  @action
  addMembers(roomId, members) {
    ChatApi.addMembers(roomId, members)
      .then(() => {
        NotificationAlert.success('Success');
      })
      .catch(r => {
        NotificationAlert.error(r.message);
        this.rootStore.errorStore.addError(r);
      });
  }

  @action
  removeMembers(roomId, members) {
    ChatApi.removeMembers(roomId, members)
      .then(() => {
        NotificationAlert.success('Success');
      })
      .catch(r => {
        NotificationAlert.error(r.message);
        this.rootStore.errorStore.addError(r);
      });
  }

  @action
  writeMessageToRoom(roomId, message, eventDetails = null) {
    const keyNumber = TimeService.now() * 1000000 + (window.performance.now() % 1000000);
    const key = keyNumber.toString(16);
    message.msg = message.msg.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, '');

    return firebase
      .database()
      .ref(`/rooms/${roomId}/messages/${key}`)
      .set(message)
      .then(() => {
        this.setLastMessageDate(roomId, message);

        runInAction(() => {
          const room = this.roomsDetails[roomId];
          const meAttendee = eventDetails ? eventDetails.me : {};
          const opponentMember = room.members[this.rootStore.userStore.user.id];
          opponentMember.lastSeenMessage = key;
          const opponentEventInfo = opponentMember.eventInfo || {};
          const checkAttendeeId =
            meAttendee.id && (!opponentEventInfo.attendeeId || opponentEventInfo.attendeeId !== meAttendee.id);
          const checkEventId =
            meAttendee.id && (!opponentEventInfo.eventId || opponentEventInfo.eventId !== meAttendee.eventId);

          if (checkAttendeeId && checkEventId) {
            this.setEventInfoToMember(roomId, meAttendee);
          }
        });

        return roomId;
      })
      .catch(r => {
        NotificationAlert.error(r.message);
      });
  }

  @action
  cleanFirstMessage(roomId) {
    this.roomsDetails[roomId].firstMessage = false;
  }

  @action
  setLastMessageDate(roomId, message) {
    firebase
      .database()
      .ref(`/rooms/${roomId}/meta`)
      .child('lastMessageDate')
      .set(message.dt)
      .then(() => {
        runInAction(() => {
          this.roomsDetails[roomId].lastMessageDate = message.dt;
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  @action
  setEventInfoToMember(roomId, attendee) {
    attendee.userId = attendee.userId ? attendee.userId : attendee.id;

    firebase
      .database()
      .ref(`/rooms/${roomId}/meta/members/${attendee.userId}/eventInfo`)
      .set({attendeeId: attendee.id, eventId: attendee.eventId})
      .then(() => {
        runInAction(() => {
          this.roomsDetails[roomId]['members'][attendee.userId].eventInfo = {
            attendeeId: attendee.id,
            eventId: attendee.eventId,
          };
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  @action
  markLastSeenMessage(roomId, userId, messageId) {
    firebase
      .database()
      .ref(`rooms/${roomId}/meta/members/${userId}`)
      .child('lastSeenMessage')
      .set(messageId)
      .then(() => {
        runInAction(() => {
          this.roomsDetails[roomId].members[userId].lastSeenMessage = messageId;
        });
        this.computedUnreadMessagesByRoom(roomId);
      });
  }

  @action
  trackMessage(roomId, userId) {
    const ref = firebase.database().ref(`/rooms/${roomId}/messages`);

    ref
      .orderByChild('dt')
      .startAt(TimeService.now())
      .on('child_added', snapshot => {
        if (snapshot.val()) {
          this.setLastMessageDate(roomId, snapshot.val());
          runInAction(() => {
            if (this.roomsDetails[roomId].messages === undefined || this.roomsDetails[roomId].messages === null) {
              this.roomsDetails[roomId].messages = {};
              this.roomsDetails[roomId].messages[snapshot.key] = snapshot.val();
            } else {
              this.roomsDetails[roomId].messages[snapshot.key] = snapshot.val();
            }
          });
          if (userId !== snapshot.val().sndr) {
            this.computedUnreadMessagesByRoom(roomId);
          }
        }
      });

    ref.on('child_changed', snapshot => {
      runInAction(() => {
        this.roomsDetails[roomId].messages[snapshot.key] = snapshot.val();
      });
    });

    ref.on('child_removed', snapshot => {
      if (snapshot.val()) {
        runInAction(() => {
          delete this.roomsDetails[roomId].messages[snapshot.key];
        });
      }
    });
  }

  @action
  trackLastSeenMessage(roomId, userId) {
    firebase
      .database()
      .ref(`rooms/${roomId}/meta/members/${userId}`)
      .on('child_changed', snapshot => {
        if (snapshot.key === 'lastSeenMessage') {
          runInAction(() => {
            this.roomsDetails[roomId].members[userId].lastSeenMessage = snapshot.val();
          });
        }
      });
  }

  @action
  computedUnreadMessagesByRoom(roomId) {
    const room = this.roomsDetails[roomId];
    const userId = this.rootStore.userStore.user.id;

    const lastMessageSeen = room.members[userId].lastSeenMessage;
    const countReadMessage = room.messages && Object.keys(room.messages).findIndex(i => i === lastMessageSeen) + 1;
    const unreadMessages = room.messages && Object.fromEntries(Object.entries(room.messages).slice(countReadMessage));
    const notMyUnreadMessages =
      unreadMessages && Object.keys(unreadMessages).filter(id => unreadMessages[id].sndr !== userId);
    this.roomsDetails[roomId].countUnreadMessages = notMyUnreadMessages ? notMyUnreadMessages.length : null;
  }

  @computed
  get getUnSeenMessages() {
    let count = 0;
    Object.keys(this.roomsDetails).map(id => {
      const {oppositeMember, countUnreadMessages} = this.roomsDetails[id];
      if (oppositeMember) {
        count += countUnreadMessages;
      }
      return count;
    });
    return count;
  }

  @action
  pinRoom(userId, roomId, pinStatus = true) {
    return firebase
      .database()
      .ref(`/userRooms/${userId}/${roomId}`)
      .child('pin')
      .set(pinStatus, error => {
        if (error) {
          return error;
        }
        return '';
      });
  }

  @action
  openFloatChat() {
    this.floatingSelectedRoom = true;
  }

  @action
  openFloatingChat(attendee, user) {
    let attendeeId = attendee.userId || attendee.id;
    if (Object.keys(this.allMembers).includes(attendeeId)) {
      runInAction(() => {
        this.floatingSelectedRoom = this.allMembers[attendeeId];
        this.isFloatingChat = true;
      });
    } else {
      const roomId = [user.id, attendeeId].sort().join('-');
      this.roomsDetails[roomId] = {
        oppositeMember: attendee,
        details: {
          id: roomId,
        },
        members: {
          [attendeeId]: attendee,
          [user.id]: user,
        },
        pin: false,
        firstMessage: true,
        messages: null,
      };
      this.floatingSelectedRoom = roomId;
    }
  }

  @action
  openMainChat(attendee, user) {
    let attendeeId = attendee.userId || attendee.id;
    this.selectChat(attendeeId, false);
    if (Object.keys(this.allMembers).includes(attendeeId)) {
      runInAction(() => {
        this.selectedRoom = this.allMembers[attendeeId];
      });
    } else {
      const roomId = [user.id, attendeeId].sort().join('-');
      this.roomsDetails[roomId] = {
        oppositeMember: attendee,
        details: {
          id: roomId,
        },
        members: {
          [attendeeId]: attendee,
          [user.id]: user,
        },
        pin: false,
        firstMessage: true,
        messages: null,
      };
      this.selectedRoom = roomId;
    }
  }

  @action
  setOptionsForFilters(members) {
    if (Object.keys(members).length > 0) {
      Object.keys(members).forEach(id => {
        const member = members[id];
        this.filterCriteria.forEach(criteria => {
          if (member[criteria]) {
            if (!this.filteredRoom[criteria]) {
              this.filteredRoom[criteria] = [member[criteria]];
            } else if (!this.filteredRoom[criteria].includes(member[criteria])) {
              this.filteredRoom[criteria].push(member[criteria]);
            }
          }
        });
      });
    }
  }

  @action
  applyFilter() {
    this.filteredRoom = filter(values(this.roomsDetails), conforms(this.filters));
  }

  @action
  filterSearch(property, rule) {
    const re = new RegExp(escapeRegExp(rule), 'i');
    if (!rule) {
      this.removeFilter(property);
      return;
    }
    this.filters[property] = val => re.test(val);
    this.applyFilter();
  }

  @action
  removeFilter(property) {
    delete this.filters[property];
    this[property] = null;
    this.applyFilter();
  }

  @action
  cleanFilter() {
    this.filters = {};
    this.filterOptions = {};
  }

  @action
  selectChat(chatId, isFloating) {
    htmlClass.add(document.body, 'chat--selected');
    if (isFloating) {
      this.floatingSelectedRoom = chatId;
    } else {
      this.selectedRoom = chatId;
    }
  }

  @action
  unSelectChat() {
    htmlClass.remove(document.body, 'chat--selected');
    this.selectedRoom = null;
    this.floatingSelectedRoom = null;
  }

  @action
  cleanFloatingChat() {
    this.isFloatingChat = false;
  }

  @action
  clean() {
    this.cleanFilter();
    this.userRooms = null;
    this.roomsDetails = {};
    this.selectedRoom = null;
    this.floatingSelectedRoom = null;
  }
}
