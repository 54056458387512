import React from 'react';
import {observer} from 'mobx-react-lite';
import {Dimmer, Segment, Loader} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {useParams} from 'react-router-dom';
import {LocalStore} from '../../../../services/core/storage-service';
import ModalUrl from '../../../../ui/ModalUrl';
import AttendeeDetails from '../../../attendee/AttendeeDetails';
import {AppContext} from '../../../../store';
import './AttendeeModal.scss';

const AttendeeModal = observer(() => {
  const {attendeeId, eventId} = useParams();
  const {participantStore, eventStore, userStore} = React.useContext(AppContext);
  const {user} = userStore;
  const {meParticipant} = eventStore;
  const [banner, setBanner] = React.useState(null);
  const [attendee, setAttendee] = React.useState(null);

  React.useEffect(() => {
    if (attendeeId && eventId) {
      eventStore.getEventDetails(eventId, true).then(details => {
        setBanner(details.images.banner);
        setTimeout(() => {
          participantStore.getParticipantDetails(attendeeId).then(participant => {
            setAttendee(participant);
          });
        }, 300);
      });
      if (meParticipant) {
        participantStore.markAsSeen(eventId, user.id, meParticipant.id);
      }
      const viewedAttendeeList = LocalStore.getObject('viewedAttendees');
      const checkAttendeeIsViewed = Object.values(viewedAttendeeList).some(
        attendeeIdFromList => attendeeIdFromList === attendeeId,
      );
      !checkAttendeeIsViewed &&
        LocalStore.addToObject('viewedAttendees', {[Object.keys(viewedAttendeeList).length]: attendeeId});
    }

    return () => {
      setAttendee(null);
    };
  }, [eventStore, attendeeId, participantStore, meParticipant]);

  return (
    <ModalUrl className="modal-attendee" open={!!attendeeId} size="small">
      {!isEmpty(attendee) ? (
        <AttendeeDetails attendee={attendee} banner={banner} />
      ) : (
        <Segment className="modal-attendee__loader">
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        </Segment>
      )}
    </ModalUrl>
  );
});

export default AttendeeModal;
