import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Dimmer, Loader, Segment} from 'semantic-ui-react';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import AgendaFilter from '../../agenda/AgendaFilter';
import AgendaList from '../../agenda/AgendaList';
import './AgendaContent.scss';
import {ReactComponent as AgendaImg} from '../../../assets/images/icons/header/agenda.svg';
import {runInAction} from "mobx";

const STEP_LOAD = 5;

const AgendaContent = observer(({attendee}) => {
  const {agendaStore, eventStore, modalStore} = React.useContext(AppContext);
  const {myAgendaList, loadingPastAgenda, stepLoadPastSessions, loaded} = agendaStore.data;
  const {pastEvents} = eventStore;
  const countPastEventForLoading = pastEvents.length + STEP_LOAD - stepLoadPastSessions;
  const [open, setOpen] = React.useState(false);
  const [allSessions, setAllSessions] = useState([])
  const [sessionsLoaded, setSessionsLoaded] = useState(false)
  useEffect(()=>{
    if(attendee){
      if(!modalStore.modals.agendaOffTimeModal.open) {
        setSessionsLoaded(false)
        agendaStore.data.getParticipantPrivateSessionsOnce(attendee.eventId,
          attendee).then(data => {
          console.log(data)
          setAllSessions(data)
          setSessionsLoaded(true)
        })
      }
    }
    else{
        setSessionsLoaded(loaded)
        setAllSessions(myAgendaList);
    }
  }, [myAgendaList, attendee, loaded, modalStore.modals.agendaOffTimeModal.open])

  const liveAndUpcomingSessions = allSessions
  .filter(s => !s.expired || s.upcomingState !== 'expired')
  .sort((a, b) => new Date(a.sdt) - new Date(b.sdt));
  const handleClick = () => {
    agendaStore.data.loadAgendaForPatsEvents();
  };

  if (!sessionsLoaded) {
    return (
      <Segment className="agenda__loader">
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      </Segment>
    );
  }

  return (
    <div className="agenda">
      <div className="agenda__wrapper">
        {
          attendee == null?
            <>
              <h1 className="agenda__title">
                <FormattedMessage id="myAgenda.title" />
              </h1>
              <AgendaFilter collapse={() => setOpen(!open)} isOpen={open} />
            </>:null
        }


        <div className="agenda__btns">
          <Button color="blue" onClick={() => modalStore.open('agendaOffTimeModal', {attendee:attendee})}>
            <FormattedMessage id="agenda.sidebar.addBreak.btn" />
          </Button>
          {
            attendee == null?<Button
              color="blue"
              basic
              disabled={!liveAndUpcomingSessions.length}
              onClick={() => modalStore.open('exportAgendaModal')}
            >
              <FormattedMessage id="agenda.sidebar.exportAgenda.btn" />
            </Button>:null
          }

        </div>
      </div>
      {allSessions.length > 0 ? (
        <div className={classNames('agenda-content', {'agenda-content--filters': open})}>
          <div className="agenda-content__live">
            <h2>Live and upcoming agenda</h2>
            <AgendaList
              attendee={attendee}
              list={allSessions
                .filter(s => !s.expired || s.upcomingState !== 'expired')
                .sort((a, b) => new Date(a.sdt) - new Date(b.sdt))}
            />
          </div>
          <div className="agenda-content__past">
            <h2>Past agenda</h2>
            <AgendaList
              attendee={attendee}
              list={allSessions
                .filter(s => s.expired || s.upcomingState === 'expired')
                .sort((a, b) => new Date(b.sdt) - new Date(a.sdt))}
            />
            {attendee == null && pastEvents.length > 0 ? (
              <div className="agenda-content__load">
                <Button
                  onClick={() => handleClick()}
                  loading={loadingPastAgenda}
                  disabled={countPastEventForLoading <= 0}
                >
                  {countPastEventForLoading <= 0
                    ? 'Loaded all agenda'
                    : `Load agenda for past events (${countPastEventForLoading} events)`}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="agenda__empty">
          <div className="agenda__empty-img">
            <AgendaImg />
          </div>
          <div className="agenda__empty-text">
            <FormattedMessage id="agenda.content.empty" />
          </div>
          <div className="agenda__empty-info">
            <FormattedMessage id="agenda.content.empty.info" />
          </div>
        </div>
      )}
    </div>
  );
});

export default injectIntl(AgendaContent);
