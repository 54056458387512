export default function getDomain(url) {
  if (!url) return null;
  try {
    let {hostname} = new URL(url);
    if (hostname.startsWith('www.')) {
      hostname = hostname.substring(4);
    }
    return hostname;
  } catch (e) {
    return url;
  }
}
