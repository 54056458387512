import TimeService from '../core/time-service';
import {stores} from '../../store';

export function processSession(ses, eventDetails, participantDetails, mySessions) {
  let nonDeletedSessions = {};
  ses.visibleToParticipant = true;
  if (ses.restricted) {
    ses.visibleToParticipant =
      ses.allowedParticipants != null && ses.allowedParticipants.indexOf(participantDetails.typeId) >= 0;
  }
  ses.visibleToParticipant =
    (ses.visibleToParticipant && (!ses.hidden || participantDetails.organizer)) ||
    (mySessions != null && mySessions[ses.id] != null);

  TimeService.sessionDatePopulate(eventDetails, participantDetails, ses);
  ses.speakersMap = {};

  if (ses.speakers) {
    ses.speakers.forEach(speakerid => {
      ses.speakersMap[speakerid] = true;
    });
    const speakerList = stores.speakerStore.speakers;
    const speakerObjects = [];
    ses.speakers.forEach(speakerId => {
      const speakerObject = speakerList?.find(speakerObj => speakerObj.id === speakerId);
      if (speakerObject) {
        speakerObjects.push(speakerObject);
      }
    });
    ses.speakerObjects = speakerObjects;
  }

  if (ses.tracks != null) {
    const trackConfs = [];
    ses.tracks.forEach(track => {
      const trackConf = {label: track};
      if (
        eventDetails.colors != null &&
        eventDetails.colors.tracks != null &&
        eventDetails.colors.tracks[track] != null
      ) {
        trackConf.backgroundColor = eventDetails.colors.tracks[track].backgroundColor;
        trackConf.color = eventDetails.colors.tracks[track].color;
        if (!trackConf.color) {
          trackConf.color = '#fff';
        }
      }
      trackConfs.push(trackConf);
    });
    ses.tracks = trackConfs;
  }

  let sessionCount = 0;
  const sesArray = [];
  let available = false;
  let totCap = 0;
  let usedCap = 0;
  for (const subsesId in ses.ses) {
    // eslint-disable-next-line no-plusplus
    sessionCount++;
    const subses = ses.ses[subsesId];
    if (subses.state === 'available') {
      available = true;
    }
    for (const locId in subses.locs) {
      const locDetails = subses.locs[locId];
      if (locDetails.capacity) {
        totCap += locDetails.capacity;
        usedCap += locDetails.usedCapacity ? locDetails.usedCapacity : 0;
      }
    }
    sesArray.push(subses);
    TimeService.sessionDatePopulate(eventDetails, participantDetails, subses);
  }
  ses.availableCapacity = totCap - usedCap;
  ses.available = available;
  ses.sessionCount = sessionCount;
  ses.sesArray = sesArray.sort((o1, o2) => (o1.sdt > o2.sdt ? 1 : -1));

  ses.availableSesArray = sesArray.filter(val => val.state === 'available' && val.expiresAt > TimeService.now());
  if (ses.deleted !== true) {
    nonDeletedSessions = ses;
  }

  return nonDeletedSessions;
}

export function getSpeakersFromSession(sessions) {
  const speakersArray = [];
  const sessionsWithSpeakers = sessions.filter(el => el.speakers);
  const sessionSpeakers = sessionsWithSpeakers.map(el => el.speakers);

  sessionSpeakers.forEach(el => {
    el.forEach(speaker => (!speakersArray.includes(speaker) ? speakersArray.push(speaker) : null));
  });

  return stores.speakerStore.speakers.filter(el => !!speakersArray.includes(el.id));
}

export function getTracksFromSession(sessions) {
  const tracksArray = [];
  const sessionsWithTracks = sessions.filter(el => el.tracks);
  const sessionTracks = sessionsWithTracks.map(el => el.tracks);

  sessionTracks.forEach(el => {
    el.forEach(track => (!tracksArray.includes(track.label) ? tracksArray.push(track.label) : null));
  });

  return tracksArray;
}
