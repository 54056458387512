import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Form, Message} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {useForm} from 'react-hook-form';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import './LoginForm.scss';

const LoginForm = observer(() => {
  const intl = useIntl();
  const {userStore, eventStore} = React.useContext(AppContext);
  const {isLoading} = userStore;
  const {domainDetails} = eventStore;
  const {handleSubmit, register, errors} = useForm();
  const [showPassword, setShowPassword] = React.useState(false);

  React.useEffect(
    () => () => {
      userStore.cleanError();
    },
    [userStore],
  );

  const onSubmit = value => {
    userStore.login('cognito', value);
  };

  const onTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const idMessageConverter = (error, parentMessageId) => {
    if (!error.code) return error;
    const id = `${parentMessageId}.${error.code}`;
    const message = intl.formatMessage({id: id});

    // eslint-disable-next-line
    if (!!intl.messages[id]) return message || error.message;
  };

  const errorMessage = error => {
    const errorMessage = idMessageConverter(error, 'login.auth.error');
    const {code, message} = error;

    switch (true) {
      case code === 'NotAuthorizedException' && message.includes('Password attempts'):
        return intl.formatMessage({id: 'login.auth.error.LimitedPasswordAttempts'});
      default:
        return errorMessage;
    }
  };

  const classesPassword = classNames('link icon', {
    eye: showPassword,
    'eye slash': !showPassword,
  });

  return (
    <div className="login-form">
      <div className="login-form__header">
        <h1 className="login-form__title">
          <FormattedMessage id="login.title" />
        </h1>
        {domainDetails ? <p className="login-form__desc">Please log in to join the event</p> : null}
      </div>
      <Form error={!isEmpty(errors) || !!userStore.error} onSubmit={handleSubmit(onSubmit)}>
        <Form.Field error={!!errors.username}>
          <label>
            <FormattedMessage id="login.forgot.label.email" />
          </label>
          <div className="ui left icon input">
            <i className="user icon" />
            <input
              name="username"
              placeholder={intl.formatMessage({id: 'login.forgot.placeholder.email'})}
              ref={register({
                required: intl.formatMessage({id: 'validation.error.required'}, {name: 'E-mail'}),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: intl.formatMessage({id: 'validation.error.email'}),
                },
              })}
            />
          </div>
          {errors.username ? <div className="error">{errors.username.message}</div> : null}
        </Form.Field>
        <Form.Field error={!!errors.password}>
          <label>
            <FormattedMessage id="login.forgot.label.password" />
          </label>
          <div className="ui left icon input">
            <i className="lock icon" />
            <input
              name="password"
              placeholder={intl.formatMessage({id: 'login.forgot.placeholder.password'})}
              type={showPassword ? 'text' : 'password'}
              ref={register({
                required: intl.formatMessage({id: 'validation.error.required'}, {name: 'Password'}),
                minLength: {
                  value: 8,
                  message: intl.formatMessage({id: 'validation.error.minlength'}, {number: 8}),
                },
              })}
            />
            <i aria-hidden="true" className={classesPassword} onClick={onTogglePassword} />
          </div>
          {errors.password ? <div className="error">{errors.password.message}</div> : null}
          <Link to="/forgot-password" className="login-form__forgot">
            <FormattedMessage id="login.forgot.password" />
          </Link>
        </Form.Field>
        <Button fluid disabled={isLoading} loading={isLoading}>
          <FormattedMessage id="login.btn" />
        </Button>
        {userStore.error ? (
          <Message error>
            <ReactMarkdown>{errorMessage(userStore.error)}</ReactMarkdown>
          </Message>
        ) : null}
      </Form>
      <div className="login-form__footer">
        <FormattedMessage id="login.back.text" /> <Link to="/registration">Register</Link>
      </div>
    </div>
  );
});

export default injectIntl(LoginForm);
