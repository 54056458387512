import React from 'react';
import {observer} from 'mobx-react-lite';
import {runInAction} from 'mobx';
import Modal from '../../../../ui/Modal';
import {AppContext} from '../../../../store';
import ChatRemoveMessageForm from '../../../chat/ChatRemoveMessageForm';
import './MessageActionConfirmationModal.scss';

const MessageActionConfirmationModal = observer(() => {
  const {modalStore} = React.useContext(AppContext);
  const {open, type} = modalStore.modals.messageActionConfirmationModal;

  return open ? (
    <Modal
      className="message-action-confirmation-modal"
      open
      onClose={() => {
        modalStore.close('messageActionConfirmationModal');
      }}
      onUnmount={() => {
        const modal = modalStore.modals.messageActionConfirmationModal;
        runInAction(() => {
          modal.type = null;
          modal.messageInfo = null;
        });
      }}
      size="mini"
    >
      <div className="message-action-confirmation-modal__wrapper">
        {type === 'delete' ? <ChatRemoveMessageForm /> : null}
      </div>
    </Modal>
  ) : null;
});

export default MessageActionConfirmationModal;
