import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Form, Message, Segment, Icon} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {useForm} from 'react-hook-form';
import {FormattedMessage, injectIntl} from 'react-intl';
import ReactMarkdown from 'react-markdown';
import {runInAction} from 'mobx';
import {AppContext} from '../../../store';
import './ForgotPasswordForm.scss';

const ForgotPasswordForm = observer(props => {
  const {intl} = props;
  const {userStore} = React.useContext(AppContext);
  const {isLoading} = userStore;
  const {handleSubmit, register, errors} = useForm();
  const [email, setEmail] = React.useState('');

  React.useEffect(
    () => () => {
      runInAction(() => {
        userStore.error = '';
      });
    },
    [userStore],
  );

  const onSubmit = value => {
    userStore.forgotPassword(value.username);
  };

  const handleChange = event => {
    const {target} = event;
    setEmail(target.value);
  };

  return (
    <div className="forgot-password-form">
      <div className="forgot-password-form__header">
        <h2 className="forgot-password-form__title">
          <FormattedMessage id="forgot.password.title" />
        </h2>
      </div>
      {!userStore.resetPassword ? (
        <Form
          className="forgot-password-form__body"
          error={!isEmpty(errors) || !!userStore.error}
          onSubmit={handleSubmit(onSubmit)}
        >
          <p className="forgot-password-form__desc">
            <FormattedMessage id="forgot.password.desc" />
          </p>
          <Form.Field error={!!errors.username}>
            <label>
              <FormattedMessage id="app.field.email" />
            </label>
            <input
              name="username"
              placeholder={intl.formatMessage({id: 'forgot.password.placeholderUsername'})}
              ref={register({
                required: intl.formatMessage({id: 'validation.error.required'}, {name: 'E-mail'}),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: intl.formatMessage({id: 'validation.error.email'}),
                },
              })}
              onChange={handleChange}
            />
          </Form.Field>
          <Button fluid disabled={isLoading} loading={isLoading} color="teal">
            <FormattedMessage id="forgot.password.btn.reset" />
          </Button>
          <Message
            error
            content={
              <ul className="list">
                {Object.keys(errors).map((error, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={i}>{errors[error].message}</li>
                ))}
                {userStore.error ? (
                  <li>
                    <ReactMarkdown>
                      {intl.formatMessage({id: `forgot.password.error.${userStore.error}`})}
                    </ReactMarkdown>
                  </li>
                ) : null}
              </ul>
            }
          />
        </Form>
      ) : (
        <Segment stacked>
          <Icon name="check circle outline" size="huge" className="forgot-password-form__success" />
          <p className="forgot-password-form__text-success">
            <FormattedMessage id="forgot.password.emailSent" values={{email}} />
          </p>
        </Segment>
      )}
    </div>
  );
});

export default injectIntl(ForgotPasswordForm);
