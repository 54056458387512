import React from 'react';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import {CarouselProvider, Slider, Slide, DotGroup} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import slideAImg from '../../../assets/images/login-slider/1.png';
import slideBImg from '../../../assets/images/login-slider/2.png';
import slideCImg from '../../../assets/images/login-slider/3.png';
import './CarouselDealroom.scss';

const CarouselDealroom = observer(() => (
  <div
    ref={ref => {
      if (ref) ref.style.setProperty('max-width', 'max(78vh, 380px)', 'important');
    }}
    className="dealroom-carousel"
  >
    <div className="dealroom-carousel__wrapper">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={115}
        interval={10000}
        totalSlides={3}
        isPlaying
        infinite
      >
        <Slider>
          <Slide index={0} className="dealroom-carousel__slide">
            <a href="https://share.hsforms.com/1yboAy90lSsy195D4j4DgUw2h464" rel="noopener noreferrer" target="_blank">
              <img src={slideAImg} alt="dealroom" />
            </a>
          </Slide>
          <Slide index={1} className="dealroom-carousel__slide">
            <a href="https://share.hsforms.com/1yboAy90lSsy195D4j4DgUw2h464" rel="noopener noreferrer" target="_blank">
              <img src={slideBImg} alt="dealroom" />
            </a>
          </Slide>
          <Slide index={2} className="dealroom-carousel__slide">
            <a href="https://share.hsforms.com/1yboAy90lSsy195D4j4DgUw2h464" rel="noopener noreferrer" target="_blank">
              <img src={slideCImg} alt="dealroom" />
            </a>
          </Slide>
        </Slider>
        <div className="dealroom-carousel__action">
          <DotGroup />
        </div>
      </CarouselProvider>
    </div>
  </div>
));

export default injectIntl(CarouselDealroom);
