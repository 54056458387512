import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import {AppContext} from '../../../store';
import Search from '../../../ui/Search';
import './ChatSearchUsers.scss';

const ChatSearchUsers = observer(() => {
  const intl = useIntl();
  const {chatStore} = React.useContext(AppContext);

  return (
    <div className="chat-search-users">
      <Search
        placeholder={intl.formatMessage({id: 'global.search'})}
        label={<FormattedMessage id="chat.search.users.placeholder" />}
        onSearchChange={value => {
          chatStore.filterSearch('searchName', value);
        }}
      />
    </div>
  );
});

export default injectIntl(ChatSearchUsers);
