import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {Popup} from 'semantic-ui-react';
import {useHistory, useLocation} from 'react-router-dom';
import {AppContext} from '../../../store';
import {openChatMain} from '../../../services/helpers/chatHelper';
import './InvitationPopupBtns.scss';

const InvitationPopupBtns = props => {
  const {invitation, meeting} = props;
  const {userStore, invitationStore} = React.useContext(AppContext);
  const attendee = invitation?.opponentParticipant || meeting?.participants[0];
  const {user} = userStore;
  const history = useHistory();
  const location = useLocation();

  return attendee && user.id !== attendee.userId ? (
    <div className="invitation-card-chat-btn">
      <Popup
        on="click"
        className="invitation-card-chat-btn__list"
        content={
          <div role="list">
            <div
              aria-hidden
              className="item"
              onClick={() =>
                openChatMain(attendee, user, history, location, () => {
                  invitationStore.showInvitation(false);
                })
              }
            >
              <div className="content">
                <FormattedMessage id="invite.card.chat.btn" />
              </div>
            </div>
          </div>
        }
        trigger={<i className="ellipsis horizontal icon button" />}
      />
    </div>
  ) : null;
};

InvitationPopupBtns.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.object]),
  meeting: PropTypes.oneOfType([PropTypes.object]),
};

export default InvitationPopupBtns;
