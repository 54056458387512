import React from 'react';
import {observer} from 'mobx-react-lite';
import UpdatePasswordForm from '../../components/auth/UpdatePasswordForm';
import DeleteAccountForm from '../../components/account/AccountDeleteForm';

const Security = observer(() => (
  <div className="security">
    <UpdatePasswordForm />
    <DeleteAccountForm />
  </div>
));

export default Security;
