import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import last from 'lodash/last';
import InvitationCardSent from '../InvitationCardSent';
import InvitationCardReceived from '../InvitationCardReceived';
import InvitationCardDecline from '../InvitationCardDecline';
// import AgendaCardMeeting from '../../agenda/AgendaCardMeeting';
import InvitationCardMeetingCanceled from '../InvitationCardMeetingCanceled';
import {AppContext} from '../../../store';

const InvitationBoxMapByStatus = observer(props => {
  const {invitation, type} = props;
  const {participantStore, invitationStore} = React.useContext(AppContext);
  const {states} = participantStore;
  const state = states && states[invitation.oppId];

  const showInvite = inv => {
    if (inv.meeting && state !== 'reschedule') {
      // TODO need to improve
      invitationStore.removeInvitation(inv);
      return null;
    }
    if (last(inv.meetingDetails) && last(inv.meetingDetails).status === 'cancelled') {
      return <InvitationCardMeetingCanceled invitation={inv} key={`${inv.id}-${inv.eid}`} page="invitations" />;
    }
    if ((type === 'sent' && inv.state === 'processed') || (type === 'received' && inv.state === 'processed')) {
      return <InvitationCardDecline key={`${inv.id}-${inv.eid}`} invitation={inv} />;
    }
    if (type === 'sent' || (type === 'company' && inv.sent)) {
      return <InvitationCardSent key={`${inv.id}-${inv.eid}`} invitation={inv} type={type} page="invitations" />;
    }
    if (type === 'received' || (type === 'company' && !inv.sent)) {
      return <InvitationCardReceived key={`${inv.id}-${inv.eid}`} invitation={inv} type={type} page="invitations" />;
    }
    return null;
  };

  return showInvite(invitation);
});

InvitationBoxMapByStatus.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.object]),
};

export default InvitationBoxMapByStatus;
