import React from 'react';
import {observer} from 'mobx-react-lite';
import {runInAction} from 'mobx';
import {Dimmer, Loader} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {AppContext} from '../../../../store';
import Modal from '../../../../ui/Modal';
import InvitationBoxMapByAttendee from '../../../invitations/InvitationBoxMapByAttendee';
import './InvitationModal.scss';

const InvitationModal = observer(() => {
  const {modalStore, participantStore, uiStore} = React.useContext(AppContext);
  const {open, attendee, action, isBooth, invitationId} = modalStore.modals.attendeeMeet;
  const {pageTitle} = uiStore;

  React.useEffect(
    () => () => {
      runInAction(() => {
        participantStore.participantData = null;
      });
    },
    [attendee, participantStore],
  );

  const handleClose = () => {
    runInAction(() => {
      modalStore.modals.attendeeMeet.invitation = null;
      modalStore.modals.attendeeMeet.meeting = null;
      modalStore.modals.attendeeMeet.newMeeting = false;
      modalStore.modals.attendeeMeet.isBooth = false;
    });
    modalStore.close('attendeeMeet');
  };

  return (
    <Modal className="invitation-modal" open={open} onClose={handleClose} size="tiny">
      {attendee ? (
        <>
          {pageTitle ? (
            <h1>
              <FormattedMessage id={`${pageTitle}.page.title`} />
            </h1>
          ) : null}
          <InvitationBoxMapByAttendee attendee={attendee} action={action} isBooth={isBooth} invitationId={invitationId}/>
        </>
      ) : (
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      )}
    </Modal>
  );
});

export default InvitationModal;
