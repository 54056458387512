import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Form, Message} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {useForm} from 'react-hook-form';
import {FormattedMessage, injectIntl} from 'react-intl';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import './UserEmailVerificationForm.scss';

const UserEmailVerificationForm = observer(props => {
  const {intl} = props;
  const {userStore} = React.useContext(AppContext);
  const {newEmail} = userStore;
  const {handleSubmit, register, errors} = useForm();
  const [loading, setLoading] = React.useState(false);

  // eslint-disable-next-line consistent-return
  const onSubmit = value => {
    setLoading(true);
    userStore
      .verifyCodeForAddingEmail(value)
      .then(() => {
        NotificationAlert.success(<FormattedMessage id="email.verification.success.verification" />);
        userStore.getConnectedEmails();
        setLoading(false);
      })
      .catch(err => {
        NotificationAlert.error(err.message);
        setLoading(false);
      });
  };

  const resendEmail = (e, newEmail) => {
    e.preventDefault();
    userStore
      .connectNewEmail(newEmail)
      .then(() => {
        NotificationAlert.success(
          intl.formatMessage({id: 'email.verification.success.confirmation'}, {username: newEmail?.email}),
        );
      })
      .catch(err => {
        NotificationAlert.error(err.message);
      });
  };

  return (
    <Form
      className="email-verification-form"
      error={!isEmpty(errors) || !!userStore.error}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="email-verification-form__desc">
        {intl.formatMessage({id: 'email.verification.success.confirmation'}, {username: newEmail?.email})}
        <br />
        <FormattedMessage id="email.verification.message" />
      </div>
      <Form.Field error={!!errors.email}>
        <input
          name="code"
          placeholder={intl.formatMessage({id: 'email.verification.dont.receive'})}
          ref={register({
            required: intl.formatMessage({
              id: 'validation.error.required',
              name: intl.formatMessage({id: 'app.field.username'}),
            }),
            pattern: {
              value: /^\d+$/,
              message: intl.formatMessage({id: 'validation.error.verification.code.number'}),
            },
          })}
        />
        {errors.code ? <div className="error">{errors.code.message}</div> : null}
      </Form.Field>
      <Button color="teal" className="email-verification-form__send-btn" disabled={loading} loading={loading}>
        <FormattedMessage id="email.verification.btn.add" />
      </Button>
      <div className="email-verification-form__resend-btn">
        <Button onClick={e => resendEmail(e, newEmail)}>
          <FormattedMessage id="email.verification.resend.or.change.1" />
        </Button>
      </div>
      <Message error>{userStore.error ? userStore.error : null}</Message>
    </Form>
  );
});

export default injectIntl(UserEmailVerificationForm);
