import React from 'react';
import {observer} from 'mobx-react-lite';
import Modal from '../../../../ui/Modal';
import {FormattedMessage} from 'react-intl';
import {AppContext} from '../../../../store';
import ContactForm from '../../../contact/ContactForm';
import './AddContactModal.scss';

const AddContactModal = observer(() => {
  const {modalStore, contactStore} = React.useContext(AppContext);
  const {open} = modalStore.modals.addContactModal;

  return open ? (
    <Modal
      className="add-contact-modal"
      open
      onClose={() => {
        modalStore.close('addContactModal');
        contactStore.setContactForm({});
      }}
      size="tiny"
    >
      <div className="add-contact-modal__wrapper">
        <h3>
          <FormattedMessage id="add.contact.modal" />
        </h3>
        <ContactForm />
      </div>
    </Modal>
  ) : null;
});

export default AddContactModal;
