import {action, extendObservable, computed} from 'mobx';
import filter from 'lodash/filter';
import conforms from 'lodash/conforms';
import escapeRegExp from 'lodash/escapeRegExp';
import UtilsService from '../../../services/core/utils-service';

export class SessionsFilterStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    extendObservable(this, {
      filteredSessions: {},
      filterCriteria: ['type', 'dateFilter', 'speakers', 'mlocname', 'tracks'],
      filterOptions: {},
      filterValues: {},
      filters: {},
    });
  }

  @action
  setOptionsForFilters(sessions) {
    this.filterOptions = {};
    if (Object.keys(sessions).length > 0) {
      Object.keys(sessions).forEach(id => {
        const session = sessions[id];

        this.filterCriteria.forEach(criteria => {
          const sessionCriteria = session[criteria];
          if (sessionCriteria) {
            if (!this.filterOptions[criteria]) {
              this.filterOptions[criteria] = [sessionCriteria];
            } else if (!this.filterOptions[criteria].includes(sessionCriteria)) {
              this.filterOptions[criteria].push(sessionCriteria);
            }
          }
        });
      });
    }
  }

  @action
  applyFilter() {
    const list = UtilsService.mergeAsObject(this.rootStore.sessionsNewStore.data.sessions, 'id');
    this.filteredSessions = filter(list, conforms(this.filters));
  }

  @action
  filterExact(property, rule) {
    if (!rule) {
      this.removeFilter(property);
      return;
    }
    this.filters[property] = val => val === rule;
    this.filterValues[property] = rule;
    this.applyFilter();
  }

  @action
  filterArray(property, array) {
    if (!array) {
      this.removeFilter(property);
      return;
    }
    this.filters[property] = val =>
      val.some(el => {
        if (property === 'tracks') {
          return array.includes(el.label);
        }
        return array.includes(el);
      });
    this.filterValues[property] = array;
    this.applyFilter();
  }

  @action
  filterSearch(property, rule) {
    const re = new RegExp(escapeRegExp(rule), 'i');
    if (!rule) {
      this.removeFilter(property);
      return;
    }
    this.filters[property] = val => re.test(val);
    this.filterValues[property] = rule;
    this.applyFilter();
  }

  @computed
  get countFilters() {
    return Object.keys(this.filterValues).length;
  }

  @action
  removeFilter(property) {
    delete this.filters[property];
    delete this.filterValues[property];
    this[property] = null;
    this.applyFilter();
  }

  @action
  cleanFilter() {
    this.filters = {};
    this.filterOptions = {};
    this.filterValues = {};
  }
}
