/* eslint class-methods-use-this: ["error", {
"exceptMethods": ["getMomentUser", "toRealEventTime", "getMyLocalTimezone", "getMyLocalTimezoneOffset", "collides", "getEventIntervalDate"]
}] */
import {DateTime} from 'luxon';
import {stores} from '../../store';

class TimeService {
  localTime = DateTime.now();
  localNowMark = null;
  serverNow = null;
  dateTime = DateTime;

  setNow(now) {
    if (now == null) return;
    this.serverNow = now;
    this.localNowMark = DateTime.now();
  }

  now() {
    if (this.localNowMark == null) {
      return DateTime.now();
    }
    return DateTime.now() - this.localNowMark + this.serverNow;
  }

  getMomentUserOrEvent(eventDetails, dateMillis) {
    if (eventDetails) {
      if (eventDetails.eventType == 'normal') {
        return this.getMomentUser(eventDetails.timezone, dateMillis);
      }
    }
    return this.getMomentUser(this.getCurrentUserTimezone(), dateMillis);
  }

  getMomentUser(participantTimezone, dateMillis) {
    return participantTimezone
      ? this.dateTime.fromMillis(dateMillis, {zone: participantTimezone})
      : this.dateTime.fromMillis(dateMillis);
  }

  toRealEventTime(eventTimezone, dateMillis) {
    const time = this.dateTime.fromMillis(dateMillis).setZone(eventTimezone);
    const utcOffset = time.offset;
    return time.toUTC().minus({minutes: utcOffset}).setZone(eventTimezone).valueOf();
  }

  getCurrentUserTimezone() {
    return stores.userStore.user.timezone || this.getMyLocalTimezone();
  }

  getMyLocalTimezone() {
    return DateTime.local().zoneName;
  }

  getMyLocalTimezoneOffset() {
    return DateTime.local().offsetNameShort;
  }

  collides(startDate1, endDate1, startDate2, endDate2) {
    return (
      (startDate1 <= startDate2 && endDate1 > startDate2) ||
      (startDate1 < endDate2 && endDate1 >= endDate2) ||
      (startDate2 <= startDate1 && endDate1 <= endDate2)
    );
  }

  // EVENT TIME
  eventDatePopulating(event) {
    event.timezoneAbbr = this.localTime.setZone(event.timezone).offsetNameShort;

    event.realSdt = this.toRealEventTime(event.timezone, event.startDateMillis || event.startDate);
    event.realEdt = this.toRealEventTime(event.timezone, event.endDateMillis || event.endDate);

    const startDateMoment = this.getMomentUserOrEvent(event, event.realSdt);
    const endDateMoment = this.getMomentUserOrEvent(event, event.realEdt);

    this.getEventIntervalDate(event, startDateMoment, endDateMoment);
    event.isFinished = event.realEdt + 24 * 60 * 60000 <= this.now();
    event.startDateYear = this.getMomentUserOrEvent(event, event.realSdt).toFormat('yyyy');

    return event;
  }

  getEventIntervalDate(event, startDate, endDate) {
    const timeStart = this.dateTime.fromMillis(event.startDateMillis || event.startDate).setZone(event.timezone);
    const timeEnd = this.dateTime.fromMillis(event.endDateMillis || event.endDate).setZone(event.timezone);
    const utcOffset = timeStart.offset;

    const utcEventStartTime = timeStart.toUTC().minus({minutes: utcOffset}).setZone(event.timezone);
    const utcEventEndTime = timeEnd.toUTC().minus({minutes: utcOffset}).setZone(event.timezone);

    let startDateFullStr = startDate.toFormat('EEEE dd LLLL, yyyy');
    let endDateFullStr = endDate.toFormat('EEEE dd LLLL, yyyy');

    let utcStartDateFullStr = utcEventStartTime.toFormat('EEEE dd LLLL yyyy');

    if (startDateFullStr === endDateFullStr) {
      event.fullDateStrInUserTime = startDate.toFormat('EEEE dd LLLL, yyyy');
      event.fullDateStrInEventTime = utcStartDateFullStr;
    } else {
      startDateFullStr = startDate.toFormat('LLLL, yyyy');
      endDateFullStr = endDate.toFormat('LLLL, yyyy');
      utcStartDateFullStr = utcEventStartTime.toFormat('LLLL yyyy');
      if (startDateFullStr === endDateFullStr) {
        event.fullDateStrInUserTime = `${startDate.toFormat('dd')} - ${endDate.toFormat('dd')} ${startDateFullStr}`;
        event.fullDateStrInEventTime = `${utcEventStartTime.toFormat('dd')} - ${utcEventEndTime.toFormat(
          'dd',
        )} ${utcStartDateFullStr}`;
      } else {
        startDateFullStr = startDate.toFormat(', yyyy');
        endDateFullStr = endDate.toFormat(', yyyy');
        utcStartDateFullStr = utcEventStartTime.toFormat('yyyy');

        if (startDateFullStr === endDateFullStr) {
          event.fullDateStrInUserTime = `${startDate.toFormat('dd LLLL')} - ${endDate.toFormat(
            'dd LLLL',
          )} ${startDateFullStr}`;
          event.fullDateStrInEventTime = `${utcEventStartTime.toFormat('dd LLLL')} - ${utcEventEndTime.toFormat(
            'dd LLLL',
          )} ${utcStartDateFullStr}`;
        } else {
          event.fullDateStrInUserTime = `${startDate.toFormat('dd LLLL yyyy')} - ${endDate.toFormat('dd LLLL yyyy')}`;
          event.fullDateStrInEventTime = `${utcEventStartTime.toFormat('dd LLLL yyyy')} - ${utcEventEndTime.toFormat(
            'dd LLLL yyyy',
          )}`;
        }
      }
    }
    return event;
  }

  // SESSION TIME
  sessionDatePopulate(eventDetails, participantDetails, session) {
    if (session == null || !session.edt || !session.sdt) return;
    const expiresAt = this.toRealEventTime(
      eventDetails.timezone,
      session.type === 'dealroom' || session.sessionDefinition == null ? session.edt : session.sessionDefinition.edt,
    );
    const startsAt = this.toRealEventTime(
      eventDetails.timezone,
      session.type === 'dealroom' || session.sessionDefinition == null ? session.sdt : session.sessionDefinition.sdt,
    );
    const startMoment = this.getMomentUserOrEvent(eventDetails, startsAt);
    const endMoment = this.getMomentUserOrEvent(eventDetails, expiresAt);
    const startMomentReal = this.getMomentUser(session.timezone, startsAt);
    const endMomentReal = this.getMomentUser(session.timezone, expiresAt);
    session.startMoment = startMoment;
    session.expiresAt = expiresAt;
    session.startsAt = startsAt;
    session.expired = expiresAt < this.now();
    session.started = this.now() >= startsAt;
    session.sdtStr = startMoment.toFormat('t dd LLL yyyy');
    session.edtStr = endMoment.toFormat(' t dd LLL yyyy');
    session.sdts = {
      date: startMoment.toFormat('dd LLLL yyyy'),
      dateSortOrder: startMoment.toFormat('yyyy-LL-dd t'),
      hour: startMoment.toFormat('t'),
      month: startMoment.toFormat('dd LLL'),
      monthFull: startMoment.toFormat('dd LLLL'),
    };
    session.edts = {
      date: endMoment.toFormat('dd LLLL yyyy'),
      dateSortOrder: endMoment.toFormat('yyyy-LL-dd t'),
      hour: endMoment.toFormat('t'),
      month: endMoment.toFormat('dd LLL'),
      monthFull: endMoment.toFormat('dd LLLL'),
    };
    if (session.sdts.dateSortOrder !== startMomentReal.toFormat('yyyy-LL-dd t')) {
      session.sdtsReal = {
        date: startMomentReal.toFormat('dd LLLL yyyy'),
        dateSortOrder: startMomentReal.toFormat('yyyy-LL-dd t'),
        hour: startMomentReal.toFormat('t'),
        month: startMomentReal.toFormat('dd LLL'),
        monthFull: startMomentReal.toFormat('dd LLLL'),
      };
      session.edtsReal = {
        date: endMomentReal.toFormat('dd LLLL yyyy'),
        dateSortOrder: endMomentReal.toFormat('yyyy-LL-dd t'),
        hour: endMomentReal.toFormat('t'),
        month: endMomentReal.toFormat('dd LLL'),
        monthFull: endMomentReal.toFormat('dd LLLL'),
      };
    }
    if (session.ljt != null) {
      const ljdt = this.toRealEventTime(eventDetails.timezone, session.ljt);
      const ljMoment = this.getMomentUserOrEvent(eventDetails, ljdt);
      session.ljts = {date: ljMoment.toFormat('dd LLL yyyy'), hour: ljMoment.toFormat('HH:mm')};
    }
    session.dateFilter = startMoment.toFormat('dd LLL yyyy');
  }

  // INVITATION TIME
  invitationDatePopulate(invitation) {
    const momentDt = this.getMomentUserOrEvent(null, invitation.cdt);
    invitation.state = invitation.state == null ? 'waiting' : invitation.state;
    invitation.cdtStr = momentDt.toFormat('dd LLLL, t');
    invitation.cdtHourStr = momentDt.toFormat('t');
    invitation.cdtDateStr = momentDt.toFormat('dd LLL');
    return invitation;
  }
}

export default new TimeService();
