import {action, extendObservable} from 'mobx';
import filter from 'lodash/filter';
import conforms from 'lodash/conforms';
import escapeRegExp from 'lodash/escapeRegExp';
import UtilsService from '../../../services/core/utils-service';

export class AgendaFilterStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    extendObservable(this, {
      filteredAgenda: {},
      filterCriteria: ['name'],
      filterOptions: {},
      filterValues: {},
      filters: {},
    });
  }

  @action
  setOptionsForFilters(sessions) {
    this.filterOptions = {};
    if (Object.keys(sessions).length > 0) {
      Object.keys(sessions).forEach(id => {
        const session = sessions[id];

        this.filterCriteria.forEach(criteria => {
          if (session[criteria]) {
            if (!this.filterOptions[criteria]) {
              this.filterOptions[criteria] = [session[criteria]];
            } else if (!this.filterOptions[criteria].includes(session[criteria])) {
              this.filterOptions[criteria].push(session[criteria]);
            }
          }
        });
      });
    }
  }

  @action
  applyFilter() {
    const list = UtilsService.mergeAsObject(this.rootStore.agendaStore.data.myAgendaList, 'id');
    this.filteredAgenda = filter(list, conforms(this.filters));
  }

  @action
  filterSearch(property, rule) {
    const re = new RegExp(escapeRegExp(rule), 'i');
    if (!rule) {
      this.removeFilter(property);
      return;
    }
    this.filters[property] = val => re.test(val);
    this.filterValues[property] = rule;
    this.applyFilter();
  }

  @action
  removeFilter(property) {
    delete this.filters[property];
    delete this.filterValues[property];
    this[property] = null;
    this.applyFilter();
  }

  @action
  cleanFilter() {
    this.filters = {};
    this.filterOptions = {};
    this.filterValues = {};
  }
}
