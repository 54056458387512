import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import {Button} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import './ErrorBoundary.scss';

export default class ErrorBoundary extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    error: '',
    eventId: '',
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return {hasError: true, error};
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({eventId, error});
    });
  }

  render() {
    const {hasError, error} = this.state;
    if (hasError) {
      return (
        <section className="fallback">
          <div className="fallback__wrapper">
            <header className="fallback__header">
              <h3 className="fallback__title">
                <FormattedMessage id="error.boundary.title" />
              </h3>
            </header>
            <div className="fallback__body">
              <p className="fallback__error">
                <FormattedMessage id="error.boundary.title" />
                <strong>
                  <FormattedMessage id="error.boundary.error" />
                </strong>
                {error.message}
              </p>
              <p className="fallback__trace">
                <strong>
                  <FormattedMessage id="error.boundary.trace" />
                </strong>{' '}
                {error && error.stack}
              </p>
            </div>
            <div className="fallback__action">
              <Link to="/event/list" className="ui primary button">
                <FormattedMessage id="error.boundary.action" />
              </Link>
              <Button className="fallback__btn-reload" onClick={() => window.location.reload()}>
                <FormattedMessage id="error.boundary.reload" />
              </Button>
              <Button
                className="fallback__btn-feedback"
                onClick={() => Sentry.showReportDialog({eventId: this.state.eventId})}
              >
                <FormattedMessage id="error.boundary.feedback" />
              </Button>
            </div>
          </div>
        </section>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
