export default {
  requestStatuses: {
    alreadyCheckedIn: 'alreadyCheckedIn',
    alreadyCheckedOut: 'alreadyCheckedOut',
    fully: 'fullyOccupied',
    checkedIn: 'checkedIn',
    checkedOut: 'checkedOut',
    wrongTicketType: 'wrongTicketType',
  },
};
