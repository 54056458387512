import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import './EmbeddedIFrame.scss';

const EmbeddedIFrame = props => {
  let iframeRef = useRef();

  useEffect(() => {
    window.onmessage = e => {
      try {
        let data = JSON.parse(e.data);
        if (props.src.indexOf(data.path) >= 0) {
          if (iframeRef.current) {
            console.log(data, data.height);
            iframeRef.current.style.height = data.height + 'px';
          }
        }
      } catch (e) {
        console.log('error', e);
      }
    };
  }, []);

  return <iframe ref={iframeRef} title="event-iframe" className="embedded-iframe" src={props.src} />;
};

EmbeddedIFrame.propTypes = {
  src: PropTypes.string,
};

EmbeddedIFrame.defaultProps = {
  src: '',
};

export default EmbeddedIFrame;
