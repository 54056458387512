import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import {AppContext} from '../../../store';
import AnnouncementCommentList from '../AnnouncementCommentList';
import AnnouncementPopupModerator from '../AnnouncementPopupModerator';
import UtilsService from '../../../services/core/utils-service';
import TimeService from '../../../services/core/time-service';
import ParserText from '../../../ui/ParserText';
import './Announcement.scss';

const Announcement = observer(props => {
  const {announcement, participants, isModal, announcementList, announcementModal} = props;
  const {announcementStore, userStore, modalStore} = React.useContext(AppContext);
  const [openEditPopup, setOpenEditPopup] = React.useState(false);
  const comments = announcementStore.comments ? announcementStore.comments[announcement.id] : [];

  React.useEffect(() => {
    announcementStore.getCommentList(announcement.eventId, announcement.id);
  }, [announcementStore, announcement, userStore]);

  /*
  const toggleLike = () => {
    if (announcementStore.isLiked(announcement.id)) {
      announcementStore.onLike(announcement.id, false);
    } else {
      announcementStore.onLike(announcement.id);
    }
  };

  const classesComment = classNames('actions__item--like', {
    'actions__item--like-has': announcement.likes && Object.keys(announcement.likes).length > 0,
  });
  */

  const handleOpen = () => {
    if (!isModal) {
      modalStore.open('announcementInfo', {announcement});
      announcementStore.setParticipantAnnouncement(participants);
      setOpenEditPopup(false);
    }
  };

  return announcement ? (
    <div className="announcement-item">
      <div className="announcement-item__wrapper">
        <div className="announcement-item__message">
          <AnnouncementPopupModerator announcement={announcement} open={openEditPopup} />
          <div className="announcement-item__content">
            {!announcementList && !announcementModal ? (
              <div className="announcement-item__date">
                {TimeService.dateTime.fromMillis(announcement.postAt).toRelative()}
              </div>
            ) : (
              TimeService.dateTime.fromMillis(announcement.postAt).toFormat('dd LLLL, HH:mm')
            )}
            <div
              aria-hidden
              className={`announcement-item__text${
                !announcementModal ? ' announcement-item__text--link-disabled' : ''
              }`}
              onClick={() => handleOpen()}
            >
              <ParserText type={'html'}>{announcement.text}</ParserText>
            </div>
            {announcement.files ? (
              <div className="announcement-item__images">
                {announcement.files.map(a => (
                  <div key={a} className="announcement-item__img">
                    <img src={`${UtilsService.fixPublicBucketUrl(a)}?rand=${Math.random()}`} alt="" />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div className="announcement-item__actions">
            {/*
            <div className="announcement-item__like" aria-hidden>
              <i className="heart outline icon" />
              <span className="announcement-item__like-count">87</span>
            </div>
            */}
            <div className="announcement-item__comment" onClick={() => handleOpen()} aria-hidden>
              <i className="comment outline icon" />
              <span className="announcement-item__comment-count">{comments && Object.keys(comments).length}</span>
            </div>
          </div>
        </div>
        {isModal ? <AnnouncementCommentList announcement={announcement} participants={participants} /> : null}
      </div>
    </div>
  ) : null;
});

Announcement.propTypes = {
  announcement: PropTypes.objectOf(PropTypes.any).isRequired,
  participants: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default injectIntl(Announcement);
