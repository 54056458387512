import {defineMessages} from 'react-intl';

export default defineMessages({
  accountNotificationSettingsTitle: {
    id: 'account.notification.device-settings.title',
    defaultMessage: 'Notification Settings',
  },
  accountBtnAdd: {
    id: 'account.btn.add',
    defaultMessage: 'Connect',
  },
  accountTextEmails1: {
    id: 'account.text.emails.1',
    defaultMessage: 'Please add your other e-mail addresses here.',
  },
  accountTextEmails2: {
    id: 'account.text.emails.2',
    defaultMessage: 'Your currently associated email addresses are:',
  },
  accountTextSuccess: {
    id: 'account.text.success',
    defaultMessage: 'Notification settings changed successfully',
  },
  accountConfNotification1: {
    id: 'account.conf.notification1',
    defaultMessage: 'I received a 1:1 meeting invitation',
  },
  accountConfNotification2: {
    id: 'account.conf.notification2',
    defaultMessage: 'My 1:1 meeting invitation has been accepted',
  },
  accountConfNotification3: {
    id: 'account.conf.notification3',
    defaultMessage: 'My 1:1 meeting invitation has been rejected',
  },
  accountConfNotification4: {
    id: 'account.conf.notification4',
    defaultMessage: 'I received a request to reschedule a 1:1 meeting',
  },
  accountConfNotification5: {
    id: 'account.conf.notification5',
    defaultMessage: 'My request to reschedule a 1:1 meeting has been accepted',
  },
  accountConfNotification6: {
    id: 'account.conf.notification6',
    defaultMessage: 'My request to reschedule a 1:1 meeting has been rejected',
  },
  accountConfNotification7: {
    id: 'account.conf.notification7',
    defaultMessage: 'My meeting has been cancelled',
  },
  accountConfNotification8: {
    id: 'account.conf.notification8',
    defaultMessage: 'A session added to my Agenda was cancelled',
  },
  accountConfNotification9: {
    id: 'account.conf.notification9',
    defaultMessage: 'Remind me about a 1:1 meeting an hour before it begins',
  },
});
