import {action, extendObservable, runInAction} from 'mobx';
import {subscribeAreasList} from '../../../services/firebase/checkInFbApi';

export class CheckInStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    extendObservable(this, {
      areaList: [],
      scanTypeData: {},
      mode: 'scanType',
      scannedContact: {},
    });
  }

  @action
  getAreaList(selectedEventId) {
    const allEventArea = {id: 'allEvent', name: 'Venue entrance'};
    subscribeAreasList(selectedEventId, snapshot => {
      runInAction(() => {
        const dataAreas = snapshot?.val();

        if (dataAreas) {
          const areas = [];
          Object.keys(dataAreas).forEach(id => {
            areas.push({...{id: id}, ...dataAreas[id].info});
          });
          areas.unshift(allEventArea);
          this.areaList = areas;
          return areas;
        } else {
          this.areaList = [allEventArea];
          return [allEventArea];
        }
      });
    });
  }

  @action
  setScannedContact(contact) {
    this.scannedContact = contact;
  }

  @action
  setMode(mode) {
    this.mode = mode;
  }

  @action
  setScanTypeData(data) {
    this.scanTypeData = data;
  }

  @action
  backToInitStep(scanTypeData) {
    this.mode = 'scanType';
    this.scannedContact = {};
    this.scanTypeData = {
      entityId: null,
      entityType: 'event',
      eventId: scanTypeData.eventId,
      scanType: scanTypeData.scanType,
      scanMethod: scanTypeData.scanMethod,
    };
  }

  @action
  clear() {
    this.areaList = [];
    this.mode = 'scanType';
    this.scanTypeData = {};
    this.scannedContact = {};
  }
}
