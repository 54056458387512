import orderBy from 'lodash/orderBy';
import compact from 'lodash/compact';
import {stores} from '../../store';
import UtilsService from '../core/utils-service';

export function processBooth(eventId, booth, checkMembers = true) {
  const newImages = {};
  if (booth.images) {
    for (const imageKey in booth.images) {
      newImages[imageKey] = `${UtilsService.fixPublicBucketUrl(booth.images[imageKey])}?r=${booth.updatedAt}`;
    }
  }
  booth.eventId = eventId;
  booth.images = newImages;
  if (booth.agendaEnabled == null) {
    booth.agendaEnabled = true;
  }
  if (booth.documents == null) {
    booth.documents = [];
  }
  booth.bannerVideo = booth.documents.find(doc => doc.type === 'bvideo');
  if (booth.boothType == null) {
    booth.boothType = 'exhibitor';
  }
  booth.website = UtilsService.getUrlHttpPref(booth.website ? booth.website.trim() : '');
  booth.websiteDisplay = UtilsService.getDomain(booth.website);
  const participants = [];
  if (booth.members == null) {
    booth.members = [];
  }
  if (booth.members.filter(member => member === booth.ownerParticipantId).length === 0) {
    booth.members.push(booth.ownerParticipantId);
  }
  if (checkMembers) {
    booth.members.forEach(memberId => {
      participants.push(stores.participantStore.getParticipantDetails(memberId));
    });
    booth.memberParticipants = participants;
  }

  if (booth.some != null) {
    const some = {};
    for (const key in booth.some) {
      const tag = booth.some[key];
      some[key] = UtilsService.getSomeLink(key, tag);
    }
    booth.someLinks = some;
  }
  let score = 0;
  booth.rate = booth.rate == null ? 1 : booth.rate;
  if (booth.images != null) {
    if (booth.images.logo) {
      score += 1;
    }
    if (booth.images.banner || booth.bannerVideo) {
      score += 1;
    }
  }
  if (booth.description) {
    score += 1;
  }
  booth.score = score;
  booth.lowerCaseBoothName = booth.boothName.toLowerCase();

  return booth;
}

export function openCompanyInvite(booths, meParticipant) {
  if (meParticipant.requestedBoothIds) {
    const boothId = meParticipant.requestedBoothIds[0];
    const boothToBeConfirmed = booths.find(booth => booth.id === boothId);
    if (boothToBeConfirmed != null) {
      stores.modalStore.open('boothConfirmation', {booth: boothToBeConfirmed});
    }
  }
}

export const boothDetailsLocation = (meeting, eventDetails, myParticipantBooth, meBoothDetails) => {
  const meDetails = {id: meeting.me.id, boothDetails: myParticipantBooth || meBoothDetails};
  const participants = [];
  participants.push(meDetails);
  meeting.participants.forEach(attendee => participants.push(attendee));
  const attendeesLocation = compact(
    participants.map(attendee => {
      let isBoothMeeting =
        attendee?.boothDetails && meeting?.sessionDefinition?.opId === attendee?.boothDetails.ownerParticipantId;
      if (isBoothMeeting && attendee?.boothDetails?.location) {
        return {id: attendee.id, location: meeting?.location?.name + ' at ' + attendee.boothDetails?.location};
      } else if (attendee?.location) {
        return {id: attendee.id, location: attendee?.location};
      }
      return null;
    }),
  );

  if (attendeesLocation.length) {
    return orderBy(attendeesLocation, ['id'], ['asc'])[0].location;
  } else if (
    ((eventDetails &&
      eventDetails.type === 'hybrid' &&
      (meeting.me.present === 'venue' ||
        meeting.participants[0].present === 'venue' ||
        (!meeting.participants[0].present && !meeting.me.present))) ||
      eventDetails.type === 'normal') &&
    meeting.location
  ) {
    return meeting.location.name;
  }
  return null;
};
