import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {Helmet} from 'react-helmet-async';
import {observer} from 'mobx-react-lite';
import htmlClass from '../../../utils/htmlClass';
import {AppContext} from '../../../store';

const Title = observer(props => {
  const {id} = props;
  const {uiStore} = React.useContext(AppContext);

  React.useEffect(() => {
    addPageClassToBody(id);
    uiStore.setPageTitle(id);

    return () => {
      removePageClassFromBody();
    };
  });

  const addPageClassToBody = page => {
    removePageClassFromBody();
    htmlClass.add(document.body, `page--${page}`);
  };

  const removePageClassFromBody = () => {
    htmlClass.remove(document.body, /^page--/);
  };

  return (
    <Helmet>
      <title>{id}: Deal Room Events</title>
      <meta name="description" content={id} />
      <link rel="canonical" href="https://www.dealroomevents.com/" />
    </Helmet>
  );
});

Title.propTypes = {
  id: PropTypes.string.isRequired,
};

export default injectIntl(Title);
