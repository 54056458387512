import firebase from 'firebase/app';
import 'firebase/database';

export const subscribePollList = (eventId, moderator, cb) => {
  firebase
    .database()
    .ref(`/newEvents/${eventId}/polls/${eventId}/${moderator ? 'private' : 'public'}`)
    .on('value', snapshot => {
      cb(snapshot);
    });
};

export const subscribeAnswerByUser = (eventId, questionId, pId, cb) => {
  firebase
    .database()
    .ref(`/newEvents/${eventId}/polls/${eventId}/private/${questionId}/answers/${pId}`)
    .on('value', snapshot => {
      cb(snapshot);
    });
};
