import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {Button} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import './InvitationsFilterByStatus.scss';

const InvitationsFilterByStatus = observer(() => {
  const {invitationStore} = React.useContext(AppContext);
  const {filterOptions, filterValues} = invitationStore;
  let options = filterOptions.state;
  const [active, setActive] = React.useState('all');

  const classes = type =>
    classNames('invitations-filter-status__btn', {
      primary: (!filterValues.state && type.trim() === active) || type.trim() === filterValues.state,
    });

  if (!filterValues.state && active !== 'all') {
    setActive('all');
  }

  return options ? (
    <div className="invitations-filter-status">
      <p className="invitations-filter-status__label">
        <FormattedMessage id="invitation.filter.status" />
      </p>
      <div className="invitations-filter-status__list">
        <Button
          className={classes('all')}
          size="small"
          onClick={() => {
            invitationStore.filterExact('state');
            setActive('all');
          }}
        >
          <FormattedMessage id="invitation.filter.status.all" />
        </Button>
        {options.map(type => (
          <Button
            className={classes(type)}
            size="small"
            key={type}
            onClick={() => {
              invitationStore.filterExact('state', type.trim());
              setActive(type);
            }}
          >
            <FormattedMessage id={`invitation.filter.status.option.${type}`} />
          </Button>
        ))}
      </div>
    </div>
  ) : null;
});

export default injectIntl(InvitationsFilterByStatus);
