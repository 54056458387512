import {rawTimeZones} from '@vvo/tzdb';

export function getGmtTimezoneByTimezoneName(timeZoneName: string): string | undefined {
  const rawTimezone = rawTimeZones.find(timeZone => timeZone.name === timeZoneName);
  const gmtTimeZone = rawTimezone ? parseInt(rawTimezone.rawFormat.substring(0, 5), 10) : NaN;
  if (!isNaN(gmtTimeZone)) {
    return `GMT${gmtTimeZone >= 0 ? '+' : ''}${gmtTimeZone}`;
  }
}

interface TimezoneDataList {
  key: number;
  text: string;
  value: string;
  className: string;
}
export function getTimezoneFieldDataList(className: string): Array<TimezoneDataList> {
  return rawTimeZones.map((timeZone, i) => {
    const gmtTimezone = parseInt(timeZone.rawFormat.substring(0, 5), 10);
    return {
      key: i,
      text: `GMT${gmtTimezone >= 0 ? '+' : ''}${gmtTimezone} ${timeZone.name}`,
      value: timeZone.name,
      className: className,
    };
  });
}
