import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Form, Button} from 'semantic-ui-react';
import {useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {AppContext} from '../../../store';
import ButtonLoading from '../../../ui/ButtonLoading';
import InvitationApi from '../../../services/api/invitationApi';
import Title from '../../shared/Title';
import {redirectBack} from '../../../services/helpers/redirectHelper';
import useMedia from '../../../hooks/useMedia';
import './MeetingFormRescheduleDecline.scss';
import {useLocation} from 'react-router-dom';

const MeetingFormRescheduleDecline = observer(props => {
  const intl = useIntl();
  const {invitation} = props;
  const {eventStore, userStore, modalStore} = React.useContext(AppContext);
  const {eventList} = eventStore;
  const eventDetails = eventList.filter(e => e.id === invitation.eid)[0];
  const {user} = userStore;
  const {register, errors, getValues} = useForm();
  const location = useLocation();
  const isMobile = useMedia('(max-width: 768px)');

  const onSubmit = () => {
    const message = getValues().message || 'Unfortunately I cannot reschedule.';
    return InvitationApi.rejectRescheduleInvitation(
      user.id,
      eventDetails.id,
      invitation.lastInvitationDetails.id,
      message,
      false,
    ).then(() => {
      backRedirect();
    });
  };

  const backRedirect = () => {
    if (isMobile) {
      redirectBack(location);
    } else {
      modalStore.close('attendeeMeet');
    }
  };

  return (
    <>
      <Title id="meeting.reschedule-decline" />
      <Form className="meeting-reschedule-decline">
        <Form.Field className="field__textarea" error={!!errors.username}>
          <div className="ui left input">
            <textarea
              rows="4"
              name="message"
              placeholder={intl.formatMessage({id: 'meeting.form.reschedule.decline.placeholder'})}
              ref={register()}
            />
          </div>
        </Form.Field>
        <div className="meeting-reschedule-decline__action">
          <Button color="red" basic onClick={() => backRedirect()}>
            <FormattedMessage id="meeting.form.reschedule.decline.btn.cancel" />
          </Button>
          <ButtonLoading color="blue" onClick={() => onSubmit()}>
            <FormattedMessage id="meeting.form.reschedule.decline.btn.confirm" />
          </ButtonLoading>
        </div>
      </Form>
    </>
  );
});

MeetingFormRescheduleDecline.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.object]),
};

export default MeetingFormRescheduleDecline;
