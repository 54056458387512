import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import classNames from 'classnames';
import {useHistory, useLocation} from 'react-router-dom';
import {injectIntl, useIntl} from 'react-intl';
import {DateTime} from 'luxon';
import {Icon} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import TimeService from '../../../services/core/time-service';
import ParserText from '../../../ui/ParserText';
import ChatMessageByInvitation from '../ChatMessageByInvitation';
import ChatMessageByContact from '../ChatMessageByContact';
import tickImg from '../../../assets/images/icons/tick.svg';
import doubleTickImg from '../../../assets/images/icons/double-tick-indicator.svg';
import attendeeImg from '../../../assets/images/defaultImages/attendee.png';
import './ChatMessage.scss';

const ChatMessage = observer(props => {
  const {
    message,
    room,
    floating,
    messageId,
    messageIndex,
    messagesAmount,
    previousMessage,
    nextMessage,
    interlocutor,
    checkUsernameIsClickable,
  } = props;
  const {userStore, chatStore, modalStore} = React.useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const userId = userStore.user.id;
  const interlocutorInfo = room['members'][interlocutor] || {};

  const lastMessageSeen = room.members[interlocutor] && room.members[interlocutor].lastSeenMessage;
  const countReadMessage = Object.keys(room.messages).findIndex(i => i === lastMessageSeen) + 1;
  const unreadMessages = Object.keys(room.messages).slice(countReadMessage);

  const messageDay =
    previousMessage &&
    previousMessage.dt &&
    TimeService.getMomentUser(TimeService.getCurrentUserTimezone(), message.dt).hasSame(
      TimeService.getMomentUser(TimeService.getCurrentUserTimezone(), previousMessage.dt),
      'day',
    );
  const isToday = TimeService.getMomentUser(TimeService.getCurrentUserTimezone(), message.dt).hasSame(
    DateTime.now(),
    'day',
  );
  const sameMinute =
    previousMessage &&
    previousMessage.dt &&
    TimeService.getMomentUser(TimeService.getCurrentUserTimezone(), message.dt).hasSame(
      TimeService.getMomentUser(TimeService.getCurrentUserTimezone(), previousMessage.dt),
      'minute',
    );
  const lastSameMinute =
    nextMessage &&
    TimeService.getMomentUser(TimeService.getCurrentUserTimezone(), message.dt).hasSame(
      TimeService.getMomentUser(TimeService.getCurrentUserTimezone(), nextMessage.dt),
      'minute',
    );
  const defaultImage = attendeeImg;

  const openOpponentProfile = interlocutorInfo => {
    const interlocutorEventInfo = interlocutorInfo.eventInfo;
    interlocutorEventInfo &&
      history.push({
        pathname: `/event/${interlocutorEventInfo.eventId}/attendees/${interlocutorEventInfo.attendeeId}`,
        state: {
          background: location,
          page: 'attendees',
          title: interlocutorInfo.name,
          openedFromChat: true,
        },
      });
  };

  const chatMessageTypeClass = type => {
    const invMessageTypes = ['cmreq', 'mreq', 'revmreq', 'rmreq', 'rreq', 'rres', 'cres', 'lses'];

    if (type === 'contact') {
      return 'chat-msg--contact';
    }
    if (invMessageTypes.includes(type)) {
      return 'chat-msg--inv';
    }
    return 'chat-msg--user';
  };

  const deleteMessage = (room, messageId) => {
    const messageInfo = {
      roomId: room?.details?.id,
      messageId,
    };

    if (messageInfo.roomId && messageInfo.messageId) {
      modalStore.open('messageActionConfirmationModal', {type: 'delete', messageInfo});
    }
  };

  const classes = classNames(`chat-msg ${chatMessageTypeClass(message.type)}`, {
    [message.type]: message.type,
    'msg-admin': message.sndr === userId,
    floating,
    'first-msg': (previousMessage && previousMessage.sndr !== message.sndr) || (previousMessage && !messageDay),
    'same-min': sameMinute && !floating && !message.type,
    'next-same-min': lastSameMinute && !floating && !message.type,
    'date-divided': !messageDay && !isToday && !floating,
    today: isToday && !floating,
    'today-divided': !messageDay && isToday && !floating,
    'msg-deleted': !message.msg && !message.type,
  });

  React.useEffect(() => {
    if (messageIndex === messagesAmount - 1) {
      chatStore.markLastSeenMessage(room.details.id, userId, messageId);
    }
  }, [chatStore, messageId, messageIndex, messagesAmount, room.details.id, userId]);

  const CurrentStatus = mId => {
    if (unreadMessages.includes(mId)) {
      return (
        <img className={`chat-msg__seen waiting ${floating ? 'floating' : ''}`} src={tickImg} alt="tickIndicator" />
      );
    }
    return <img className={`chat-msg__seen ${floating ? 'floating' : ''}`} src={doubleTickImg} alt="tickIndicator" />;
  };

  return room.members[message.sndr] ? (
    <div className={classes} data-message={messagesAmount - messageIndex}>
      <div className="chat-msg__metadata-divider">
        {isToday
          ? 'Today'
          : TimeService.getMomentUser(TimeService.getCurrentUserTimezone(), message.dt).toFormat('dd LLLL yyyy')}
      </div>
      <div className="chat-msg__body">
        <div className="chat-msg__avatar">
          <img
            className={classNames({
              'chat-msg__avatar--profile-link': checkUsernameIsClickable,
            })}
            onClick={() => checkUsernameIsClickable && openOpponentProfile(interlocutorInfo)}
            src={room.members[message.sndr].profilePhoto || defaultImage}
            alt={room.members[message.sndr].name}
          />
        </div>
        <div className="chat-msg__content">
          <div className="chat-msg__author">{room.members[message.sndr].fullName}</div>
          <div className="chat-msg__text-wrapper">
            {message.msg && message.sndr === userId && (!message.type || message.type === 'contact') ? (
              <Icon
                name="trash alternate"
                className="chat-msg__delete-btn"
                onClick={() => deleteMessage(room, messageId)}
              />
            ) : null}
            <div className="chat-msg__text">
              {!message.type ? (
                <ParserText videoEmbedded>
                  {message.msg || intl.formatMessage({id: 'chat.message.deleted.text'})}
                </ParserText>
              ) : message.type === 'contact' ? (
                <ChatMessageByContact message={message} room={room} />
              ) : (
                <ChatMessageByInvitation message={message} nextMessage={nextMessage} room={room} />
              )}
            </div>
          </div>
          <div className="chat-msg__metadata">
            {CurrentStatus(messageId)}
            <div className="metadata-time">
              {TimeService.getMomentUser(TimeService.getCurrentUserTimezone(), message.dt).toFormat('t')}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
});

ChatMessage.propTypes = {
  room: PropTypes.oneOfType([PropTypes.object]).isRequired,
  messageIndex: PropTypes.number,
  messagesAmount: PropTypes.number,
  message: PropTypes.oneOfType([PropTypes.object]).isRequired,
  previousMessage: PropTypes.oneOfType([PropTypes.object]),
  messageId: PropTypes.string,
  scroll: PropTypes.func,
  floating: PropTypes.bool,
  interlocutor: PropTypes.string,
  checkUsernameIsClickable: PropTypes.bool,
};

ChatMessage.defaultProps = {
  messageIndex: 0,
  messagesAmount: 0,
  previousMessage: {},
  messageId: '',
  scroll: () => {},
  floating: false,
};

export default injectIntl(ChatMessage);
