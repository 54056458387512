import {getTimezoneFieldDataList} from '../../../utils/getGmtTimezones';

export const UserFields = [
  {
    type: 'input',
    fieldName: 'name',
    placeholder: 'John',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'surname',
    placeholder: 'Doe',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'username',
    placeholder: 'user@email.com',
    required: true,
    disabled: true,
  },
  {
    type: 'select',
    fieldName: 'timezone',
    placeholder: 'Time zone',
    dataList: getTimezoneFieldDataList('fds'),
    required: false,
  },
];
