import React from 'react';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import {Tab} from 'semantic-ui-react';
import Profile from '../../../pages/Profile';
import AccountNotificationSettings from '../AccountNotificationSettings';
import Security from '../../../pages/Security';
import ConnectedEmails from '../../../pages/ConnectedEmails';
import './AccountTabs.scss';

const AccountTabs = observer(props => {
  const {defaultTab, intl} = props;

  const panes = [
    {
      menuItem: intl.formatMessage({id: 'account.nav.link.1'}),
      render: () => (
        <Tab.Pane>
          <Profile />
        </Tab.Pane>
      ),
    },
    {
      menuItem: intl.formatMessage({id: 'account.nav.link.2'}),
      render: () => (
        <Tab.Pane>
          <AccountNotificationSettings />
        </Tab.Pane>
      ),
    },
    {
      menuItem: intl.formatMessage({id: 'account.nav.link.3'}),
      render: () => (
        <Tab.Pane>
          <Security />
        </Tab.Pane>
      ),
    },
    {
      menuItem: intl.formatMessage({id: 'account.nav.link.4'}),
      render: () => (
        <Tab.Pane>
          <ConnectedEmails />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className="account-tab">
      <Tab panes={panes} defaultActiveIndex={defaultTab} />
    </div>
  );
});

export default injectIntl(AccountTabs);
