import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Button, Dropdown, Form} from 'semantic-ui-react';
import {useForm} from 'react-hook-form';
import Modal from '../../../ui/Modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {AppContext} from '../../../store';
import AttendeePreview from '../../attendee/AttendeePreview/AttendeePreview';
import InvitationApi from '../../../services/api/invitationApi';
import ButtonLoading from '../../../ui/ButtonLoading';
import MeetingTimeSlots from '../../meeting/MeetingTimeSlots';
import {redirectBack} from '../../../services/helpers/redirectHelper';
import useMedia from '../../../hooks/useMedia';
import './PreBookedMeeting.scss';
import PersonItem from "../../../ui/PersonItem";
import debounce from "lodash/debounce";
import MeetingApi from "../../../services/api/meetingApi";
import {ParticipantStore} from "../../../store/domainStore/participantStore";
import AgendaContent from "../../agenda/AgendaContent/AgendaContent";

const ShowAgenda = observer(props => {
  const {modalStore} = React.useContext(AppContext);
  const {open, attendee} = modalStore.modals.showAgendaModal;
  const  closeModal = ()=>{
    modalStore.close('showAgendaModal')
  }
  return (
    <Modal
      className="pre-booked-meeting-modal"
      open={open}
      onClose={() => closeModal()}
    >
      <h3>
        <FormattedMessage id="showAgenda.modal.header" />
      </h3>
      <AgendaContent attendee={attendee}/>
    </Modal>
  );
});

ShowAgenda.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
  invitation: PropTypes.oneOfType([PropTypes.object]),
};

export default ShowAgenda;
