export const meetingStatus = {
  INVITE_NEW: 'INVITE_NEW',
  INVITE_PENDING: 'INVITE_PENDING',
  INVITE_RECEIVED: 'INVITE_RECEIVED',
  INVITE_LIMIT: 'INVITE_LIMIT',
  INVITE_DETAILS_LOADING: 'INVITE_DETAILS_LOADING',
  INVITE_REJECTED: 'INVITE_REJECTED',
  INVITE_NOT_FOUND: 'INVITE_NOT_FOUND',
  MEETING_DETAILS: 'MEETING_DETAILS',
  MEETING_RESCHEDULE_PENDING: 'MEETING_RESCHEDULE_PENDING',
  MEETING_RESCHEDULE: 'MEETING_RESCHEDULE',
};
