import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {AppContext} from '../../../store';
import {COUNT_MESSAGES} from '../../../store/domainStore/chatStore';
import ChatMessage from '../ChatMessage';
import './ChatMessagesList.scss';

const ChatMessagesList = observer(props => {
  const {isFloating} = props;
  const {userStore, chatStore, eventStore} = React.useContext(AppContext);
  const {eventList} = eventStore;
  const {roomsDetails, selectedRoom, floatingSelectedRoom} = chatStore;
  const selectChat = isFloating ? floatingSelectedRoom : selectedRoom;
  const room = roomsDetails[selectChat];
  const listRef = React.createRef();
  const interlocutorsArray = Object.keys(room.members).filter(member => member !== userStore.user.id);
  const interlocutor = interlocutorsArray.length === 1 ? interlocutorsArray[0] : '';
  const interlocutorInfo = room['members'][interlocutor] || {};
  const checkUsernameIsClickable =
    interlocutorInfo.eventInfo &&
    !!interlocutorInfo.eventInfo.attendeeId &&
    !!interlocutorInfo.eventInfo.eventId &&
    eventList.some(event => event.id === interlocutorInfo.eventInfo.eventId);

  const onScroll = e => {
    if (
      e.target.scrollHeight + e.target.scrollTop - e.target.clientHeight === 0 &&
      room.messages &&
      Object.keys(room.messages).length >= COUNT_MESSAGES &&
      !roomsDetails[selectChat].loaded
    ) {
      chatStore.getRoomsNextMessages(room.details.id);
    }
  };

  React.useEffect(() => {
    if (listRef) {
      listRef.current.addEventListener('scroll', onScroll);
    }

    return () => window.removeEventListener('scroll', onScroll);
  }, [listRef]);

  return (
    <div className="chat-messages-list" ref={listRef}>
      <div className="chat-section">
        {room.loaded ? (
          <div className="chat-section__all">
            <FormattedMessage id="chat.messages.list.all" />
          </div>
        ) : null}
        {/* eslint-disable react/no-array-index-key */}
        {room.messages &&
          Object.keys(room.messages)
            .sort((a, b) => room.messages[a].dt - room.messages[b].dt)
            .map((messageId, index) => (
              <ChatMessage
                previousMessage={room.messages && room.messages[Object.keys(room.messages)[index !== 0 && index - 1]]}
                nextMessage={room.messages && room.messages[Object.keys(room.messages)[index !== 0 && index + 1]]}
                room={room}
                messageIndex={index}
                messagesAmount={room.messages && Object.keys(room.messages).length}
                message={room.messages && room.messages[messageId]}
                messageId={messageId}
                key={Object.keys(room.messages).length - index}
                interlocutor={interlocutor}
                checkUsernameIsClickable={checkUsernameIsClickable}
              />
            ))}
        {/* eslint-enable */}
      </div>
    </div>
  );
});

ChatMessagesList.defaultProps = {
  floating: PropTypes.bool,
};

ChatMessagesList.defaultProps = {
  floating: false,
};

export default injectIntl(ChatMessagesList);
