import firebase from 'firebase/app';
import 'firebase/database';

export const subscribeAreasList = (eventId, cb) => {
  firebase
    .database()
    .ref(`/newEvents/${eventId}/areas`)
    .on(
      'value',
      snapshot => {
        cb(snapshot);
      },
      errorObject => {
        cb();
        console.log(errorObject);
      },
    );
};

export const subscribeAttendeeCheckIns = (eventId, participantId, cb) => {
  firebase
    .database()
    .ref(`/newEvents/${eventId}/participants/${participantId}/checkIns`)
    .on('value', snapshot => {
      cb(snapshot);
    });
};

export const markBadgeAsPrinted = (eventId, ticketId, status) => {
  firebase
    .database()
    .ref(`/newEvents/${eventId}/badges/astate/${ticketId}/`)
    .child('printed')
    .set(status)
    .then(el => el)
    .catch(error => {
      console.log('error', error);
    });
};

// Currently working only for ZPL printing
export const printByPrintQueue = (eventId, ticketId, printer) => {
  return firebase
    .database()
    .ref(`/newEvents/${eventId}/printing/queue/`)
    .child(ticketId)
    .set({...printer})
    .then(el => el);
};

// Gets a printer from the queue. If there is a printer, then printing is in progress.
export const getPrinterFromPrintQueue = (eventId, ticketId) => {
  return firebase
    .database()
    .ref(`/newEvents/${eventId}/printing/queue/${ticketId}`)
    .once('value')
    .then(snapshot => snapshot);
};

export const getBadgePrintingStatus = (eventId, ticketId) => {
  return firebase
    .database()
    .ref(`/newEvents/${eventId}/badges/astate/${ticketId}`)
    .once('value')
    .then(snapshot => snapshot);
};

export const subscribeBadgesForCheckIn = (eventId, cb) => {
  firebase
    .database()
    .ref(`/newEvents/${eventId}/badges/data/badgesForCheckIn`)
    .on('value', snapshot => {
      cb(snapshot);
    });
};
