import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Popup, Form, Label, Icon} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import TimeService from '../../../services/core/time-service';
import {boothDetailsLocation} from '../../../services/helpers/expoHelper';
import AttendeePreview from '../../attendee/AttendeePreview';
import ImageLazy from '../../../ui/ImageLazy';
import {startMeeting} from '../../../services/helpers/meetingHelper';
import Title from '../../shared/Title';
import {useHistory, useLocation} from 'react-router-dom';
import {openChatMain} from '../../../services/helpers/chatHelper';
import useMedia from '../../../hooks/useMedia';
import {ReactComponent as AddIcon} from '../../../assets/images/icons/add.svg';
import bannerImg from '../../../assets/images/defaultImages/banner.jpg';
import {ReactComponent as LocationImg} from '../../../assets/images/icons/location.svg';
import './MeetingDetails.scss';
import {ParticipantStore} from "../../../store/domainStore/participantStore";

const MeetingDetailsView = observer(props => {
  const {meeting, preloadLazyImages, state} = props;
  const {eventStore, userStore, modalStore, exhibitorStore, invitationStore} = React.useContext(AppContext);
  const {invitations} = invitationStore;
  const [meBoothDetails, setMeeBoothDetails] = React.useState({});
  const {eventList} = eventStore;
  const {view} = exhibitorStore;
  const {myParticipantBooth, user} = userStore;
  const eventDetails = eventList?.filter(event => event.id === meeting.eventId)[0];
  const meParticipantTypesConf = meeting.me.participantTypes[meeting.me.typeId];
  const imageUrl = eventDetails?.images.logo || bannerImg;
  const history = useHistory();
  const location = useLocation();
  const invitation = invitations.find(inv => meeting && inv?.meeting?.id === meeting.id);
  const [openPopup, setOpenPopup] = React.useState(false);
  const isMobile = useMedia('(max-width: 768px)');

  React.useEffect(() => {
    if (!myParticipantBooth && meeting.me.boothId && !Object.keys(meBoothDetails).length) {
      view.getExhibitorDetail(meeting.eventId, meeting.me.boothId).then(meBooth => setMeeBoothDetails(meBooth));
    }
    return () => {};
  }, [meBoothDetails, myParticipantBooth]);

  const boothLocation = !isEmpty(meeting)
    ? boothDetailsLocation(meeting, eventDetails, myParticipantBooth, meBoothDetails)
    : null;
  const remaining = meeting.startsAt - TimeService.now();

  const reservedPart = ParticipantStore.PARTICIPANT_TABLE_RESERVER(meeting.me?.eventId);
  const reservedTable = meeting.participants.filter(attendee => attendee.id === reservedPart.id).length > 0;
  const contentBtn = () => {
    if (
      (meeting.upcomingState === 'starting' || meeting.upcomingState === 'started') &&
      eventDetails.type !== 'normal'
    ) {
      return (
        <Button color="blue" onClick={() => startMeeting(meeting)}>
          <FormattedMessage id="meeting.btn.start" />
        </Button>
      );
    }
    if (meeting.guest) {
      return (
        <>
          <Button
            color="red"
            basic
            onClick={() => {
              props.onClick('cancelMeeting');
            }}
          >
            <FormattedMessage id="meeting.btn.leave" />
          </Button>
          {eventDetails?.type === 'online' || eventDetails?.type === 'hybrid' ? (
            <Button color="blue" onClick={() => startMeeting(meeting)}>
              <FormattedMessage id="meeting.btn.start" />
            </Button>
          ) : null}
        </>
      );
    }
    if (remaining >= 300000 || eventDetails?.type === 'normal') {
      return (
        <>
          <Button
            color="red"
            basic
            onClick={() => {
              props.onClick('cancelMeeting');
            }}
          >
            <FormattedMessage id="meeting.btn.cancel" />
          </Button>
          {!reservedTable?<Button
            color="blue"
            onClick={() => {
              if (state === 'reschedule') {
                isMobile
                  ? props.onClick('reschedulePending')
                  : modalStore.open('attendeeMeet', {
                      attendee: meeting.participants[0],
                      action: null,
                      isBooth: !!invitation.toBoothId,
                    });
              } else {
                props.onClick('requestReschedule');
              }
            }}
          >
            {state === 'reschedule' ? (
              <FormattedMessage id="invitation.btn.reschedule.pending" />
            ) : (
              <FormattedMessage id="meeting.btn.reschedule" />
            )}
          </Button>:null}
        </>
      );
    } else {
      return (
        <>
          {!reservedTable?<Button
            color="blue"
            basic
            onClick={() => {
              if (state === 'reschedule') {
                isMobile
                  ? props.onClick('reschedulePending')
                  : modalStore.open('attendeeMeet', {
                      attendee: meeting.participants[0],
                      action: null,
                      isBooth: !!invitation.toBoothId,
                    });
              } else {
                props.onClick('requestReschedule');
              }
            }}
          >
            {state === 'reschedule' ? (
              <FormattedMessage id="invitation.btn.reschedule.pending" />
            ) : (
              <FormattedMessage id="meeting.btn.reschedule" />
            )}
          </Button>:null}
          <Button color="blue" onClick={() => startMeeting(meeting)}>
            <FormattedMessage id="meeting.btn.start" />
          </Button>
        </>
      );
    }
  };

  return !isEmpty(meeting) ? (
    <>
      <Title id="meeting-details" />
      <Form className="meeting-details">
        {meeting.upcomingState === 'started' ? (
          <div className="agenda-item__label">
            <FormattedMessage id="agenda.item.live.label" />
          </div>
        ) : null}
        <div className="meeting-details__info">
          <div className="meeting-details__details">
            {boothLocation ? (
              <span className="meeting-details__location">
                <LocationImg />
                {boothLocation}
              </span>
            ) : null}
            <div className="meeting-details__date">
              <div className="meeting-details__date--time">
                {meeting.sdts.monthFull}, {meeting.sdts.hour} - {meeting.edts.hour}
                {eventDetails?.type === 'normal' ? (
                  <>
                    {' '}
                    <FormattedMessage id="meeting.date.eventTime" />
                  </>
                ) : null}
              </div>
              {meeting.sdtsReal ? (
                <div className="meeting-details__date--event-time">
                  {meeting.sdtsReal.monthFull}, {meeting.sdtsReal.hour} - {meeting.edtsReal.hour}{' '}
                  <FormattedMessage id="meeting.date.yourTime" />
                </div>
              ) : null}
            </div>
            <div className="meeting-details__event">{eventDetails ? eventDetails.name : null}</div>
          </div>
          <div className="meeting-details__event-logo">
            <ImageLazy
              src={imageUrl}
              errorSrc={bannerImg}
              alt={eventDetails.name}
              visibleByDefault={preloadLazyImages}
            />
          </div>
        </div>
        <div className="meeting-details__attendees">
          <div className="meeting-details__attendee-list">
            <AttendeePreview
              attendee={meeting.me}
              boothDetails={meBoothDetails}
              guest={meeting.guest}
              preloadLazyImages={preloadLazyImages}
            />
            {meeting.participants.map(attendee => {
              if(!reservedTable) {
                return (
                  <AttendeePreview key={attendee.id} attendee={attendee}
                                   preloadLazyImages={preloadLazyImages}/>
                )
              }
              return null;
            })}
          </div>
          {!meeting.expired && !meParticipantTypesConf?.disableGuest && !meeting.guest ? (
            <div className="meeting-details__attendee-guest">
              <Button
                icon
                className="meeting-details__add-guest"
                onClick={() => modalStore.open('addGuestToMeetingModal', {meeting: meeting})}
              >
                <AddIcon />
                <span>
                  <FormattedMessage id="agenda.item.addGuest.btn" />
                </span>
              </Button>
            </div>
          ) : null}
        </div>
        {meeting.preBooked ? (
          <Label className="agenda-item__prebooked-badge" color="yellow">
            <Icon name="star" />
            <FormattedMessage id={reservedTable?"agenda.item.reservedTable.label":"agenda.item.preBookedByOrganizer.label"} />
          </Label>
        ) : null}
        {meeting.expired ? null : (
          <div className={classNames('meeting-details__action', {guest: meeting.guest})}>
            <div className={"expl-buttons"}>
            {
              contentBtn()
            }
            </div>
            <Popup
              trigger={<div className={"meeting-details__btn-more"}><i className="ellipsis horizontal icon button" /></div>}
              content={
                <div role="list" className="meeting-details__more-list">
                  {!(meeting.onSite || (eventDetails && eventDetails.type === 'normal')) &&
                  !meeting.guest &&
                  meeting.upcomingState !== 'starting' &&
                  meeting.upcomingState !== 'started' &&
                  remaining >= 300000 ? (
                    <div
                      aria-hidden
                      className="item"
                      onClick={() => {
                        startMeeting(meeting);
                      }}
                    >
                      <div className="content">
                        <FormattedMessage id="meeting.btn.start" />
                      </div>
                    </div>
                  ) : (
                    <div
                      aria-hidden
                      className="item"
                      onClick={() => {
                        props.onClick('cancelMeeting');
                      }}
                    >
                      <div className="content">
                        <FormattedMessage id="meeting.btn.cancel" />
                      </div>
                    </div>
                  )}
                  <div
                    aria-hidden
                    className="item"
                    onClick={() =>
                      openChatMain(meeting.participants[0], user, history, location, () => {
                        invitationStore.showInvitation(false);
                      })
                    }
                  >
                    <div className="content">
                      <FormattedMessage id="meeting.btn.chat" />
                    </div>
                  </div>
                </div>
              }
              className="meeting-details__popup"
              basic
              position="bottom right"
              on="click"
              onClose={() => setOpenPopup(false)}
              onOpen={() => setOpenPopup(true)}
              open={openPopup}
            />
          </div>
        )}
      </Form>
    </>
  ) : null;
});

MeetingDetailsView.propTypes = {
  meeting: PropTypes.oneOfType([PropTypes.object]).isRequired,
  actionType: PropTypes.string,
  onClick: PropTypes.func,
};

MeetingDetailsView.defaultProps = {
  onClick: () => {},
};

export default injectIntl(MeetingDetailsView);
