import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {LocalStore} from '../../../services/core/storage-service';
import htmlClass from '../../../utils/htmlClass';
import {AppContext} from '../../../store';
import MobileSidebarMenu from '../MobileSidebarMenu';
import {ReactComponent as CloseImg} from '../../../assets/images/icons/closeBold.svg';
import {ReactComponent as AgendaImg} from '../../../assets/images/icons/eventNav/agenda.svg';
import useChangeHistoryCallbackAction from '../../../hooks/useChangeHistoryCallbackAction';
import './MobileHeader.scss';

const MobileHeader = observer(() => {
  const {eventStore, uiStore} = React.useContext(AppContext);
  const {eventDetails} = eventStore;
  const {pageTitle} = uiStore;
  const location = useLocation();
  const history = useHistory();
  const pageURL = location.pathname;

  useChangeHistoryCallbackAction(() => {
    if (location && location.state && location.state.prevPage) {
      uiStore.setPageTitle(location.state.prevPage);
    }
  });

  React.useEffect(() => {
    addPageClassToBody(pageTitle);

    return () => {
      removePageClassFromBody();
    };
  });

  const addPageClassToBody = page => {
    const pageClass = page ? `page--${page}` : `page--common`;
    removePageClassFromBody();
    htmlClass.add(document.body, pageClass);
  };

  const removePageClassFromBody = () => {
    htmlClass.remove(document.body, /^page--/);
  };

  const btnMyEventProfile = (
    <Link className="header-mobile__agenda" role="button" aria-hidden="true" to="/my-agenda">
      <AgendaImg />
      <span>
        <FormattedMessage id="mobile.header.myAgenda.btn" />
      </span>
    </Link>
  );

  const btnBack =
    pageURL !== '/event/list' ? (
      <div
        className="header-mobile__back-btn"
        role="button"
        aria-hidden="true"
        onClick={() => {
          const lastVisitedEvent = LocalStore.getObject('lastVisitedEvent');
          const {lastVisitedEventPath} = lastVisitedEvent;
          const eventProfileIsFilled = Object.keys(LocalStore.getObject('filledEventProfileForEvents')).some(
            eventId => eventId === lastVisitedEvent.id,
          );

          if (lastVisitedEventPath && eventProfileIsFilled) {
            history.push(lastVisitedEventPath);
          } else {
            history.push('/event/list');
          }
        }}
      >
        <CloseImg />
      </div>
    ) : null;

  return (
    <header className="header-mobile" id="header">
      <div className="header-mobile__wrapper">
        <div className="header-mobile__btn">
          {(eventDetails &&
            window.location.pathname.split('/').length === 4 &&
            !window.location.pathname.endsWith('/event-profile')) ||
          window.location.pathname.endsWith('/event/list')
            ? btnMyEventProfile
            : btnBack}
        </div>
        <div className="header-mobile__details">
          <h2 className="header-mobile__name">
            {pageTitle ? <FormattedMessage id={`${pageTitle}.page.title`} /> : null}
          </h2>
        </div>
        <MobileSidebarMenu />
      </div>
    </header>
  );
});

MobileHeader.propTypes = {
  eventDetails: PropTypes.oneOfType([PropTypes.object]),
};

export default injectIntl(MobileHeader);
