import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import {Form, Button, Icon} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import {useFetch} from '../../../hooks/useFetch';
import PollApi from '../../../services/api/pollApi';
import './PollCreateQuestionForm.scss';

const PollCreateQuestionForm = observer(props => {
  const {intl} = props;
  const {pollStore, eventStore, modalStore} = React.useContext(AppContext);
  const {question, isUpdateQuestion} = pollStore;
  const {handleSubmit, register, errors, setValue, getValues, reset} = useForm();
  const [answersList, setAnswersList] = React.useState(['']);
  const pollAnswerRefs = React.useRef([]);

  const createPoll = useFetch({
    fetchFn: PollApi.createPoll,
    immediate: false,
  });

  const editPoll = useFetch({
    fetchFn: PollApi.editPoll,
    immediate: false,
  });

  const onSubmit = value => {
    if (isUpdateQuestion) {
      editPoll.makeRequest(value, question).then(() => modalStore.close('poll'));
    } else {
      createPoll.makeRequest(value, eventStore.eventDetails.id).then(() => modalStore.close('poll'));
    }
  };

  React.useEffect(() => {
    if (isUpdateQuestion) {
      setTimeout(() => {
        const answersEdit = question.answers.map(answer => answer.text);

        setAnswersList(answersEdit);
        setValue('pollQuestion', question.question);
        setValue('pollPublish', question.postAt > 0 || question.postAt === 0);
        setValue('pollVisible', question.autoPublishResults);
      });
    }

    return () => {
      pollStore.setQuestionForUpdate(null, false);
    };
  }, [pollStore]);

  const handleAddClick = () => {
    setAnswersList([...answersList, '']);
  };

  const handleRemoveClick = index => {
    const list = getValues().pollAnswers;
    list.splice(index, 1);
    setAnswersList(list);
    pollAnswerRefs.current = [];

    reset({
      pollQuestion: question ? question.question : getValues().pollQuestion,
      pollPublish: question ? !!question.postAt : getValues().pollPublish,
      pollVisible: question ? question.autoPublishResults : getValues().pollVisible,
    });
  };

  const handleNextAnswerFieldOnPressEnter = (el, index) => {
    if (el.keyCode === 13) {
      el.preventDefault();
      answersList.length === index + 1 && handleAddClick();
      setTimeout(() => {
        pollAnswerRefs.current[index + 1].focus();
      });
    }
  };

  return (
    <div className="poll-create">
      <Form size="large">
        <Form.Field className="field__textarea" error={!!errors.pollQuestion}>
          <label>
            <FormattedMessage id="poll.form.label.question" />
          </label>
          <textarea
            name="pollQuestion"
            id="poll-question"
            rows="2"
            onChange={e => {
              setValue(`pollQuestion`, e.target.value);
            }}
            ref={register({
              required: intl.formatMessage({id: 'validation.error.comment'}),
            })}
          />
        </Form.Field>
        <div className="poll-create__answers">
          <label>
            <FormattedMessage id="poll.form.label.answers" />
          </label>
          {answersList.map((x, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Form.Field key={i} error={errors.pollAnswers && !!errors.pollAnswers[i]}>
              <div className="ui action labeled input">
                <div className="ui basic label">{i + 1}</div>
                <input
                  name={`pollAnswers[${i}]`}
                  defaultValue={x}
                  onChange={e => {
                    setValue(`pollAnswers[${i}]`, e.target.value);
                  }}
                  onKeyDown={e => handleNextAnswerFieldOnPressEnter(e, i)}
                  ref={refEl => {
                    register(refEl, {
                      required: intl.formatMessage({id: 'validation.error.comment'}),
                    });
                    refEl && !pollAnswerRefs.current.includes(refEl) && pollAnswerRefs.current.push(refEl);
                  }}
                />
                {answersList.length !== 1 && (
                  <Button icon onClick={() => handleRemoveClick(i)} color="red">
                    <Icon name="minus" />
                  </Button>
                )}
                {answersList.length - 1 === i && (
                  <Button icon onClick={handleAddClick} color="green">
                    <Icon name="plus" />
                  </Button>
                )}
              </div>
            </Form.Field>
          ))}
        </div>
        <Form.Field className="field__checkbox">
          <div className="ui checkbox">
            <input
              name="pollPublish"
              type="checkbox"
              ref={register}
              onChange={e => {
                setValue(`pollPublish`, e.target.checked);
              }}
            />
            <label>
              <FormattedMessage id="poll.form.label.publish" />
            </label>
          </div>
        </Form.Field>
        <Form.Field className="field__checkbox">
          <div className="ui checkbox">
            <input
              name="pollVisible"
              type="checkbox"
              ref={register}
              onChange={e => {
                setValue(`pollVisible`, e.target.checked);
              }}
            />
            <label>
              <FormattedMessage id="poll.form.label.results" />
            </label>
          </div>
        </Form.Field>
        <Button
          onClick={handleSubmit(onSubmit)}
          loading={createPoll.loading || editPoll.loading}
          color="teal"
          size="large"
        >
          {isUpdateQuestion ? 'Update' : <FormattedMessage id="poll.form.btn.create" />}
        </Button>
      </Form>
    </div>
  );
});

PollCreateQuestionForm.propTypes = {
  eventDetails: PropTypes.objectOf(PropTypes.any),
};

export default injectIntl(PollCreateQuestionForm);
