import React from 'react';
import {observer} from 'mobx-react-lite';
import RegistrationForm from '../../components/auth/RegistrationForm';
import Title from '../../components/shared/Title';
import {AppContext} from '../../store';

const Registration = observer(() => {
  const {eventStore} = React.useContext(AppContext);
  const {eventDetails} = eventStore;

  return (
    <React.Fragment>
      <Title id="registration" event={eventDetails?.id} />
      <div className="registration-page">
        <RegistrationForm />
      </div>
    </React.Fragment>
  );
});

export default Registration;
