import React from 'react';
import PropTypes from 'prop-types';
import {useHistory, useLocation} from 'react-router-dom';
import {Icon, Modal, Transition} from 'semantic-ui-react';
import {LocalStore} from '../../services/core/storage-service';
import closeImg from '../../assets/images/icons/X.svg';
import './ModalUrl.scss';

function getUrl(location) {
  const path = location;
  const store = window.localStorage;
  let url = '';

  url = store.getItem('modalUrlFirstOpen');
  if (!url) {
    LocalStore.setObject('modalUrlFirstOpen', path);
  }

  url = LocalStore.getObject('modalUrlFirstOpen');

  return {url};
}

const ModalUrl = props => {
  const {open, children, size, closeIcon} = props;
  const history = useHistory();
  const {state} = useLocation();
  const location = useLocation();
  const {url} = getUrl(location);

  const close = e => {
    e.stopPropagation();
    localStorage.removeItem('modalUrlFirstOpen');
    history.push(state.background.pathname);
  };

  const back = () => {
    history.push(url, LocalStore.getObject('modalUrlFirstOpen'));
  };

  return (
    <Transition transitionOnMount unmountOnHide animation="fade down" duration={400}>
      <Modal closeIcon={closeIcon} open={open} onClose={close} size={size} centered {...props}>
        {url.pathname !== location.pathname ? (
          <div className="modal-btn-back" aria-hidden onClick={() => back()}>
            <Icon name="angle left" /> {url.state && url.state.title ? `Back to ${url.state.title}` : `Back`}
          </div>
        ) : null}
        <Modal.Content>{children}</Modal.Content>
      </Modal>
    </Transition>
  );
};

ModalUrl.propTypes = {
  children: PropTypes.node,
  closeIcon: PropTypes.node,
  open: PropTypes.bool,
  size: PropTypes.string,
};

ModalUrl.defaultProps = {
  children: '',
  open: true,
  size: 'tiny',
  closeIcon: <img className="close-icon" src={closeImg} alt="closeIcon" />,
};

export default ModalUrl;
