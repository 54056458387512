import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Dimmer, Segment, Loader} from 'semantic-ui-react';
import AttendeeCard from '../AttendeeCard';
import AttendeeQuestions from '../AttendeeQuestions';
import AttendeeButtons from '../AttendeeButtons';
import './AttendeeDetails.scss';

const AttendeeDetails = observer(props => {
  const {attendee, banner} = props;

  return (
    <div className="attendee-details">
      {attendee ? (
        <React.Fragment>
          {banner ? (
            <div className="event-banner">
              <img src={banner} alt="event-banner" width={720} height={96} />
            </div>
          ) : null}
          <AttendeeCard attendee={attendee} />
          <AttendeeQuestions categories={attendee.categories} />
          <AttendeeButtons attendee={attendee} />
        </React.Fragment>
      ) : (
        <Segment className="attendee-details__loader">
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        </Segment>
      )}
    </div>
  );
});

AttendeeDetails.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
  banner: PropTypes.string,
};

export default AttendeeDetails;
