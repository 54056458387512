import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Popup} from 'semantic-ui-react';
import {useLocation, useHistory} from 'react-router-dom';
import {AppContext} from '../../../store';
import {startMeeting} from '../../../services/helpers/meetingHelper';
import useMedia from '../../../hooks/useMedia';
import './AgendaCardSpeakerRole.scss';

const AgendaCardSpeakerRole = observer(props => {
  const {session} = props;
  const {eventStore, uiStore} = React.useContext(AppContext);
  const {eventList} = eventStore;
  const {pageTitle} = uiStore;
  const location = useLocation();
  const history = useHistory();
  const eventDetails = eventList?.filter(event => event.id === session.eventId)[0];
  const isMobile = useMedia('(max-width: 768px)');

  return (
    <div className="agenda-details speaker-role">
      <div className="agenda-item__name">{session.name}</div>
      <div className="agenda-item__role">You are speaker</div>
      {!session.expired &&
      session.upcomingState !== 'expired' &&
      session.onSite &&
      eventDetails &&
      eventDetails.type === 'hybrid' ? (
        <Popup
          content="This session is available only on-site. To take part in it, please proceed to the session location."
          trigger={
            <Button className="session-btn__start-stream" basic color="teal">
              <FormattedMessage id="agenda.item.startStreaming.btn.onSite" />
            </Button>
          }
        />
      ) : null}
      <div className="agenda-item__action">
        <Button
          className="agenda-btn__details"
          color="blue"
          basic
          onClick={() => {
            if (isMobile) {
              history.push({
                pathname: `/my-agenda/session/${session.eventId}/${session.rsesId || session.sdefId}`,
                state: {
                  background: location,
                  page: 'agenda',
                  title: session.name,
                  prevPage: pageTitle,
                },
              });
            } else {
              history.push({
                pathname: `/event/${session.eventId}/sessions/${session.rsesId || session.sdefId}`,
                state: {
                  background: location,
                  page: 'sessions',
                  title: session.name,
                },
              });
            }
          }}
        >
          <FormattedMessage id="agenda.item.viewDetails.btn" />
        </Button>
        {!session.expired &&
        session.upcomingState !== 'expired' &&
        !(session.onSite || (eventDetails && eventDetails.type === 'normal')) ? (
          <Button className="session-btn__start-stream" color="blue" onClick={() => startMeeting(session)}>
            {session.sessionDefinition.interactive ? (
              <FormattedMessage id="agenda.item.joinNow.btn" />
            ) : (
              <FormattedMessage id="agenda.item.startStreaming.btn" />
            )}
          </Button>
        ) : null}
      </div>
    </div>
  );
});

AgendaCardSpeakerRole.propTypes = {
  session: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default injectIntl(AgendaCardSpeakerRole);
