import {action, extendObservable, runInAction} from 'mobx';
import 'firebase/database';
import escapeRegExp from 'lodash/escapeRegExp';
import filter from 'lodash/filter';
import conforms from 'lodash/conforms';
import EventApi from '../../services/api/eventApi';
import {processEventForDetails, memoizedProcessEventForDetails, processEvent} from '../../services/helpers/eventHelper';

export class EventStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    extendObservable(this, {
      eventList: null,
      activeEvents: [],
      pastEvents: [],
      eventDetails: null,
      loading: false,
      error: null,
      filteredEvents: [],
      filterCriteria: ['name'],
      filterOptions: {},
      filters: {},
      domainDetails: null,
      loadedActiveEvents: false,
      isParticipantUpdated: false,
      allowedTabs: ['participant', 'agenda', 'venueMap', 'speakers', 'sponsor', 'partner', 'booth'],
    });
  }

  @action
  getEvents() {
    const eventObservables = [];

    return EventApi.getEventList()
      .then(resp => {
        runInAction(() => {
          let events = [];
          for (const eventIndex in resp.data) {
            const event = resp.data[eventIndex];
            processEvent(event, event.me);

            if (!event.isFinished && !this.activeEvents.some(e => e.id === event.id)) {
              this.activeEvents.push(event);
              eventObservables.push(this.getEventDetails(event.id, 'update'));
            } else {
              this.pastEvents.push(event);
            }
            events.push(event);
          }

          this.eventList = events;
          this.setOptionsForFilters(this.eventList);
        });
        return Promise.all([Promise.all(eventObservables)])
          .then(results => {
            runInAction(() => {
              this.loadedActiveEvents = true;
            });
            return results[0];
          })
          .catch(() => {
            runInAction(() => {
              this.loadedActiveEvents = true;
            });
          });
      })
      .catch(error => {
        this.rootStore.errorStore.addError(error);
      });
  }

  getEventForDetailsFromProcess = memoizedProcessEventForDetails();

  @action
  getEventDetails(id, useCache = false) {
    return EventApi.getEventDetails(id, useCache)
      .then(resp => this.getEventForDetailsFromProcess(resp.data, resp.data.me))
      .catch(error => {
        console.log('EventLayout Details error: ', {error});
        runInAction(() => {
          this.loading = false;
          this.error = error.response?.data?.message;
        });
        return Promise.reject(this.error);
      });
  }

  @action
  selectEvent(event) {
    this.eventDetails = event;
    this.eventDetails.presentTypes = event.eventType === 'hybrid' ? ['online', 'venue'] : [];
    this.setMeParticipant(event, event.me);
  }

  @action
  setMeParticipant(eventData, me) {
    this.meParticipant = me;
    if (eventData.participantTypes) {
      const typeObject = eventData.participantTypes[eventData.me.typeId];

      if (typeObject) {
        this.meParticipant.participantType = typeObject.texts[eventData.defaultLanguage];
        if (this.meParticipant.participantType == null) {
          // eslint-disable-next-line prefer-destructuring
          this.meParticipant.participantType = Object.values(typeObject.texts)[0];
        }

        this.meParticipant.participantTypeConf = typeObject;
      }
    }
  }

  @action.bound
  updateParticipantDetails(participantDetails) {
    this.getEventForDetailsFromProcess = memoizedProcessEventForDetails();

    return EventApi.updateParticipantDetails(participantDetails)
      .then(resp => {
        runInAction(() => {
          this.isParticipantUpdated = true;
        });
        EventApi.getEventDetails(participantDetails.eventId, false).then(resp => {
          const eDetails = this.getEventForDetailsFromProcess(resp.data, resp.data.me);
          this.setMeParticipant(eDetails, eDetails.me);
          // TODO Need to check and rework
          setTimeout(() => {
            this.rootStore.participantStore.loadParticipants(eDetails); // solution can be improved
            this.rootStore.participantStore.loadParticipantProfileData(eDetails);
          });
        });
        return Promise.resolve(resp.data);
      })
      .catch(error => Promise.reject(error.response.data));
  }

  @action
  resetParticipantUpdated() {
    this.isParticipantUpdated = false;
  }

  loadDomainDetails() {
    let eventName = window.location.hostname;
    const firstDotIndex = eventName.indexOf('.');
    if (firstDotIndex > 0) {
      eventName = eventName.substring(0, eventName.indexOf('.'));
    }
    // eventName - absaconference
    return EventApi.getEventDetailsByDomain(eventName)
      .then(resp => {
        runInAction(() => {
          this.domainDetails = processEventForDetails(resp.data, null, true);
        });
        return this.domainDetails;
      })
      .catch(err => Promise.reject(err));
  }

  @action
  setOptionsForFilters(events) {
    this.filterOptions = {};
    if (Object.keys(events).length > 0) {
      Object.keys(events).forEach(id => {
        const event = events[id];

        this.filterCriteria.forEach(criteria => {
          if (event[criteria]) {
            if (!this.filterOptions[criteria]) {
              this.filterOptions[criteria] = [event[criteria]];
            } else if (!this.filterOptions[criteria].includes(event[criteria])) {
              this.filterOptions[criteria].push(event[criteria]);
            }
          }
        });
      });
    }
  }

  @action
  applyFilter() {
    this.filteredEvents = filter(this.eventList, conforms(this.filters));
  }

  @action
  filterSearch(property, rule) {
    const re = new RegExp(escapeRegExp(rule), 'i');
    if (!rule) {
      this.removeFilter(property);
      return;
    }
    this.filters[property] = val => re.test(val);
    this.filters[property].value = rule;
    this.applyFilter();
  }

  @action
  removeFilter(property) {
    delete this.filters[property];
    this[property] = null;
    this.applyFilter();
  }

  @action
  clean() {
    this.eventDetails = null;
    this.error = null;
    this.loading = false;
  }

  @action
  cleanActiveEvents() {
    this.activeEvents = [];
    this.pastEvents = [];
    this.eventList = null;
  }
}
