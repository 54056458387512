import React from 'react';
import PropTypes from 'prop-types';
import {Loader, Dimmer, Segment} from 'semantic-ui-react';
import classNames from 'classnames';
import './LoaderPage.scss';

const LoaderPage = props => {
  const {className} = props;

  const classes = classNames('loader-page', {
    className,
  });

  return (
    <Segment className={classes}>
      <Dimmer active inverted>
        <Loader inverted />
      </Dimmer>
    </Segment>
  );
};

LoaderPage.propTypes = {
  className: PropTypes.string,
};

LoaderPage.defaultProps = {
  className: '',
};

export default LoaderPage;
