import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage} from 'react-intl';
import {Button, Icon} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import ButtonLoading from '../../../ui/ButtonLoading';
import InvitationApi from '../../../services/api/invitationApi';
import AttendeePreview from '../../attendee/AttendeePreview/AttendeePreview';
import useMedia from '../../../hooks/useMedia';
import './MeetingFormReschedulePending.scss';

const MeetingFormReschedulePending = observer(props => {
  const {invitation} = props;
  const {eventStore, userStore, modalStore, routerStore} = React.useContext(AppContext);
  const {eventList} = eventStore;
  const eventDetails = eventList.filter(e => e.id === invitation.eid)[0];
  const {user} = userStore;
  const isMobile = useMedia('(max-width: 768px)');

  const onSubmit = () => {
    return InvitationApi.revokeRescheduleInvitation(user.id, eventDetails.id, invitation.lastInvitationDetails.id).then(
      () => {
        if (isMobile) {
          routerStore.goBack();
        } else {
          modalStore.close('attendeeMeet');
        }
      },
    );
  };

  return (
    <div className="meeting-reschedule-pending">
      <AttendeePreview attendee={invitation.opponentParticipant} big />
      <div className="meeting-reschedule-pending__time">
        <Icon name="check" />
        <span>{invitation.cdtStr}</span>
      </div>
      <p className="meeting-reschedule-pending__message">{invitation.message}</p>
      <div className="meeting-reschedule-pending__action">
        <Button
          color="blue"
          basic
          onClick={() => {
            if (isMobile) {
              routerStore.goBack();
            } else {
              modalStore.close('attendeeMeet');
            }
          }}
        >
          <FormattedMessage id="meeting.form.reschedule.pending.btn.back" />
        </Button>
        <ButtonLoading color="red" onClick={() => onSubmit()}>
          <FormattedMessage id="meeting.form.reschedule.pending.btn.revoke" />
        </ButtonLoading>
      </div>
    </div>
  );
});

MeetingFormReschedulePending.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.object]),
};

export default MeetingFormReschedulePending;
