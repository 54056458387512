import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage} from 'react-intl';
import {Button, Form} from 'semantic-ui-react';
import {getTimezoneFieldDataList} from '../../../utils/getGmtTimezones';
import {useForm, FormProvider} from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import {AppContext} from '../../../store';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import TimeService from '../../../services/core/time-service';
import UniversalField from '../UniversalField/UniversalField';
import ButtonLoading from '../../../ui/ButtonLoading';
import './TimezoneForm.scss';

const TimezoneForm = observer(props => {
  const {registeredNewUser} = props;
  const {modalStore, userStore} = React.useContext(AppContext);
  const {user, error} = userStore;
  const methods = useForm();
  const {handleSubmit} = methods;
  const timezoneDataList = getTimezoneFieldDataList('fds');

  const closeModal = () => {
    modalStore.close('timezoneModal');
    userStore.setSkippedTimezone(user.timezone, TimeService.getMyLocalTimezone());
  };

  const onSubmit = value =>
    userStore
      .updateUserDetails(user.id, {
        action: 'profile',
        channels: {sms: user.phoneNumber || ''},
        name: user.name,
        surname: user.surname,
        timezone: value.timezone,
      })
      .then(() => {
        NotificationAlert.success(<FormattedMessage id="timezone.form.success" />);
        userStore.getDetails(true);
        closeModal();
      })
      .catch(err => {
        NotificationAlert.error(err.message);
      });

  return registeredNewUser ? (
    <div className="timezone-form">
      <h1 className="timezone-form__title">
        <FormattedMessage
          id="timezone.form.newUser.title"
          values={{
            timezone: <span>[{TimeService.getMyLocalTimezone()}]</span>,
          }}
        />
      </h1>
      <FormProvider {...methods}>
        <Form error={!isEmpty(methods.errors) || !!error}>
          <UniversalField
            key="timezone"
            label="Timezone"
            name="timezone"
            type="select"
            defaultValue={TimeService.getMyLocalTimezone() || ''}
            required={true}
            dataList={timezoneDataList}
            placeholder="timezone"
            error={methods.errors.timezone}
          />
          <div className="timezone-form__btn-group">
            <ButtonLoading primary className="timezone-form__btn-set" onClick={handleSubmit(onSubmit)}>
              <FormattedMessage id="timezone.form.btn.set" />
            </ButtonLoading>
          </div>
        </Form>
      </FormProvider>
    </div>
  ) : (
    <div className="timezone-form">
      <h1 className="timezone-form__title">
        <FormattedMessage id="timezone.form.title" />
      </h1>
      <FormProvider {...methods}>
        <Form error={!isEmpty(methods.errors) || !!error}>
          <UniversalField
            key="timezone"
            label="Timezone"
            name="timezone"
            type="select"
            defaultValue={TimeService.getMyLocalTimezone() || ''}
            required={true}
            dataList={timezoneDataList}
            placeholder="timezone"
            error={methods.errors.timezone}
          />
          <div className="timezone-form__current-time">{}</div>
          <div className="timezone-form__btn-group">
            <ButtonLoading primary className="timezone-form__btn-set" onClick={handleSubmit(onSubmit)}>
              <FormattedMessage id="timezone.form.btn.set" />
            </ButtonLoading>
            <Button icon className="timezone-form__decline" onClick={closeModal}>
              <FormattedMessage id="timezone.form.btn.decline" />
            </Button>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
});

export default TimezoneForm;
