import {extendObservable, computed, autorun, action} from 'mobx';
import debounce from 'lodash/debounce';
import inRange from 'lodash/inRange';
import reduce from 'lodash/reduce';

export class WindowSizeStore {
  constructor() {
    extendObservable(this, {
      windowWidth: null,
      screenWindow: null,
    });

    autorun(() =>
      setTimeout(() => {
        this.setWindow();
      }),
    );
  }

  @computed
  get windowSizes() {
    // TODO we need to return combine value
    const SIZES = {
      mobile: inRange(this.windowWidth, 0, 767),
      tablet: inRange(this.windowWidth, 768, 1024),
      desktop: inRange(this.windowWidth, 1025, Number.POSITIVE_INFINITY),
    };

    return reduce(
      SIZES,
      (result, value, key) => {
        if (value) {
          // eslint-disable-next-line no-param-reassign
          result = key;
        }
        return result;
      },
      [],
    );
  }

  @action
  setWindow = () => {
    if (typeof window === 'object') {
      this.screenWindow = window;
      this.handleWindowWidthChange();
      this.screenWindow.addEventListener('resize', this.handleWindowWidthChange);
    }
  };

  handleWindowWidthChange = debounce(() => {
    const width = this.screenWindow.innerWidth;
    this.setWindowWidth(width);
  }, 100);

  @action
  setWindowWidth = width => {
    this.windowWidth = width;
    return this.windowWidth;
  };
}
