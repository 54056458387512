import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Label} from 'semantic-ui-react';
import {v4 as uuidv4} from 'uuid';
import ParserText from '../../../ui/ParserText';
import './AttendeeQuestions.scss';

const AttendeeQuestions = observer(props => {
  const {categories} = props;

  const field = f => {
    const {category} = f;
    if (category.type === 'select') {
      return f.selectedCategoryIds ? (
        f.selectedCategoryIds.map(subCategoryId =>
          category.subCategories[subCategoryId] ? (
            <Label key={uuidv4()}>
              <ParserText>{category.subCategories[subCategoryId].name}</ParserText>
            </Label>
          ) : (
            'No info'
          ),
        )
      ) : (
        <span>No info</span>
      );
    }
    if (category.type === 'text') {
      return f.value ? (
        <Label>
          <ParserText>{f.value}</ParserText>
        </Label>
      ) : (
        <span>No info</span>
      );
    }
    return null;
  };

  return categories && categories.length > 0 ? (
    <div className="attendee-questions">
      {categories.map(c => {
        const {category} = c;
        return (
          <div key={uuidv4()} className="categories">
            <h4>
              <ParserText>{category.name}</ParserText>
            </h4>
            {field(c)}
          </div>
        );
      })}
    </div>
  ) : null;
});

AttendeeQuestions.propTypes = {
  categories: PropTypes.oneOfType([PropTypes.array]),
};

export default AttendeeQuestions;
