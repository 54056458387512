import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Confirm, Popup, Transition} from 'semantic-ui-react';
import {injectIntl, FormattedMessage} from 'react-intl';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import {useFetch} from '../../../hooks/useFetch';
import AnnouncementApi from '../../../services/api/announcementApi';
import './AnnouncementPopupModerator.scss';

const AnnouncementPopupModerator = observer(props => {
  const {announcement, open} = props;
  const {announcementStore, userStore, eventStore, modalStore} = React.useContext(AppContext);
  const {meParticipant} = eventStore;
  const [openEditPopup, setOpenEditPopup] = React.useState(false);
  const [confirmRemove, setConfirmRemove] = React.useState(false);

  React.useEffect(() => {
    announcementStore.getCommentList(announcement.eventId, announcement.id);
  }, [announcementStore, announcement, userStore]);

  React.useEffect(() => {
    setOpenEditPopup(false);
  }, [open]);

  const deleteAnnouncement = useFetch({
    fetchFn: AnnouncementApi.deleteAnnouncement,
    immediate: false,
  });

  const editContent = (
    <div role="list" className="announcement-edit">
      <div
        aria-hidden
        className="announcement-edit__item announcement-edit__item--edit"
        onClick={event => {
          event.stopPropagation();
          modalStore.open('announcementCreate', {announcement});
          setOpenEditPopup(false);
        }}
      >
        <div className="content">
          <FormattedMessage id="announcement.label.edit" />
        </div>
      </div>
      <div
        aria-hidden
        className="announcement-edit__item announcement-edit__item--remove"
        onClick={event => {
          event.stopPropagation();
          setConfirmRemove(true);
          setOpenEditPopup(false);
        }}
      >
        <div className="content">
          <FormattedMessage id="announcement.label.remove" />
        </div>
      </div>
    </div>
  );

  return announcement &&
    ((meParticipant.id === announcement.participantId && meParticipant.organizer) || meParticipant.organizer) ? (
    <div className="announcement-item__edit">
      <Popup
        trigger={<i className="ellipsis horizontal icon" />}
        content={editContent}
        offset={[3, 0]}
        position="bottom right"
        on="click"
        className="announcement-item__popup"
        onClose={() => setOpenEditPopup(false)}
        onOpen={event => {
          event.stopPropagation();
          setOpenEditPopup(true);
        }}
        open={openEditPopup}
        hideOnScroll
      />

      <Transition visible={confirmRemove} animation="fade down" duration={400}>
        <Confirm
          className="announcement-item__confirm"
          open={confirmRemove}
          cancelButton={{content: <FormattedMessage id="announcement.btn.no" />}}
          confirmButton={{
            loading: deleteAnnouncement.loading,
            disabled: deleteAnnouncement.loading,
            content: <FormattedMessage id="announcement.btn.remove" />,
          }}
          onCancel={() => {
            setConfirmRemove(false);
          }}
          onConfirm={() => {
            deleteAnnouncement
              .makeRequest(announcement)
              .then(() => {
                modalStore.close('announcementInfo');
                setConfirmRemove(false);
                NotificationAlert.success(<FormattedMessage id="announcement.popup.moderator.delete.success" />);
              })
              .catch(() => {
                NotificationAlert.error(<FormattedMessage id="announcement.popup.moderator.delete.error" />);
              });
          }}
        />
      </Transition>
    </div>
  ) : null;
});

AnnouncementPopupModerator.propTypes = {
  announcement: PropTypes.objectOf(PropTypes.any).isRequired,
  open: PropTypes.bool,
};

export default injectIntl(AnnouncementPopupModerator);
