import React from 'react';
import {observer} from 'mobx-react-lite';
import ForgotPasswordConfirmForm from '../../components/auth/ForgotPasswordConfirmForm';
import Title from '../../components/shared/Title';
import {AppContext} from '../../store';

const ForgotPasswordConfirm = observer(props => {
  const {event} = props;
  const {eventStore} = React.useContext(AppContext);
  const {eventDetails} = eventStore;

  return (
    <React.Fragment>
      <Title id="forgot-password-confirm" event={eventDetails?.id || event?.id} />
      <div className="forgot-password-confirm-page">
        <ForgotPasswordConfirmForm />
      </div>
    </React.Fragment>
  );
});

export default ForgotPasswordConfirm;
