import React from 'react';
import {toast} from 'react-toastify';
import {FormattedMessage} from 'react-intl';
import {ReactComponent as WarningImg} from '../../../assets/images/icons/toast/warning.svg';
import {ReactComponent as ErrorImg} from '../../../assets/images/icons/toast/error.svg';
import {ReactComponent as SuccessImg} from '../../../assets/images/icons/toast/success.svg';
import {ReactComponent as InfoImg} from '../../../assets/images/icons/toast/info.svg';
import './NotificationAlert.scss';

const NotificationAlert = {
  warning(message) {
    return toast.error(
      <div className="toast__body">
        <div className="toast__icon">
          <WarningImg />
        </div>
        <div className="toast__message">
          <div className="toast__message-title">
            <FormattedMessage id="notification.alert.warning" />
          </div>
          <div className="toast__message-text">{message}</div>
        </div>
      </div>,
    );
  },
  error(message) {
    return toast.error(
      <div className="toast__body">
        <div className="toast__icon">
          <ErrorImg />
        </div>
        <div className="toast__message">
          <div className="toast__message-title">
            <FormattedMessage id="notification.alert.error" />
          </div>
          <div className="toast__message-text">{message}</div>
        </div>
      </div>,
    );
  },
  success(message) {
    return toast.success(
      <div className="toast__body">
        <div className="toast__icon">
          <SuccessImg />
        </div>
        <div className="toast__message">
          <div className="toast__message-title">
            <FormattedMessage id="notification.alert.title.success" />
          </div>
          <div className="toast__message-text">{message}</div>
        </div>
      </div>,
    );
  },
  info(message) {
    return toast.info(
      <div className="toast__body">
        <div className="toast__icon">
          <InfoImg />
        </div>
        <div className="toast__message">
          <div className="toast__message-title">
            <FormattedMessage id="notification.alert.info" />
          </div>
          <div className="toast__message-text">{message}</div>
        </div>
      </div>,
    );
  },
};

export default NotificationAlert;
