import React from 'react';
import PropTypes from 'prop-types';
import * as linkify from 'linkifyjs';
import ReactMarkdown from 'react-markdown';
import './ParserText.scss';

const ParserText = props => {
  const {children, type, videoEmbedded} = props;

  const stripHtml = html => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const isExternalLink = url => {
    const tmp = document.createElement('a');
    tmp.href = url;
    return tmp.host !== window.location.host;
  };

  const linkCheck = (match, index) => {
    // url
    if (match.type === 'url') {
      const youtubeUrlReqExp =
        /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:watch\?(?:[\w-=&]*&)?v=|(?:embed|v)\/))([\w-]+)[\w-=&#]*/;

      if (videoEmbedded && youtubeUrlReqExp.test(match.href)) {
        // youtube url
        return (
          <iframe
            key={index}
            src={match.href.replace(youtubeUrlReqExp, 'https://www.youtube.com/embed/$1')}
            title={match.value}
            frameBorder="0"
            allowFullScreen
          />
        );
      }
      // default url
      return (
        <a key={index} rel="noreferrer" target={isExternalLink(match.href) ? '_blank' : null} href={match.href}>
          {match.value}
        </a>
      );
    }
    // email
    if (match.type === 'email') {
      return (
        <a key={index} href={match.href} target="_blank" rel="noreferrer">
          {match.value}
        </a>
      );
    }
    // default
    return match.value;
  };

  const parser = inputText => {
    if (!children) return inputText;

    const matches = linkify.find(children);
    const result = [];

    if (matches.length === 0) return inputText;

    matches.forEach((match, index) => {
      const prevMatch = matches[index - 1];
      const nextMatch = matches[index + 1];
      if (!prevMatch && match.start !== 0) result.push(inputText.substring(0, match.start));
      if (prevMatch && prevMatch.end !== match.start) result.push(inputText.substring(prevMatch.end, match.start));

      result.push(linkCheck(match, index));

      if (!nextMatch && match.end !== inputText.length) result.push(inputText.substring(match.end, inputText.length));
    });

    return result;
  };

  const content = () => {
    if (type === 'html') return <ReactMarkdown allowDangerousHtml>{children}</ReactMarkdown>;
    if (type === 'strippedHtml') return stripHtml(children);

    return parser(children);
  };

  return <div className="parser-text">{content()}</div>;
};

ParserText.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(['html', 'strippedHtml']),
  videoEmbedded: PropTypes.bool,
};

export default ParserText;
