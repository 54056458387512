import {stores} from '../../store';

export function chatMessageText(message: Record<string, unknown>, excludeObjectParams: Array<string>) {
  if (typeof message === 'object') {
    message = Object.assign({fullName: `${message.name} ${message.surname}`}, message);
    excludeObjectParams.forEach(e => delete message[e]);
    return Object.values(message)
      .filter(text => text !== '')
      .join('\n');
  }
  return message;
}

export function parseJsonFromText(message: string) {
  try {
    return JSON.parse(message);
  } catch {
    return message;
  }
}

export function openChatMain(attendee, user, history, location, callback) {
  try {
    if (user.id === attendee.userId || attendee.state === 'deleted') {
      return;
    }
    if (localStorage.getItem('modalUrlFirstOpen')) {
      localStorage.removeItem('modalUrlFirstOpen');
      history.push(location?.state.background.pathname);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (stores.windowSizeStore.windowSizes === 'mobile') {
      stores.chatStore.openMainChat(attendee, user);
      history.push('/chat');
    } else {
      stores.chatStore.openFloatingChat(attendee, user);
    }
    callback();
  } catch (e) {
    return e;
  }
}
