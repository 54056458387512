import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Message} from 'semantic-ui-react';
import {useDropzone} from 'react-dropzone';
import Cropper from 'react-cropper';
import isEmpty from 'lodash/isEmpty';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import ButtonLoading from '../../../ui/ButtonLoading';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import './PhotoUpload.scss';

const PhotoUpload = observer(({maxWidth, maxHeight}) => {
  const {userStore, modalStore} = React.useContext(AppContext);
  const [photo, setPhoto] = React.useState([]);
  const [cropper, setCropper] = React.useState(null);
  const [error, setError] = React.useState(null);

  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    multiple: false,
    onDrop: uploadFile => {
      setError(null);
      setPhoto(
        uploadFile.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
    onDropRejected: errors => {
      if (errors[0].errors[0].code === 'file-too-large') {
        setError(<FormattedMessage id="photo.upload.error.large" />);
      } else {
        setError(errors[0].errors[0].message);
      }
    },
  });

  const savePhoto = () => {
    if (typeof cropper !== 'undefined') {
      let cropperOptions = {};
      if (maxWidth) {
        cropperOptions.maxWidth = maxWidth;
      }
      if (maxHeight) {
        cropperOptions.maxHeight = maxHeight;
      }
      return userStore
        .updateUserDetails(userStore.user.id, {
          action: 'photo',
          image: cropper.getCroppedCanvas(cropperOptions).toDataURL(),
        })
        .then(() => {
          modalStore.close('updatePhoto');
          NotificationAlert.success(<FormattedMessage id="photo.upload.success" />);
        })
        .catch(err => {
          NotificationAlert.error(err.message);
        });
    }
  };

  const preview = () => {
    if (photo.length > 0) {
      const f = photo[0];

      return (
        <Cropper
          className="photo-upload__cropper"
          src={f.preview}
          preview=".img-preview"
          viewMode={1}
          guides
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          initialAspectRatio={1}
          aspectRatio={1}
          responsive
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={instance => {
            setCropper(instance);
          }}
        />
      );
    }
    return null;
  };

  const photoName = !isEmpty(acceptedFiles) ? (
    acceptedFiles.map(p => (
      <p key={p.path}>
        {p.path} - {p.size} bytes
      </p>
    ))
  ) : (
    <span className="photo-upload__placeholder">
      <FormattedMessage id="photo.upload.form.accepted.img" />
    </span>
  );

  return userStore.user ? (
    <div className="photo-upload">
      <div className="photo-upload__upload">
        <Button
          onClick={open}
          labelPosition="left"
          icon="file"
          content={<FormattedMessage id="photo.upload.form.btn.upload" />}
        />
        {photoName}
      </div>
      {error ? <Message negative>{error}</Message> : null}
      <section>
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
        </div>
        {preview()}
      </section>

      {photo.length > 0 ? (
        <div className="user-avatar-form__actions">
          <Button
            secondary
            type="submit"
            className="user-avatar-form__cancel"
            onClick={() => modalStore.close('updatePhoto')}
          >
            <FormattedMessage id="photo.upload.form.btn.cancel" />
          </Button>
          <ButtonLoading primary onClick={() => savePhoto()} type="submit" className="user-avatar-form__save">
            <FormattedMessage id="photo.upload.form.btn.save" />
          </ButtonLoading>
        </div>
      ) : null}
    </div>
  ) : null;
});

export default injectIntl(PhotoUpload);
