import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import last from 'lodash/last';
import ImageLazy from '../../../ui/ImageLazy';
import TimeService from '../../../services/core/time-service';
import attendeeImg from '../../../assets/images/defaultImages/attendee.png';
import './InvitationHeaderCard.scss';

const InvitationHeaderCard = observer(props => {
  const {invitation, type} = props;
  const isRescheduled = invitation.type === 'reschedule' || last(invitation.meetingDetails)?.status === 'rescheduled';
  const momentDt =
    isRescheduled && last(invitation.meetingDetails)?.createdAt
      ? TimeService.getMomentUserOrEvent(invitation.event, last(invitation.meetingDetails)?.createdAt)
      : null;
  const timeLastInvitation =
    isRescheduled && last(invitation.meetingDetails)?.createdAt ? momentDt.toFormat('dd LLLL, t') : null;
  const timeInv = isRescheduled ? timeLastInvitation : invitation.cdtStr;

  const InvitationHeader = () => {
    if (type === 'company' && !last(invitation.meetingDetails)) {
      return (
        <div className="invitation-header invitation-header--company">
          <div className="invitation-header__label">
            <FormattedMessage
              id="invite.card.received.label.company"
              values={{
                name: invitation.myDetails.fullName,
              }}
            />
            <ImageLazy
              errorSrc={attendeeImg}
              src={invitation.myDetails.profilePhoto || attendeeImg}
              alt={invitation.myDetails.fullName}
            />
          </div>
          <div className="invitation-header__time">{timeInv}</div>
        </div>
      );
    } else if (type === 'company' && last(invitation.meetingDetails)?.status === 'accepted') {
      return (
        <div className="invitation-header invitation-header--company">
          <div className="invitation-header__label">
            <FormattedMessage
              id="invite.card.received-accepted.label.company"
              values={{
                name: invitation.myDetails.fullName,
              }}
            />
            <ImageLazy
              errorSrc={attendeeImg}
              src={invitation.myDetails.profilePhoto || attendeeImg}
              alt={invitation.myDetails.fullName}
            />
          </div>
          <div className="invitation-header__time">{timeInv}</div>
        </div>
      );
    } else if (isRescheduled && last(invitation.meetingDetails)?.executorId === invitation.oppId) {
      return (
        <div className="invitation-header invitation-header--reschedule-received">
          <div className="invitation-header__label">
            <FormattedMessage id="invite.card.received.label.reschedule" />
          </div>
          <div className="invitation-header__time">{timeInv}</div>
        </div>
      );
    } else if (isRescheduled && (type === 'sent' || type === 'company')) {
      return (
        <div className="invitation-header invitation-header--reschedule-sent">
          <div className="invitation-header__label">
            <FormattedMessage id="invite.card.sent.label.reschedule" />
          </div>
          <div className="invitation-header__time">{timeInv}</div>
        </div>
      );
    } else if (invitation.state === 'revoked' && type === 'sent') {
      return (
        <div className="invitation-header invitation-header--revoked-sent">
          <div className="invitation-header__label">
            <FormattedMessage id="invite.card.sent.label.revoked" />
          </div>
          <div className="invitation-header__time">{timeInv}</div>
        </div>
      );
    } else if (invitation.state === 'revoked' && type === 'received') {
      return (
        <div className="invitation-header invitation-header--revoked-received">
          <div className="invitation-header__label">
            <FormattedMessage id="invite.card.received.label.revoked" />
          </div>
          <div className="invitation-header__time">{timeInv}</div>
        </div>
      );
    } else if (type === 'received') {
      return (
        <div className="invitation-header invitation-header--received">
          <div className="invitation-header__label">
            <FormattedMessage id="invite.card.received.label.respond" />
          </div>
          <div className="invitation-header__time">{timeInv}</div>
        </div>
      );
    } else if (type === 'sent') {
      return (
        <div className="invitation-header invitation-header--sent">
          <div className="invitation-header__label">
            <FormattedMessage id="invite.card.sent.label.sent" />
          </div>
          <div className="invitation-header__time">{invitation.cdtStr}</div>
        </div>
      );
    } else {
      return null;
    }
  };

  return InvitationHeader();
});

InvitationHeaderCard.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.object]),
  type: PropTypes.string,
};

export default injectIntl(InvitationHeaderCard);
