import Api from '../core/axios-service';
import {CommandExecutor} from '../core/command-executor';
import {stores} from '../../store';

const commandExecutor = new CommandExecutor();

const InvitationApi = {
  sendMeetingRequest(executorUserId, eventId, participantId, message, toAll) {
    return commandExecutor.execute(
      executorUserId,
      Api.post('/event/schedule/join', {
        eventId,
        toId: participantId,
        message,
        toAll,
        toType: 'participant',
      })
        .then(resp => resp.data)
        .catch(err => {
          const error = err.response.data.message;
          stores.errorStore.addError(error);
          return Promise.reject(error);
        }),
    );
  },
  sendRescheduleRequest(executorUserId, eventId, sessionId, message, invitationId) {
    return commandExecutor.execute(
      executorUserId,
      Api.post('/event/schedule/reschedule', {eventId, sessionId, message, invitationId})
        .then(resp => resp.data)
        .catch(err => {
          stores.errorStore.addError(err);
          return err;
        }),
    );
  },
  revokeInvitation(executorUserId, eventId, invitationId) {
    return commandExecutor.execute(
      executorUserId,
      Api.post('/event/schedule/revoke', {eventId, invitationId})
        .then(resp => resp.data)
        .catch(err => {
          stores.errorStore.addError(err);
          return err;
        }),
    );
  },
  revokeRescheduleInvitation(executorUserId, eventId, invitationId) {
    return commandExecutor.execute(
      executorUserId,
      Api.post('/event/schedule/revokeReschedule', {eventId, invitationId})
        .then(resp => resp.data)
        .catch(err => {
          stores.errorStore.addError(err);
          return err;
        }),
    );
  },
  rejectInvitation(executorUserId, eventId, invitationId, message) {
    return commandExecutor.execute(
      executorUserId,
      Api.post('/event/schedule/reject', {eventId, invitationId, message})
        .then(resp => resp.data)
        .catch(err => {
          stores.errorStore.addError(err);
          return err;
        }),
    );
  },
  rejectRescheduleInvitation(executorUserId, eventId, invitationId, message) {
    return commandExecutor.execute(
      executorUserId,
      Api.post('/event/schedule/rejectReschedule', {eventId, invitationId, message})
        .then(resp => resp.data)
        .catch(err => {
          stores.errorStore.addError(err);
          return err;
        }),
    );
  },
  acceptMeetingRequest(executorUserId, eventId, invitationId, message, sessionId, asParticipantId) {
    return commandExecutor.execute(
      executorUserId,
      Api.post('/event/schedule/confirm', {eventId, invitationId, message, sessionId, asParticipantId})
        .then(resp => resp.data)
        .catch(err => {
          stores.errorStore.addError(err);
          return err;
        }),
    );
  },
  acceptRescheduleRequest(executorUserId, eventId, invitationId, message, sessionId) {
    return commandExecutor.execute(
      executorUserId,
      Api.post('/event/schedule/confirmReschedule', {eventId, invitationId, message, sessionId})
        .then(resp => resp.data)
        .catch(err => {
          stores.errorStore.addError(err);
          return err;
        }),
    );
  },
};

export default InvitationApi;
