import Api from '../core/axios-service';
import {stores} from '../../store';

const PollApi = {
  createPoll(body, eventId) {
    return Api.post('event/poll', {
      eventId,
      autoPublishResults: body.pollVisible,
      question: body.pollQuestion,
      answers: body.pollAnswers,
      postAt: body.pollPublish ? 0 : null,
    }).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  editPoll(value, question) {
    return Api.post('event/poll', {
      id: question.id,
      eventId: question.eventId,
      autoPublishResults: value.pollVisible,
      question: value.pollQuestion,
      answers: value.pollAnswers,
      postAt: value.pollPublish ? 0 : null,
    }).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  deletePoll(question) {
    return Api.delete('event/poll', {
      params: {
        id: question.id,
        eventId: question.eventId,
      },
    }).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  submitAnswer(question, selectedAnswers) {
    return Api.post('event/poll/submit', {
      id: question.id,
      eventId: question.eventId,
      answers: selectedAnswers,
    }).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  publishQuestion(question, publish) {
    return Api.post('event/poll', {
      eventId: question.eventId,
      id: question.id,
      action: publish ? 'publish' : 'unpublish',
    }).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  finishPoll(question, finish) {
    return Api.post('event/poll', {
      eventId: question.eventId,
      id: question.id,
      action: finish ? 'close' : 'reopen',
    }).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
};

export default PollApi;
