import React from 'react';
import {observer} from 'mobx-react-lite';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import MobileInsideLayout from '../../../../layouts/MobileInsideLayout';
import InvitationBoxMapByAttendee from '../../../invitations/InvitationBoxMapByAttendee';
import useMedia from '../../../../hooks/useMedia';
import './InvitationModalPage.scss';

const InvitationModalPage = observer(props => {
  const {action} = props;
  const {eventId} = useParams();
  const history = useHistory();
  const {state} = useLocation();
  const isMobile = useMedia('(max-width: 768px)');

  React.useEffect(() => {
    if (!isMobile) {
      history.push(`/event/${eventId}/attendees`);
    }
  }, [isMobile]);

  return (
    <MobileInsideLayout>
      <InvitationBoxMapByAttendee action={action} isBooth={state?.isBooth}  invitationId={state?.invitationId}/>
    </MobileInsideLayout>
  );
});

export default InvitationModalPage;
