import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button} from 'semantic-ui-react';
import {AppContext} from '../../store';
import UserDetailsForm from '../../components/account/UserDetailsForm';
import UserUpdateAvatar from '../../components/account/UserUpdateAvatar';
import './Profile.scss';

const Profile = observer(() => {
  const {userStore} = React.useContext(AppContext);

  return (
    <div className="user-details">
      <h2 className="user-details__title">
        <FormattedMessage id="profile.title" />
      </h2>
      <div className="user-details__wrapper">
        <UserUpdateAvatar />
        <UserDetailsForm />
      </div>
      <Button className="user-details__logout" onClick={() => userStore.logout()}>
        <FormattedMessage id="profile.logout" />
      </Button>
    </div>
  );
});

export default injectIntl(Profile);
