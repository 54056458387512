import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Popup} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {AppContext} from '../../../store';
import EventApi from '../../../services/api/eventApi';
import {handleCheckInFromAttendees} from '../../../services/helpers/checkInHelper';
import './AttendeeBtnsMore.scss';
import reserveATable
  from "../../invitations/InvitationFormRespond/ReserveATable";

const AttendeeBtnsMore = observer(props => {
  const {attendee, user} = props;
  const {participantStore, eventStore, modalStore} = React.useContext(AppContext);
  const {eventDetails} = eventStore;
  const {tags} = participantStore;
  const [openPopup, setOpenPopup] = React.useState(false);
  const canCheckIn = eventDetails && eventDetails.me.checkInAdmin && !eventDetails.isFinished;

  const handleIgnore = attendeeItem => {
    setOpenPopup(false);
    participantStore.markAsIgnored(attendeeItem);
  };

  const handleFav = attendeeItem => {
    setOpenPopup(false);
    participantStore.markAsFav(attendeeItem);
  };

  const handleCheckIn = (attendeeDetails, id) => {
    setOpenPopup(false);
    handleCheckInFromAttendees(attendeeDetails, id);
  };

  const handleBadgePrint = (attendeeDetails, id) => {
    setOpenPopup(false);
    EventApi.getParticipantDetailsForOrganizer(attendeeDetails.eventId, attendeeDetails.id).then(res => {
      handleCheckInFromAttendees(res, id, 'printBadgeForced');
    });
  };

  const handlePreBookedMeeting = (attendeeDetails) => {
    setOpenPopup(false);
    modalStore.open('preBookedMeetingModal', {attendee:attendeeDetails});
  }




  const handleReserveATable = (attendeeDetails) => {
    setOpenPopup(false);
    modalStore.open('reserveATableModal', {attendee:attendeeDetails});
  }

  const handleShowAgenda = (attendeeDetails) => {
    setOpenPopup(false);
    modalStore.open('showAgendaModal', {attendee:attendeeDetails});
  }

  const self = user.id === attendee.userId;
  const actionButtons = attendeeDetails => (

    <div role="list" className="attendee-card__more-list">
      {!self?<div aria-hidden className="item" onClick={() => handleFav(attendeeDetails)}>
        <div className="content">
          {tags[attendeeDetails.id] !== undefined && tags[attendeeDetails.id].fav ? (
            <FormattedMessage id="attendee.btn.remove.favorite" />
          ) : (
            <FormattedMessage id="attendee.btn.add.favorite" />
          )}
        </div>
      </div>:null}
      {!self?<div aria-hidden className="item" onClick={() => handleIgnore(attendeeDetails)}>
        <div className="content">
          {tags[attendeeDetails.id] !== undefined && tags[attendeeDetails.id].ignored ? (
            <FormattedMessage id="attendee.btn.remove.ignore" />
          ) : (
            <FormattedMessage id="attendee.btn.add.ignore" />
          )}
        </div>
      </div>:null}
      {canCheckIn ? (
        <div aria-hidden className="item" onClick={() => handleCheckIn(attendeeDetails, eventDetails?.id)}>
          <div className="content">
            <FormattedMessage id="attendee.btn.checkIn" />
          </div>
        </div>
      ) : null}
      {eventDetails.me.organizer ? (
        <div aria-hidden className="item" onClick={() => handleBadgePrint(attendeeDetails, eventDetails?.id)}>
          <div className="content">
            <FormattedMessage id="attendee.btn.printBadge" />
          </div>
        </div>
      ) : null}

      {eventDetails.me?.rights?.onbehalfmeeting? (
        <div aria-hidden className="item" onClick={() => handlePreBookedMeeting(attendeeDetails, eventDetails?.id)}>
          <div className="content">
            <FormattedMessage id="attendee.btn.preBookMeeting" />
          </div>
        </div>
      ) : null}

      {eventDetails.me?.rights?.onbehalfmeeting? (
        <div aria-hidden className="item" onClick={() => handleShowAgenda(attendeeDetails, eventDetails?.id)}>
          <div className="content">
            <FormattedMessage id="attendee.btn.showAgenda" />
          </div>
        </div>
      ) : null}

      {eventDetails.me?.rights?.reserveatable && user.id === attendeeDetails.userId? (
        <div aria-hidden className="item" onClick={() => handleReserveATable(attendeeDetails, eventDetails?.id)}>
          <div className="content">
            <FormattedMessage id="attendee.btn.reserveATable" />
          </div>
        </div>
      ) : null}


    </div>
  );

  return eventDetails?.id === attendee?.eventId ? (
    <Popup
      trigger={<i className="attendee-card__btn-more ellipsis horizontal icon button" />}
      content={actionButtons(attendee)}
      className="attendee-card__popup"
      basic
      position="bottom right"
      on="click"
      onClose={() => setOpenPopup(false)}
      onOpen={() => setOpenPopup(true)}
      open={openPopup}
    />
  ) : null;
});

AttendeeBtnsMore.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
};

AttendeeBtnsMore.defaultProps = {
  attendee: {},
};

export default AttendeeBtnsMore;
