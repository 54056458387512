import React from 'react';
import {observer} from 'mobx-react-lite';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Icon} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import ParserText from '../../../ui/ParserText';
import ImageLazy from '../../../ui/ImageLazy';
import attendeeImg from '../../../assets/images/defaultImages/attendee.png';
import {chatMessageText, parseJsonFromText} from '../../../services/helpers/chatHelper';
import './ChatUser.scss';

const ChatUser = observer(
  (props, userRef) => {
    const {oppositeMember, room, floating} = props;
    const {chatStore, userStore} = React.useContext(AppContext);
    const {selectedRoom, floatingSelectedRoom} = chatStore;
    const userId = userStore.user.id;
    const selectUser = floating ? floatingSelectedRoom === room.details.id : selectedRoom === room.details.id;

    const interlocutorsArray = Object.keys(room.members).filter(member => member !== userId);
    const interlocutor = interlocutorsArray.length === 1 ? interlocutorsArray[0] : '';

    const isPinned = room?.pin;
    const lastMessage = room.messages && Object.values(room.messages)[Object.keys(room.messages).length - 1];

    const lastMessageTextParse = lastMessage => {
      if (!lastMessage.msg) return null;
      if (lastMessage.type === 'contact') {
        return chatMessageText(parseJsonFromText(lastMessage.msg), ['name', 'surname']);
      }
      if (lastMessage.type) {
        return <ParserText type="strippedHtml">{lastMessage.msg}</ParserText>;
      }

      return lastMessage.msg;
    };
    const lastMessageText = lastMessage ? lastMessageTextParse(lastMessage) : null;

    React.useEffect(() => {
      chatStore.trackLastSeenMessage(room.details.id, interlocutor);
    }, [chatStore, room.details.id, interlocutor]);

    React.useEffect(() => {
      chatStore.trackMessage(room.details.id, userId);
    }, [room.details.id, userId]);

    const chatUserInfo = oppositeMember ? (
      <div className="chat-user__info">
        <h3 className={'chat-user__name'}>
          {oppositeMember.name} {oppositeMember.surname}
        </h3>
        {oppositeMember.jobTitle ? (
          <p className="chat-user__job">
            {oppositeMember.jobTitle} at {oppositeMember.company}
          </p>
        ) : null}
        {lastMessageText ? <div className="chat-user__message">{lastMessageText}</div> : null}
      </div>
    ) : null;

    const pinButton = pinned => {
      const classesPinButton = classNames('chat-user__pin-button', {
        pinned,
      });

      return !room.firstMessage ? (
        <div
          aria-hidden
          className={classesPinButton}
          onClick={event => {
            event.stopPropagation();
            chatStore
              .pinRoom(userId, room.details.id, !pinned)
              .then(() => {})
              .catch(e => console.log('e', e));
          }}
        >
          <Icon name="pin" />
        </div>
      ) : null;
    };

    const classesChatUser = classNames('chat-user', {
      selected: selectUser,
      floating,
    });

    if (room.members && oppositeMember) {
      return (
        <div
          aria-hidden
          ref={userRef}
          className={classesChatUser}
          onClick={() => {
            chatStore.selectChat(room.details.id, floating);
          }}
        >
          <div className="chat-user__avatar">
            {oppositeMember.profilePhoto ? (
              <ImageLazy src={oppositeMember.profilePhoto} errorSrc={attendeeImg} alt={oppositeMember.name} />
            ) : (
              <img src={attendeeImg} alt={oppositeMember.name} />
            )}
          </div>
          {room.countUnreadMessages > 0 ? <div className="chat-user__unseen">{room.countUnreadMessages}</div> : null}
          {chatUserInfo}
          {pinButton(isPinned)}
        </div>
      );
    }

    return null;
  },
  {
    forwardRef: true,
  },
);

ChatUser.defaultProps = {
  room: PropTypes.oneOfType([PropTypes.object]).isRequired,
  floating: PropTypes.bool,
};

ChatUser.defaultProps = {
  floating: false,
};

export default ChatUser;
