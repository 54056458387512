import React from 'react';
import {observer} from 'mobx-react-lite';
import Modal from '../../../../ui/Modal';
import {FormattedMessage} from 'react-intl';
import {AppContext} from '../../../../store';
import QrGenerateForm from '../../../contact/QrGenerateForm';
import './QrGenerateModal.scss';

const QrGenerateModal = observer(() => {
  const {modalStore, contactStore} = React.useContext(AppContext);
  const {open} = modalStore.modals.qrGenerate;

  return open ? (
    <Modal
      className="qr-generate-modal"
      open
      onClose={() => {
        modalStore.close('qrGenerate');
        contactStore.setContactForm({});
      }}
      size="tiny"
    >
      <div className="qr-generate-modal__wrapper">
        <h3>
          <FormattedMessage id="qr.generate.modal" />
        </h3>
        <QrGenerateForm />
      </div>
    </Modal>
  ) : null;
});

export default QrGenerateModal;
