import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Dimmer, Loader, Message, Segment} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import './ContactScanWindow.scss';

const ContactScanWindow = observer(() => {
  const {contactStore} = React.useContext(AppContext);
  const {initScanning, error, camQrResult, notFoundContact} = contactStore;

  React.useEffect(() => {
    contactStore.initScan();
  }, []);

  return (
    <div className="contact-scan-window">
      {initScanning && !error ? (
        <Segment className="contact-scan-window__loader">
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        </Segment>
      ) : null}
      <div id="reader" />
      {error ? (
        <Message error>{error}</Message>
      ) : (
        <div className="contact-scan-window__video">
          <div id="reader" />
          <div className="contact-scan-window__result">
            Detected QR / Barcode code: <span>{camQrResult}</span>
            {notFoundContact ? (
              <span>
                {' - '}
                <FormattedMessage id="contact.scan.window.notFound" />
              </span>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
});

export default injectIntl(ContactScanWindow);
