import React from 'react';
import PopupTable from './PopupTable';
import './PopupTable.scss';

const PopupTables = props => {
  const {popupMeetings} = props;

  return (
    <div className="popup-tables">
      {popupMeetings?.map(popupMeeting => {
        return <PopupTable key={popupMeeting.roomId} popupMeeting={popupMeeting} />;
      })}
    </div>
  );
};

export default PopupTables;
