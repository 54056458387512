import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Button, Dimmer, Image, Loader, Segment} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';
import InvitationsList from '../InvitationsList';
import {AppContext} from '../../../store';
import {ReactComponent as InvitationImg} from '../../../assets/images/icons/header/invitationsFull.svg';
import './InvitationsContent.scss';

const STEP_LOAD = 5;

const InvitationsContent = observer(props => {
  const {liveInvitations, pastInvitations, type} = props;
  const {invitationStore, eventStore} = React.useContext(AppContext);
  const {loadedInvitations, loadingPastInvitations, stepLoadPastInvitations} = invitationStore;
  const {pastEvents} = eventStore;
  const countPastEventForLoading = pastEvents.length + STEP_LOAD - stepLoadPastInvitations;
  const ref = React.useRef();
  const [refParent, setRefParent] = React.useState(null);

  React.useEffect(
    () => () => {
      invitationStore.cleanFilter();
    },
    [invitationStore],
  );

  React.useEffect(() => {
    if (ref?.current && !refParent) {
      setRefParent(ref?.current);
    }
  }, [ref]);

  const classes = classNames('invitations-content', {
    'invitations-content--received': type === 'received',
    'invitations-content--sent': type === 'sent',
    'invitations-content--company': type === 'company',
  });

  if (!loadedInvitations || !liveInvitations || !pastInvitations) {
    return (
      <Segment className="invitations-content__loader">
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    );
  }
  return (
    <div className={classes} ref={ref}>
      {liveInvitations.length > 0 ? (
        <div className="invitations-content__live">
          <InvitationsList invitations={liveInvitations} type={type} customScrollParent={refParent} />
        </div>
      ) : (
        <div className="invitations-content__empty">
          <div className="invitations-content__empty-img">
            <InvitationImg />
          </div>
          <div className="invitations-content__empty-text">No pending invitations</div>
        </div>
      )}
      {pastInvitations.length > 0 && ref?.current ? (
        <div className="invitations-content__pass">
          <h3 className="invitations-content__title">
            <FormattedMessage id="invitations.content.past.title" />
          </h3>
          <div className="invitations-list--pass">
            <InvitationsList invitations={pastInvitations} type={type} customScrollParent={refParent} />
            {pastEvents.length > 0 ? (
              <div className="invitations-content__load">
                <Button
                  onClick={() => invitationStore.loadInvitationsForPatsEvents()}
                  loading={loadingPastInvitations}
                  disabled={countPastEventForLoading <= 0}
                >
                  {countPastEventForLoading <= 0
                    ? 'Loaded all invitations'
                    : `Load invitations for past events (${countPastEventForLoading} events)`}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
});

InvitationsContent.propTypes = {
  liveInvitations: PropTypes.oneOfType([PropTypes.array]),
  pastInvitations: PropTypes.oneOfType([PropTypes.array]),
};

export default injectIntl(InvitationsContent);
