import React, {useEffect, lazy, Suspense} from 'react';
import {observer} from 'mobx-react-lite';
import {AppContext} from '../../../store';
import CheckInForm from '../CheckInForm';
import CheckInScanner from '../CheckInScanner';
import CheckInScanResultForm from '../CheckInScanResultForm';
import CheckInHandheldScanner from '../CheckInHandheldScan';

const CheckInPrintBadgeForm = lazy(() => import('../CheckInPrintBadgeForm'));

const CheckInContainer = observer(() => {
  const {checkInStore, eventStore} = React.useContext(AppContext);
  const {eventDetails} = eventStore;
  const {scanTypeData, mode, scannedContact} = checkInStore;

  useEffect(() => {
    return () => {
      checkInStore.clear();
    };
  }, []);

  // TODO: Fix so that the page does not crash when switching from the event page
  if (!eventDetails?.id) {
    return null;
  }

  return (
    <div className="check-in-container">
      {mode === 'scanType' ? (
        <CheckInForm />
      ) : mode === 'scan' ? (
        <CheckInScanner />
      ) : mode === 'handheldScan' ? (
        <CheckInHandheldScanner />
      ) : mode === 'scanResult' ? (
        <CheckInScanResultForm contact={scannedContact} />
      ) : mode === 'printBadge' ? (
        <Suspense fallback={null}>
          <CheckInPrintBadgeForm contact={scannedContact} scanTypeData={scanTypeData} />
        </Suspense>
      ) : mode === 'printBadgeForced' ? (
        <Suspense fallback={null}>
          <CheckInPrintBadgeForm forced={true} contact={scannedContact} scanTypeData={scanTypeData} />
        </Suspense>
      ) : null}
    </div>
  );
});

export default CheckInContainer;
