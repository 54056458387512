import React from 'react';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {Form, Button} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {useForm} from 'react-hook-form';
import AnnouncementApi from '../../../services/api/announcementApi';
import {useFetch} from '../../../hooks/useFetch';

const AnnouncementCommentAdd = observer(props => {
  const {announcement, intl} = props;
  const {handleSubmit, register, errors} = useForm();

  const addCommentInAnnouncement = useFetch({
    fetchFn: AnnouncementApi.addComment,
    immediate: false,
  });

  const addComment = value => {
    const postBody = {
      type: 'comment',
      parentId: announcement.id,
      eventId: announcement.eventId,
      text: value.comment,
    };
    addCommentInAnnouncement
      .makeRequest(postBody)
      .then(() => {
        const textarea = document.querySelector('#text-comment');
        textarea.value = '';
        NotificationAlert.success(<FormattedMessage id="announcement.comment.add.success" />);
      })
      .catch(() => {
        NotificationAlert.error(<FormattedMessage id="announcement.comment.add.error" />);
      });
  };

  return (
    <Form className="announcement-item__form-comment" onSubmit={handleSubmit(addComment)}>
      <Form.Field className="field__textarea" error={!!errors.comment}>
        <textarea
          name="comment"
          id="text-comment"
          placeholder={intl.formatMessage({id: 'announcement.comment.add.placeholder'})}
          rows="2"
          ref={register({
            required: intl.formatMessage({id: 'validation.error.comment'}),
          })}
        />
        {errors.comment ? <div className="error">{errors.comment.message}</div> : null}
      </Form.Field>
      <Button loading={addCommentInAnnouncement.loading} type="submit" className="ui primary">
        <i className="telegram plane icon" />
        <FormattedMessage id="announcement.comment.btn.create" />
      </Button>
    </Form>
  );
});

AnnouncementCommentAdd.propTypes = {
  announcement: PropTypes.oneOfType([PropTypes.object]),
};

export default injectIntl(AnnouncementCommentAdd);
