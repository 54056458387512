import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Icon} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import {AppContext} from '../../../store';
import ImageLazy from '../../../ui/ImageLazy';
import {getGmtTimezoneByTimezoneName} from '../../../utils/getGmtTimezones';
import {HostUrl} from '../../../services/core/axios-service';
import LastVisitedEventBtn from '../../header/LastVisitedEventBtn';
import ParticipantProfile from '../../header/ParticipantProfile/ParticipantProfile';
import {ReactComponent as HamburgerImg} from '../../../assets/images/icons/hamburger.svg';
import {ReactComponent as CloseImg} from '../../../assets/images/icons/close.svg';
import {ReactComponent as AgendaImg} from '../../../assets/images/icons/header/agenda.svg';
import {ReactComponent as Account} from '../../../assets/images/icons/header/account.svg';
import {ReactComponent as NotificationsImg} from '../../../assets/images/icons/header/notifications.svg';
import {ReactComponent as ChatImg} from '../../../assets/images/icons/header/chat.svg';
import {ReactComponent as InvitationImg} from '../../../assets/images/icons/header/invitationsFull.svg';
import {ReactComponent as ContactsImg} from '../../../assets/images/icons/header/contacts.svg';
import {ReactComponent as HomeImg} from '../../../assets/images/icons/header/home.svg';
import {ReactComponent as SignOutImg} from '../../../assets/images/icons/header/sign-out.svg';
import userImg from '../../../assets/images/user.jpg';
import './MobileSidebarMenu.scss';
import {ReactComponent as CheckInImg} from '../../../assets/images/icons/header/checkIn.svg';

const MobileSidebarMenu = observer(() => {
  const {uiStore, userStore, invitationStore, chatStore, activityStore, agendaStore, eventStore, modalStore} =
    React.useContext(AppContext);
  const {user} = userStore;
  const {invitations, loadingActiveInvitations} = invitationStore;
  const {getUnSeenMessages} = chatStore;
  const {notifications, countNotificationUnSeed, isLoading} = activityStore;
  const {agendaLive} = agendaStore.data;
  const {eventDetails} = eventStore;
  const gmtUserTimezone = getGmtTimezoneByTimezoneName(user.timezone);
  const canCheckIn = eventDetails && eventDetails.me.checkInAdmin && !eventDetails.isFinished;

  const wrapperRef = React.useRef(null);
  const buttonRef = React.useRef(null);

  const countLiveReceivedInvitations = invitations.filter(
    invitation => invitation.status === 'live' && invitation.segment === 'received',
  ).length;

  const handleClick = e => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(e.target) &&
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target)
    ) {
      uiStore.closeMobileMenu();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const classes = classNames('mobile-sidebar', {
    'mobile-sidebar--open': uiStore.isOpenMobileMenu,
  });

  const handleItemClick = type => {
    uiStore.triggerMobileMenu();
    if (type === 'notifications' && notifications.length > 0) {
      activityStore.setLastNotificationDate(user.id, notifications[0]);
    }
  };

  const summaryCount =
    countLiveReceivedInvitations + countNotificationUnSeed + getUnSeenMessages + (agendaLive.length > 0 ? 1 : 0);

  return (
    <div className="hamburger-menu item header-event__btn">
      <div
        ref={buttonRef}
        className="hamburger-menu__button"
        role="button"
        aria-hidden="true"
        onClick={() => uiStore.triggerMobileMenu()}
      >
        <HamburgerImg />
        <ImageLazy
          className="hamburger-menu__avatar"
          src={`${HostUrl}/${userStore.user.profilePhotoPath}`}
          errorSrc={userImg}
          alt={userStore.user.name}
        />
        {summaryCount > 0 ? <div className="ui red label mini circular">{summaryCount}</div> : null}
      </div>
      <div className={classes}>
        {userStore.user ? (
          <div className="mobile-sidebar__wrapper">
            <div className="mobile-sidebar__mobile-box" ref={wrapperRef}>
              <div
                className="mobile-sidebar__close-button"
                role="button"
                aria-hidden="true"
                onClick={() => uiStore.triggerMobileMenu()}
              >
                <CloseImg />
              </div>
              <div className="mobile-sidebar__content">
                <div className="mobile-sidebar__group mobile-sidebar__group--user">
                  <div className="mobile-sidebar__user-image">
                    <ImageLazy
                      src={`${HostUrl}/${userStore.user.profilePhotoPath}`}
                      errorSrc={userImg}
                      alt={userStore.user.name}
                    />
                  </div>
                  <div className="mobile-sidebar__user-name">
                    <p className="mobile-sidebar__user-fullname">
                      {userStore.user.name} {userStore.user.surname}
                    </p>
                    <div className="mobile-sidebar__user-email">{userStore.user.username}</div>
                    {gmtUserTimezone ? (
                      <Link
                        to="/account/profile"
                        onClick={() => handleItemClick()}
                        className="mobile-sidebar__user-timezone"
                      >
                        {gmtUserTimezone} {user.timezone}
                      </Link>
                    ) : null}
                  </div>
                </div>
                <div className="mobile-sidebar__group mobile-sidebar__group--top">
                  <Link className="mobile-sidebar__item" to="#" onClick={() => modalStore.open('qrGenerate')}>
                    <Icon className="qrcode" />
                    <p className="mobile-sidebar__item-text">
                      <FormattedMessage id="menu.link.generateQr" />
                    </p>
                  </Link>
                  <NavLink to="/account/profile" className="mobile-sidebar__item" onClick={() => handleItemClick()}>
                    <Account />
                    <p className="mobile-sidebar__item-text">
                      <FormattedMessage id="menu.link.device-settings" />
                    </p>
                  </NavLink>
                  <NavLink
                    to="/notifications"
                    className="mobile-sidebar__item"
                    onClick={() => handleItemClick('notifications')}
                  >
                    <NotificationsImg />
                    <p className="mobile-sidebar__item-text">
                      <FormattedMessage id="menu.link.notifications" />
                    </p>
                    {!isLoading && countNotificationUnSeed && countNotificationUnSeed > 0 ? (
                      <div className="ui red label mini circular">{countNotificationUnSeed}</div>
                    ) : null}
                  </NavLink>
                  <NavLink to="/my-agenda" className="mobile-sidebar__item" onClick={() => handleItemClick()}>
                    <AgendaImg />
                    <p className="mobile-sidebar__item-text">
                      <FormattedMessage id="menu.link.calendar" />
                    </p>
                    {agendaLive.length > 0 ? <div className="ui green label mini circular" /> : null}
                  </NavLink>
                  <NavLink to="/chat" className="mobile-sidebar__item" onClick={() => handleItemClick()}>
                    <ChatImg />
                    <p className="mobile-sidebar__item-text">
                      <FormattedMessage id="menu.link.chat" />
                    </p>
                    {getUnSeenMessages > 0 && <div className="ui red label mini circular">{getUnSeenMessages}</div>}
                  </NavLink>
                  <NavLink
                    to="/invitations/received"
                    className="mobile-sidebar__item"
                    onClick={() => handleItemClick()}
                  >
                    <InvitationImg />
                    <p className="mobile-sidebar__item-text">
                      <FormattedMessage id="menu.link.invitations" />
                    </p>
                    {!loadingActiveInvitations && countLiveReceivedInvitations && countLiveReceivedInvitations > 0 ? (
                      <div className="ui red label mini circular">{countLiveReceivedInvitations}</div>
                    ) : null}
                  </NavLink>
                  <NavLink
                    to={`/contacts${eventDetails ? '?eventId=' + eventDetails.id : ''}`}
                    className="mobile-sidebar__item"
                    onClick={() => handleItemClick()}
                  >
                    <ContactsImg />
                    <p className="mobile-sidebar__item-text">
                      <FormattedMessage id="menu.link.contacts" />
                    </p>
                  </NavLink>
                </div>
                <div className="mobile-sidebar__group mobile-sidebar__group--events">
                  <NavLink to="/event/list" className="mobile-sidebar__item" onClick={() => handleItemClick()}>
                    <HomeImg />
                    <p className="mobile-sidebar__item-text">
                      <FormattedMessage id="menu.link.events" />
                    </p>
                  </NavLink>
                  {canCheckIn && (
                    <Link className="mobile-sidebar__item" to="#" onClick={() => modalStore.open('checkInQrModal')}>
                      <CheckInImg />
                      <p className="mobile-sidebar__item-text">
                        <FormattedMessage id="menu.link.checkIn" />
                      </p>
                    </Link>
                  )}
                  {!isEmpty(userStore.user) && eventDetails ? <ParticipantProfile isMobile /> : null}
                </div>
                <div className="mobile-sidebar__group mobile-sidebar__group--your-event">
                  <LastVisitedEventBtn eventDetails={eventDetails} />
                </div>
                <div className="mobile-sidebar__group mobile-sidebar__group--bottom">
                  <Link
                    className="mobile-sidebar__item"
                    to="#"
                    onClick={() => {
                      uiStore.triggerMobileMenu();
                      userStore.logout();
                    }}
                  >
                    <SignOutImg />
                    <p className="mobile-sidebar__item-text">
                      <FormattedMessage id="menu.link.logout" />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
});

export default injectIntl(MobileSidebarMenu);
