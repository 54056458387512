import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Icon, Label} from 'semantic-ui-react';
import {Link, useLocation, useParams, useHistory} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';
import {observer} from 'mobx-react-lite';
import {AppContext} from '../../../store';
import SessionBtns from '../SessionBtns';
import ImageLazy from '../../../ui/ImageLazy';
import {HostUrl} from '../../../services/core/axios-service';
import bannerImg from '../../../assets/images/defaultImages/default_banner.png';
import speakerImg from '../../../assets/images/defaultImages/speaker.png';
import './SessionDetails.scss';

const SessionDetails = observer(props => {
  const {session, meetings} = props;
  const {eventStore} = React.useContext(AppContext);
  const {eventList} = eventStore;
  const eventDetails = eventList?.filter(event => event.id === session.eventId)[0];
  const speakers = session.speakerObjects;
  const {eventId} = useParams();
  const location = useLocation();
  const history = useHistory();
  const sessionDefaultBanner = `${HostUrl}/public/event/images/${eventId}/banner.png`;
  const altBanner = eventDetails?.images?.banner || sessionDefaultBanner;

  const parseHtml = htmlParser({
    isValidNode: node => node.type !== 'script',
  });

  const sessionTracks = session.tracks
    ? session.tracks.map((type, key) => (
        <Label key={session.id + key} className="session-details__metadata--track">
          # {type.label}
        </Label>
      ))
    : null;

  const sessionTypes = session.type ? (
    <Label className="session-details__metadata--type">
      <Icon name="tag" />
      {session.type}
    </Label>
  ) : null;

  return (
    <div className="session-details">
      {session ? (
        <>
          <div className="session-details__banner">
            {session?.images?.top_banner ? (
              <ImageLazy
                src={`${HostUrl}/${session.images.top_banner}`}
                errorSrc={bannerImg}
                alt="banner"
                height={96}
              />
            ) : (
              <ImageLazy src={altBanner} errorSrc={bannerImg} alt={session.name} height={96} />
            )}
          </div>
          <div className="session-details__content">
            <p className="session-details__label">
              <FormattedMessage id="session.details.label" />
            </p>
            <div className="session-details__metadata">
              <Label>
                <Icon name="location arrow" />
                {session.mlocname}
              </Label>
              {sessionTracks}
              {sessionTypes}
            </div>
            <h1 className="session-details__title">{session.name}</h1>
            <div className="session-details__time-wrapper">
              <div className="session-details__timeline">
                <div className="session-details__date">{session.sdts.date}</div>
                <div className="session-details__time">
                  <span className="session-details__time-start">
                    <span className="session-details__time-value">{session.sdts.hour}</span>
                    <span className="session-details__time-text">
                      <FormattedMessage id="session.form.session.start" />
                    </span>
                  </span>
                  <span className="session-details__time-duration">
                    {session.duration ? (
                      <>
                        <span className="session-details__time-value">{session.duration} mins</span>
                        <span className="session-details__time-text">
                          <FormattedMessage id="session.form.session.duration" />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="session-details__time-value">{session.edts.hour}</span>
                        <span className="session-details__time-text">
                          end time
                          {eventDetails?.type === 'normal' ? (
                            <>
                              {' '}
                              <FormattedMessage id="session.item.eventTime" />
                            </>
                          ) : null}
                        </span>
                      </>
                    )}
                  </span>
                </div>
              </div>
              <SessionBtns session={session} meetings={meetings} />
            </div>
          </div>
          <div className="session-details__desc-text">
            <h2>
              <FormattedMessage id="session.form.session.about.title" />
            </h2>
            {session.dets && session.dets.description ? (
              <ReactMarkdown allowDangerousHtml astPlugins={[parseHtml]}>
                {session.dets.description}
              </ReactMarkdown>
            ) : (
              <FormattedMessage id="global.no.info" />
            )}
          </div>
        </>
      ) : null}
      {speakers && speakers.length > 0 ? (
        <div className="session-details__speakers">
          <h2 className="session-details__title">
            <FormattedMessage id="session.details.title" />
          </h2>
          <div className="session-details__speakers-list">
            {speakers.map(speaker => (
              <div key={speaker.id} className="speaker-short">
                <div className="speaker-short__img">
                  <ImageLazy
                    src={`${HostUrl}/${speaker.profilePhoto}`}
                    errorSrc={speakerImg}
                    alt={speaker.name}
                    onClick={() =>
                      history.push({
                        pathname: `/event/${eventId}/speakers/${speaker.id}`,
                        state: {
                          background: location.state.background,
                          page: 'speakers',
                          title: speaker.name,
                        },
                      })
                    }
                  />
                </div>
                <div className="speaker-short__details">
                  <Link
                    to={{
                      pathname: `/event/${eventId}/speakers/${speaker.id}`,
                      state: {
                        background: location.state.background,
                        page: 'speakers',
                        title: speaker.name,
                      },
                    }}
                  >
                    <p className="speaker-short__name" aria-hidden>
                      {speaker.name} {speaker.surname}
                    </p>
                  </Link>
                  {speaker.jobTitle && speaker.company ? (
                    <Fragment>
                      <span className="speaker-short__company">{speaker.company}</span>
                      <span className="speaker-short__job">{speaker.jobTitle}</span>
                    </Fragment>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
});

SessionDetails.propTypes = {
  session: PropTypes.oneOfType([PropTypes.object]),
  meetings: PropTypes.oneOfType([PropTypes.array]),
};

export default injectIntl(SessionDetails);
