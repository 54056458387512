import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import StaticPageNavbar from '../../components/shared/StaticPageNavbar';
import Header from '../../components/header/Header';
import Footer from '../../components/shared/Footer';
import StaticContent from '../../components/shared/StaticContent';
import './StaticPageDecorator.scss';

const StaticPageLayout = observer(() => (
  <div className="main main--static">
    <Header />
    <div className="container">
      <div className="main-static">
        <div className="main-static__main">
          <StaticPageNavbar />
        </div>
        <div className="main-static__aside">
          <Switch>
            {['terms', 'faq', 'privacy-notice', 'cookies'].map(page => (
              <Route key={page} path={`/${page}`} render={() => <StaticContent page={page} />} exact />
            ))}
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  </div>
));

export default StaticPageLayout;
