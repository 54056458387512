import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import InvitationBoxMapByStatus from '../InvitationBoxMapByStatus';
import './InvitationsList.scss';

const InvitationsList = observer(props => {
  const {invitations, type} = props;
  const dataInv = invitations.sort((a, b) => new Date(b.cdt) - new Date(a.cdt));

  return invitations.length > 0 ? (
    <div className="invitations-list">
      {dataInv.map((inv, i) => (
        <InvitationBoxMapByStatus invitation={inv} type={type} key={i} />
      ))}
    </div>
  ) : null;
});

InvitationsList.propTypes = {
  invitations: PropTypes.oneOfType([PropTypes.array]),
};

export default InvitationsList;
