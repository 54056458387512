import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Icon} from 'semantic-ui-react';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import Drawer from '../../shared/Drawer';
import NotificationContent from '../../notifications/NotificationContent';
import {ReactComponent as NotificationImg} from '../../../assets/images/icons/header/notifications.svg';
import './NotificationsSidebar.scss';

const NotificationsSidebar = observer(() => {
  const {activityStore, userStore, uiStore} = React.useContext(AppContext);
  const {notifications, countNotificationUnSeed, isLoading} = activityStore;
  const {user} = userStore;
  const {isMenuCollapsed} = uiStore;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    if (notifications.length > 0) {
      activityStore.setLastNotificationDate(user.id, notifications[0]);
    }
  };

  return (
    <div className={classNames('nav-menu__item nav-menu__item--notification', {active: open})}>
      <div className="nav-menu__button" role="button" aria-hidden="true" onClick={() => handleOpen()}>
        <div className="nav-menu__item-icon">
          <NotificationImg />
        </div>
        <p className="nav-menu__item-text">
          <FormattedMessage id="notification.title" />
        </p>
        {!isLoading && countNotificationUnSeed && countNotificationUnSeed > 0 ? (
          <div className="ui red label mini circular">{countNotificationUnSeed}</div>
        ) : null}
        <Icon name="angle right" />
      </div>
      <Drawer show={open} close={() => setOpen(false)} classes="notifications-drawer" leftOffset={isMenuCollapsed}>
        <NotificationContent />
      </Drawer>
    </div>
  );
});

export default injectIntl(NotificationsSidebar);
