import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {ReactComponent as HomeImg} from '../../../assets/images/icons/header/home.svg';
import './EventsSidebar.scss';

const EventsSidebar = observer(() => (
  <div className="nav-menu__item nav-menu__item--events">
    <NavLink className="nav-menu__button" to="/event/list">
      <div className="nav-menu__item-icon">
        <HomeImg />
      </div>
      <p className="nav-menu__item-text">
        <FormattedMessage id="event.sidebar.title" />
      </p>
    </NavLink>
  </div>
));

export default injectIntl(EventsSidebar);
