import React from 'react';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router-dom';
import firebase from 'firebase/app';
import {AppContext} from '../../../../store';
import {processSession} from '../../../../services/helpers/sessionHelper';
import ModalUrl from '../../../../ui/ModalUrl';
import SessionDetails from '../../../session/SessionDetails';
import './SessionModal.scss';

const SessionModal = observer(() => {
  const {eventId, sessionId} = useParams();
  const {agendaStore, sessionsNewStore, speakerStore, eventStore, participantStore} = React.useContext(AppContext);
  const {sessions} = sessionsNewStore.data;
  const {meetings} = agendaStore.data;
  const eventDetails = eventStore.eventList?.filter(e => e.id === eventId)[0];
  const [sessionInfo, setSession] = React.useState(null);

  const session = sessions ? sessions.filter(s => s.id === sessionId)[0] : null;

  if (!session && !sessionInfo && eventDetails?.participantId) {
    participantStore.getParticipantDetails(eventDetails.participantId).then(participant => {
      speakerStore.getSpeakers(eventId).then(() => {
        firebase
          .database()
          .ref(`/newEvents/${eventId}/public_sessions/${sessionId}`)
          .once('value')
          .then(res => {
            const ses = res.val();
            processSession(ses, eventDetails, participant);
            setSession(ses);
            return Promise.resolve(ses);
          });
      });
    });
  }

  return session || sessionInfo ? (
    <ModalUrl className="modal-session" open={!!session || !!sessionInfo} size="small">
      <SessionDetails session={session || sessionInfo} meetings={meetings} />
    </ModalUrl>
  ) : null;
});

export default SessionModal;
