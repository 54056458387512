import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Button, Form, Message, Segment, Dimmer, Loader, Dropdown} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import './MeetingTimeSlots.scss';

const MeetingTimeSlots = observer(props => {
  const {attendee} = props;
  const {participantStore} = React.useContext(AppContext);
  const {action, availableSessions, availableSessionDayIndex, selectedSessionId} = participantStore;

  return (
    <div className="time-slots">
      {availableSessions != null && availableSessions.days.length > 0 ? (
        <div className="time-slots__date">
          <h2>
            {action === 'accept' ? (
              <FormattedMessage id="meeting.time.slots.message.select" />
            ) : (
              <FormattedMessage id="meeting.time.slots.message.select.new" />
            )}
          </h2>
          <Form.Field className="field__select">
            <Dropdown
              search
              selection
              defaultValue={availableSessions.days[availableSessionDayIndex]}
              options={availableSessions.days.map(d => {
                return {
                  value: d,
                  text: d,
                };
              })}
              onChange={(_, value) => participantStore.selectMeetingDay(value)}
            />
          </Form.Field>
        </div>
      ) : null}

      {availableSessions ? (
        <div className="time-slots__time-slots">
          {availableSessions.availableSessions.length > 0 ? (
            <>
              <h2>Please select time you would like to meet</h2>
              <div className="time-slots__time-list">
                {Array.from(availableSessions.availableSessions[availableSessionDayIndex]?.slots).map(
                  availableSession => (
                    <Button
                      key={availableSession.id}
                      basic
                      onClick={() => participantStore.selectSession(availableSession)}
                      className={classNames('time-slot', {
                        'time-slot--active active': selectedSessionId === availableSession.id,
                      })}
                    >
                      {availableSession.sdts.hour}
                    </Button>
                  ),
                )}
              </div>
              <div className="time-slots__note">Please note you can reschedule the time later</div>
            </>
          ) : null}
          {availableSessions.noDealRoom ? (
            <Message error>
              <FormattedMessage id="meeting.time.slots.message.error1" />
            </Message>
          ) : null}
          {availableSessions.noOpponentSpace ? (
            <Message info>
              <FormattedMessage
                id="meeting.time.slots.message.info"
                values={{
                  fullName: attendee.fullName,
                  b: chunks => <b>{chunks}</b>,
                }}
              />
            </Message>
          ) : null}
          {availableSessions.noSelfSpace ? (
            <Message error>
              <FormattedMessage id="meeting.time.slots.message.error2" />
            </Message>
          ) : null}
        </div>
      ) : (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        </Segment>
      )}
    </div>
  );
});

MeetingTimeSlots.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
};

export default MeetingTimeSlots;
