import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import {useForm, FormProvider} from 'react-hook-form';
import {Button, Form} from 'semantic-ui-react';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import UniversalField from '../../shared/UniversalField/UniversalField';
import MeetingApi from '../../../services/api/meetingApi';
import './SessionAddToAgendaForm.scss';

const SessionAddToAgendaForm = observer(props => {
  const {session} = props;
  const {userStore, modalStore, agendaStore} = React.useContext(AppContext);
  const {user, error} = userStore;
  const methods = useForm({mode: 'onChange'});
  const [loading, setLoading] = React.useState(false);

  const onSubmit = value => {
    setLoading(true);
    MeetingApi.joinSession(user.id, session.eventId, value.selectedSessionId)
      .then(() => {
        NotificationAlert.success(
          <FormattedMessage
            id="session.addToAgenda.text.success"
            values={{
              name: (
                <span
                  className="session__success close-icon"
                  aria-hidden
                  onClick={() => {
                    agendaStore.data.showAgenda(true);
                  }}
                >
                  {session.name}
                </span>
              ),
            }}
          />,
        );
      })
      .finally(() => {
        setLoading(false);
        modalStore.close('addSessionToAgendaModal');
      });
  };

  const availableToMeSessions = session.availableToMe
    ? Object.keys(session.availableToMe).map(type => {
        const availableToMeSession = session.availableToMe[type];
        return {
          label: `${availableToMeSession.sdts.date} ${availableToMeSession.sdts.hour}`,
          value: availableToMeSession.id,
          checked: false,
        };
      })
    : [];

  return (
    <FormProvider {...methods}>
      <Form
        className="session-add-to-agenda-form"
        error={!isEmpty(methods.errors) || !!error}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <p>
          <FormattedMessage id="session.addToAgenda.title" />
        </p>
        <div className="session-add-to-agenda-form__fields equal width fields">
          <UniversalField
            name="selectedSessionId"
            type="radioGroup"
            required
            fields={availableToMeSessions}
            error={methods.errors.selectedSessionId}
          />
        </div>
        <Button color="teal" loading={loading} disabled={!methods.formState.isValid}>
          <FormattedMessage id="session.addToAgenda.form.btn" />
        </Button>
      </Form>
    </FormProvider>
  );
});

export default injectIntl(SessionAddToAgendaForm);
