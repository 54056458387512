import React, {Suspense, lazy, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Button} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import AgendaLinkCalendar from '../AgendaLinkCalendar/AgendaLinkCalendar';
import './AgendaExportMeetings.scss';

const AgendaLinkPDF = lazy(() => import('../AgendaLinkPDF'));

const AgendaExportMeetings = observer(() => {
  const [exportType, setExportType] = useState(null);

  useEffect(() => {
    return () => {
      setExportType(null);
    };
  }, []);

  return (
    <div className="agenda-export-meetings">
      <Suspense fallback={null}>
        {exportType === 'emailAgenda' ? (
          <AgendaLinkCalendar goBack={() => setExportType(null)} />
        ) : (
          <div className="agenda-export-meetings__btns">
            <Button color="blue" onClick={() => setExportType('emailAgenda')}>
              <FormattedMessage id="agenda.export.meetings.email.btn" />
            </Button>
            <AgendaLinkPDF />
          </div>
        )}
      </Suspense>
    </div>
  );
});

export default AgendaExportMeetings;
