import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button} from 'semantic-ui-react';
import Webcam from 'react-webcam';
import {useDropzone} from 'react-dropzone';
import Cropper from 'react-cropper';
import ButtonLoading from '../../../ui/ButtonLoading';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import './PhotoByCamera.scss';

const PhotoByCamera = observer(({maxWidth, maxHeight}) => {
  const {userStore, modalStore} = React.useContext(AppContext);
  const webcamRef = React.useRef(null);
  const [photo, setPhoto] = React.useState(null);
  const [cropper, setCropper] = React.useState(null);

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: uploadFile => {
      setPhoto(
        uploadFile.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const savePhoto = () => {
    if (typeof cropper !== 'undefined') {
      let cropperOptions = {};
      if (maxWidth) {
        cropperOptions.maxWidth = maxWidth;
      }
      if (maxHeight) {
        cropperOptions.maxHeight = maxHeight;
      }
      return userStore
        .updateUserDetails(userStore.user.id, {
          action: 'photo',
          image: cropper.getCroppedCanvas().toDataURL(),
        })
        .then(() => {
          modalStore.close('updatePhoto');
          NotificationAlert.success(<FormattedMessage id="photo.byCamera.success" />);
        })
        .catch(err => {
          NotificationAlert.error(err.message);
        });
    }
  };

  const preview = () => {
    if (photo) {
      return (
        <Cropper
          className="photo-upload__cropper"
          src={photo}
          preview=".img-preview"
          viewMode={1}
          guides
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          initialAspectRatio={1}
          aspectRatio={1}
          responsive
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={instance => {
            setCropper(instance);
          }}
        />
      );
    }
    return null;
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhoto(imageSrc);
  }, [webcamRef, setPhoto]);

  return userStore.user ? (
    <div className="photo-by-camera">
      {!photo ? (
        <div className="photo-by-camera__view">
          <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
          <Button primary type="submit" className="photo-by-camera__submit" onClick={capture}>
            <FormattedMessage id="photo.byCamera.btn.capture" />
          </Button>
        </div>
      ) : null}
      <section>
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
        </div>
        {preview()}
      </section>

      {photo ? (
        <div className="photo-by-camera__actions">
          <Button
            secondary
            type="submit"
            className="photo-by-camera__cancel"
            onClick={() => modalStore.close('updatePhoto')}
          >
            <FormattedMessage id="photo.byCamera.btn.cancel" />
          </Button>
          <ButtonLoading primary onClick={() => savePhoto()} type="submit" className="photo-by-camera__save">
            <FormattedMessage id="photo.byCamera.btn.save" />
          </ButtonLoading>
        </div>
      ) : null}
    </div>
  ) : null;
});

export default injectIntl(PhotoByCamera);
