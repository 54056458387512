import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import {Button, Form} from 'semantic-ui-react';
import {useForm} from 'react-hook-form';
import {AppContext} from '../../../store';
import ButtonLoading from '../../../ui/ButtonLoading';
import MeetingApi from '../../../services/api/meetingApi';
import AttendeePreview from '../../attendee/AttendeePreview';
import Title from '../../shared/Title';
import useMedia from '../../../hooks/useMedia';
import {redirectBack} from '../../../services/helpers/redirectHelper';
import {useLocation} from 'react-router-dom';
import './MeetingFormCancel.scss';

const MeetingFormCancel = observer(props => {
  const {meeting, isDirectOpen} = props;
  const intl = useIntl();
  const {userStore, modalStore, invitationStore} = React.useContext(AppContext);
  const {invitations} = invitationStore;
  const {user} = userStore;
  const location = useLocation();
  const {register, errors, getValues} = useForm();
  const isMobile = useMedia('(max-width: 768px)');
  const invitation = invitations ? invitations.find(i => meeting && i.csid === meeting.id) : {};

  const onSubmit = () => {
    const message = getValues().message || 'Unfortunately I have to cancel our meeting.';
    return MeetingApi.cancelMeeting(user.id, meeting.eid, meeting.id, message, invitation.id).then(() => {
      if (isMobile) {
        redirectBack(location);
      } else {
        modalStore.close('attendeeMeet');
      }
    });
  };

  return meeting ? (
    <>
      <Title id="meeting.cancel" />
      <Form className="meeting-form-cancel">
        <AttendeePreview attendee={meeting.participants[0]} big />
        <Form.Field className="field__textarea" error={!!errors.username}>
          <div className="ui left input">
            <textarea
              rows="4"
              name="message"
              placeholder={intl.formatMessage({id: 'meeting.form.cancel.placeholder'})}
              ref={register()}
            />
          </div>
        </Form.Field>
        <div className="meeting-form-cancel__action">
          {!isDirectOpen ? (
            <Button color="red" basic onClick={() => props.onBack()}>
              <FormattedMessage id="meeting.form.cancel.btn.back" />
            </Button>
          ) : isMobile ? (
            <Button color="blue" basic onClick={() => redirectBack(location)}>
              <FormattedMessage id="meeting.form.cancel.btn.back" />
            </Button>
          ) : null}
          <ButtonLoading color="red" onClick={() => onSubmit()}>
            <FormattedMessage id="meeting.form.cancel.btn.cancel" />
          </ButtonLoading>
        </div>
      </Form>
    </>
  ) : null;
});

MeetingFormCancel.propTypes = {
  meeting: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBack: PropTypes.func,
};

MeetingFormCancel.defaultProps = {
  onBack: () => {},
};

export default injectIntl(MeetingFormCancel);
