import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage} from 'react-intl';
import {AppContext} from '../../../../store';
import Modal from '../../../../ui/Modal';
import SessionAddToAgendaForm from '../../../session/SessionAddToAgendaForm';
import './AddSessionToAgendaModal.scss';

const AddSessionToAgendaModal = observer(() => {
  const {modalStore} = React.useContext(AppContext);
  const {open, session} = modalStore.modals.addSessionToAgendaModal;

  return (
    <Modal
      className="add-session-to-agenda-modal"
      open={open}
      onClose={() => modalStore.close('addSessionToAgendaModal')}
    >
      <h3>
        <FormattedMessage id="add.session.to.agenda.modal.title" />
      </h3>
      <SessionAddToAgendaForm session={session} />
    </Modal>
  );
});

export default AddSessionToAgendaModal;
