import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage} from 'react-intl';
import Modal from '../../../../ui/Modal';
import {AppContext} from '../../../../store';
import AgendaExportMeetings from '../../../agenda/AgendaExportMeetings';
import './ExportAgendaModal.scss';

const ExportAgendaModal = observer(() => {
  const {modalStore} = React.useContext(AppContext);
  const {open} = modalStore.modals.exportAgendaModal;

  return (
    <Modal
      className="export-agenda-modal"
      open={open}
      onClose={() => {
        modalStore.close('exportAgendaModal');
      }}
      size="tiny"
    >
      <h3>
        <FormattedMessage id="export.agenda.modal.title" />
      </h3>
      <AgendaExportMeetings />
    </Modal>
  );
});

export default ExportAgendaModal;
