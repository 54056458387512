import React from 'react';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router-dom';
import {AppContext} from '../../../../store';
import MobileInsideLayout from '../../../../layouts/MobileInsideLayout';
import AttendeeDetails from '../../../attendee/AttendeeDetails';
import Title from '../../../shared/Title';
import './AttendeeModalPage.scss';

const AttendeeModalPage = observer(() => {
  const {attendeeId, eventId} = useParams();
  const {participantStore, eventStore, userStore} = React.useContext(AppContext);
  const {user} = userStore;
  const {meParticipant} = eventStore;
  const [banner, setBanner] = React.useState(null);
  const [attendee, setAttendee] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (attendeeId && eventId) {
      setLoading(true);
      eventStore.getEventDetails(eventId, true).then(details => {
        setBanner(details.images.banner);
        setTimeout(() => {
          participantStore
            .getParticipantDetails(attendeeId)
            .then(participant => {
              setAttendee(participant);
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        }, 300);
      });
      if (meParticipant) {
        participantStore.markAsSeen(eventId, user.id, meParticipant.id);
      }
    }

    return () => {
      setAttendee(null);
    };
  }, [eventStore, attendeeId, participantStore, meParticipant]);

  return loading || attendee?.id ? (
    <MobileInsideLayout>
      <Title id="attendee-details" />
      <AttendeeDetails attendee={attendee} banner={banner} />
    </MobileInsideLayout>
  ) : null;
});

export default AttendeeModalPage;
