import PropTypes from 'prop-types';
import {FormattedMessage, useIntl} from 'react-intl';
import {Dropdown} from 'semantic-ui-react';

const CheckinStatusSelector = props => {
  const {status, onChangeStatus, className} = props;
  const intl = useIntl();

  const checkInStatusOptions = [
    {
      text: 'Check-in',
      value: true,
    },
    {
      text: 'Checkout',
      value: false,
    },
  ];

  return (
    <>
      <label htmlFor="checkin-status">
        <FormattedMessage id="checkIn.statusSelector.label" />
      </label>
      <Dropdown
        className={className}
        selection
        placeholder={intl.formatMessage({id: 'checkIn.form.chooseScanMethod.placeholder'})}
        options={checkInStatusOptions}
        id="checkin-status"
        onChange={(e, {value}) => {
          onChangeStatus(value);
        }}
        value={status}
      />
    </>
  );
};

CheckinStatusSelector.propTypes = {
  onChangeStatus: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

CheckinStatusSelector.defaultProps = {
  className: '',
};

export default CheckinStatusSelector;
