import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Icon} from 'semantic-ui-react';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import InvitationsDrawerBody from '../../invitations/InvitationsDrawerBody';
import {filterInvitation} from '../../../services/helpers/invitationHelper';
import Drawer from '../../shared/Drawer';
import {ReactComponent as InvitationImg} from '../../../assets/images/icons/header/invitationsFull.svg';
import './InvitationsSidebar.scss';

const InvitationsSidebar = observer(() => {
  const {invitationStore, participantStore, uiStore} = React.useContext(AppContext);
  const {loadingActiveInvitations, invitations, invitationSidebarShow} = invitationStore;
  const {isMenuCollapsed} = uiStore;
  const {states} = participantStore;

  const handleClose = async () => {
    invitationStore.showInvitation(false);
  };

  const countReceivedInvitations =
    invitations.filter(invitation => {
      const state = states && states[invitation.oppId];
      return filterInvitation(invitation, 'received', 'live') && ['request_meeting', 'reschedule'].includes(state);
    }).length +
    invitations.filter(invitation => {
      const state = states && states[invitation.oppId];
      return filterInvitation(invitation, 'company', 'live') && ['request_meeting', 'reschedule'].includes(state);
    }).length;

  return (
    <div className={classNames('nav-menu__item nav-menu__item--invitations', {active: invitationSidebarShow})}>
      <div
        className="nav-menu__button"
        role="button"
        aria-hidden="true"
        onClick={() => invitationStore.showInvitation(true)}
      >
        <div className="nav-menu__item-icon">
          <InvitationImg />
        </div>
        <p className="nav-menu__item-text">
          <FormattedMessage id="invitation.sidebar.menuTitle" />
        </p>
        {!loadingActiveInvitations && countReceivedInvitations && countReceivedInvitations > 0 ? (
          <div className="ui red label mini circular">{countReceivedInvitations}</div>
        ) : null}
        <Icon name="angle right" />
      </div>
      <Drawer
        show={invitationSidebarShow}
        close={handleClose}
        classes="invitations-drawer"
        leftOffset={isMenuCollapsed}
      >
        <InvitationsDrawerBody />
      </Drawer>
    </div>
  );
});

export default injectIntl(InvitationsSidebar);
