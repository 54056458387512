import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ImageLazy from '../ImageLazy';
import {HostUrl} from '../../services/core/axios-service';
import attendeeImg from '../../assets/images/defaultImages/attendee.png';
import './PersonItem.scss';

const PersonItem = ({...props}) => {
  const {person, openAgenda, fullImageLink, short, allSpeakers, checkbox, classes, isInDropdown} = props;
  const {id, profilePhoto, name, company, jobTitle, width, height} = person;
  const [active, setActive] = useState(false);

  const PersonUserName = () => {
    const text = `${person.name} ${person.surname}`;
    return (
      <p className="person__user-name" onClick={openAgenda} aria-hidden>
        {isInDropdown && text.length > 9 ? `${text.slice(0, 9)}..` : text}
      </p>
    );
  };

  return person ? (
    <div
      className={`person ${active && 'active'} ${classes}`}
      aria-hidden
      key={id}
      onClick={() => {
        openAgenda();
        setActive(!active);
      }}
    >
      {checkbox && (
        <div className="person__checkbox">
          <input checked={active} type="checkbox" onChange={() => setActive(!active)} />
        </div>
      )}
      <div className="person__img">
        {profilePhoto ? (
          <ImageLazy
            src={fullImageLink ? profilePhoto : `${HostUrl}/${profilePhoto}`}
            errorSrc={attendeeImg}
            alt={name}
            onClick={() => openAgenda}
            width={width}
            height={height}
          />
        ) : (
          <img src={attendeeImg} alt={name} onClick={() => openAgenda} width={40} height={40} aria-hidden />
        )}
        {allSpeakers && Object.keys(allSpeakers).length > 1 ? (
          <div className="person__other-speakers">+{Object.keys(allSpeakers).length}</div>
        ) : null}
      </div>
      {!short ? (
        <div className="person__user-info">
          <PersonUserName />
          <p className="person__user-jobTitle">
            {jobTitle}
            {', '}
          </p>
          <p className="person__user-company">{company}</p>
        </div>
      ) : null}
    </div>
  ) : null;
};

PersonItem.propTypes = {
  person: PropTypes.oneOfType([PropTypes.object]).isRequired,
  openAgenda: PropTypes.func,
  checkbox: PropTypes.bool,
  short: PropTypes.bool,
  classes: PropTypes.string,
  isInDropdown: PropTypes.bool,
  allSpeakers: PropTypes.oneOfType([PropTypes.array]),
  fullImageLink: PropTypes.bool,
};

PersonItem.defaultProps = {
  openAgenda: () => {},
  short: false,
  checkbox: false,
  allSpeakers: [],
  classes: '',
  isInDropdown: false,
  fullImageLink: false,
};

export default PersonItem;
