import React from 'react';
import {observer} from 'mobx-react-lite';
//import {useParams} from 'react-router-dom';
//import ScrollToTop from '../../ui/ScrollToTop';
import htmlClass from '../../utils/htmlClass';
import './MobileInsideLayout.scss';

const MobileInsideLayout = observer(props => {
  React.useEffect(() => {
    htmlClass.add(document.body, 'mobile-page');
    return () => {
      htmlClass.remove(document.body, 'mobile-page');
    };
  }, []);

  return <div className="mobile-inside">{props.children}</div>;
});

export default MobileInsideLayout;
