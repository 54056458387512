import {action, extendObservable, runInAction, autorun} from 'mobx';
import firebase from 'firebase/app';
import 'firebase/database';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import differenceBy from 'lodash/differenceBy';
import TimeService from '../../../services/core/time-service';
import ServiceCacheService from '../../../services/core/cache-service';
import {checkUpcomingState} from '../../../services/helpers/meetingHelper';
import {processSession} from '../../../services/helpers/sessionHelper';

export class SessionsListStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.sessionCache = ServiceCacheService.create();

    extendObservable(this, {
      sessions: [],
      sessionLoadedForEvent: {},
    });

    autorun(() => {
      setInterval(() => {
        this.processSessionsForAgenda();
      }, 60000);
    });
  }

  getPublicSession(eventId, sessionId, originalId, resetCache) {
    return this.sessionCache.getOrCreate(
      [eventId, sessionId, originalId],
      (key, subject) => {
        return this.getPublicSessionFromFirebase(eventId, sessionId).then(data => {
          runInAction(() => subject.set(data));
          return data;
        });
      },
      resetCache,
    );
  }

  getPublicSessionFromFirebase(eventId, sessionId) {
    let prevSession;
    const ref = firebase.database().ref(`/newEvents/${eventId}/public_sessions/${sessionId}`);
    return new Promise((resolve, reject) => {
      ref.once('value').then(
        res => {
          prevSession = res.val();
          const ses = res.val();
          return this.rootStore.eventStore.getEventDetails(eventId).then(eventDetails => {
            return resolve(processSession(ses, eventDetails, eventDetails.me));
          });
        },
        errorObject => reject(errorObject),
      );
      ref.on('child_changed', res => {
        prevSession[res.key] = res.val();
        this.rootStore.eventStore.getEventDetails(eventId, true).then(eventDetails => {
          this.rootStore.agendaStore.data.meetings.forEach((meeting, key) => {
            if ((meeting.rsesId || meeting.id) === prevSession.id) {
              this.rootStore.agendaStore.data
                .getPrivateMeeting(eventId, eventDetails.me.id, meeting.id, prevSession)
                .then(session => {
                  if (session) {
                    runInAction(() => {
                      this.rootStore.agendaStore.data.meetings[key] = session;
                    });
                  }
                });
            }
          });
        });
      });
    });
  }

  @action
  getPublicSessions(eventId, groupId, currentEvent) {
    return this.sessionCache.getOrCreate(
      [eventId, groupId],
      (key, subject) =>
        this.getPublicDefinitionsFirebase(eventId, groupId, currentEvent).then(data => {
          runInAction(() => subject.set(data));
          return data;
        }),
      false,
    );
  }

  @action
  getPublicDefinitionsFirebase(eventId, groupId) {
    const ref = firebase.database().ref(`/newEvents/${eventId}/public_sessions/${groupId}`);
    const mySessions = this.rootStore.agendaStore.data.mySessions;

    return new Promise(resolve => {
      ref.once('value').then(
        snapshot =>
          this.rootStore.eventStore.getEventDetails(eventId).then(eventDetails => {
            if (snapshot.val()) {
              const participantDetails = eventDetails.me;
              const sessions = snapshot.val();
              let sessionsAfterProcessing = [];

              Object.keys(sessions).forEach(val => {
                const ses = sessions[val];
                const session = processSession(ses, eventDetails, participantDetails, mySessions);
                if (!isEmpty(session) && session.eventId === eventId && session.visibleToParticipant) {
                  sessionsAfterProcessing.push(session);
                  runInAction(() => {
                    if (session.visibleToParticipant) {
                      this.sessions.push(session);
                    }
                  });
                }
              });

              this.processSessionsForAgenda();
              runInAction(() => {
                this.sessionLoadedForEvent[eventId] = true;
              });
              return resolve(sessionsAfterProcessing);
            }
            return resolve([]);
          }),
        errorObject => {
          this.rootStore.errorStore.addError(errorObject.code);
        },
      );

      ref.on('child_added', newSession => {
        if (!some(this.sessions, ['id', newSession.val().id]) && this.sessionLoadedForEvent[eventId]) {
          this.rootStore.eventStore.getEventDetails(eventId, true).then(eventDetails => {
            const session = processSession(newSession.val(), eventDetails, eventDetails.me, mySessions);
            if (!isEmpty(session) && session.eventId === eventId && session.visibleToParticipant) {
              runInAction(() => {
                this.sessions.push(session);
              });
            }
            this.processSessionsForAgenda();
          });
        }
      });

      ref.on('child_removed', res => {
        runInAction(() => {
          this.sessions = differenceBy(this.sessions, [res.val()], 'id');
        });
      });

      ref.on('child_changed', res => {
        this.rootStore.eventStore.getEventDetails(eventId, true).then(eventDetails => {
          const session = processSession(res.val(), eventDetails, eventDetails.me, mySessions);
          if (!isEmpty(session) && session.eventId === eventId && session.visibleToParticipant) {
            runInAction(() => {
              this.sessions = differenceBy(this.sessions, [res.val()], 'id');
              this.sessions.push(session);
            });
          }
          this.processSessionsForAgenda();
        });
      });
    });
  }

  @action
  processSessionsForAgenda() {
    let updated = false;
    if (this.sessions == null) return;
    const {myBlockingSessions} = this.rootStore.agendaStore.data;
    this.sessions.slice().forEach(session => {
      session.availableToMe = session.availableSesArray;

      session.availableToMe = session.availableSesArray.filter(val => {
        let collides = false;
        if (myBlockingSessions != null) {
          for (const mySessionIndex in myBlockingSessions) {
            const mySession = myBlockingSessions[mySessionIndex];
            if (TimeService.collides(mySession.sdt, mySession.edt, val.sdt, val.edt)) {
              collides = true;
            }
          }
        }
        const expired = session.expiresAt < TimeService.now();

        return ((collides === false && session.blocking) || !session.blocking) && !expired;
      });
      let allExpired = true;
      let started = false;
      let rehersalStarted = false;
      let collision = myBlockingSessions != null && myBlockingSessions.length > 0;
      session.sesArray.forEach(ses => {
        let collides = false;
        if (myBlockingSessions != null) {
          for (const mySessionIndex in myBlockingSessions) {
            const mySession = myBlockingSessions[mySessionIndex];
            if (TimeService.collides(mySession.sdt, mySession.edt, ses.sdt, ses.edt)) {
              collides = true;
            }
          }
        }
        collision = collision && collides;
        const expired = ses.expiresAt < TimeService.now();
        allExpired = allExpired && expired;
        if (ses.startsAt - 900000 >= TimeService.now()) {
          started = true;
        }
        if (ses.startsAt - 900000 <= TimeService.now()) {
          rehersalStarted = true;
        }
      });
      if (session.allExpired !== allExpired) {
        updated = true;
      }
      session.allExpired = allExpired;
      session.started = started;
      session.rehersalStarted = rehersalStarted;
      const newUpcomingState = checkUpcomingState(session.sesArray?.slice()[0], allExpired);
      if (session.upcomingState !== newUpcomingState) {
        updated = true;
      }
      session.upcomingState = newUpcomingState;
      session.collision = collision;
      if (session.collision !== collision) {
        updated = true;
      }
      const newIsFull = session.availableSesArray.length === 0;
      if (session.isFull !== newIsFull) {
        updated = true;
      }
      session.isFull = newIsFull;
    });

    if (updated) {
      // TODO
      // this.search();
    }
  }

  @action
  clean() {
    this.sessions = [];
    this.sessionLoadedForEvent = {};
  }

  @action
  cleanAfterLogout() {
    this.sessions = [];
  }
}
