import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Button} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import {chatMessageText, parseJsonFromText} from '../../../services/helpers/chatHelper';
import ParserText from '../../../ui/ParserText';
import './ChatMessageByContact.scss';

const ChatMessageByContact = observer(props => {
  const {message} = props;
  const {userStore, contactStore, modalStore} = React.useContext(AppContext);
  const {user} = userStore;
  const userId = user.id;
  const isSender = message.sndr === userId;

  const userInfoFromMessage = parseJsonFromText(message.msg);
  const checkShowAddContactBtn =
    !isSender && typeof userInfoFromMessage === 'object' && userInfoFromMessage.name && userInfoFromMessage.email;

  const onSubmit = () => {
    userInfoFromMessage.phone = userInfoFromMessage.phone.replace(/\+/g, '');

    contactStore.setContactForm({
      firstName: userInfoFromMessage.name,
      lastName: userInfoFromMessage.surname,
      email: userInfoFromMessage.email,
      phone: userInfoFromMessage.phone,
      company: userInfoFromMessage.company,
      jobTitle: userInfoFromMessage.jobTitle,
      website: userInfoFromMessage.website,
    });

    modalStore.open('addContactModal');
  };

  return (
    <div className="chat-msg__contact">
      <div className="chat-msg__contact-img">
        <i className="ellipsis user icon" />
      </div>
      <div className="chat-msg__contact-msg">
        <FormattedMessage id="chat.message.contact.text" />
        <ParserText>{chatMessageText(userInfoFromMessage, ['name', 'surname'])}</ParserText>
      </div>
      {checkShowAddContactBtn ? (
        <div className="chat-msg__contact-action">
          <Button basic onClick={() => onSubmit()}>
            <i className="ellipsis user plus icon" />
            <FormattedMessage id="chat.message.contact.btn" />
          </Button>
        </div>
      ) : null}
    </div>
  );
});

ChatMessageByContact.propTypes = {
  message: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default injectIntl(ChatMessageByContact);
