import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {useHistory, useLocation} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {AppContext} from '../../../store';
import useUserInvitation from '../../../hooks/useUserInvitation';
import useMedia from '../../../hooks/useMedia';
import {meetingStatus} from '../../../constants/meetingStatus';
import './InvitationButton.scss';

const InvitationButton = observer(props => {
  const {attendee, type, containerWidth} = props;
  const {modalStore, userStore, eventStore, sessionsNewStore, uiStore} = React.useContext(AppContext);
  const {eventDetails} = eventStore;
  const {user, myParticipantBooth} = userStore;
  const {meeting, status, isActiveMeeting, state} = useUserInvitation(attendee, type === 'partner');
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMedia('(max-width: 768px)');
  const eventIsFinished = eventDetails && eventDetails.isFinished;
  const {sessions} = sessionsNewStore.data;
  const isAvailableOneToOneSessions = sessions.some(session => session.type === 'dealroom' && !session.expired);
  const showInvitationBtn = (attendee.canInvite || type === 'partner') && isAvailableOneToOneSessions;

  const openAttendeeInvite = () => {
    history.push({
      pathname: `/event/${attendee.eventId}/invitation/${attendee.id}`,
      state: {
        background: location,
        page: 'invitation',
        prevPage: uiStore.pageTitle,
        isBooth: type === 'partner',
        state,
        title: 's.name',
      },
    });
  };

  const btn = () => {
    if (myParticipantBooth && myParticipantBooth.id === attendee.boothId && user.id !== attendee.userId) {
      return (
        <Button
          disabled={true}
          className="attendee-card__btn attendee-card__btn-meet"
          color="blue"
          style={{fontSize: containerWidth >= 320 ? '14px' : '12px'}}
        >
          {type === 'partner' ? (
            <FormattedMessage id="invitation.btn.meet.partner" />
          ) : (
            <FormattedMessage id="invitation.btn.meet.other" />
          )}
        </Button>
      );
    } else if (user.id !== attendee.userId) {
      // Sent new invite
      if (status === meetingStatus.INVITE_NEW) {
        return (
          <Button
            disabled={eventIsFinished}
            className="attendee-card__btn attendee-card__btn-meet"
            color="blue"
            onClick={() => {
              if (isMobile) {
                openAttendeeInvite();
              } else {
                modalStore.open('attendeeMeet', {attendee, isBooth: type === 'partner', newMeeting: true});
              }
            }}
            style={{fontSize: containerWidth >= 320 ? '14px' : '12px'}}
          >
            {type === 'partner' ? (
              <FormattedMessage id="invitation.btn.meet.partner" />
            ) : (
              <FormattedMessage id="invitation.btn.meet.other" />
            )}
          </Button>
        );
      }
      // Waiting
      if (status === meetingStatus.INVITE_PENDING) {
        return (
          <Button
            disabled={eventIsFinished}
            className="attendee-card__btn attendee-card__btn--pending"
            onClick={() => {
              if (isMobile) {
                openAttendeeInvite();
              } else {
                modalStore.open('attendeeMeet', {attendee, isBooth: type === 'partner'});
              }
            }}
          >
            <i className="spinner icon" /> <FormattedMessage id="invitation.btn.request.meeting.pending" />
          </Button>
        );
      }
      // Respond
      if (status === meetingStatus.INVITE_RECEIVED) {
        return (
          <Button
            disabled={eventIsFinished}
            className="attendee-card__btn attendee-card__btn--respond"
            onClick={() => {
              if (isMobile) {
                openAttendeeInvite();
              } else {
                modalStore.open('attendeeMeet', {attendee, isBooth: type === 'partner'});
              }
            }}
          >
            <FormattedMessage id="invitation.btn.request.meeting.respond" />
          </Button>
        );
      }
      // Declined
      if (status === meetingStatus.INVITE_REJECTED) {
        return (
          <Button
            disabled={eventIsFinished}
            className="attendee-card__btn attendee-card__btn--rejected"
            color="red"
            onClick={() => {
              if (isMobile) {
                openAttendeeInvite();
              } else {
                modalStore.open('attendeeMeet', {attendee, isBooth: type === 'partner'});
              }
            }}
          >
            <FormattedMessage id="invitation.btn.rejected" />
          </Button>
        );
      }
      // Meeting details in live
      if (status === meetingStatus.MEETING_DETAILS && isActiveMeeting) {
        return (
          <Button
            disabled={eventIsFinished}
            className="attendee-card__btn attendee-card__btn--meeting"
            color="green"
            onClick={() => {
              if (isMobile) {
                openAttendeeInvite();
              } else {
                modalStore.open('attendeeMeet', {attendee, state, isBooth: type === 'partner'});
              }
            }}
            style={{height: containerWidth >= 320 ? 'auto' : '100%'}}
          >
            <FormattedMessage id="invitation.btn.meeting.join" />
          </Button>
        );
      }
      // Meeting details
      if (status === meetingStatus.MEETING_DETAILS) {
        return (
          <Button
            disabled={eventIsFinished}
            className="attendee-card__btn attendee-card__btn--meeting"
            color="green"
            onClick={() => {
              if (isMobile) {
                openAttendeeInvite();
              } else {
                modalStore.open('attendeeMeet', {attendee, state, isBooth: type === 'partner'});
              }
            }}
          >
            {meeting ? (
              `${meeting?.sdts?.hour} ${meeting?.sdts?.month}`
            ) : (
              <FormattedMessage id="invitation.btn.meeting.details" />
            )}
          </Button>
        );
      }
      // Reschedule
      if (status === meetingStatus.MEETING_RESCHEDULE) {
        return (
          <Button
            disabled={eventIsFinished}
            className="attendee-card__btn attendee-card__btn--reschedule"
            onClick={() => {
              if (isMobile) {
                openAttendeeInvite();
              } else {
                modalStore.open('attendeeMeet', {attendee, isBooth: type === 'partner'});
              }
            }}
          >
            <i className="redo icon" />
            <span>
              <FormattedMessage id="invitation.btn.reschedule.respond" />
            </span>
          </Button>
        );
      }
      if (status === meetingStatus.MEETING_RESCHEDULE_PENDING) {
        return (
          <Button
            disabled={eventIsFinished}
            className="attendee-card__btn attendee-card__btn--reschedule-pending"
            color="olive"
            onClick={() => {
              if (isMobile) {
                openAttendeeInvite();
              } else {
                modalStore.open('attendeeMeet', {attendee, isBooth: type === 'partner'});
              }
            }}
          >
            <i className="spinner icon" />
            <span>
              <FormattedMessage id="invitation.btn.reschedule.pending" />
            </span>
          </Button>
        );
      }
    }
    return null;
  };

  return showInvitationBtn ? btn() : null;
});

InvitationButton.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
  type: PropTypes.string,
};

InvitationButton.defaultProps = {
  attendee: {},
  type: '',
};

export default InvitationButton;
