import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button} from 'semantic-ui-react';
import classNames from 'classnames';
import {useHistory, useLocation} from 'react-router-dom';
import {AppContext} from '../../../store';
import {commonActiveMeeting} from '../../../services/helpers/meetingHelper';
import AttendeePreview from '../../attendee/AttendeePreview';
import InvitationPopupBtns from '../InvitationPopupBtns';
import InvitationFormDecline from '../InvitationFormDecline';
import InvitationFormRespond from '../InvitationFormRespond';
import InvitationHeaderCard from '../InvitationHeaderCard';
import InvitationDateSection from '../InvitationDateSection';
import InvitationFormHasMeeting from '../InvitationFormHasMeeting';
import useMedia from '../../../hooks/useMedia';
import './InvitationCardReceived.scss';

const InvitationCardReceived = observer(props => {
  const {invitation, attendee, actionType, page, type} = props;
  const {modalStore, uiStore, routerStore, agendaStore} = React.useContext(AppContext);
  const {meetings} = agendaStore.data;
  const {pageTitle} = uiStore;
  const isRescheduled = invitation.lastInvitationDetails.type === 'reschedule';
  const commonNotExpiredMeeting = commonActiveMeeting(meetings, invitation.opponentParticipant?.id);
  const invToCompanyTeamMember =
    invitation &&
    invitation.me.boothId &&
    invitation.me.boothId === invitation.opponentParticipantReceived.boothId &&
    !invitation.toBooth &&
    invitation.tpid === invitation.opponentParticipantReceived.id &&
    invitation.tpid !== invitation.me.id &&
    invitation.opponentParticipantReceived.userId !== 'booth';
  const [step, setStep] = React.useState(1);
  const [content, setContent] = React.useState('');
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMedia('(max-width: 768px)');

  const classes = classNames('invitation-card invitation-card--received', {
    'invitation-card--reschedule': isRescheduled,
    'invitation-card--past': invitation.status === 'past',
    'invitation-card--company': type === 'company',
  });

  return (
    <>
      {step === 1 && !actionType ? (
        <>
          <div className={classes}>
            <InvitationHeaderCard invitation={invitation} type={type || 'received'} />
            <InvitationDateSection invitation={invitation} />
            <div className="invitation-card__top">
              <AttendeePreview attendee={invitation.opponentParticipant} />
            </div>
            <div className="invitation-card__bottom">
              <div
                className="invitation-card__message"
                dangerouslySetInnerHTML={{__html: invitation.lastInvitationDetails?.message?.replaceAll('\n', '<br>')}}
              ></div>
            </div>
            {invitation.status === 'live' && !isRescheduled ? (
              <div className="invitation-card__action">
                <Button
                  className="attendee-card__btn attendee-card__btn-decline"
                  color="red"
                  disabled={invToCompanyTeamMember}
                  onClick={() => {
                    if (page === 'invitations' && isMobile) {
                      history.push({
                        pathname: `/event/${invitation.eid}/invitation/${invitation.opponentParticipant.id}/decline`,
                        state: {
                          background: location.state ? location.state.background : location,
                          page: 'invitation',
                          prevPage: pageTitle,
                          invitationId:invitation.id,
                          isBooth: !!invitation.toBoothId,
                        },
                      });
                    } else if (page === 'invitations') {
                      modalStore.open('attendeeMeet', {
                        attendee: invitation.opponentParticipant,
                        action: 'decline',
                        invitationId:invitation.id,
                        isBooth: !!invitation.toBoothId,
                      });
                    } else {
                      setStep(2);
                      setContent('reject');
                    }
                  }}
                >
                  <FormattedMessage id="invite.card.received.btn.decline" />
                </Button>
                <Button
                  className="attendee-card__btn attendee-card__btn-respond"
                  color={!commonNotExpiredMeeting ? 'blue' : null}
                  onClick={() => {
                    if (page === 'invitations' && isMobile) {
                      history.push({
                        pathname: `/event/${invitation.eid}/invitation/${invitation.opponentParticipant.id}/accept`,
                        state: {
                          background: location.state ? location.state.background : location,
                          page: 'invitation',
                          prevPage: pageTitle,
                          isBooth: !!invitation.toBoothId,
                        },
                      });
                    } else if (page === 'invitations') {
                      modalStore.open('attendeeMeet', {
                        attendee: invitation.opponentParticipant,
                        action: 'accept',
                        isBooth: !!invitation.toBoothId,
                      });
                    } else {
                      setStep(2);
                      setContent('accept');
                    }
                  }}
                >
                  <FormattedMessage id="invite.card.received.btn.respond" />
                </Button>
                <InvitationPopupBtns invitation={invitation} />
              </div>
            ) : null}
            {invitation.status === 'live' && isRescheduled ? (
              <div className="invitation-card__action">
                <Button
                  color="red"
                  basic
                  className="meeting-reschedule__btn-cancel"
                  onClick={() => {
                    if (page === 'invitations' && isMobile) {
                      history.push({
                        pathname: `/event/${invitation.eid}/invitation/${invitation.opponentParticipant.id}/decline`,
                        state: {
                          background: location.state ? location.state.background : location,
                          page: 'invitation',
                          prevPage: pageTitle,
                          isBooth: !!invitation.toBoothId,
                        },
                      });
                    } else if (page === 'invitations') {
                      modalStore.open('attendeeMeet', {
                        attendee: invitation.opponentParticipant,
                        action: 'decline',
                        isBooth: !!invitation.toBoothId,
                      });
                    }
                  }}
                >
                  <FormattedMessage id="meeting.form.reschedule.respond.btn.decline" />
                </Button>
                <Button
                  onClick={() => {
                    if (page === 'invitations' && isMobile) {
                      history.push({
                        pathname: `/event/${invitation.eid}/invitation/${invitation.opponentParticipant.id}/accept`,
                        state: {
                          background: location.state ? location.state.background : location,
                          page: 'invitation',
                          prevPage: pageTitle,
                          isBooth: !!invitation.toBoothId,
                        },
                      });
                    } else if (page === 'invitations') {
                      modalStore.open('attendeeMeet', {
                        attendee: invitation.opponentParticipant,
                        action: 'accept',
                        isBooth: !!invitation.toBoothId,
                      });
                    }
                  }}
                  color="blue"
                >
                  <FormattedMessage id="meeting.form.reschedule.respond.btn.accept" />
                </Button>
                <InvitationPopupBtns invitation={invitation} />
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      {step === 2 || actionType ? (
        <React.Fragment>
          {content === 'reject' || actionType === 'decline' ? (
            <InvitationFormDecline
              invitation={invitation}
              onBack={() => {
                if (!isMobile) {
                  modalStore.close('attendeeMeet');
                } else if (actionType) {
                  routerStore.goBack();
                } else {
                  setStep(1);
                }
              }}
            />
          ) : null}
          {(content === 'accept' || actionType === 'accept') && !commonNotExpiredMeeting ? (
            <InvitationFormRespond
              invitation={invitation}
              attendee={attendee}
              onBack={() => {
                if (!isMobile) {
                  modalStore.close('attendeeMeet');
                } else if (actionType) {
                  routerStore.goBack();
                } else {
                  setStep(1);
                }
              }}
            />
          ) : (content === 'accept' || actionType === 'accept') && commonNotExpiredMeeting ? (
            <InvitationFormHasMeeting
              invitation={invitation}
              commonMeeting={commonNotExpiredMeeting}
              onBack={() => {
                if (!isMobile) {
                  modalStore.close('attendeeMeet');
                } else if (actionType) {
                  routerStore.goBack();
                } else {
                  setStep(1);
                }
              }}
            />
          ) : null}
        </React.Fragment>
      ) : null}
    </>
  );
});

InvitationCardReceived.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.object]),
  page: PropTypes.string,
};

InvitationCardReceived.defaultProps = {
  page: 'modal',
};

export default injectIntl(InvitationCardReceived);
