import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Icon} from 'semantic-ui-react';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import AgendaContent from '../../agenda/AgendaContent';
import Drawer from '../../shared/Drawer';
import {ReactComponent as AgendaImg} from '../../../assets/images/icons/header/agenda.svg';
import './AgendaSidebar.scss';

const AgendaSidebar = observer(() => {
  const {agendaStore, uiStore} = React.useContext(AppContext);
  const {agendaLive, agendaSidebarShow} = agendaStore.data;
  const {isMenuCollapsed} = uiStore;

  const handleClose = async () => {
    agendaStore.data.showAgenda(false);
  };

  return (
    <div className={classNames('nav-menu__item nav-menu__item--agenda', {active: agendaSidebarShow})}>
      <div
        className="nav-menu__button"
        role="button"
        aria-hidden="true"
        onClick={() => agendaStore.data.showAgenda(true)}
      >
        <div className="nav-menu__item-icon">
          <AgendaImg />
        </div>
        <p className="nav-menu__item-text">
          <FormattedMessage id="agenda.sidebar.menuTitle" />
        </p>
        {agendaLive.length > 0 ? <div className="ui green label mini circular" /> : null}
        <Icon name="angle right" />
      </div>
      <Drawer show={agendaSidebarShow} close={handleClose} classes="agenda-drawer" leftOffset={isMenuCollapsed}>
        <AgendaContent />
      </Drawer>
    </div>
  );
});

export default injectIntl(AgendaSidebar);
