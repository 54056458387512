import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import UtilsService from '../../services/core/utils-service';
import './PreCallTestButton.scss';

const PreCallTestButton = observer(() => {
  const openConference = () => {
    window.open(UtilsService.createConferenceUrl(false, false, false, false, false, true), 'drconference');
    return false;
  };

  return (
    <Button className="pre-call-button" color="teal" onClick={openConference}>
      <FormattedMessage id="pre.call.test.button" />
    </Button>
  );
});

export default injectIntl(PreCallTestButton);
