import {action, computed, extendObservable, runInAction, when} from 'mobx';
import firebase from 'firebase/app';
import filter from 'lodash/filter';
import size from 'lodash/size';
import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';
import uniqBy from 'lodash/uniqBy';

const LIMIT_LOAD = 30;

export class ActivityStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    extendObservable(this, {
      notifications: [],
      notificationsUser: [],
      notificationsEvents: [],
      notificationsSystem: [],
      notificationsAllCount: 0,
      lastNotificationDate: null,
      offset: 0,
      isLoading: false,
      tags: {},
    });

    when(() => {
      if (this.rootStore.eventStore.eventList && !this.rootStore.userStore.isLoading) {
        this.getAllCountNotifications();
      }
    });
  }

  @computed
  get countNotificationUnSeed() {
    if (this.isLoading) {
      return 0;
    }
    if (this.lastNotificationDate) {
      return this.notifications.findIndex(i => i.dt === this.lastNotificationDate);
    }
    return 0;
  }

  @action
  getUserActivity(userId) {
    const ref = firebase.database().ref(`/newActivity/users/${userId}/sessions`);

    return new Promise((resolve, reject) => {
      ref.limitToFirst(LIMIT_LOAD).once(
        'value',
        snapshot => {
          const notificationsUser = snapshot.val();
          if (notificationsUser) {
            const sessionsByUser = [];
            Object.keys(notificationsUser).map(not => {
              const n = notificationsUser[not];
              if (n.type) {
                return sessionsByUser.push(this.rootStore.sessionsNewStore.data.getPublicSession(n.eId, n.sesId));
              }
              return null;
            });

            return Promise.all([sessionsByUser]).then(res => {
              const sessionsUser = res[0];
              const nUser = [];
              for (const n in notificationsUser) {
                const notificationUserWithDetails = this.processUserNotification(notificationsUser[n], sessionsUser);
                nUser.push(notificationUserWithDetails);
              }

              runInAction(() => {
                this.notificationsUser = nUser;
              });
              return resolve(nUser);
            });
          }
          return resolve([]);
        },
        errorObject => reject(errorObject),
      );

      ref
        .orderByChild('dt')
        .startAt(Date.now())
        .on('child_added', snapshot => {
          const newNotification = snapshot.val();
          const sessionsUser = this.rootStore.sessionsNewStore.data.getPublicSession(
            newNotification.eId,
            newNotification.sesId,
          );
          const notificationsUserWithDetails = this.processUserNotification(newNotification, sessionsUser);

          runInAction(() => {
            this.notifications.unshift(notificationsUserWithDetails);
            this.notificationsAllCount += 1;
          });
        });
    });
  }

  @action
  async getEventsNotifications(next) {
    const {activeEvents} = this.rootStore.eventStore;
    let eventNotifications = [];

    for (const item of activeEvents) {
      // eslint-disable-next-line no-await-in-loop
      const eventsA = !next ? await this.getEventsActivity(item.id) : await this.getNextEventsActivity(item.id);
      if (eventNotifications.length <= 0 && eventsA) {
        eventNotifications = eventsA;
      } else if (eventsA) {
        eventNotifications = eventNotifications.concat(eventsA);
      }
    }
    return differenceWith(eventNotifications, this.notifications, isEqual);
  }

  @action
  loadNotifications() {
    this.isLoading = true;
    const {eventList} = this.rootStore.eventStore;
    const {user} = this.rootStore.userStore;

    if (eventList.length === 0) {
      this.isLoading = false;
      return Promise.resolve();
    }

    return Promise.all([this.getEventsNotifications(), this.getTagsActivity(user.id)])
      .then(results => {
        const notificationsByEvents = filter(results[0], size);

        runInAction(() => {
          this.notifications = notificationsByEvents.sort((a, b) => b.dt - a.dt).slice(0, LIMIT_LOAD);
          this.isLoading = false;
        });
        return this.getUserNotificationMeta(user.id).then(() => {
          return results;
        });
      })
      .catch(e => {
        console.log('error', e);
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  getTagsActivity(userId) {
    const ref = firebase.database().ref(`/newActivity/users/${userId}/tags`);
    return new Promise((resolve, reject) => {
      ref.on(
        'value',
        snapshot => {
          if (snapshot.val()) {
            const tags = snapshot.val();
            runInAction(() => {
              this.tags = tags;
            });
            return resolve(tags);
          }
          return resolve();
        },
        () => reject(),
      );
    });
  }

  @action
  getAllCountNotifications() {
    const {activeEvents} = this.rootStore.eventStore;
    // const userId = this.rootStore.userStore.user.id;
    // const refUsers = firebase.database().ref(`/newActivity/users/${userId}/sessions`);

    activeEvents.forEach(event => {
      const refEvents = firebase.database().ref(`/newActivity/events/${event.id}`);
      refEvents.once(
        'value',
        snapshot => {
          const notificationsAllEvents = snapshot.val();
          if (notificationsAllEvents) {
            const countNotifications = Object.keys(notificationsAllEvents).length;
            runInAction(() => {
              this.notificationsAllCount += countNotifications;
            });
          }
        },
        errorObject => {
          console.log('errorObject', errorObject);
        },
      );
    });

    /*
    refUsers.once('value', snapshot => {
      const notificationsAllEvents = snapshot.val();
      if (notificationsAllEvents) {
        const countNotifications = Object.keys(notificationsAllEvents).length;
        runInAction(() => {
          this.notificationsAllCount += countNotifications;
        });
      }
    });
    */
  }

  @computed
  get countNotificationUnRead() {
    let countUnRead = 0;

    if (this.notifications) {
      this.notifications.forEach(n => {
        if (!this.tags[n.id]) {
          countUnRead += 1;
        }
      });
      return countUnRead;
    }
    return countUnRead;
  }

  @action
  getEventsActivity(eventId) {
    const ref = firebase.database().ref(`/newActivity/events/${eventId}`);
    if (this.notificationsEvents.length > LIMIT_LOAD) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      ref.once('value').then(
        snapshot => {
          const notificationsAllEvents = snapshot.val();
          if (notificationsAllEvents) {
            const notificationsEventArr = [];
            Object.keys(notificationsAllEvents).forEach(nEvent => {
              const notificationEvent = this.processEventsNotification(notificationsAllEvents[nEvent], nEvent);
              notificationsEventArr.push(notificationEvent);

              runInAction(() => {
                this.notificationsEvents.push(notificationEvent);
              });
            });
            return resolve(notificationsEventArr);
          }
          return resolve([]);
        },
        errorObject => {
          console.log('e', errorObject);
          return reject();
        },
      );

      ref
        .orderByChild('dt')
        .startAt(Date.now())
        .on('child_added', snapshot => {
          const newNotification = this.processEventsNotification(snapshot.val());

          runInAction(() => {
            newNotification.id = snapshot.key;
            this.notifications.unshift(newNotification);
            this.notificationsAllCount += 1;
          });
        });

      ref.on('child_removed', snapshot => {
        const removedNotification = this.processEventsNotification(snapshot.val());

        runInAction(() => {
          removedNotification.id = snapshot.key;
          this.notifications = this.notifications.filter(item => item.id !== snapshot.key);
          this.notificationsAllCount -= 1;
          if (removedNotification.dt >= this.lastNotificationDate) {
            this.lastNotificationDate = this.notifications.find(item => item.dt <= this.lastNotificationDate)?.dt;
          }
        });
      });
    });
  }

  @computed
  get hasMore() {
    return this.notificationsAllCount > this.notifications.length;
  }

  @computed
  get getNextUser() {
    let count = 0;
    this.notifications.forEach(n => {
      if (n.sesId) {
        count += 1;
      }
    });
    return this.notificationsUser[count];
  }

  @computed
  get getNextEvent() {
    const difLength =
      this.notificationsEvents.length - differenceWith(this.notifications, this.notificationsEvents, isEqual).length;
    return this.notificationsEvents[difLength]?.event.id;
  }

  @action
  getOffset(num) {
    this.offset = num;
  }

  @action
  loadNextNotifications() {
    const {eventList} = this.rootStore.eventStore;

    if (eventList.length === 0) {
      this.isLoading = false;
      return Promise.resolve();
    }

    return Promise.all([
      this.getEventsNotifications(true),
      this.getNextUserActivity(this.rootStore.userStore.user.id),
    ]).then(results => {
      const eventsN = results[0];
      const userN = results[1];
      const allNotification = eventsN.concat(userN).slice(0, LIMIT_LOAD);

      if (allNotification.length <= 0) {
        return allNotification;
      }

      eventList.forEach((e, i) => {
        if (e.id === this.getNextEvent) {
          this.getOffset(i);
        }
      });

      runInAction(() => {
        const diff = differenceWith(allNotification, this.notifications, isEqual);
        this.notifications = this.notifications.concat(diff).sort((a, b) => b.dt - a.dt);
      });

      return results;
    });
  }

  @action
  getNextUserActivity() {
    const userId = this.rootStore.userStore.user.id;
    const ref = firebase.database().ref(`/newActivity/users/${userId}/sessions`);

    if (!this.getNextUser) {
      return Promise.resolve([]);
    }
    return new Promise((resolve, reject) => {
      ref
        .orderByKey()
        .startAt(this.getNextUser.id)
        .limitToFirst(LIMIT_LOAD)
        .once(
          'value',
          snapshot => {
            const notificationsUser = snapshot.val();
            if (notificationsUser) {
              const sessionsObservables = [];

              Object.keys(notificationsUser).map(not => {
                const n = notificationsUser[not];

                if (n.type) {
                  return sessionsObservables.push(
                    this.rootStore.sessionsNewStore.data.getPublicSession(n.eId, n.sesId),
                  );
                }
                return null;
              });

              return Promise.all([sessionsObservables]).then(res => {
                const sessionsUser = res[0];
                const nUser = [];
                for (const n in notificationsUser) {
                  const notificationsUserWithDetails = this.processUserNotification(notificationsUser[n], sessionsUser);
                  nUser.push(notificationsUserWithDetails);
                }

                runInAction(() => {
                  this.notificationsUser = uniqBy(this.notificationsUser.concat(nUser), 'id');
                });

                return resolve(differenceWith(this.notificationsUser, this.notifications, isEqual));
              });
            }
            return resolve([]);
          },
          () => reject(),
        );
    });
  }

  @action
  getNextEventsActivity(eventId) {
    const ref = firebase.database().ref(`/newActivity/events/${eventId}`);

    return new Promise((resolve, reject) => {
      ref.once(
        'value',
        snapshot => {
          const notificationsAllEvents = snapshot.val();

          if (notificationsAllEvents) {
            const notificationsEventArr = [];
            Object.keys(notificationsAllEvents).forEach(nEvent => {
              const notificationsEvent = this.processEventsNotification(notificationsAllEvents[nEvent]);
              notificationsEventArr.push(notificationsEvent);
            });

            runInAction(() => {
              this.notificationsEvents = uniqBy(this.notificationsEvents.concat(notificationsEventArr), 'id');
            });
            return resolve(notificationsEventArr);
          }
          return resolve([]);
        },
        () => reject(),
      );
    });
  }

  @action
  getUserNotificationMeta(userId) {
    const ref = firebase.database().ref(`/newActivity/users/${userId}/meta`);

    return ref.once('value').then(
      snapshot => {
        const meta = snapshot.val();
        if (meta) {
          const lastNotificationDate = this.notifications.find(item => item.dt <= meta.lastNotificationDate)?.dt;
          runInAction(() => {
            this.lastNotificationDate = lastNotificationDate;
          });
        }
        return meta;
      },
      errorObject => errorObject,
    );
  }

  @action
  setLastNotificationDate(userId, notification) {
    firebase
      .database()
      .ref(`/newActivity/users/${userId}/meta`)
      .child('lastNotificationDate')
      .set(notification.dt)
      .then(() => {
        const countReadMessage = this.notifications.findIndex(i => i.dt === notification.dt);
        runInAction(() => {
          this.lastNotificationDate = this.notifications[countReadMessage].dt;
        });
      })
      .catch(err => {
        console.log('err', err);
      });
  }

  @action
  clean() {
    this.notificationsUser = [];
    this.notificationsEvents = [];
    this.notificationsSystem = [];
    this.tags = {};
  }

  // eslint-disable-next-line class-methods-use-this
  markRead(userId, notificationId) {
    return firebase.database().ref(`/newActivity/users/${userId}/tags/${notificationId}/seen`).set(true);
  }

  markAllRead(userId) {
    this.notifications.forEach(notification => {
      firebase.database().ref(`/newActivity/users/${userId}/tags/${notification.id}/seen`).set(true);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  typeNotification(type) {
    switch (type) {
      case 'confirmed':
      case 'session_cancelled':
      case 'rescheduled_session':
      case 'invited_to_event':
        return 'user';
      case 'announcement':
      case 'poll':
      case 'poll_finished':
        return 'event';
      default:
        return null;
    }
  }

  processUserNotification(notificationUser, sessionsUser) {
    notificationUser.typeNotification = this.typeNotification(notificationUser.type);
    // eslint-disable-next-line prefer-destructuring
    notificationUser.session = sessionsUser.filter(s => s && s.id === notificationUser.sesId)[0];
    // eslint-disable-next-line prefer-destructuring
    notificationUser.event = this.rootStore.eventStore.eventList.filter(e => e.id === notificationUser.eId)[0];
    return notificationUser;
  }

  processEventsNotification(notificationEvent, id) {
    notificationEvent.id = id;
    notificationEvent.typeNotification = this.typeNotification(notificationEvent.type);
    // eslint-disable-next-line prefer-destructuring
    notificationEvent.event = this.rootStore.eventStore.eventList.filter(
      e => e.id === notificationEvent.det.eventId,
    )[0];
    return notificationEvent;
  }
}
