import React from 'react';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import ReactMarkdown from 'react-markdown';
import ImageLazy from '../../../ui/ImageLazy';
import EmbeddedIFrame from '../../../ui/EmbeddedIFrame';
import bannerImg from '../../../assets/images/defaultImages/banner.jpg';

const EventDescriptionPreLogin = observer(props => {
  const {event} = props;

  return event ? (
    event.pages?.login ? (
      <EmbeddedIFrame src={event.pages.login} />
    ) : (
      <div className="event-description">
        <div className="event-info">
          <div className="event-banner">
            <ImageLazy src={event.images.side_banner} errorSrc={bannerImg} alt="banner" />
          </div>
          <div className="event-desc">
            {event.description ? <ReactMarkdown escapeHtml={false}>{event.description}</ReactMarkdown> : 'No info'}
          </div>
        </div>
      </div>
    )
  ) : null;
});

export default injectIntl(EventDescriptionPreLogin);
