import firebase from 'firebase/app';
import 'firebase/database';

export const subscribeAnnouncementList = (eventId, cb) => {
  firebase
    .database()
    .ref(`/newEvents/${eventId}/posts/public/posts/${eventId}`)
    .on('value', snapshot => {
      cb(snapshot);
    });
};

export const subscribeCommentList = (eventId, postId, cb) => {
  firebase
    .database()
    .ref(`/newEvents/${eventId}/posts/public/comments/${eventId}/${postId}`)
    .on('value', snapshot => {
      cb(snapshot);
    });
};
