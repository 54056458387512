import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button} from 'semantic-ui-react';
import {useDropzone} from 'react-dropzone';
import Cropper from 'react-cropper';
import isEmpty from 'lodash/isEmpty';
import {AppContext} from '../../../store';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import './ImageUpload.scss';

const imageSize = {
  banner: {w: 1260, h: 250},
  logo: {w: 700, h: 700},
};

const ImageUpload = observer(props => {
  const {type, maxWidth, maxHeight} = props;
  const {modalStore, exhibitorStore} = React.useContext(AppContext);
  const [photo, setPhoto] = React.useState([]);
  const [cropper, setCropper] = React.useState(null);

  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: uploadFile => {
      setPhoto(
        uploadFile.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const savePhoto = () => {
    if (typeof cropper !== 'undefined') {
      let cropperOptions = {};
      if (maxWidth) {
        cropperOptions.maxWidth = maxWidth;
      }
      if (maxHeight) {
        cropperOptions.maxHeight = maxHeight;
      }
      exhibitorStore.view.setBanner(type, cropper.getCroppedCanvas(cropperOptions).toDataURL());
      modalStore.close('imageUpload');
    }
  };

  const preview = () => {
    if (photo.length > 0) {
      const f = photo[0];

      return (
        <Cropper
          className="photo-upload__cropper"
          src={f.preview}
          preview=".img-preview"
          viewMode={1}
          guides
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          initialAspectRatio={1}
          aspectRatio={imageSize[type].w / imageSize[type].h}
          responsive
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={instance => {
            setCropper(instance);
          }}
        />
      );
    }
    return null;
  };

  const photoName = !isEmpty(acceptedFiles) ? (
    acceptedFiles.map(p => (
      <p key={p.path}>
        {p.path} - {p.size} bytes
      </p>
    ))
  ) : (
    <FormattedMessage id="image.upload.form.accepted.img" />
  );

  return (
    <div className="image-upload">
      <div className="image-upload__upload">
        <Button
          onClick={open}
          labelPosition="left"
          icon="file"
          content={<FormattedMessage id="image.upload.form.btn.upload" />}
        />
        {photoName}
      </div>
      <section>
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
        </div>
        {preview()}
      </section>

      {photo.length > 0 ? (
        <div className="user-avatar-form__actions">
          <Button
            secondary
            type="submit"
            className="user-avatar-form__cancel"
            onClick={() => modalStore.close('imageUpload')}
          >
            <FormattedMessage id="image.upload.form.btn.cancel" />
          </Button>
          <Button primary onClick={() => savePhoto()} type="submit" className="user-avatar-form__save">
            <FormattedMessage id="image.upload.form.btn.save" />
          </Button>
        </div>
      ) : null}
    </div>
  );
});

export default injectIntl(ImageUpload);
