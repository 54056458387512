import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage} from 'react-intl';
import AgendaHeaderCard from '../AgendaHeaderCard';
import AgendaCardMeeting from '../AgendaCardMeeting';
import AgendaCardSession from '../AgendaCardSession';
import AgendaCardTranslatorRole from '../AgendaCardTranslatorRole';
import AgendaCardSpeakerRole from '../AgendaCardSpeakerRole';
import AgendaCardAttendeeRole from '../AgendaCardAttendeeRole';
import AgendaCardModeratorRole from '../AgendaCardModeratorRole';
import AgendaCardOffTime from '../AgendaCardOffTime';
import './AgendaList.scss';

const AgendaList = observer(props => {
  const list = props.list.slice();
  const attendee = props.attendee;
  const preloadLazyImages = props.preloadLazyImages;

  return (
    <div className="agenda-list">
      {list.length > 0 ? (
        list.map(session => {
          if (session.type === 'dealroom' && session.participants) {
            return (
              <AgendaCardMeeting
                meeting={session}
                key={`${session.id}-${session.eid}`}
                page="agenda"
                preloadLazyImages={preloadLazyImages}
              />
            );
          }
          if (session.type === '_participant_off_' && (!session.offType || session.offType === 'break')) {
            return (
              <AgendaHeaderCard
                session={session}
                key={`${session.id}-${session.eid}`}
                preloadLazyImages={preloadLazyImages}
              >
                <AgendaCardOffTime attendee={attendee} session={session} />
              </AgendaHeaderCard>
            );
          }
          if (
            session.isOffTime &&
            session.offType !== 'speaker' &&
            session.offType !== 'translator' &&
            session.offType !== 'moderator' &&
            (session.rsesId != null || session.offType === 'sessionPlaceholder')
          ) {
            return (
              <AgendaHeaderCard
                session={session}
                key={`${session.id}-${session.eid}`}
                preloadLazyImages={preloadLazyImages}
              >
                <AgendaCardSession attendee={attendee} session={session} />
              </AgendaHeaderCard>
            );
          }
          if (session.offType === 'translator') {
            return (
              <AgendaHeaderCard
                session={session}
                key={`${session.id}-${session.eid}`}
                preloadLazyImages={preloadLazyImages}
              >
                <AgendaCardTranslatorRole attendee={attendee} session={session} />
              </AgendaHeaderCard>
            );
          }
          if (session.offType === 'speaker') {
            return (
              <AgendaHeaderCard
                session={session}
                key={`${session.id}-${session.eid}`}
                preloadLazyImages={preloadLazyImages}
              >
                <AgendaCardSpeakerRole attendee={attendee} session={session} />
              </AgendaHeaderCard>
            );
          }
          if (session.offType === 'moderator') {
            return (
              <AgendaHeaderCard
                session={session}
                key={`${session.id}-${session.eid}`}
                preloadLazyImages={preloadLazyImages}
              >
                <AgendaCardModeratorRole attendee={attendee} session={session} />
              </AgendaHeaderCard>
            );
          }
          return (
            <AgendaHeaderCard
              session={session}
              key={`${session.id}-${session.eid}`}
              preloadLazyImages={preloadLazyImages}
            >
              <AgendaCardAttendeeRole attendee={attendee} session={session} />
            </AgendaHeaderCard>
          );
        })
      ) : (
        <p>
          <FormattedMessage id="global.notFound" />
        </p>
      )}
    </div>
  );
});

AgendaList.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array]),
};

export default AgendaList;
