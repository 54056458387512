import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import MeetingFormCancel from '../MeetingFormCancel';
import MeetingFormRescheduleSent from '../MeetingFormRescheduleSent';
import MeetingDetailsView from './MeetingDetailsView';
import './MeetingDetails.scss';

const MeetingDetails = observer(props => {
  const {meeting, actionType} = props;
  const [step, setStep] = React.useState(actionType ? 2 : 1);
  const [content, setContent] = React.useState('');

  React.useEffect(() => {
    return () => {
      setStep(1);
    };
  }, []);

  const contentStep1 = () => {
    return (
      <MeetingDetailsView
        {...props}
        onClick={action => {
          setContent(action);
          setStep(2);
        }}
      />
    );
  };

  const contentStep2 = () => {
    if (content === 'requestReschedule' || actionType === 'requestReschedule') {
      return <MeetingFormRescheduleSent meeting={meeting} onBack={() => setStep(1)} isDirectOpen={actionType} />;
    }
    if (content === 'cancelMeeting' || actionType === 'cancelMeeting') {
      return <MeetingFormCancel meeting={meeting} onBack={() => setStep(1)} isDirectOpen={actionType} />;
    }
    return null;
  };

  return (
    <div className="meeting-details">
      {step === 1 ? contentStep1() : null}
      {step === 2 ? contentStep2() : null}
    </div>
  );
});

MeetingDetails.propTypes = {
  meeting: PropTypes.oneOfType([PropTypes.object]).isRequired,
  actionType: PropTypes.string,
  onClick: PropTypes.func,
  state: PropTypes.string,
};

MeetingDetails.defaultProps = {
  onClick: () => {},
};

export default injectIntl(MeetingDetails);
