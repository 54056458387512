import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Button, Dropdown, Form} from 'semantic-ui-react';
import {useForm} from 'react-hook-form';
import Modal from '../../../ui/Modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {AppContext} from '../../../store';
import AttendeePreview from '../../attendee/AttendeePreview/AttendeePreview';
import InvitationApi from '../../../services/api/invitationApi';
import ButtonLoading from '../../../ui/ButtonLoading';
import MeetingTimeSlots from '../../meeting/MeetingTimeSlots';
import {redirectBack} from '../../../services/helpers/redirectHelper';
import useMedia from '../../../hooks/useMedia';
import './PreBookedMeeting.scss';
import PersonItem from "../../../ui/PersonItem";
import debounce from "lodash/debounce";
import MeetingApi from "../../../services/api/meetingApi";
import {ParticipantStore} from "../../../store/domainStore/participantStore";

const ReserveATable = observer(props => {
  const {eventStore, agendaStore, userStore, participantStore, modalStore} = React.useContext(AppContext);
  const {open, attendee} = modalStore.modals.reserveATableModal;
  const intl = useIntl();
  const {user} = userStore;
  const {selectedSessionId, availableSessions, availableSessionDayIndex} = participantStore;
  const {register, errors, getValues} = useForm();
  const isMobile = useMedia('(max-width: 768px)');
  useEffect(()=>{
    if(attendee) {
      participantStore.availableSessionsForParticipants(attendee,
        ParticipantStore.PARTICIPANT_TABLE_RESERVER(attendee?.eventId)).then();
    }
  }, [attendee?.id])

  function onSubmit() {
    return MeetingApi.preBookMeeting(
      user.id,
      attendee.eventId,
      selectedSessionId,
      attendee.id,
      ParticipantStore.PARTICIPANT_TABLE_RESERVER(attendee?.eventId).id,
      ""
    ).then(() => {
      if (isMobile) {
        redirectBack(location);
      } else {
        closeModal()
      }
    }, err=>{
      console.log(err)
    });
  }
  const  closeModal = ()=>{
    modalStore.close('reserveATableModal')
  }
  return (
    <Modal
      className="pre-booked-meeting-modal"
      open={open}
      onClose={() => closeModal()}
    >
      <h3>
        <FormattedMessage id="reserve.modal.header" />
      </h3>


      <div className="pre-booked-meeting">

            <>
              <MeetingTimeSlots attendee={ParticipantStore.PARTICIPANT_TABLE_RESERVER(attendee?.eventId)} />
            </>
        <div className="pre-booked-meeting__action">
          <Button color="red" basic className="btn-link__back" onClick={()=>{
            closeModal()
          }
          }>
            <FormattedMessage id="invite.form.respond.btn.back" />
          </Button>
          <ButtonLoading color="blue" disabled={selectedSessionId == null} onClick={() => onSubmit()}>
            <FormattedMessage id="reserve.form.btn.book" />
          </ButtonLoading>
        </div>
      </div>
    </Modal>
  );
});

ReserveATable.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
  invitation: PropTypes.oneOfType([PropTypes.object]),
};

export default ReserveATable;
