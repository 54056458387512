import UtilsService from '../core/utils-service';
import {stores} from '../../store';

export function filterAttendees(participants, params) {
  const {tags} = params;
  const {segment} = params;
  const ignore = segment !== 'ignored';
  if (participants && tags) {
    if (segment === 'all') {
      if (!ignore) return participants;
      const nonIgnoreds = [];
      participants.forEach(val => {
        if (tags[val.id] == null || tags[val.id].ignored !== true) {
          nonIgnoreds.push(val);
        }
      });

      return nonIgnoreds;
    }
    const nonIgnoreds = [];
    participants.forEach(val => {
      if (tags[val.id] != null && tags[val.id][segment] === true) {
        nonIgnoreds.push(val);
      }
    });

    return nonIgnoreds;
  }
  return participants;
}

export function processParticipant(details, showPrivateInfo) {
  let boothDetails = {};
  if (details == null) {
    return Promise.resolve({name: 'Deleted', surname: 'deleted'});
  }
  if (
    details.eventId == null ||
    (stores.eventStore.eventList && !stores.eventStore.eventList.some(e => e.id === details.eventId))
  ) {
    details.profilePhoto = UtilsService.fixPublicBucketUrl(details.profilePhoto);
    details.fullName = `${details.name} ${details.surname}`;
    details.website = details.website ? details.website.trim() : '';
    if (details.website.length > 0 && details.website.indexOf('http') !== 0) {
      details.website = `http://${details.website}`;
    }
    return Promise.resolve(details);
  }
  const eventDetails = stores.eventStore.getEventDetails(details.eventId, true);
  if (details.boothId) {
    boothDetails = stores.exhibitorStore.view.getExhibitorDetail(details.eventId, details.boothId);
  }
  return Promise.all([eventDetails, boothDetails])
    .then(results => {
      const eventDetails = results[0];
      // let states = results[1];
      if (details != null) {
        details.profilePhoto = UtilsService.fixPublicBucketUrl(details.profilePhoto);
        details.fullName = `${details.name} ${details.surname}`;
        // TODO need to fix on BE side
        if (eventDetails.me.userId === details.userId) {
          details.organizer = eventDetails.me.organizer;
          details.id = eventDetails.me.id;
        }
        if (details.typeId) {
          const typeObject = eventDetails.participantTypes[details.typeId];
          if (typeObject) {
            details.participantType = typeObject.texts[eventDetails.defaultLanguage];
            if (details.participantType == null) {
              // eslint-disable-next-line prefer-destructuring
              details.participantType = Object.values(typeObject.texts)[0];
            }
            details.participantTypeConf = typeObject;
          }
        }
        if (details.some != null) {
          const some = {};
          for (const key in details.some) {
            const tag = details.some[key];
            some[key] = UtilsService.getSomeLink(key, tag);
          }
          details.someLinks = some;
        }
        details.pstate = details.state;
        details.canInvite =
          eventDetails.me.organizer ||
          !eventDetails.restricted ||
          (eventDetails.restricted &&
            eventDetails.me.participantTypeConf != null &&
            eventDetails.me.participantTypeConf.invitationRights != null &&
            eventDetails.me.participantTypeConf.invitationRights[details.typeId]);
        details.website = UtilsService.getUrlHttpPref(details.website ? details.website.trim() : '');
        details.websiteDisplay = UtilsService.getDomain(details.website);
        // details.state = states[details.id];
        details.categories = details.categories == null ? [] : details.categories;
        const selectedPCategories = {};
        details.categories.forEach(cat => {
          selectedPCategories[cat.categoryId] = cat;
        });
        const allParticipantCategories = [];
        const participantType = eventDetails.participantTypes[details.typeId];
        eventDetails.categoriesArray.forEach(eventCategory => {
          const {categoryId} = eventCategory;
          if (showPrivateInfo || !eventCategory.isPrivate) {
            if (
              eventCategory.visibleToAll ||
              eventCategory.visibleParticipants[details.typeId] ||
              (participantType != null && participantType.categories[eventCategory.categoryId] != null)
            ) {
              let pcategory = {};
              if (selectedPCategories[categoryId]) {
                pcategory = selectedPCategories[categoryId];
              }
              if (eventCategory.matchingCategoryId != null) {
                // eslint-disable-next-line no-param-reassign
                eventCategory = eventDetails.categories[eventCategory.matchingCategoryId];
              }
              pcategory.category = eventCategory;
              allParticipantCategories.push(UtilsService.cloneData(pcategory));
            }
          }
        });
        details.categories = allParticipantCategories;
        if (Object.keys(results[1]).length > 0) {
          details.boothDetails = results[1];
        }
      }

      return details;
    })
    .catch(function (err) {
      console.log('err', err?.message);
      return Promise.reject(err);
    });
}
