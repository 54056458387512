import React from 'react';
import {AppContext} from '../store';
import {meetingStatus} from '../constants/meetingStatus';

const useUserInvitation = (attendee, isBooth = false, invitationId=null) => {
  const {invitationStore, participantStore, eventStore} = React.useContext(AppContext);
  const {invitations, totalMeetingsForEvent, typeCommandByUser} = invitationStore;
  const {meParticipant} = eventStore;

  if (!attendee) {
    return {};
  }

  let invitation = invitations
    ? invitations
        .slice()
        .sort((a, b) => b.cdt - a.cdt)
        .find(i => {
          if(invitationId != null){
            return i.id === invitationId;
          }
          else {
            i = i.lastInvitationDetails;
            const isSameAttendeeId = i.opponentParticipant?.id === attendee.id
              || i.myDetails?.id === attendee.id;
            const isSameAttendeeBoothId = i.opponentParticipant?.boothId
              === attendee.boothId;
            const isSameEvent = i?.eid === attendee?.eventId;

            if (isBooth) {
              return isSameEvent && (isSameAttendeeId || isSameAttendeeBoothId)
                && i.toBoothId;
            }
            return isSameEvent && isSameAttendeeId && !i.toBoothId;
          }
        })
    : null;

  const meeting = invitation && invitation.meeting;
  const isExpiredMeeting = meeting && meeting.expired && meeting.upcomingState === 'expired';
  const isActiveMeeting = meeting && meeting.started && !isExpiredMeeting;
  const {states} = participantStore;

  const boothState =
    (states &&
      invitation &&
      states.booth &&
      states.booth[invitation.toBoothId] &&
      (states.booth[invitation.toBoothId][invitation.myDetails.boothId] ||
        states.booth[invitation.toBoothId][invitation.opponentParticipant.id])) ||
    null;

  const attendeeState = (states && attendee && states[attendee.id]) || null;
  const state = isBooth && invitation?.toBoothId ? boothState : attendeeState;

  const meParticipantTypesConf = invitation
    ? invitation.lastInvitationDetails.me.participantTypes[invitation.lastInvitationDetails.me.typeId]
    : meParticipant && meParticipant.participantTypeConf;

  const statusInvite = () => {
    if (typeCommandByUser[attendee.id] === 'pending') {
      return meetingStatus.INVITE_DETAILS_LOADING;
    }
    if (state === 'request_meeting' && invitation && invitation?.lastInvitationDetails?.sent === true) {
      return meetingStatus.INVITE_PENDING;
    }
    if (meeting && state === 'meeting' && !isExpiredMeeting) {
      return meetingStatus.MEETING_DETAILS;
    }
    if (
      meParticipantTypesConf?.maxMeetingCount &&
      totalMeetingsForEvent[attendee?.eventId] >= meParticipantTypesConf?.maxMeetingCount
    ) {
      return meetingStatus.INVITE_LIMIT;
    }
    if (
      (invitation &&
        invitation.state === 'waiting' &&
        ((invitation.type !== 'reschedule') ||
          (isBooth && invitation.status === 'live' && invitation.segment === 'company'))) ||
      (invitation &&
        invitation.state === 'waiting' &&
        invitation.segment === 'company' &&
        invitation.type === 'join' &&
        invitation.opponentParticipant.id !== invitation.me.boothId &&
        invitation.opponentParticipant.boothId !== invitation.me.boothId)
    ) {
      return meetingStatus.INVITE_RECEIVED;
    }
    if (
      ((!state || state === 'none') && !meeting) ||
      (state && state !== 'none' && meeting && isExpiredMeeting) ||
      (state === 'rejected' && invitation && (invitation.sent !== true || invitation.toBoothId === attendee.id)) ||
      (!meeting &&
        invitation &&
        invitation.me.id !== invitation.opponentParticipant.id &&
        invitation.me.id !== invitation.myDetails.id)
    ) {
      return meetingStatus.INVITE_NEW;
    }
    if (meeting && state === 'reschedule' && invitation?.sent === true) {
      return meetingStatus.MEETING_RESCHEDULE_PENDING;
    }
    if (meeting && state === 'reschedule' && invitation?.sent === false) {
      return meetingStatus.MEETING_RESCHEDULE;
    }
    if (state === 'rejected' && invitation && (invitation.sent || invitation.segment === 'company')) {
      return meetingStatus.INVITE_REJECTED;
    }
    return meetingStatus.INVITE_NOT_FOUND;
  };

  let status = statusInvite();

  return {
    invitation,
    meeting,
    status,
    isActiveMeeting,
    state,
  };
};

export default useUserInvitation;
