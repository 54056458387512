import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import ImageLazy from '../../../ui/ImageLazy';
import bannerImg from '../../../assets/images/defaultImages/banner.jpg';
import './InvitationDateSection.scss';

const InvitationDateSection = observer(props => {
  const {invitation} = props;

  return (
    <div className="invitation-card__event">
      <div className="invitation-card__event-name">{invitation.event?.name}</div>
      <div className="invitation-card__event-logo">
        <ImageLazy src={invitation.event?.images.logo} errorSrc={bannerImg} alt={invitation.event?.name} />
      </div>
    </div>
  );
});

InvitationDateSection.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.object]),
};

export default injectIntl(InvitationDateSection);
