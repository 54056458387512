import React from 'react';
import {observer} from 'mobx-react-lite';
import {runInAction} from 'mobx';
import {Button, Form, Message} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {FormProvider, useForm} from 'react-hook-form';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import {AppContext} from '../../../store';
import UniversalField from '../../shared/UniversalField';
import {RegistrationFields, TicketRegistrationFieldsTicket} from './RegistrationFields';
import ReactMarkdown from 'react-markdown';
import validateEmail from '../../../utils/validateEmail';
import './RegistrationForm.scss';

const RegistrationForm = observer(() => {
  const {userStore, eventStore} = React.useContext(AppContext);
  const {isLoading} = userStore;
  const {domainDetails} = eventStore;
  const methods = useForm();
  const intl = useIntl();
  const isTicketIdRegistration = !!(domainDetails && domainDetails.id && domainDetails.useTicketIdForSignup);
  let fields = isTicketIdRegistration ? TicketRegistrationFieldsTicket : RegistrationFields;

  // TODO in the future need to do using refs
  methods.watch(['ticketRegistrationUsername']);
  const values = methods.getValues();

  if (values?.ticketRegistrationUsername?.length >= 4 && !validateEmail(values.ticketRegistrationUsername)) {
    addEmailFieldForTicketIdRegistration(fields);
  } else {
    fields = fields.filter(field => field.fieldName !== 'username');
  }

  React.useEffect(
    () => () => {
      runInAction(() => {
        userStore.error = '';
      });
    },
    [userStore],
  );

  const onSubmit = values => {
    if (isTicketIdRegistration) {
      values.eventId = domainDetails?.id;
      const ticketIdOrEmail = values.ticketRegistrationUsername;

      if (validateEmail(ticketIdOrEmail)) {
        values.username = ticketIdOrEmail.toLowerCase().trim();
        values.email = values.username;
      } else {
        values.ticketId = ticketIdOrEmail;
      }
      delete values.ticketRegistrationUsername;
    }

    if (!isTicketIdRegistration && values.email) {
      values.username = values.email.toLowerCase().trim();
    }

    userStore.signup('cognito', values);
  };

  const parseError = (instance, message) => {
    if (instance?.code === 'DEAL-00082')
      return <ReactMarkdown>{intl.formatMessage({id: 'registration.form.error'})}</ReactMarkdown>;

    return message.message || message;
  };

  return (
    <div className="registration-form">
      <div className="registration-form__header">
        <h2 className="registration-form__title">
          <FormattedMessage id="registration.form.title" />
        </h2>
        {domainDetails ? <p className="registration-form__desc">Please register to access the event</p> : null}
      </div>
      <FormProvider {...methods}>
        <Form
          className="registration-form__body"
          error={!isEmpty(methods.errors) || !!userStore.error}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {fields.map(field => (
            <UniversalField
              key={field.fieldName}
              label={field.fieldName}
              name={field.fieldName}
              type={field.type}
              required={field.required}
              dataList={field.dataList}
              placeholder={field.placeholder}
              error={methods.errors[field.fieldName]}
            />
          ))}
          <Button fluid disabled={isLoading} loading={isLoading}>
            <FormattedMessage id="registration.form.btn" />
          </Button>
          <Message error>{userStore.error ? parseError(userStore.errorInstance, userStore.error) : null}</Message>
        </Form>
      </FormProvider>
    </div>
  );
});

const addEmailFieldForTicketIdRegistration = fields => {
  if (!fields.find(field => field.fieldName === 'username')) {
    fields.splice(3, 0, {
      type: 'input',
      fieldName: 'username',
      placeholder: 'user@email.com',
      required: true,
    });
  }

  return fields;
};

export default injectIntl(RegistrationForm);
