import firebase from 'firebase/app';
import 'firebase/database';

export class CommandExecutor {
  // eslint-disable-next-line class-methods-use-this
  execute(userId, commandRequest) {
    return commandRequest.then(data => {
      if (data.commandId == null) {
        return data;
      }
      const {commandId} = data;
      return new Promise((resolve, reject) => {
        firebase
          .database()
          .ref(`commands/${userId}/${commandId}`)
          .on(
            'value',
            res => {
              const commandRes = res.val();
              if (commandRes != null) {
                if (commandRes.executed) {
                  if (commandRes.error) {
                    return reject(commandRes.error);
                  }
                  setTimeout(() => {
                    firebase.database().ref(`commands/${userId}/${commandId}`).remove();
                  });
                  return resolve({loading: false});
                }
                return reject(commandRes.error);
              }
              return null;
            },
            errorObject => reject(errorObject),
          );
      });
    });
  }
}
