import {v4 as uuidv4} from 'uuid';

export default function createVideo(url, width, height, autoPlay, invalidateCache = false) {
  // Returns an iframe of the video with the specified URL.
  const videoObj = parseVideo(url);
  let embedUrl =
    '//www.youtube.com/embed/' +
    videoObj.id +
    '?loop=1&feature=oembed&playlist=' +
    videoObj.id +
    (invalidateCache && '&noCache=' + uuidv4()) +
    (autoPlay ? '&autoplay=1&mute=1&enablejsapi=1' : '');
  if (videoObj.type === 'vimeo') {
    embedUrl = '//player.vimeo.com/video/' + videoObj.id + (invalidateCache && '?noCache=' + uuidv4());
  }
  return (
    "<iframe src='" +
    embedUrl +
    "' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen width='" +
    width +
    "' height='" +
    height +
    "'></iframe>"
  );
}

export function parseVideo(url) {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/,
  );

  let type;
  if (RegExp.$3.indexOf('youtu') > -1) {
    type = 'youtube';
  } else if (RegExp.$3.indexOf('vimeo') > -1) {
    type = 'vimeo';
  }

  return {
    type: type,
    id: RegExp.$6,
  };
}
