import React from 'react';
import {FormattedMessage} from 'react-intl';
import NotificationAlert from '../../components/notifications-alert/NotificationAlert';
import UtilsService from '../core/utils-service';
import TimeService from '../core/time-service';
import {stores} from '../../store';

export async function processMeeting(session, eventDetails, participantDetails, publicSession, isUpdate) {
  const participantIds = {};
  const sessionObservables = [];
  const participantsObservables = [];
  const sessionDefinitionObserversArr = [];
  const sessionDefinitionObservers = {};

  if (session.sdefId != null) {
    sessionDefinitionObservers[session.sdefId] = stores.sessionsNewStore.data.getPublicSession(
      eventDetails.id,
      session.sdefId,
      session.id,
      isUpdate,
    );
  }

  session.uiState = 'loading';
  session.eventId = eventDetails.id;
  session.timezone = eventDetails.timezone;
  session.onlineOnly = eventDetails.eventType === 'online';
  session.hasOnline = eventDetails.eventType === 'hybrid' || session.onlineOnly;
  session.calculateRemaining = () => {
    if (session.started) {
      session.remaining = session.expiresAt - TimeService.now();
    } else {
      session.remaining = session.startsAt - TimeService.now();
    }
    return session.remaining;
  };
  session.populateExpiration = () => {
    TimeService.sessionDatePopulate(eventDetails, participantDetails, session);
    session.calculateRemaining();
    session.upcomingState = checkUpcomingState(session, session.expired);
  };

  session.populateExpiration();

  if (session.prts == null) {
    session.prts = [];
  }
  session.isOffTime = session.type === '_participant_off_';
  if (session.isOffTime) {
    session.prts.push(participantDetails.id);
  }
  if (publicSession) {
    sessionObservables.push(publicSession);
  } else if (session.isOffTime && session.rsesId != null) {
    sessionObservables.push(
      stores.sessionsNewStore.data.getPublicSession(eventDetails.id, session.rsesId, session.id, isUpdate),
    );
  } else if (!session.isOffTime) {
    sessionObservables.push(
      stores.sessionsNewStore.data.getPublicSession(eventDetails.id, session.id, session.id, isUpdate),
    );
  }

  session.prts.forEach(pId => {
    participantIds[pId] = true;
  });

  for (const participantId in participantIds) {
    participantsObservables.push(stores.participantStore.getParticipantDetails(participantId));
  }
  for (const defId in sessionDefinitionObservers) {
    sessionDefinitionObserversArr.push(sessionDefinitionObservers[defId]);
  }

  return Promise.all([
    session,
    Promise.all(sessionObservables),
    Promise.all(participantsObservables),
    Promise.all(sessionDefinitionObserversArr),
  ]).then(results => {
    let mySession = results[0];
    mySession = mySession == null ? {} : mySession;
    const sessionDetailsMap = UtilsService.mergeAsObject(results[1], 'id');
    const sessionParticipants = UtilsService.mergeAsObject(results[2], 'id');
    const sessionDefinitionMap = UtilsService.mergeAsObject(results[3], 'id');

    const sessionDetails = mySession.isOffTime ? sessionDetailsMap[mySession.rsesId] : sessionDetailsMap[mySession.id];
    let sessionDefinition = null;
    if (sessionDetails != null) {
      mySession.location = sessionDetails.locs[mySession.locId];
      sessionDefinition = !mySession.rsesId
        ? sessionDefinitionMap[sessionDetails.sdid]
        : sessionDetailsMap[mySession.rsesId];
      mySession.name = sessionDetails.name;
    }
    if (mySession.isOffTime) {
      mySession.name = sessionDetails?.name;
    }
    mySession.onSite = sessionDefinition?.onSite;
    mySession.onlineOnly = eventDetails.eventType === 'online';
    mySession.hasOnline = eventDetails.eventType === 'hybrid' || mySession.onlineOnly;
    mySession.blocking = mySession.blocking || mySession.type === 'dealroom';
    mySession.sessionDefinition = sessionDefinition;
    mySession.me = participantDetails;
    mySession.me.participantTypes = eventDetails.participantTypes;

    TimeService.sessionDatePopulate(eventDetails, participantDetails, mySession);
    const participants = [];

    mySession.prts.forEach(pid => {
      participants.push(sessionParticipants[pid]);
    });
    mySession.participants = participants;
    if (mySession.participants[0]?.profilePhoto) {
      mySession.participants[0].profilePhoto = UtilsService.fixPublicBucketUrl(mySession.participants[0].profilePhoto);
    }
    if (mySession.type === 'dealroom' && mySession.participants) {
      mySession.isMeeting = true;
    } else {
      mySession.isSession = true;
    }

    return mySession;
  });
}

export function checkUpcomingState(session, allExpired) {
  if (!allExpired) {
    if (session.startsAt < TimeService.now()) {
      return 'started';
    }
    if (session.startsAt - TimeService.now() < 300000) {
      return 'starting';
    }
    return 'notstarted';
  }
  return 'expired';
}

export function openSession(session, rehearsal = false) {
  if (session.externalLink && session.externalLink.trim() !== '') {
    window.open(
      session.externalLink + (localStorage.getItem('appNative') ? '?choicely_open_in_browser=true' : ''),
      'drconferenceexternal',
    );
  } else {
    stores.eventStore.getEventDetails(session.eventId, true).then(data => {
      const popup = window.open(
        UtilsService.createConferenceUrl(data.me, session.sesArray[0].id, rehearsal),
        'drconference',
      );

      if (popup) {
        popup.onload = function () {
          if (popup.innerHeight > 0) {
            console.log('open');
          } else {
            NotificationAlert.info(<FormattedMessage id="global.blocked.byBrowser" />);
          }
        };
      } else {
        NotificationAlert.info(<FormattedMessage id="global.blocked.byBrowser" />);
      }
    });
  }
  return null;
}

export function startMeeting(session, isModerate) {
  if (session.expired) {
    return;
  }
  if (
    session.sessionDefinition != null &&
    session.sessionDefinition.externalLink &&
    session.sessionDefinition.externalLink.trim() !== ''
  ) {
    window.open(
      session.sessionDefinition.externalLink +
        (localStorage.getItem('appNative') ? '?choicely_open_in_browser=true' : ''),
      'drconferenceexternal',
    );
  } else {
    const sessionId = UtilsService.getSessionIdForConference(session);
    stores.eventStore.getEventDetails(session.eventId, true).then(data => {
      window.open(UtilsService.createConferenceUrl(data.me, sessionId, false, isModerate), 'drconferenceexternal');
    });
  }
}

export function startBroadcast(session, rehearsal = false, moderator = false) {
  if (session.externalLink && session.externalLink.trim() !== '') {
    window.open(
      session.externalLink + (localStorage.getItem('appNative') ? '?choicely_open_in_browser=true' : ''),
      'drconferenceexternal',
    );
  } else {
    window.open(
      UtilsService.createConferenceUrl(stores.eventStore.meParticipant, session.sesArray[0].id, rehearsal, moderator),
      'drconferenceexternal',
    );
  }
}

export function commonActiveMeeting(meetings, opponentParticipantId) {
  return meetings.find(m => {
    const isExpiredMeeting = m.expired && m.upcomingState === 'expired';
    const hasOpponentParticipant =
      m.participants?.length > 0 && m.participants.find(prt => prt.id === opponentParticipantId) != null;

    if (!isExpiredMeeting && hasOpponentParticipant) {
      return m;
    }
  });
}
