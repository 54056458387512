import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router-dom';
import {Dimmer, Loader} from 'semantic-ui-react';
import {AppContext} from '../../../../store';
import MobileInsideLayout from '../../../../layouts/MobileInsideLayout';
import SpeakerDetails from '../../../../components/speaker/SpeakerDetails';
import Title from '../../../shared/Title';
import './SpeakerModalPage.scss';

const SpeakerModalPage = observer(() => {
  const {eventId, speakerId} = useParams();
  const {speakerStore, sessionsNewStore, eventStore} = React.useContext(AppContext);
  const {speakers} = speakerStore;
  const {sessions} = sessionsNewStore.data;
  const speaker = speakers ? speakers.filter(s => s.id === speakerId)[0] : null;
  const sessionsAvailable =
    sessions && speaker ? sessions.filter(s => s.speakers && s.speakers.includes(speaker.id)) : [];

  const [sessionsState, setSessions] = useState([]);
  const [speakerState, setSpeaker] = useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (eventId && !speaker) {
      setLoading(true);
      speakerStore.getSpeakers(eventId).then(data => {
        const speakers = Object.values(data);
        const speakerFind = speakers ? speakers.find(s => s.id === speakerId) : null;
        if (speakerFind) {
          setSpeaker(speakerFind);
        }
      });
      eventStore.getEventDetails(eventId).then(event => {
        sessionsNewStore.data.getPublicSessions(eventId, event.organizer, true).then(data => {
          setLoading(false);
          setSessions(Object.values(data));
        });
      });
    }
  }, [eventId, eventStore, sessionsNewStore.data, speakerStore]);

  return loading || speaker ? (
    <MobileInsideLayout>
      <Title id="speaker-details" />
      <SpeakerDetails speaker={speaker || speakerState} sessionsAvailable={sessionsAvailable || sessionsState} />
    </MobileInsideLayout>
  ) : (
    <Dimmer active inverted>
      <Loader />
    </Dimmer>
  );
});

export default SpeakerModalPage;
