import React from 'react';
import {observer} from 'mobx-react-lite';
import {Card, Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import ImageLazy from '../../../ui/ImageLazy';
import {HostUrl} from '../../../services/core/axios-service';
import attendeeImg from '../../../assets/images/defaultImages/attendee.png';
import {contactFullName} from '../../../services/helpers/checkInHelper';
import checkInConfig from '../checkInConfig';
import './CheckInContact.scss';

const CheckInContact = observer(props => {
  const {contact, showPhoto, checkInStatus} = props;

  const checkInStatusBlock = checkInStatus ? (
    <div className="check-in-contact__status">
      {checkInStatus === checkInConfig.requestStatuses.checkedIn ||
      checkInStatus === checkInConfig.requestStatuses.checkedOut ? (
        <span className="check-in-contact__status--success">
          <FormattedMessage
            id={`checkIn.contact.${checkInStatus}.success`}
            values={{
              name: contactFullName(contact),
            }}
          />
        </span>
      ) : (
        <span className="check-in-contact__status--error">
          <FormattedMessage
            id={`checkIn.contact.${checkInStatus}.error`}
            values={{
              name: contactFullName(contact),
            }}
          />
        </span>
      )}
    </div>
  ) : null;

  return contact ? (
    <div className="check-in-contact">
      {showPhoto && (
        <div className="check-in-contact__img">
          {contact.profilePhotoPath ? (
            <ImageLazy
              src={
                contact.profilePhotoPath.includes(HostUrl)
                  ? `${contact.profilePhotoPath}`
                  : `${HostUrl}/${contact.profilePhotoPath}`
              }
              errorSrc={attendeeImg}
              alt={contact.firstName}
              width={200}
              height={200}
            />
          ) : (
            <img aria-hidden src={attendeeImg} alt={contact.name} width={100} height={100} />
          )}
        </div>
      )}
      <Card>
        <Card.Content>
          <Card.Header className="ui">
            <div>{contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.firstName}</div>
          </Card.Header>
          <div className="check-in-contact__ticket-id">{contact.ticketId}</div>
          <Card.Meta>
            {contact.jobTitle}
            {contact.jobTitle && contact.company ? ` | ${contact.company}` : contact.company}
          </Card.Meta>
          <Card.Description>
            {contact.phone && (
              <div>
                <Icon name="phone" /> {contact.phone}
              </div>
            )}
            {contact.email && (
              <div>
                <Icon name="mail" /> {contact.email}
              </div>
            )}
            {contact.website && (
              <div>
                <Icon name="globe" /> {contact.website}
              </div>
            )}
            {checkInStatusBlock}
          </Card.Description>
        </Card.Content>
      </Card>
    </div>
  ) : null;
});

export default CheckInContact;
