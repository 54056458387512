import {action, computed, extendObservable, runInAction, autorun} from 'mobx';
import firebase from 'firebase/app';
import 'firebase/database';
import some from 'lodash/some';
import uniqBy from 'lodash/uniqBy';
import differenceBy from 'lodash/differenceBy';
import {DateTime} from 'luxon';
import UtilsService from '../../../services/core/utils-service';
import TimeService from '../../../services/core/time-service';
import ServiceCacheService from '../../../services/core/cache-service';
import {processMeeting, checkUpcomingState} from '../../../services/helpers/meetingHelper';
import {showNotificationByAgenda} from '../../../services/helpers/agendaHelper';

export class AgendaListStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.meetingCache = ServiceCacheService.create();

    extendObservable(this, {
      meetings: [],
      mySessions: {},
      myBlockingSessions: [],
      loaded: false,
      loadedByEvent: {},
      closestMeeting: {
        session: null,
        remaining: '',
      },
      visibleSessions: true,
      visibleMeetings: true,
      stepLoadPastSessions: 5,
      loadingPastAgenda: false,
      agendaLive: [],
      agendaSidebarShow: false,
      participants: [],
    });

    autorun(() => {
      setInterval(() => {
        this.getClosestMeeting();
      }, 60000);
    });
  }

  @action
  loadAgendaForActiveEvents() {
    const events = this.rootStore.eventStore.activeEvents;
    const meetingsObservables = [];

    events.slice(0, 40).forEach(event => {
      meetingsObservables.push(
        this.rootStore.eventStore.getEventDetails(event.id).then(event => {
          return this.rootStore.participantStore
            .getStates(event.id, event.me.id)
            .then(() => this.getMyMeetings(event.id, event.me.id));
        }),
      );
    });

    if (events.length === 0) {
      this.loading = false;
      this.loaded = true;
      return Promise.resolve();
    }

    return Promise.all([events, Promise.all(meetingsObservables)])
      .then(results => {
        runInAction(() => {
          this.loaded = true;
        });
        return results;
      })
      .catch(() => {
        runInAction(() => {
          this.loaded = true;
        });
      });
  }

  @action
  loadAgendaForPatsEvents() {
    this.loadingPastAgenda = true;
    const events = this.rootStore.eventStore.pastEvents;
    const meetingsObservables = [];

    events.slice(this.stepLoadPastSessions - 5, this.stepLoadPastSessions).forEach(event => {
      meetingsObservables.push(
        this.rootStore.eventStore.getEventDetails(event.id).then(event => {
          return this.rootStore.participantStore
            .getStates(event.id, event.me.id)
            .then(() => this.getMyMeetings(event.id, event.me.id));
        }),
      );
    });

    if (events.length === 0) {
      this.loadingPastAgenda = false;
      return Promise.resolve();
    }

    return Promise.all([events, Promise.all(meetingsObservables)]).then(results => {
      runInAction(() => {
        this.stepLoadPastSessions += 5;
        this.loadingPastAgenda = false;
      });
      return results;
    });
  }

  @action
  updateMeetingsFromAgenda() {
    this.loaded = false;
    const eventsId = [...new Set(this.meetings.map(meeting => meeting.eid))];
    const meetingsObservables = [];

    if (eventsId.length === 0) {
      this.loaded = true;
      return Promise.resolve();
    }

    eventsId.forEach(id => {
      meetingsObservables.push(
        this.rootStore.eventStore.getEventDetails(id).then(event => {
          return this.rootStore.participantStore.getStates(event.id, event.me.id).then(() => {
            return this.getMyMeetings(event.id, event.me.id, true);
          });
        }),
      );
    });

    return Promise.all(meetingsObservables).then(results => {
      runInAction(() => {
        this.loaded = true;
      });
      return results;
    });
  }

  @action
  getMyMeetings(eventId, participantId, resetCache = false) {
    return this.meetingCache.getOrCreate(
      eventId,
      (key, subject) =>
        this.getParticipantPrivateSessions(eventId, participantId).then(data => {
          runInAction(() => subject.set(data));
          return data;
        }),
      resetCache,
    );
  }

  getPrivateMeeting(eventId, participantId, sessionId, publicSession) {
    return firebase
      .database()
      .ref(`/newEvents/${eventId}/participants/${participantId}/sessions/${sessionId}`)
      .once('value')
      .then(res =>
        this.rootStore.eventStore.getEventDetails(eventId).then(eventDetails => {
          const meeting = res.val();
          if (!meeting) {
            return Promise.resolve();
          }
          return processMeeting(meeting, eventDetails, eventDetails.me, publicSession).then(s => {
            return Promise.resolve(s);
          });
        }),
      );
  }

  subscribePrivateMeeting(eventId, participantId, sessionId, cb) {
    return firebase
      .database()
      .ref(`/newEvents/${eventId}/participants/${participantId}/sessions/${sessionId}`)
      .on('value', snapshot => {
        cb(snapshot);
      });
  }

  @action
  getParticipantPrivateSessions(eventId, participantId) {
    const ref = firebase.database().ref(`/newEvents/${eventId}/participants/${participantId}/sessions`);
    return new Promise((resolve, reject) => {
      ref.once('value').then(
        res =>
          this.rootStore.eventStore.getEventDetails(eventId).then(eventDetails => {
            const sessions = res.val();
            const sessionObservables = [];

            for (const sessionId in sessions) {
              const session = sessions[sessionId];

              sessionObservables.push(
                processMeeting(session, eventDetails, eventDetails.me).then(s => {
                  runInAction(() => {
                    if (s.offType === 'speaker' || s.offType === 'translator') {
                      this.mySessions[s.rsesId] = s;
                    } else if (s.sdefId) {
                      this.mySessions[s.sdefId] = s;
                    }
                    if (s.blocking) {
                      this.myBlockingSessions.push(s);
                    }
                  });
                  return s;
                }),
              );
            }

            return Promise.all([Promise.all(sessionObservables)]).then(results => {
              const mySessions = results[0];
              runInAction(() => {
                if (results[0]) {
                  if (this.meetings.length === 0) {
                    // eslint-disable-next-line prefer-destructuring
                    this.meetings = results[0];
                  } else {
                    this.meetings = uniqBy(results[0].concat(this.meetings), 'id');
                  }
                }
                this.loadedByEvent[eventId] = true;
              });

              this.getClosestMeeting();
              this.rootStore.sessionsNewStore.data.processSessionsForAgenda();
              return resolve(mySessions);
            });
          }),
        errorObject => reject(errorObject),
      );

      ref.on('child_added', newSession => {
        if (!some(this.meetings, ['id', newSession.val().id]) && this.loaded && this.loadedByEvent[eventId]) {
          this.rootStore.eventStore.getEventDetails(eventId).then(eventDetails => {
            if (!eventDetails.isFinished) {
              processMeeting(newSession.val(), eventDetails, eventDetails.me).then(newSes => {
                runInAction(() => {
                  this.meetings.push(newSes);
                  showNotificationByAgenda(newSes);

                  if (newSes.offType === 'speaker' || newSes.offType === 'translator') {
                    this.mySessions[newSes.rsesId] = newSes;
                  } else if (newSes.sdefId) {
                    this.mySessions[newSes.sdefId] = newSes;
                  }
                  if (newSes.blocking) {
                    this.myBlockingSessions.push(newSes);
                  }
                  this.getClosestMeeting();
                  this.rootStore.sessionsNewStore.data.processSessionsForAgenda();
                });
              });
            }
          });
        }
      });

      ref.on('child_removed', res => {
        runInAction(() => {
          const session = res.val();
          this.meetings = differenceBy(this.meetings, [res.val()], 'id');
          this.agendaLive = this.agendaLive.filter(agenda => agenda.id !== res.val().id);
          delete this.mySessions[session.sdefId || session.rsesId];
          if (session.blocking) {
            this.myBlockingSessions = differenceBy(this.myBlockingSessions, [session], 'id');
          }
        });
      });

      ref.on('child_changed', res => {
        this.rootStore.eventStore.getEventDetails(eventId).then(eventDetails => {
          processMeeting(res.val(), eventDetails, eventDetails.me, null, true).then(newSes => {
            runInAction(() => {
              this.meetings = differenceBy(this.meetings, [res.val()], 'id');
              this.meetings.push(newSes);

              if (newSes.offType === 'speaker' || newSes.offType === 'translator') {
                this.mySessions[newSes.rsesId] = newSes;
              } else if (newSes.sdefId) {
                this.mySessions[newSes.sdefId] = newSes;
              }
              if (newSes.blocking) {
                this.myBlockingSessions.push(newSes);
              }
            });
            this.getClosestMeeting();
          });
        });
      });
    });
  }


  @action
  getParticipantPrivateSessionsOnce(eventId, attendee) {
    attendee = JSON.parse(JSON.stringify(attendee));
    const participantId = attendee.id;
    const ref = firebase.database().ref(`/newEvents/${eventId}/participants/${participantId}/sessions`);
    return new Promise((resolve, reject) => {
      ref.once('value').then(
        res =>
          this.rootStore.eventStore.getEventDetails(eventId).then(eventDetails => {
            const sessions = res.val();
            const sessionObservables = [];

            for (const sessionId in sessions) {
              const session = sessions[sessionId];
              sessionObservables.push(
                processMeeting(session, eventDetails, attendee).then(s => {
                  return s;
                }),
              );
            }

            return Promise.all([Promise.all(sessionObservables)]).then(results => {
              const mySessions = results[0];
              return resolve(mySessions);
            });
          }),
        errorObject => reject(errorObject),
      );
    });
  }

  @action
  getClosestMeeting() {
    let tmpClosestMeeting = null;
    const {eventList} = this.rootStore.eventStore;

    this.meetings.forEach(meeting => {
      const eventDetails = eventList?.find(e => e.id === meeting.eventId);
      if (meeting.populateExpiration && eventDetails) {
        meeting.populateExpiration();
        const expiresAt = TimeService.toRealEventTime(eventDetails.timezone, meeting.edt);
        meeting.expired = expiresAt < TimeService.now();
        meeting.upcomingState = checkUpcomingState(meeting, meeting.expiresAt < TimeService.now());
        const diffTime = DateTime.fromMillis(meeting.startsAt)
          .diff(DateTime.fromMillis(TimeService.now()), 'minutes')
          .toFormat('m');
        if (!meeting.expired && diffTime <= 7) {
          meeting.buttonShow = true;
          this.agendaLive.push(meeting);
        } else if (this.agendaLive.length > 0) {
          this.agendaLive = this.agendaLive.filter(agenda => agenda.id !== meeting.id);
        }
        if (!meeting.expired && (!meeting.isOffTime || meeting.type === 'speaker')) {
          if (tmpClosestMeeting == null || tmpClosestMeeting.sdt > meeting.sdt) {
            tmpClosestMeeting = meeting;
          }
        }
      }
    });
    this.closestMeeting.session = tmpClosestMeeting;
    if (tmpClosestMeeting) {
      this.closestMeeting.remaining = UtilsService.countDownFormatter(this.closestMeeting.session.calculateRemaining());
    }
  }

  @computed
  get myAgendaList() {
    if (this.meetings.length > 0) {
      if (this.visibleSessions && this.visibleMeetings) {
        return this.meetings;
      }
      if (this.visibleSessions) {
        return this.meetings.filter(s => s.isSession);
      }
      if (this.visibleMeetings) {
        return this.meetings.filter(s => s.isMeeting);
      }
      return [];
    }

    return [];
  }

  @action
  setVisible(type, value) {
    if (type === 'sessions') {
      this.visibleSessions = value;
    } else {
      this.visibleMeetings = value;
    }
  }

  @action
  cleanAfterLogout() {
    this.meetings = [];
    this.agendaLive = [];
    this.stepLoadPastSessions = 5;
  }

  @action
  showAgenda(show) {
    this.agendaSidebarShow = show;
  }
}
