import React from 'react';
import {observer} from 'mobx-react-lite';
import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';
import Modal from '../../../../ui/Modal';
import {AppContext} from '../../../../store';
import ImageLazy from '../../../../ui/ImageLazy';
import bannerImg from '../../../../assets/images/defaultImages/banner.jpg';
import './EventInfoModal.scss';

const EventInfoModal = observer(() => {
  const {modalStore} = React.useContext(AppContext);
  const {open, event} = modalStore.modals.eventInfo;
  const imageUrl = event ? event.images.banner : null;

  const parseHtml = htmlParser({
    isValidNode: node => node.type !== 'script',
  });

  return (
    <Modal className="modal-event" open={open} onClose={() => modalStore.close('eventInfo')} size="small">
      {event ? (
        <>
          <div className="event-info-banner">
            <ImageLazy src={imageUrl} errorSrc={bannerImg} alt="banner" />
          </div>
          <div className="event-info__wrapper">
            <div className="event-info__top">
              <div className="event-info__title">
                <h3>{event.name}</h3>
              </div>
              <div className="event-info__date">
                <span className="event-info__date-time">{event.fullDateStrInEventTime}</span>
              </div>
            </div>
            <div className="event-info__desc">
              <ReactMarkdown allowDangerousHtml astPlugins={[parseHtml]}>
                {event.description}
              </ReactMarkdown>
            </div>
            <div className="event-organizer">
              {event.some ? (
                <div className="event-organizer__socials">
                  {Object.keys(event.some).map(social => {
                    if (event.some[social]) {
                      return (
                        <a
                          key={social}
                          rel="noopener noreferrer"
                          target="_blank"
                          href={event.some[social]}
                          className={`ui ${social} circular icon button`}
                        >
                          <i className={`${social} icon`} />
                        </a>
                      );
                    }
                    return null;
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </>
      ) : null}
    </Modal>
  );
});

export default EventInfoModal;
