/* eslint-disable */
// TODO move each function to utils folder

import cloneDeep from 'lodash/cloneDeep';
import {parse, generate} from 'vcard-parser';
import {HostUrl} from './axios-service';

class UtilsService {
  getUrlHttpPref(url) {
    url = this.urlProtocolHelper(url);
    if (!url) return null;
    if (url && !url.startsWith('http')) {
      url = `http://${url}`;
    }
    return url;
  }

  urlProtocolHelper(url) {
    if (url === null || url === undefined || !url.trim().length) return null;
    if (url.startsWith('http')) return url;
    url = url.charAt(0) === '/' ? url.substring(1) : url;
    return url;
  }

  getDomain(url) {
    if (!url) return null;
    try {
      let {hostname} = new URL(url);
      if (hostname.startsWith('www.')) {
        hostname = hostname.substring(4);
      }
      return hostname;
    } catch (e) {
      return url;
    }
  }

  fixPublicBucketUrl(url) {
    url = this.urlProtocolHelper(url);
    if (!url) return null;
    if (url.startsWith('http')) return url;
    url = url && url.charAt(0) === '/' ? url.substring(1) : url;
    return url ? `${HostUrl}/${url}` : null;
  }

  getSomeLink(someId, tag) {
    if (tag.indexOf('https:') >= 0 || tag.indexOf('http:') >= 0) {
      return tag;
    }
    if (
      tag.indexOf('linkedin.com') >= 0 ||
      tag.indexOf('twitter.com') >= 0 ||
      tag.indexOf('instagram.com') >= 0 ||
      tag.indexOf('facebook.com') >= 0
    ) {
      return `https://${tag}`;
    }
    if (someId === 'linkedin') {
      return `https://www.linkedin.com/company/${tag}`;
    }
    if (someId === 'facebook') {
      return `https://www.facebook.com/${tag}`;
    }
    if (someId === 'twitter') {
      return `https://twitter.com/${tag}`;
    }
    if (someId === 'instagram') {
      return `https://www.instagram.com/${tag}`;
    }

    return null;
  }

  cloneData(sessionData) {
    return cloneDeep(sessionData);
  }

  checkAllSame(pprofile, initialResult, discardedProps = {}) {
    if (this.checkNullOrTypeDisequality(pprofile, initialResult)) {
      return false;
    }
    for (let key in pprofile) {
      if (!discardedProps[key]) {
        let sourceVal = pprofile[key];
        let targetVal = initialResult[key];
        if (sourceVal != null || targetVal != null) {
          if (typeof sourceVal === 'object' || typeof targetVal === 'object') {
            if (!this.checkAllSame(sourceVal, targetVal)) {
              return false;
            }
          } else {
            if (sourceVal !== targetVal) {
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  checkNullOrTypeDisequality(sourceVal, targetVal) {
    if (sourceVal != null && targetVal == null) {
      return true;
    } else if (sourceVal == null && targetVal != null) {
      return true;
    } else if (typeof targetVal != 'object') {
      return true;
    }
    return false;
  }

  addLeadingZeros(value) {
    if (value < 10) {
      value = '0' + value;
    }
    return '' + value;
  }

  countDownFormatter(diff) {
    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
    };
    diff = diff / 1000;
    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = Math.floor(diff);
    let comps = [];
    if (timeLeft.days > 0) {
      comps.push(timeLeft.days === 1 ? '01 Days' : this.addLeadingZeros(timeLeft.days) + ' Days');
    }
    if (timeLeft.hours > 0) {
      comps.push(timeLeft.hours === 1 ? '01 Hours' : this.addLeadingZeros(timeLeft.hours) + ' Hours');
    }
    comps.push(timeLeft.min === 1 ? '01 Mins' : this.addLeadingZeros(timeLeft.min) + ' Mins');
    comps.push(timeLeft.sec === 1 ? '01 Secs' : this.addLeadingZeros(timeLeft.sec) + ' Secs');
    return comps.join(' : ');
  }

  mergeAsObject(result, id) {
    let res = {};
    if (result == null) return res;
    if (result instanceof Array) {
      for (let index in result) {
        let nestedObject = result[index];
        if (nestedObject != null) {
          if (nestedObject[id] == null) {
            let innerres = this.mergeAsObject(nestedObject, id);
            for (let innerIndex in innerres) {
              let innerObj = innerres[innerIndex];
              res[innerObj[id]] = innerObj;
            }
          } else {
            res[nestedObject[id]] = nestedObject;
          }
        }
      }
    } else {
      for (let index in result) {
        let nestedObject = result[index];
        res[nestedObject[id]] = nestedObject;
      }
    }

    return res;
  }

  mergeAsArray(result, id) {
    let res = [];
    let objects = this.mergeAsObject(result, id);
    for (let objectKey in objects) {
      res.push(objects[objectKey]);
    }
    return res;
  }

  getPoolId(eventId, participantDetails) {
    if (participantDetails.organizer) return eventId

    if (participantDetails.boothId) {
      return participantDetails.boothId
    }

    return participantDetails.id;
  }

  combineLatestFactory(locationObservables) {
    if (locationObservables == null || locationObservables.length == 0) {
      return [];
    } else {
      return locationObservables;
    }
  }

  asMap(sessionDefinitions, id) {
    let res = {};
    for (let index in sessionDefinitions) {
      let sessionDef = sessionDefinitions[index];
      res[sessionDef[id]] = sessionDef;
    }
    return res;
  }

  createConferenceUrl(meData, sessionId, rehearsal, moderator, popupMeeting = false, preCallTest) {
    if (preCallTest) {
      return (
        process.env.REACT_APP_CONFERENCE_HOST_URL +
        '/pre-device-settings' +
        (localStorage.getItem('appNative') ? '?choicely_open_in_browser=true' : '')
      );
    }

    return (
      process.env.REACT_APP_CONFERENCE_HOST_URL +
      (meData.conferenceToken ? '/meetings-login?token=' + meData.conferenceToken : '') +
      (sessionId ? '&sid=' + sessionId : '') +
      (rehearsal ? '&rehearsal=true' : '') +
      (moderator ? '&moderator=true' : '') +
      (popupMeeting ? '&popupMeeting=true' : '') +
      (localStorage.getItem('appNative') ? '&choicely_open_in_browser=true' : '')
    );
  }

  getSessionIdForConference(session) {
    let sessionId = session.id;
    if (session.type === '_participant_off_' && (session.offType === 'speaker' || session.offType === "moderator")) {
      sessionId = Object.keys(session.sessionDefinition.ses)[0];
    } else {
      if (session.sesArray != null) {
        sessionId = session.sesArray[0].id;
      } else {
        sessionId = session.id;
      }
    }
    return sessionId;
  }

  parseVCard(vCardRaw) {
    try {
      if (!vCardRaw || !vCardRaw.trim().length || (vCardRaw.indexOf('vcard') < 0 && vCardRaw.indexOf('VCARD') < 0)) {
        throw new Error('No VCard json data to be parsed ..!');
      }
      // let jCardJson = vCard.parse(vCardRaw);

      // let contact: IContact = {

      //   name: jCardJson['fn'][0]['value'],
      //   title: jCardJson['title'][0]['value'],
      //   email: jCardJson['email'][0]['value'],
      //   organization: jCardJson['org'][0]['value'],
      //   phone: jCardJson['tel'][0]['value'],
      //   url: jCardJson['url'][0]['value'],
      //   address: Array.of(jCardJson['adr'][0]['value']).join(' ')
      // };

      let jcardJson = parse(vCardRaw);
      let res = {};
      if (jcardJson.n != null) {
        if (jcardJson.n.length > 0) {
          if (typeof jcardJson.n[0].value === 'string') {
            res.firstName = jcardJson.n[0].value;
          } else if (jcardJson.n[0].value.length > 1) {
            res.firstName = jcardJson.n[0].value[1];
            res.lastName = jcardJson.n[0].value[0];
          } else if (jcardJson.n[0].value.length > 0) {
            res.firstName = jcardJson.n[0].value[0];
          }
        }
      }
      if (jcardJson.email != null && jcardJson.email.length > 0) {
        res.email = jcardJson.email[0].value;
      }
      if (jcardJson.title != null && jcardJson.title.length > 0) {
        res.jobTitle = jcardJson.title[0].value;
      }
      if (jcardJson.org != null && jcardJson.org.length > 0) {
        res.company = jcardJson.org[0].value;
      }
      if (jcardJson.tel != null && jcardJson.tel.length > 0) {
        let phoneData = jcardJson.tel.filter(tel => {
          return JSON.stringify(tel).indexOf('ticketid') < 0 && JSON.stringify(tel).indexOf('barcodeid') < 0;
        });

        if (phoneData.length > 0) {
          res.phone = phoneData[0].value;
        }

        let barcodeIdData = jcardJson.tel.filter(tel => {
          return JSON.stringify(tel).indexOf('ticketid') >= 0;
        });

        if (barcodeIdData.length > 0) {
          res.ticketId = barcodeIdData[0].value;
        }
      }

      return {jCardContact: res};
    } catch (e) {
      return {text: vCardRaw};
    }
  }

  generateVCard(data) {
    if (typeof data !== 'object') return null;

    const vCard = [];

    if (data.firstName || data.lastName) {
      vCard.n = [{value: [data.lastName, data.firstName]}];
      vCard.fn = [{value: [data.lastName ? `${data.firstName} ${data.lastName}` : data.firstName]}];
    }
    if (data.company) {
      vCard.org = [{value: data.company}];
    }
    if (data.email) {
      vCard.email = [{value: data.email}];
    }
    if (data.phone) {
      vCard.tel = [{value: data.phone}];
    }
    if (data.website) {
      vCard.url = [{value: data.website}];
    }
    if (data.jobTitle) {
      vCard.title = [{value: data.jobTitle}];
    }

    return generate(vCard);
  }
}

export default new UtilsService();
