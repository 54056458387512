import React from 'react';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import axios from 'axios';
import {cacheAdapterEnhancer, throttleAdapterEnhancer} from 'axios-extensions';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';
import compose from 'recompose/compose';
import Title from '../Title';
import './StaticContent.scss';

const http = axios.create({
  baseURL: '/',
  headers: {'Cache-Control': 'no-cache'},
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter)),
});

const StaticContent = observer(props => {
  const {page} = props;
  const [content, setContent] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    http
      .get(`${process.env.PUBLIC_URL}/page/${page}/en.html`, {useCache: true})
      .then(res => {
        setTimeout(() => {
          window.scrollTo({top: 0, behavior: 'smooth'});
        });
        setContent(res.data);
      })
      .catch(() => {
        setError('Error');
      });
  }, []);

  if (error) {
    return <div className="static-content__error">{error}</div>;
  }

  return (
    <div
      className={classNames('static-content', {
        [`static-${page}`]: page,
      })}
    >
      <Title id={page} />
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{__html: content}} />
    </div>
  );
});

export default compose(injectIntl, withRouter)(StaticContent);
