import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import {Button, Dropdown} from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import {AppContext} from '../../../store';
import MeetingApi from '../../../services/api/meetingApi';
import PersonItem from '../../../ui/PersonItem';
import ButtonLoading from '../../../ui/ButtonLoading';
import './MeetingFormAddGuest.scss';

const MeetingFormAddGuest = observer(props => {
  const intl = useIntl();
  const {meeting} = props;
  const {participantStore, agendaStore, userStore, modalStore} = React.useContext(AppContext);
  const {participants} = agendaStore.data;
  const {user} = userStore;
  const [selectedParticipantId, setSelectedParticipantId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const debouncedSave = React.useCallback(
    debounce(newValue => {
      setLoading(true);
      return participantStore
        .search({queryText: newValue, filters: []}, meeting.eventId, true)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }, 400),
    [],
  );

  const updateValue = newValue => {
    debouncedSave(newValue);
  };

  return (
    <div className="meeting-add-guest">
      <div className="meeting-add-guest__select">
        <Dropdown
          fluid
          search
          selection
          clearable
          loading={loading}
          onSearchChange={(event, value) => updateValue(value.searchQuery)}
          placeholder={intl.formatMessage({id: 'meeting.form.add.guest.placeholder'})}
          onChange={(event, data) => {
            if (data.value.length) {
              setSelectedParticipantId(data.value);
            } else {
              setSelectedParticipantId(null);
            }
          }}
          options={participants
            .filter(el => el.id)
            .map(participant => ({
              key: participant.id,
              content: <PersonItem person={participant} fullImageLink />,
              text: `${participant.name} ${participant.surname}`,
              value: participant.id,
              className: 'meeting-add-guest__attendee',
            }))}
        />
      </div>
      <div className="meeting-add-guest__action">
        <Button color="red" basic onClick={() => modalStore.close('addGuestToMeetingModal')}>
          <FormattedMessage id="meeting.form.add.guest.btn.cancel" />
        </Button>
        <ButtonLoading
          className="agenda-btn__add-to-meeting"
          color="blue"
          disabled={!selectedParticipantId}
          onClick={() =>
            MeetingApi.addParticipantToMeeting(user.id, meeting.eventId, meeting.id, '', selectedParticipantId).then(
              () => modalStore.close('addGuestToMeetingModal'),
            )
          }
        >
          <FormattedMessage id="meeting.form.add.guest.btn.add" />
        </ButtonLoading>
      </div>
    </div>
  );
});

MeetingFormAddGuest.propTypes = {
  meeting: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default injectIntl(MeetingFormAddGuest);
