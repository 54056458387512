import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Icon, Popup} from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import {AppContext} from '../../../store';
import TimeService from '../../../services/core/time-service';
import {LocalStore} from '../../../services/core/storage-service';
import './ChatPost.scss';

const ChatPost = observer(({...props}) => {
  const {floating} = props;
  const {chatStore, userStore, eventStore} = React.useContext(AppContext);
  const {selectedRoom, floatingSelectedRoom, roomsDetails} = chatStore;
  const room = roomsDetails[floating ? floatingSelectedRoom : selectedRoom];
  const roomId = room.details.id;
  const {user} = userStore;
  const userId = userStore.user.id;
  const {eventDetails, meParticipant} = eventStore;
  const interlocutorsArray = Object.keys(room.members).filter(member => member !== userId);
  const memberOppositeId = interlocutorsArray.length === 1 ? interlocutorsArray[0] : null;
  const lastVisitedEvent = LocalStore.getObject('lastVisitedEvent');
  const {attendeeId, id} = lastVisitedEvent;
  const inputEl = React.useRef(null);
  const [message, setMessage] = useState('');
  const [openPopup, setOpenPopup] = React.useState(false);
  const intl = useIntl();

  const handleClick = () => {
    if (Object.keys(chatStore.allMembers).includes(memberOppositeId)) {
      chatStore.writeMessageToRoom(roomId, {dt: TimeService.now(), msg: message, sndr: userId}, eventDetails);
    } else {
      chatStore
        .createRoom(
          [
            {id: userId, partId: attendeeId || null},
            {
              id: memberOppositeId,
              partId: room.members[memberOppositeId].id,
            },
          ],
          'private',
          id || room?.members[memberOppositeId]?.eventId,
        )
        .then(data => {
          chatStore
            .writeMessageToRoom(data.data.data, {dt: TimeService.now(), msg: message, sndr: userId}, eventDetails)
            .then(rId => {
              chatStore.cleanFirstMessage(rId);
            });
        });
    }
  };

  const vCardUser = {
    name: meParticipant?.name || '',
    surname: meParticipant?.surname || '',
    email: user.username || '',
    phone: meParticipant?.phone ? '+' + meParticipant.phone : '',
    website: meParticipant?.website || '',
    company: meParticipant?.company || '',
    jobTitle: meParticipant?.jobTitle || '',
  };

  const ChatOptionsContent = (
    <div role="list" className="chat-edit">
      <div
        aria-hidden
        className="item"
        onClick={() => {
          if (Object.keys(chatStore.allMembers).includes(memberOppositeId)) {
            chatStore.writeMessageToRoom(
              roomId,
              {dt: TimeService.now(), msg: JSON.stringify(vCardUser), sndr: userId, type: 'contact'},
              eventDetails,
            );
          } else {
            chatStore
              .createRoom(
                [
                  {id: userId, partId: attendeeId || null},
                  {
                    id: memberOppositeId,
                    partId: room.members[memberOppositeId].id,
                  },
                ],
                'private',
                id || room?.members[memberOppositeId]?.eventId,
              )
              .then(data => {
                chatStore
                  .writeMessageToRoom(
                    data.data.data,
                    {dt: TimeService.now(), msg: JSON.stringify(vCardUser), sndr: userId, type: 'contact'},
                    eventDetails,
                  )
                  .then(rId => {
                    chatStore.cleanFirstMessage(rId);
                  });
              });
          }
          setOpenPopup(false);
        }}
      >
        <div className="content">
          <FormattedMessage id="chat.post.popup.share" />
        </div>
      </div>
    </div>
  );

  const popupProps = {
    basic: true,
    position: 'bottom right',
    on: 'click',
    content: ChatOptionsContent,
    onClose: () => setOpenPopup(false),
    onOpen: () => setOpenPopup(true),
    open: openPopup,
    hideOnScroll: true,
    className: 'chat__popup',
  };

  return (
    <div className="chat-post">
      <div className="chat-input">
        <TextareaAutosize
          minRows={1}
          maxRows={5}
          value={message}
          ref={inputEl}
          placeholder={intl.formatMessage({id: 'chat.post.input.placeholder'})}
          onChange={el => setMessage(el.target.value)}
          onKeyDown={e => {
            if (e.keyCode === 13 && !e.shiftKey && message.trim().length > 0) {
              e.preventDefault();
              handleClick();
              setMessage('');
            }
          }}
        />
        <div className="chat-action-wrapper">
          <button
            type="submit"
            className="ui blue icon button chat-btn"
            onClick={() => {
              if (message.trim().length > 0) {
                handleClick();
                setMessage('');
              }
            }}
          >
            <Icon name="send" />
          </button>
        </div>
      </div>
      {eventDetails ? (
        <Popup
          {...popupProps}
          trigger={
            <div className="chat__popup-button">
              <i className="chat__btn-more plus horizontal icon button" />
            </div>
          }
        />
      ) : null}
    </div>
  );
});

ChatPost.propTypes = {
  floating: PropTypes.bool,
};

export default injectIntl(ChatPost);
