import PropTypes from 'prop-types';
import {FormattedMessage, useIntl} from 'react-intl';
import {Dropdown} from 'semantic-ui-react';
import {setLastSelectedCheckInData} from '../../../services/helpers/checkInHelper';
import {v4 as uuidv4} from 'uuid';

const CheckinPrinterSelector = ({value, onChange, placeholder, printers, eventId, className}) => {
  const intl = useIntl();

  const handleDropdownChange = (_, {value}) => {
    const printer = printers.find(printer => printer.ip === value);

    setLastSelectedCheckInData(eventId, 'printer', printer);

    return onChange(printer?.ip);
  };

  const printerSelectOptions = printers => {
    return printers.map(e => {
      return {
        key: uuidv4(),
        text: e.label,
        value: e.ip,
      };
    });
  };

  return (
    <>
      <label htmlFor="checkin-printer">
        <FormattedMessage id="checkIn.printerSelector.label" />
      </label>
      <Dropdown
        selection
        className={className}
        placeholder={placeholder ?? intl.formatMessage({id: 'checkIn.printerSelector.placeholder'})}
        options={printerSelectOptions(printers)}
        id="checkin-printer"
        onChange={handleDropdownChange}
        value={value}
      />
    </>
  );
};

CheckinPrinterSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  printers: PropTypes.array.isRequired,
  eventId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CheckinPrinterSelector.defaultProps = {
  className: '',
};

export default CheckinPrinterSelector;
