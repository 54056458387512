import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {useHistory, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Popup} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {runInAction} from 'mobx';
import {AppContext} from '../../../store';
import ImageLazy from '../../../ui/ImageLazy';
import {ReactComponent as BackImg} from '../../../assets/images/icons/arrow.svg';
import attendeeImg from '../../../assets/images/defaultImages/attendee.png';
import './ChatActiveUser.scss';

const ChatActiveUser = observer(props => {
  const {floating, isFloatingOpen} = props;
  const {chatStore, userStore, eventStore} = React.useContext(AppContext);
  const {selectedRoom, floatingSelectedRoom, roomsDetails} = chatStore;
  const {eventList} = eventStore;
  const history = useHistory();
  const location = useLocation();
  const room = roomsDetails[floating ? floatingSelectedRoom : selectedRoom];
  const interlocutorsArray = Object.keys(room.members).filter(member => member !== userStore.user.id);
  const interlocutor = interlocutorsArray.length === 1 ? interlocutorsArray[0] : '';
  const interlocutorInfo = room['members'][interlocutor] || {};
  const checkUsernameIsClickable =
    interlocutorInfo.eventInfo &&
    !!interlocutorInfo.eventInfo.attendeeId &&
    !!interlocutorInfo.eventInfo.eventId &&
    eventList.some(event => event.id === interlocutorInfo.eventInfo.eventId);
  const [openEditPopup, setOpenEditPopup] = React.useState(false);

  const openOpponentProfile = interlocutorInfo => {
    const interlocutorEventInfo = interlocutorInfo.eventInfo;
    interlocutorEventInfo &&
      history.push({
        pathname: `/event/${interlocutorEventInfo.eventId}/attendees/${interlocutorEventInfo.attendeeId}`,
        state: {
          background: location,
          page: 'attendees',
          title: interlocutorInfo.name,
          openedFromChat: true,
        },
      });
  };

  const ChatOptionsContent = (
    <div role="list" className="chat-edit">
      {/*
     <div
        aria-hidden
        className="item"
        onClick={() => {
          const usersToBeRemoved = [];
          usersToBeRemoved.push(userStore.user.id);
          chatStore.removeMembers(room.details.id, usersToBeRemoved);
        }}
      >
        <div className="content">
          <FormattedMessage {...messages.chatActiveUserDeleteChat} />
        </div>
      </div>
      */}
      <div
        aria-hidden
        className="item"
        onClick={() => {
          chatStore.pinRoom(userStore.user.id, room.details.id, !room.pin);
        }}
      >
        <div className="content">
          {!room.pin ? (
            <FormattedMessage id="chat.active.user.pin" />
          ) : (
            <FormattedMessage id="chat.active.user.unpin" />
          )}
        </div>
      </div>
      {floating && (
        <div
          aria-hidden
          className="item"
          onClick={() => {
            runInAction(() => {
              chatStore.selectedRoom = room.details.id;
              props.floatingTrigger(false);
            });
          }}
        >
          <Link to="/chat">
            <div className="content">
              <FormattedMessage id="chat.active.user.full.size" />
            </div>
          </Link>
        </div>
      )}
    </div>
  );

  const popupProps = {
    basic: true,
    position: 'bottom right',
    on: 'click',
    content: ChatOptionsContent,
    onClose: () => setOpenEditPopup(false),
    onOpen: () => setOpenEditPopup(true),
    open: openEditPopup,
    hideOnScroll: true,
    className: 'chat__popup',
  };

  return (
    <div className="chat-active-user">
      <div aria-hidden className="chat-active-user__back" onClick={() => chatStore.unSelectChat()}>
        <BackImg />
      </div>
      <div
        className="chat-active-user__user"
        onClick={() => {
          checkUsernameIsClickable && openOpponentProfile(interlocutorInfo);
        }}
        style={{cursor: checkUsernameIsClickable && 'pointer'}}
      >
        <div className="chat-active-user__avatar">
          <div className="chat-active-user__avatar">
            {interlocutorInfo.profilePhoto ? (
              <ImageLazy src={interlocutorInfo.profilePhoto} errorSrc={attendeeImg} alt={interlocutorInfo.name} />
            ) : (
              <img src={attendeeImg} alt={interlocutorInfo.name} />
            )}
          </div>
        </div>
        <div className="chat-active-user__info">
          <div
            className={classNames('chat-active-user__name', {
              'chat-active-user__name--profile-link': checkUsernameIsClickable,
            })}
          >
            {interlocutorInfo.name} {interlocutorInfo.surname}
          </div>
          {interlocutorInfo.jobTitle ? (
            <p className="chat-active-user__job">
              {interlocutorInfo.jobTitle} at {interlocutorInfo.company}
            </p>
          ) : null}
        </div>
      </div>
      {floating ? (
        isFloatingOpen && (
          <Popup
            {...popupProps}
            trigger={
              <div className="chat-active-user__popup-button">
                <i className="chat__btn-more ellipsis horizontal icon button" />
              </div>
            }
          />
        )
      ) : (
        <Popup
          {...popupProps}
          trigger={
            <div className="chat__popup-button">
              <i className="chat__btn-more ellipsis horizontal icon button" />
            </div>
          }
        />
      )}
    </div>
  );
});

ChatActiveUser.propTypes = {
  floating: PropTypes.bool,
  isFloatingOpen: PropTypes.bool,
  floatingTrigger: PropTypes.func,
};

ChatActiveUser.defaultProps = {
  floating: false,
  isFloatingOpen: false,
  floatingTrigger: () => {},
};

export default injectIntl(ChatActiveUser);
