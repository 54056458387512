import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import * as Cookies from 'js-cookie';
import {Button} from 'semantic-ui-react';
import closeImg from '../../../assets/images/icons/X-dark.svg';
import './CookieConsent.scss';

const CookieConsent = () => {
  const getConsentCookieStatus = Cookies.get('cookie_consent_status') || null;
  const [consentCookieStatus, setConsentCookieStatus] = React.useState(getConsentCookieStatus !== null);

  const handleClick = status => {
    Cookies.set('cookie_consent_status', status, {expires: 360});
    setConsentCookieStatus(true);
  };

  return !consentCookieStatus ? (
    <div className="cookie-consent">
      <span>
        <FormattedMessage
          id="cookie.consent.text"
          values={{
            privacy: (
              <Link to="/privacy-notice">
                <FormattedMessage id="cookie.consent.privacy.button" />
              </Link>
            ),
            cookie: (
              <Link to="/cookies">
                <FormattedMessage id="cookie.consent.cookie.button" />
              </Link>
            ),
          }}
        />
      </span>
      <Button primary className="cookie-consent__button--allow" onClick={() => handleClick('allow')}>
        <FormattedMessage id="cookie.consent.agree.button" />
      </Button>
      <Button icon className="cookie-consent__button--dismiss" onClick={() => handleClick('dismiss')}>
        <img src={closeImg} alt="close" />
      </Button>
    </div>
  ) : null;
};

export default CookieConsent;
