import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Link, withRouter} from 'react-router-dom';
import compose from 'recompose/compose';
import {LocalStore} from '../../../services/core/storage-service';
import ImageLazy from '../../../ui/ImageLazy';
import bannerImg from '../../../assets/images/defaultImages/banner.jpg';
import './LastVisitedEventBtn.scss';

const LastVisitedEventBtn = observer(props => {
  const {history, eventDetails} = props;
  const lastVisitedEvents = LocalStore.getObject('lastVisitedEvent');
  const eventData = lastVisitedEvents.length ? lastVisitedEvents : eventDetails;
  const eventPathName = eventData ? `/event/${eventData.id}/` + eventData.indexPagePath : null;
  const filledEventProfileForEvents = LocalStore.getObject('filledEventProfileForEvents');
  const showBtnForEventProfile =
    eventDetails &&
    (Object.keys(filledEventProfileForEvents).some(eventId => eventId === eventDetails.id) || eventDetails.isFinished);

  const mapPages = new Set([
    '/account/profile',
    '/notifications',
    '/my-agenda',
    '/chat',
    '/invitations/received',
    '/contacts',
  ]);
  showBtnForEventProfile && mapPages.add(`/event/${eventDetails.id}/event-profile`);

  return eventData && eventPathName && mapPages.has(history.location.pathname) ? (
    <div className="nav-menu__item nav-menu__item--your-event">
      <div className="last-visited-event">
        <h2 className="last-visited-event__title">
          <FormattedMessage id="your.event.title" />
        </h2>
        <Link className="nav-menu__button" to={eventPathName}>
          <ImageLazy src={eventData.images.logo || bannerImg} errorSrc={bannerImg} width={32} height={32} />
          <div className="last-visited-event__name">{eventData.name}</div>
        </Link>
      </div>
    </div>
  ) : null;
});

export default compose(injectIntl, withRouter)(LastVisitedEventBtn);
