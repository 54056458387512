import React from 'react';
import {observer, useLocalStore} from 'mobx-react-lite';
import {Form, Table} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {useForm} from 'react-hook-form';
import {FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import participantNotificationTypes from '../../../configs/participantNotificationConfig';
import {AppContext} from '../../../store';
import './AccountNotificationSettings.scss';

const AccountNotificationSettings = observer(() => {
  const {userStore} = React.useContext(AppContext);
  const [loading, setLoading] = React.useState(false);
  const notificationPrefList = userStore.user.notificationPreferenceList;
  const notification = useLocalStore(() => ({
    list: [],
  }));
  const {handleSubmit, register, errors, setValue, getValues} = useForm();

  React.useMemo(() => {
    notificationPrefList.forEach(item => {
      notification.list[item.type] = item.channels;
    });
  }, [notificationPrefList, notification.list]);

  const onSubmit = () => {
    const valueAll = {};
    const valueNotification = getValues();
    const notificationPreferenceList = [];
    setLoading(true);

    Object.keys(valueNotification).forEach(item => {
      const channels = [];
      Object.keys(valueNotification[item]).forEach(i => {
        if (valueNotification[item][i]) {
          channels.push(i);
        }
      });
      notificationPreferenceList.push({type: item, channels});
    });

    valueAll.notificationPreferenceList = notificationPreferenceList;
    valueAll.action = 'notifications';

    return userStore
      .updateNotificationSettings(userStore.user.id, valueAll)
      .then(() => {
        NotificationAlert.success(<FormattedMessage id="account.text.success" />);
      })
      .catch(err => {
        NotificationAlert.error(err.message);
      })
      .finally(() => setLoading(false));
  };

  const classes = classNames('account-notifications__body', {
    'account-notifications__body--loading': loading,
  });

  return (
    <div className="account-notifications">
      <h2 className="account-notifications__title">
        <FormattedMessage id="account.notification.device-settings.title" />
      </h2>
      <React.Fragment>
        <Form className={classes} error={!isEmpty(errors) || !!userStore.error} onSubmit={handleSubmit(onSubmit)}>
          <Table className="account-notifications__list" celled padded>
            <Table.Body>
              {participantNotificationTypes.map((n, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Table.Row key={i}>
                  <Table.Cell>{n.label}</Table.Cell>
                  <Table.Cell>
                    {Object.keys(n.channels).map((type, k) =>
                      n.channels[type] ? (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className="ui checkbox" key={k}>
                          <input
                            name={`${n.name}.${type}`}
                            checked={notification.list[n.name] ? notification.list[n.name].includes(type) : false}
                            type="checkbox"
                            ref={register}
                            onChange={e => {
                              setValue(n.name, e.target.checked);
                              handleSubmit(onSubmit());
                            }}
                          />
                          <label htmlFor={`${n.name}.${type}`}>{type}</label>
                        </div>
                      ) : null,
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Form>
      </React.Fragment>
    </div>
  );
});

export default injectIntl(AccountNotificationSettings);
