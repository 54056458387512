import Api from '../core/axios-service';
import {stores} from '../../store';

const AnnouncementApi = {
  createAnnouncement(announcement) {
    return Api.post('event/post', announcement).then(
      resp => {
        stores.announcementStore.getAnnouncementList(resp.data.eventId);
        return Promise.resolve(resp);
      },
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  updateAnnouncement(announcement) {
    return Api.put('event/post', announcement).then(
      resp => {
        if (resp.data?.errorMessage) {
          return Promise.reject(resp.data);
        }
        return Promise.resolve(resp);
      },
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  deleteAnnouncement(announcement) {
    return Api.delete('event/post', {params: {id: announcement.id, eventId: announcement.eventId}}).then(
      () => Promise.resolve(),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  addComment(comment) {
    return Api.post('event/post', comment).then(
      () => Promise.resolve(),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  deleteComment(comment) {
    return Api.delete('event/post', {params: {id: comment.id, eventId: comment.eventId}}).then(
      () => Promise.resolve(),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject();
      },
    );
  },
};

export default AnnouncementApi;
