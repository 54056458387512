import axios from 'axios';
import {cacheAdapterEnhancer, throttleAdapterEnhancer} from 'axios-extensions';
import LRUCache from 'lru-cache';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {LocalStore} from './storage-service';
import {stores} from '../../store';

const ONE_HOUR = 1000 * 60 * 60;

export const ApiUrl = process.env.REACT_APP_API_URL;
export const HostUrl = process.env.REACT_APP_HOST_URL;
export const defaultCache = new LRUCache({maxAge: ONE_HOUR});
export const cacheAdapter = cacheAdapterEnhancer(axios.defaults.adapter, {
  enabledByDefault: false,
  cacheFlag: 'useCache',
  defaultCache,
});

// Function that will be called to refresh authorization
const refreshAuthLogic = () =>
  stores.userStore.refreshToken(LocalStore.getObject('dealroom_data').auth_cognito_token).then(() => Promise.resolve());

const Api = axios.create({
  withCredentials: false,
  baseURL: `${ApiUrl}/v1/`,
  adapter: throttleAdapterEnhancer(cacheAdapter),
  headers: {
    get: {
      Authorization: LocalStore.getObject('dealroom_data').auth_cognito_token,
    },
    post: {
      Authorization: LocalStore.getObject('dealroom_data').auth_cognito_token,
    },
    delete: {
      Authorization: LocalStore.getObject('dealroom_data').auth_cognito_token,
    },
    put: {
      Authorization: LocalStore.getObject('dealroom_data').auth_cognito_token,
    },
  },
});

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(Api, refreshAuthLogic, {
  statusCodes: [401],
});

Api.interceptors.request.use(
  config => {
    const token = stores.userStore.authToken;
    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  error => Promise.reject(error),
);

Api.interceptors.response.use(
  response => response,
  err => {
    console.log('api err =', err);
    return Promise.reject(err);
  },
);

export default Api;
