import React from 'react';
import {Placeholder, Segment} from 'semantic-ui-react';
import './InvitationSkeleton.scss';

const InvitationSkeleton = () => {
  return (
    <div className="invitation-skeleton">
      <Segment raised>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
    </div>
  );
};

export default InvitationSkeleton;
