import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Button, Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import Title from '../../shared/Title';
import AttendeePreview from '../../attendee/AttendeePreview/AttendeePreview';
import './InvitationFormHasMeeting.scss';

const InvitationFormHasMeeting = observer(props => {
  const {onBack, invitation, commonMeeting} = props;

  return (
    <>
      <Title id="invitation-has-meeting" />
      <div className="invitation-has-meeting">
        <AttendeePreview attendee={invitation.opponentParticipant} big />
        <div className="meeting-reschedule-sent__time">
          <Icon name="check" />
          <span>{commonMeeting.sdtStr}</span>
        </div>
        <div className="invitation-has-meeting__text">
          <FormattedMessage id="invite.form.has-meeting.text" />
        </div>
        <div className="invitation-has-meeting__action">
          <Button color="red" basic className="btn-link__back" onClick={() => onBack()}>
            <FormattedMessage id="invite.form.respond.btn.back" />
          </Button>
        </div>
      </div>
    </>
  );
});

InvitationFormHasMeeting.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.object]),
  commonMeeting: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBack: PropTypes.func.isRequired,
};

export default InvitationFormHasMeeting;
