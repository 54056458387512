import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage} from 'react-intl';
import Modal from '../../../../ui/Modal';
import PollCreateQuestionForm from '../../../poll/PollCreateQuestionForm';
import {AppContext} from '../../../../store';
import './PollModal.scss';

const PollModal = observer(() => {
  const {modalStore} = React.useContext(AppContext);
  const {open} = modalStore.modals.poll;

  return (
    <Modal className="poll-create-modal" open={open} onClose={() => modalStore.close('poll')}>
      <h3>
        <FormattedMessage id="poll.modal.title" />
      </h3>
      <PollCreateQuestionForm />
    </Modal>
  );
});

export default PollModal;
