import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Dimmer, Loader, Segment, Tab, Menu} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import {filterInvitation} from '../../../services/helpers/invitationHelper';
import InvitationSkeleton from '../../../ui/InvitationSkeleton';
import InvitationsContent from '../InvitationsContent';
import InvitationsFilterMobile from '../InvitationsFilterMobile';
import {ReactComponent as TeamIcon} from '../../../assets/images/icons/team.svg';
import {ReactComponent as InvitationImg} from '../../../assets/images/icons/header/invitationsFull.svg';
import './InvitationsDrawerBody.scss';

const InvitationsDrawerBody = observer(props => {
  const {intl} = props;
  const {eventStore, invitationStore} = React.useContext(AppContext);
  const {invitations, loadingActiveInvitations, filters, filteredInvitations, loadedInvitations} = invitationStore;
  const {eventList} = eventStore;
  const invitationsList = !isEmpty(filters) ? filteredInvitations : invitations;
  const [open, setOpen] = React.useState(false);

  const countLiveInvitations = type =>
    invitations.filter(invitation => filterInvitation(invitation, type, 'live')).length;

  const panes = [
    {
      menuItem: (
        <Menu.Item className="invitations-tab__item" key="received">
          {intl.formatMessage({id: 'invitations.nav.link.received'})}
          {countLiveInvitations('received') > 0 ? (
            <span className="invitations-tab__item-count">{countLiveInvitations('received')}</span>
          ) : null}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <InvitationsContent
            type="received"
            liveInvitations={invitationsList.filter(el => filterInvitation(el, 'received', 'live'))}
            pastInvitations={invitationsList.filter(el => filterInvitation(el, 'received', 'past'))}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item className="invitations-tab__item" key="sent">
          {intl.formatMessage({id: 'invitations.nav.link.sent'})}
          {countLiveInvitations('sent') > 0 ? (
            <span className="invitations-tab__item-count">{countLiveInvitations('sent')}</span>
          ) : null}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <InvitationsContent
            type="sent"
            liveInvitations={invitationsList.filter(el => filterInvitation(el, 'sent', 'live'))}
            pastInvitations={invitationsList.filter(el => filterInvitation(el, 'sent', 'past'))}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem:
        invitations.filter(el => el.segment === 'company').length > 0 ? (
          <Menu.Item className="invitations-tab__item" key="company">
            <TeamIcon />
            {intl.formatMessage({id: 'invitations.nav.link.company'})}
            {countLiveInvitations('company') > 0 ? (
              <span className="invitations-tab__item-count">{countLiveInvitations('company')}</span>
            ) : null}
          </Menu.Item>
        ) : null,
      render: () => (
        <Tab.Pane>
          <InvitationsContent
            type="company"
            liveInvitations={invitationsList.filter(el => filterInvitation(el, 'company', 'live'))}
            pastInvitations={invitationsList.filter(el => filterInvitation(el, 'company', 'past'))}
          />
        </Tab.Pane>
      ),
    },
  ];

  const content = () => {
    if (loadingActiveInvitations || !eventList) {
      return (
        <div className="invitations__skeleton">
          <InvitationSkeleton />
          <InvitationSkeleton />
        </div>
      );
    }

    if (invitations.length > 0) {
      return <Tab panes={panes} />;
    }
    return (
      <div className="invitations__empty">
        <div className="invitations__empty-img">
          <InvitationImg />
        </div>
        <div className="invitations__empty-text">
          <FormattedMessage id="invitations.page.empty" />
        </div>
        <div className="invitations__empty-info">
          <FormattedMessage id="invitations.page.empty.info" />
        </div>
      </div>
    );
  };

  if (!loadedInvitations) {
    return (
      <Segment className="invitations__loader">
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      </Segment>
    );
  }

  return (
    <div className="invitations">
      <div className="invitations__wrapper">
        <h1 className="invitations__title">
          <FormattedMessage id="invitation.sidebar.menuTitle" />
        </h1>
        <InvitationsFilterMobile invitations={invitationsList} collapse={() => setOpen(!open)} isOpen={open} />
      </div>
      <div className={classNames('invitations__body-content', {'invitations__body--filters': open})}>{content()}</div>
    </div>
  );
});

export default injectIntl(InvitationsDrawerBody);
