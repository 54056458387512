import React from 'react';
import {observer} from 'mobx-react-lite';
import Modal from '../../../../ui/Modal';
import {AppContext} from '../../../../store';
import StaticContent from '../../../shared/StaticContent';

const TermsModal = observer(() => {
  const {modalStore} = React.useContext(AppContext);
  const {open} = modalStore.modals.termsModal;

  return (
    <Modal open={open} onClose={() => modalStore.close('termsModal')} size="large">
      <StaticContent page="terms" />
    </Modal>
  );
});

export default TermsModal;
