export const PresenceConfirmationFields = {
  type: 'select',
  fieldName: 'present',
  required: true,
  options: [
    {
      key: 0,
      text: 'On-site',
      value: 'venue',
    },
    {
      key: 1,
      text: 'Remote',
      value: 'online',
    },
  ],
};
