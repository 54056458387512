import {action, extendObservable, runInAction} from 'mobx';
import {subscribeAnnouncementList, subscribeCommentList} from '../../../services/firebase/announcementFbApi';

export class AnnouncementStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    extendObservable(this, {
      announcementList: null,
      participant: null,
      comments: {},
      loaded: false,
    });
  }

  @action
  getAnnouncementList(eventId) {
    return new Promise(resolve => {
      subscribeAnnouncementList(eventId, snapshot => {
        runInAction(() => {
          if (snapshot.val()) {
            this.announcementList = snapshot.val();
          } else {
            this.announcementList = [];
          }
          this.loaded = true;
        });
        return resolve(this.announcementList);
      });
    });
  }

  @action
  getCommentList(eventId, postId) {
    subscribeCommentList(eventId, postId, snapshot => {
      runInAction(() => {
        if (snapshot.val()) {
          this.comments[postId] = snapshot.val();
        } else {
          this.comments[postId] = {};
        }
      });
    });
  }

  @action
  setParticipantAnnouncement(participant) {
    this.participant = participant;
  }
}
