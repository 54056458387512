import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {useLocation, Link, useHistory} from 'react-router-dom';
import classNames from 'classnames';
import {Popup} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {AppContext} from '../../../store';
import ImageLazy from '../../../ui/ImageLazy';
import UtilsService from '../../../services/core/utils-service';
import attendeeImg from '../../../assets/images/defaultImages/attendee.png';
import companyImg from '../../../assets/images/defaultImages/company-logo.png';
import './AttendeePreview.scss';

const AttendeePreview = observer(props => {
  const {attendee, big, boothDetails, guest, preloadLazyImages} = props;
  const {eventStore, uiStore} = React.useContext(AppContext);
  const {eventList} = eventStore;
  const eventDetails = eventList?.filter(event => event.id === attendee.eventId)[0];
  const booth = attendee?.boothDetails || boothDetails;
  const history = useHistory();
  const location = useLocation();
  const {pageTitle} = uiStore;

  const classes = classNames('attendee-preview', {
    'attendee-preview--big': big,
  });

  return (
    <div className={classes}>
      <div className="attendee-preview__img">
        <ImageLazy
          src={UtilsService.fixPublicBucketUrl(attendee.profilePhoto) || attendeeImg}
          errorSrc={attendeeImg}
          alt={attendee?.name}
          visibleByDefault={preloadLazyImages}
        />
        {eventDetails?.type === 'hybrid' && attendee.present ? (
          <span className="attendee-preview__presence">
            <FormattedMessage id={`attendee.card.presence.${attendee.present}`} />
          </span>
        ) : null}
      </div>
      <div className="attendee-preview__data">
        {eventDetails ? (
          <Link
            className="attendee-preview__name"
            to={{
              pathname: `/event/${eventDetails.id}/attendees/${attendee.id}`,
              state: {
                background: location,
                page: 'attendees',
                title: attendee.name,
                prevPage: pageTitle,
              },
            }}
          >
            {attendee?.name} {attendee?.surname}
          </Link>
        ) : (
          <div className="attendee-preview__name">
            {attendee?.name} {attendee?.surname}
          </div>
        )}
        <div className="attendee-preview__job">
          <span className="attendee-preview__position">{attendee.jobTitle}</span>
          {attendee.company && attendee.jobTitle ? ', ' : null}
          <span className="attendee-preview__company">{attendee.company}</span>
          {guest ? <span className="attendee-preview__guest">Guest</span> : null}
        </div>
      </div>
      {!isEmpty(booth) ? (
        <div className="attendee-preview__booth">
          <Popup
            content={booth.boothName}
            trigger={
              <div className="attendee-preview__company-img">
                <ImageLazy
                  src={UtilsService.fixPublicBucketUrl(booth.images?.logo) || companyImg}
                  errorSrc={companyImg}
                  alt={booth.name}
                  visibleByDefault={preloadLazyImages}
                  onClick={() =>
                    history.push({
                      pathname: `/event/${booth.eventId}/expo/${booth.id}`,
                      state: {
                        background: location,
                        page: 'expo',
                        title: booth.boothName,
                        prevPage: pageTitle,
                      },
                    })
                  }
                />
              </div>
            }
          />
        </div>
      ) : null}
    </div>
  );
});

AttendeePreview.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
};

export default injectIntl(AttendeePreview);
