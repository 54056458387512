import {useContext} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Label} from 'semantic-ui-react';
import {v4 as uuidv4} from 'uuid';
import {AppContext} from '../../../store';
import ParserText from '../../../ui/ParserText';
import './PartnerQuestions.scss';

const PartnerQuestions = observer(({questionAnswers}) => {
  const {eventStore} = useContext(AppContext);
  const {eventDetails} = eventStore;
  const questionList = eventDetails?.categoriesForBoothArray ?? [];

  if (!questionList.length || !questionAnswers?.length) {
    return null;
  }

  let filteredQuestionAnswers = [];

  for (const key in questionAnswers) {
    const question = questionList.find(
      question => question.categoryId === questionAnswers[key].categoryId && !question.isPrivate,
    );

    if (question) {
      filteredQuestionAnswers[key] = {...questionAnswers[key]};
      filteredQuestionAnswers[key].question = question;
    }
  }

  if (!filteredQuestionAnswers.length) {
    return null;
  }

  const field = (answer, category) => {
    if (category.type === 'select') {
      return answer.selectedCategoryIds ? (
        answer.selectedCategoryIds.map(subCategoryId =>
          category.subCategories[subCategoryId] ? (
            <Label key={uuidv4()}>
              <ParserText>{category.subCategories[subCategoryId].name}</ParserText>
            </Label>
          ) : (
            'No info'
          ),
        )
      ) : (
        <span>No info</span>
      );
    }
    if (category.type === 'text') {
      return answer.inputValue ? (
        <Label>
          <ParserText>{answer.inputValue}</ParserText>
        </Label>
      ) : (
        <span>No info</span>
      );
    }
    return null;
  };

  return (
    <div className="partner-questions">
      {filteredQuestionAnswers.map(answer => {
        const {question} = answer;
        return (
          <div key={uuidv4()} className="partner-questions__question">
            <h4>
              <ParserText>{question.name}</ParserText>
            </h4>
            {field(answer, question)}
          </div>
        );
      })}
    </div>
  );
});

PartnerQuestions.propTypes = {
  questionAnswers: PropTypes.oneOfType([PropTypes.array]),
};

export default PartnerQuestions;
