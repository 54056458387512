import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import './CheckInHandheldScanner.scss';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Dimmer, Form, Loader, Segment} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import CheckinStatusSelector from '../CheckinStatusSelector';
import {ZPL_PRINT_TYPE_OPTIONS} from '../CheckInZplPrintTypeSelector';
import {getLastSelectedCheckInData, handleScan} from '../../../services/helpers/checkInHelper';
import {printStandard, printTXT, printZPL, printZPLByPrintQueue} from '../../../services/helpers/printHelper';
import {getBadgePrintingStatus, markBadgeAsPrinted} from '../../../services/firebase/checkInFbApi';
import useBadges from '../../../hooks/useBadges';
import {useEventOutsideElement} from '../../../hooks/useEventOutsideElement';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {ReactComponent as HandheldScanImg} from '../../../assets/images/icons/handheldScan.svg';

const CheckInHandheldScanner = observer(() => {
  const {checkInStore, eventStore} = React.useContext(AppContext);
  const {eventDetails} = eventStore;
  const intl = useIntl();
  const {scanTypeData} = checkInStore;
  const [inputValue, setInputValue] = useState('');
  const [checkInStatus, setCheckInStatus] = useState(true);
  const {badge, isLoading: isLoadingBadge} = useBadges(eventDetails.defaultBadge);
  const inputRef = useRef(null);

  useEventOutsideElement(
    inputRef,
    () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    ['click', 'touchstart'],
  );

  useEffect(() => {
    inputRef.current && document.activeElement !== inputRef.current && inputRef.current.focus();

    const timer = setTimeout(() => {
      if (inputValue) {
        console.log('Scan result: ' + inputValue);
        handleScan(inputValue, checkInStatus)
          .then(status => {
            const {scannedContact} = checkInStore;
            const selectedPrinter = getLastSelectedCheckInData(eventDetails.id, 'printer');
            const badgeTpl = badge && badge.type !== 'txt' ? badge.templates['BadgeFrontCanvas.template'] : null;

            if (status !== 'checkedIn' || !inputValue) {
              return;
            }

            getBadgePrintingStatus(eventDetails.id, inputValue).then(snapshot => {
              const data = snapshot.val();
              const isPrinted = data?.printed;

              if (isPrinted) {
                return NotificationAlert.warning(<FormattedMessage id="checkIn.container.badgeAlreadyPrinted.error" />);
              }

              return printZPLByPrintQueue(
                eventDetails.id,
                scannedContact.ticketId,
                selectedPrinter
              )
              .then(status => {
                if (status === 'isPrinting') {
                  return NotificationAlert.warning(
                    <FormattedMessage id="checkIn.container.badgeZPLIsPrinting.error" />,
                  );
                }
                NotificationAlert.success(
                  <FormattedMessage id="checkIn.container.badgeZPLIsPrinting.sentToPrint.success" />,
                );
                console.log('zpl code successfully printed by print queue');
              })
              .catch(error => console.log('zpl code by print queue failed', error));
            });
          })
          .finally(() => {
            setInputValue('');
          })
          .catch(error => {
            console.log('Scan error type: ' + error);
          });
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [inputValue]);

  const buttons = (
    <div className="check-in-scanner__btns">
      <Button color="blue" onClick={() => checkInStore.backToInitStep(scanTypeData)}>
        <FormattedMessage id="checkIn.scanner.back.btn" />
      </Button>
    </div>
  );

  const checkInSelector =
    scanTypeData.scanType === 'fast' ? (
      <Form.Field>
        <CheckinStatusSelector onChangeStatus={setCheckInStatus} status={checkInStatus} />
      </Form.Field>
    ) : null;

  if (isLoadingBadge && !badge) {
    return (
      <Segment>
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      </Segment>
    );
  }

  return (
    <div className="check-in-handheld-scanner">
      <h3>
        <FormattedMessage id="checkIn.handheldScanner.title" />
      </h3>
      <HandheldScanImg />
      <Form>
        <Form.Field>
          <input
            name="ticket-id"
            placeholder={intl.formatMessage({id: 'checkIn.handheldScanner.input.placeholder'})}
            ref={inputRef}
            value={inputValue}
            type="text"
            onChange={e => setInputValue(e.target.value)}
          />
          <div className="check-in-handheld-scanner__info">
            <FormattedMessage id="checkIn.handheldScanner.scanInfo" />
          </div>
        </Form.Field>
        {checkInSelector}
      </Form>
      {buttons}
    </div>
  );
});

export default CheckInHandheldScanner;
