import {AgendaListStore} from './AgendaListStore';
import {AgendaOffTimeStore} from './AgendaOffTimeStore';
import {AgendaFilterStore} from './AgendaFilterStore';

export class AgendaStore {
  constructor(rootStore) {
    this.data = new AgendaListStore(rootStore);
    this.filter = new AgendaFilterStore(rootStore);
    this.ofTime = new AgendaOffTimeStore(rootStore);
  }
}
