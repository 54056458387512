import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Icon} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import ImageLazy from '../../../ui/ImageLazy';
import {getGmtTimezoneByTimezoneName} from '../../../utils/getGmtTimezones';
import {HostUrl} from '../../../services/core/axios-service';
import AccountTabs from '../../account/AccountTabs';
import Drawer from '../../shared/Drawer';
import userImg from '../../../assets/images/user.jpg';
import './ProfileSidebar.scss';

const ProfileSidebar = observer(() => {
  const {userStore, uiStore} = React.useContext(AppContext);
  const {profileSidebarShow, profileSidebarTabIndex} = uiStore;
  const {user} = userStore;
  const {isMenuCollapsed} = uiStore;
  const gmtUserTimezone = getGmtTimezoneByTimezoneName(user.timezone);

  return (
    <div className="nav-menu__item nav-menu__item--profile">
      <div className="nav-menu__button" role="button" aria-hidden="true" onClick={() => uiStore.showProfile(true)}>
        <ImageLazy src={`${HostUrl}/${user.profilePhotoPath}`} errorSrc={userImg} alt={user.name} />
        <div className="nav-menu__item-text">
          <div className="profile__name">
            {user.name} {user.surname}
          </div>
          <div className="profile__status">Online</div>
          {gmtUserTimezone && (
            <div onClick={() => uiStore.showProfile(true)} className="profile__timezone">
              <FormattedMessage id="profile.sidebar.timezone" /> {gmtUserTimezone} {user.timezone}
            </div>
          )}
        </div>
        <Icon name="angle right" />
      </div>
      <Drawer
        show={profileSidebarShow}
        close={() => uiStore.showProfile(false)}
        leftOffset={isMenuCollapsed}
        classes="profile-drawer"
      >
        <div className="my-profile">
          <AccountTabs defaultTab={profileSidebarTabIndex} />
        </div>
      </Drawer>
    </div>
  );
});

export default injectIntl(ProfileSidebar);
