import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import classNames from 'classnames';
import {Button} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import ImageLazy from '../../../ui/ImageLazy';
import bannerImg from '../../../assets/images/defaultImages/banner.jpg';
import dealroomImg from '../../../assets/images/dealroom_logo.svg';
import dealroomMobileImg from '../../../assets/images/dealroom_logo_mobile.png';
import './Header.scss';

const Header = observer(props => {
  const {eventDetails} = props;
  const {userStore} = React.useContext(AppContext);
  const [sticky, setSticky] = React.useState(false);
  const history = useHistory();
  const imageUrl = eventDetails?.images.logo || bannerImg;

  const onScroll = () => {
    const headerSticky = 20;
    if (window.pageYOffset > headerSticky) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return eventDetails ? (
    <header className={classNames('header-site header-site--event', {'header-site--scrolled': sticky})} id="header">
      <div className="container">
        <div className="header-site__wrapper">
          <div className="header-site__logo">
            <ImageLazy src={imageUrl} errorSrc={bannerImg} alt={eventDetails.name} />
          </div>
          <div className="header-site__details">
            <h2 className="header-site__name">{eventDetails.name}</h2>
            <p className="header-site__date">{eventDetails.fullDateStrInEventTime}</p>
          </div>
        </div>
      </div>
    </header>
  ) : (
    <header className={classNames('header-site', {'header-site--scrolled': sticky})} id="header">
      <div className="container">
        <div className="header-site__wrapper">
          <div aria-hidden className="header-site__logo" onClick={() => history.push('/login')}>
            <picture>
              <source media="(max-width: 1024px)" srcSet={dealroomMobileImg} />
              <img src={dealroomImg} alt="dealroom" />
            </picture>
          </div>
          <div className="header-site__details">
            <h2 className="header-site__name">All-in-one Virtual Event Management Platform</h2>
            <p className="header-site__date">
              Create engaging virtual, live or hybrid events with Deal Room’s comprehensive networking functionalities.
            </p>
            <Button
              primary
              className="header-site__login"
              size="mini"
              onClick={() => (userStore.isUserLogged ? history.push('/event/list') : history.push('/login'))}
            >
              {userStore.isUserLogged ? 'Back to Event list' : 'Back to Login'}
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
});

Header.propTypes = {
  eventDetails: PropTypes.oneOfType([PropTypes.object]),
};

export default injectIntl(Header);
