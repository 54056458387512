import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';
import {Button, Icon, Popup} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import {AppContext} from '../../../store';
import {openSession, startMeeting} from '../../../services/helpers/meetingHelper';
import MeetingOffService from '../../../services/api/meetingOfApi';
import TimeService from '../../../services/core/time-service';
import ButtonLoading from '../../../ui/ButtonLoading';
import MeetingApi from '../../../services/api/meetingApi';
import {startBroadcast} from '../../../services/helpers/meetingHelper';
import './SessionBtns.scss';

const SessionBtns = observer(props => {
  const {session, intl} = props;
  const {agendaStore, userStore, participantStore, eventStore, modalStore} = React.useContext(AppContext);
  const {mySessions} = agendaStore.data;
  const {user} = userStore;
  const {states} = participantStore;
  const {eventList, eventDetails, meParticipant} = eventStore;
  const eDetails = eventDetails || eventList.find(e => e.id === session.eventId);
  const myParticipant = !isEmpty(meParticipant) ? meParticipant : eDetails.me || {};
  const allExpired = session.allExpired;
  const upcomingState = session.upcomingState;
  const sessionType = session.type;
  const statesSession = states[session.id];
  const availableToMe = session.availableToMe?.length;
  const speakersMap = session.speakersMap[myParticipant.id];
  const mySession = mySessions[session.id];
  const organizer = myParticipant.organizer;
  const speakers = session.speakers?.includes(myParticipant.id);
  const translator = some(session.translators, item => item.id === myParticipant.id);
  const moderator =
    !allExpired &&
    (organizer || (session.moderators && session.moderators.includes(myParticipant.id))) &&
    sessionType !== 'dealroom';

  const classes = classNames('session-btn', {
    'session-btn--live': session.started,
    'session-btn--upcoming': session.sdt > TimeService.now(),
    'session-btn--pass': session.allExpired,
  });

  const buttonSession = () => {
    // PAST
    if (allExpired) {
      if (session.offlineLink) {
        return (
          <a
            className="button ui green session-btn__watch"
            href={session.offlineLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FormattedMessage id="session.btn.watch" />
          </a>
        );
      }
      return null;
    }
    // LIVE and Join
    if (
      !speakers &&
      !translator &&
      !allExpired &&
      upcomingState !== 'notstarted' &&
      statesSession == null &&
      mySession == null &&
      availableToMe > 0
    ) {
      return session.onSite || (eventDetails && eventDetails.eventType === 'normal') ? (
        <ButtonLoading
          className="session-btn__calendar-add"
          color="blue"
          basic
          onClick={() => MeetingApi.joinSession(user.id, session.eventId, Object.keys(session.ses)[0])}
        >
          <Icon name="plus" /> <FormattedMessage id="session.btn.add.to.calendar" />
        </ButtonLoading>
      ) : (
        <>
          <ButtonLoading
            className="session-btn__live"
            color="teal"
            onClick={() =>
              MeetingApi.joinSession(user.id, session.eventId, Object.keys(session.ses)[0]).then(() => {
                if (session.confType === 'auto') {
                  openSession(session);
                }
              })
            }
          >
            {moderator ? (
              <FormattedMessage id="session.btn.joinAsAttendee" />
            ) : (
              <FormattedMessage id="session.btn.join" />
            )}
          </ButtonLoading>
          {moderator ? (
            <Button
              className="session-btn__moderate"
              color="orange"
              onClick={() => startBroadcast(session, false, true)}
            >
              <FormattedMessage id="session.btn.moderate" />
            </Button>
          ) : null}
        </>
      );
    }
    // LIVE
    if (
      !speakers &&
      !translator &&
      !allExpired &&
      upcomingState !== 'notstarted' &&
      (statesSession === 'meeting' || mySession)
    ) {
      return session.onSite && eventDetails && eventDetails.eventType === 'hybrid' ? (
        <Popup
          content="This session is available only on-site. To take part in it, please proceed to the session location."
          trigger={
            <Button className="session-btn__start-stream" basic color="teal">
              <FormattedMessage id="session.btn.start.onSite" />
            </Button>
          }
        />
      ) : !(session.onSite || (eventDetails && eventDetails.eventType === 'normal')) ? (
        <>
          <Button className="session-btn__join" color="teal" onClick={() => startMeeting(mySession || session)}>
            {moderator ? (
              <FormattedMessage id="session.btn.joinAsAttendee" />
            ) : (
              <FormattedMessage id="session.btn.join" />
            )}
          </Button>
          {moderator ? (
            <Button
              className="session-btn__moderate"
              color="orange"
              onClick={() => startBroadcast(session, false, true)}
            >
              <FormattedMessage id="session.btn.moderate" />
            </Button>
          ) : null}
        </>
      ) : null;
    }

    // FEATURE ADD
    if (
      !speakers &&
      !allExpired &&
      upcomingState === 'notstarted' &&
      mySession == null &&
      statesSession == null &&
      availableToMe
    ) {
      return (
        <>
          {session.sessionCount > 1 ? (
            // FEATURE ADD BY TIME
            <Button
              className="session-btn__calendar-add"
              color="blue"
              basic
              onClick={() => {
                modalStore.open('addSessionToAgendaModal', {session});
              }}
            >
              <Icon name="plus" />
              <FormattedMessage id="session.btn.add.to.calendar" />
            </Button>
          ) : (
            // FEATURE ADD
            <ButtonLoading
              className="session-btn__calendar-add"
              color="blue"
              basic
              onClick={() => MeetingApi.joinSession(user.id, session.eventId, Object.keys(session.ses)[0])}
            >
              <Icon name="plus" />
              <FormattedMessage id="session.btn.add.to.calendar" />
            </ButtonLoading>
          )}
          {moderator && organizer && !session.rehersalStarted ? (
            <Button className="session-btn__rehearsal" basic color="violet" onClick={() => openSession(session, true)}>
              <FormattedMessage id="session.btn.rehearsal" />
            </Button>
          ) : null}
        </>
      );
    }
    // FEATURE REMOVE
    if (
      !speakers &&
      !allExpired &&
      upcomingState === 'notstarted' &&
      (mySession != null || statesSession === 'meeting')
    ) {
      return (
        <>
          <ButtonLoading
            className="session-btn__calendar-remove"
            basic
            color="red"
            onClick={() => MeetingApi.leaveSession(user.id, session.eventId, mySession.id)}
          >
            <FormattedMessage id="session.btn.leave.session" />
          </ButtonLoading>
          {moderator && organizer && !session.rehersalStarted ? (
            <Button className="session-btn__rehearsal" basic color="violet" onClick={() => openSession(session, true)}>
              <FormattedMessage id="session.btn.rehearsal" />
            </Button>
          ) : null}
        </>
      );
    }
    // START
    if (speakers || translator) {
      return session.onSite && eventDetails && eventDetails.eventType === 'hybrid' ? (
        <Popup
          content="This session is available only on-site. To take part in it, please proceed to the session location."
          trigger={
            <Button className="session-btn__start-stream" basic color="teal">
              <FormattedMessage id="session.btn.start.onSite" />
            </Button>
          }
        />
      ) : !(session.onSite || (eventDetails && eventDetails.eventType === 'normal')) ? (
        <>
          <Button className="session-btn__start-stream" basic color="teal" onClick={() => openSession(session)}>
            <FormattedMessage id="session.btn.start" />
          </Button>
          {!session.rehersalStarted ? (
            <Button className="session-btn__rehearsal" basic color="violet" onClick={() => openSession(session, true)}>
              <FormattedMessage id="session.btn.rehearsal" />
            </Button>
          ) : null}
        </>
      ) : null;
    }
    // BUSY
    if (!statesSession && session.collision) {
      return (
        <Button className="session-btn__busy" color="red" basic disabled>
          <FormattedMessage id="session.btn.busy" />
        </Button>
      );
    }
    if (
      speakersMap == null &&
      !allExpired &&
      sessionType !== 'dealroom' &&
      !allExpired &&
      upcomingState !== 'notstarted' &&
      statesSession === 'request_meeting'
    ) {
      const {myAgendaList} = agendaStore.data;
      const manualSession = myAgendaList.filter(s => s.name === session.name)[0];
      return (
        <>
          <Button className="session-btn__busy" color="teal" basic disabled>
            <FormattedMessage id="session.btn.sessionBtnWaitingConfirm" />
          </Button>
          <ButtonLoading
            className="session-btn__calendar-remove"
            basic
            color="red"
            onClick={() => MeetingOffService.deleteOffTime(user.id, session.eventId, manualSession)}
          >
            <FormattedMessage id="session.btn.leave.session" />
          </ButtonLoading>
        </>
      );
    }
    if (!speakers && !allExpired && upcomingState !== 'notstarted' && statesSession === 'rejected') {
      return (
        <Popup
          content={intl.formatMessage({id: 'session.text.RequestDeclined'})}
          trigger={
            <Button className="session-btn__start-stream" basic color="red">
              <FormattedMessage id="session.text.requestDeclined" />
            </Button>
          }
        />
      );
    }
    // FULLED BOOKED
    if (availableToMe === 0 && session.isFull && !allExpired && mySession == null && statesSession !== 'meeting') {
      return (
        <Button className="session-btn__full" disabled>
          <FormattedMessage id="session.text.fullyBooked" />
        </Button>
      );
    }

    return null;
  };

  return eventDetails ? <div className={classes}>{buttonSession()}</div> : null;
});

SessionBtns.propTypes = {
  session: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default injectIntl(SessionBtns);
