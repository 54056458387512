import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import classNames from 'classnames';
import {useHistory, useLocation} from 'react-router-dom';
import TimeService from '../../../services/core/time-service';
import {AppContext} from '../../../store';
import useUserInvitation from '../../../hooks/useUserInvitation';
import useMedia from '../../../hooks/useMedia';
import MeetingDetailsView from '../../meeting/MeetingDetails/MeetingDetailsView';
import './AgendaCardMeeting.scss';

const AgendaCardMeeting = observer(props => {
  const {meeting, page} = props;
  const {userStore, modalStore, exhibitorStore, uiStore, invitationStore} = React.useContext(AppContext);
  const {invitations} = invitationStore;
  const [meBoothDetails, setMeeBoothDetails] = React.useState({});
  const {view} = exhibitorStore;
  const {myParticipantBooth} = userStore;
  const {pageTitle} = uiStore;
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMedia('(max-width: 768px)');
  const isBooth = invitations.find(inv => meeting && inv?.meeting?.id === meeting.id)?.toBoothId;
  const {state} = useUserInvitation(meeting.participants[0], isBooth);

  const classes = classNames('agenda-item agenda-item--meeting', {
    'agenda-item--past': meeting.expired || meeting.upcomingState === 'expired',
    'agenda-item--upcoming':
      !meeting.expired && meeting.startsAt > TimeService.now() && meeting.upcomingState !== 'expired',
    'agenda-item--live': meeting.upcomingState === 'started',
  });

  React.useEffect(() => {
    if (!myParticipantBooth && meeting.me.boothId && !Object.keys(meBoothDetails).length) {
      view.getExhibitorDetail(meeting.eventId, meeting.me.boothId).then(meBooth => setMeeBoothDetails(meBooth));
    }
  }, [meBoothDetails, myParticipantBooth]);

  const changeRoute = action => {
    if (isMobile) {
      if (page === 'invitations') {
        history.push({
          pathname: `/event/${meeting.eventId}/invitation/${meeting.participants[0].id}/${action}`,
          state: {
            background: location.state ? location.state.background : location,
            page: 'invitation',
            prevPage: pageTitle,
            isBooth: isBooth,
            state,
          },
        });
      } else if (page === 'agenda') {
        history.push({
          pathname: `/event/${meeting.eventId}/invitation/${meeting.participants[0].id}/${action}`,
          state: {
            background: location.state ? location.state.background : location,
            page: 'agenda',
            prevPage: pageTitle,
            isBooth: isBooth,
            state,
          },
        });
      } else {
        history.push(`/event/${meeting.eventId}/invitation/${meeting.participants[0].id}`);
      }
    } else {
      modalStore.open('attendeeMeet', {
        attendee: meeting.participants[0],
        meeting: meeting,
        action: action,
        isBooth: isBooth,
        state,
      });
    }
  };

  return (
    <div className={classes} onClick={props.onClick} aria-hidden>
      <MeetingDetailsView
        meeting={meeting}
        preloadLazyImages
        state={state}
        onClick={actionToBeExecuted => {
          if (
            actionToBeExecuted === 'cancelMeeting' ||
            actionToBeExecuted === 'requestReschedule' ||
            actionToBeExecuted === 'reschedulePending'
          ) {
            changeRoute(actionToBeExecuted);
          }
        }}
      />
    </div>
  );
});

AgendaCardMeeting.propTypes = {
  meeting: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default injectIntl(AgendaCardMeeting);
