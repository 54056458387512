import {observable, action, runInAction} from 'mobx';
import firebase from 'firebase/app';
import 'firebase/database';
import axios from 'axios';
import {cacheAdapterEnhancer, throttleAdapterEnhancer} from 'axios-extensions';
import {Settings} from 'luxon';

const http = axios.create({
  baseURL: '/',
  headers: {'Cache-Control': 'no-cache'},
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter)),
});

Settings.defaultLocale = localStorage.getItem('language') || 'en';

export class LanguageStore {
  @observable language = localStorage.getItem('language') || 'en';
  @observable messagesFromEvent = null;

  @action
  changeLanguage(value) {
    this.language = value;
    localStorage.setItem('language', value);
    Settings.defaultLocale = value;
  }

  @action
  loadTranslations(event) {
    return firebase
      .database()
      .ref(`newEvents/${event.id}/translationString`)
      .once('value')
      .then(data => {
        if (
          [
            '975a769f-0f09-4250-8215-758fd263b540',
            '6ab9ed55-8f1d-4113-b90e-0852bed031f6',
            'af732750-dddb-43dd-ae57-454a49f95ca5',
            'e9795940-d1bc-419c-ac4e-22ff2e806f37',
            '81d2fcdf-30bc-4942-b2ae-84a22114a62d',
            '400445c6-b2d7-4791-86ab-cea9a13de29b',
            '1dbe9f92-f5a2-48bf-af78-055eb26fc38e',
            '110d99c4-15cc-41b5-9844-a206ae8d80f4',
            '2a2585d6-0224-4c48-a2eb-679ce07cd926',
            '11fac6c7-e374-463b-97c9-dd9a2af8dd55',
            '88fbd659-4ac9-410a-96eb-939c6bded4c0',
            'b0b83bbc-4503-4a61-9d61-e059d655c40b',
            '52b7dacd-9133-49ef-a8cf-8433746f618d',
            'c3fc8f15-cd08-4b56-95d6-d1462538e60a',
            'b6877881-f398-4a89-b04a-b7330054b18a',
            '968cafa7-79fd-49f1-8517-abd12277eaff',
            'caa4a19a-eec1-492b-b0b9-fb1cd3485673',
            'f86e1498-f98b-4d16-9a82-e5e83a39dadf',
            '71f3a13f-8e07-4b84-8820-d80ddffc2961',
            '2efaf38f-65fc-4946-8e0b-15eef10e1c85',
            '08218d33-e55d-458c-90f1-4d14e97f1cc8',
            'f4f734fe-eda5-45b7-a6c5-14b2502991e6',
            '27a9da12-f635-4bd7-8b5c-62bfa3543efe',
            '8b3eddbf-edfc-4e0c-ac05-e08e7aef06aa',
            '52036188-8456-4aa2-8545-24cae4d2b6f9',
            '3798043b-f411-4b31-a27d-60bcd5bd9f63',
            'd0ebb351-05e8-4395-bf8a-765e13ef5b14',
            '826d3ca9-17c3-433b-b851-1e7d24716ee8',
            '2265d874-70b9-4200-8c04-65cae949c00b',
            'd4e9501f-3986-4b37-98da-8aebeaf2b153',
            '21232b12-93a5-4ca7-8a44-7c03bc29cfa8',
            '038ac61d-f62a-45e9-8d1e-c1c90d0da1c4',
            '0868b2ee-75d6-4735-a601-9bb7a1b6b97b',
            '366f7b3e-5bb0-4b37-8ab4-b32efb845d01',
            '1678ba94-c09c-4307-a332-f12cc331c084',
            '31e078b6-0796-4db2-8aad-fcd488f172df',
            '8f6e9c2d-b428-4690-af91-26944a4861fe',
            'bef57015-6e63-4df0-9da0-07122a110eae',
            '3c6e13f4-65e5-4bc9-840a-7a593a38e465',
            'fb3c8a65-be44-4dd0-adad-090d831c2b5b',
          ].includes(event.id)
        ) {
          http
            .get(`${process.env.PUBLIC_URL}/messagesForEvent/${event.id}.json`, {useCache: true})
            .then(res => {
              runInAction(() => {
                console.log(res.data);
                this.messagesFromEvent = res.data;
              });
            })
            .catch(() => {
              // setError('Error');
            });
        } else {
          if (data.val()) {
            runInAction(() => {
              this.messagesFromEvent = JSON.parse(data.val());
            });
          }
          if (this.language !== 'en') {
            this.changeLanguage(event.defaultLanguage);
          }
        }
      })
      .catch(e => e);
  }

  @action
  resetTranslationsForEvent() {
    this.messagesFromEvent = null;
  }
}
