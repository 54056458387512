import React, {useEffect, useState, useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, useIntl} from 'react-intl';
import {AppContext} from '../../../store';
import {Button, Dropdown, Form} from 'semantic-ui-react';
import ButtonLoading from '../../../ui/ButtonLoading';
import {subscribeBadgesForCheckIn} from '../../../services/firebase/checkInFbApi';
import {
  checkAllowedCheckIn,
  checkIn,
  contactFullName,
  getLastSelectedCheckInData,
  setScanTypeDataByArea,
} from '../../../services/helpers/checkInHelper';
import CheckInContact from '../CheckInContact';
import CheckinStatusSelector from '../CheckinStatusSelector';
import CheckinPrinterSelector from '../CheckinPrinterSelector';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import checkInConfig from '../checkInConfig';
import './CheckInScanResultForm.scss';
import useBadgePrinters from '../../../hooks/useBadgePrinters';

const CheckInScanResultForm = observer(props => {
  const {contact} = props;
  const {checkInStore, modalStore, eventStore} = useContext(AppContext);
  const intl = useIntl();
  const {areaList, scanTypeData} = checkInStore;
  const checkInFromAttendee = scanTypeData.checkInFromAttendee;
  const {eventDetails} = eventStore;
  const {filteredPrinterList, currentPrinter} = useBadgePrinters(eventDetails?.printers, eventDetails?.id, null, true);
  const showPrinterSelector = filteredPrinterList.length && eventDetails?.id && checkInFromAttendee;
  const [selectedPrinter, setSelectedPrinter] = useState('');
  const [checkInStatus, setCheckInStatus] = useState(true);
  const [status, setStatus] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const showPrintBtn =
    status === checkInConfig.requestStatuses.alreadyCheckedIn || status === checkInConfig.requestStatuses.checkedIn;

  useEffect(() => {
    if (checkInFromAttendee && areaList.length) {
      setSelectedArea(areaList.find(area => area.id === 'allEvent')?.id || '');
    }

    if (!selectedPrinter && currentPrinter.ip) {
      setSelectedPrinter(currentPrinter.ip);
    }
  }, [areaList, currentPrinter]);

  useEffect(() => {
    if (contact && !contact.ticketId && checkInFromAttendee) {
      subscribeBadgesForCheckIn(scanTypeData.eventId, snapshot => {
        const data = snapshot.val();
        const badge = Object.values(data).find(badge => contact.id === badge.id);
        badge && checkInStore.setScannedContact({...contact, ticketId: badge.ticketId});
      });
    }
  }, []);

  const handleCheckIn = () => {
    let postBody = {
      entityType: scanTypeData.entityType,
      entityId: scanTypeData.entityId,
      eventId: scanTypeData.eventId,
    };

    if (checkInFromAttendee) {
      const scanTypeDataByArea = setScanTypeDataByArea(selectedArea, scanTypeData.eventId);
      const allowedCheckIn = scanTypeDataByArea && checkAllowedCheckIn(scanTypeDataByArea, contact, false);
      if (!scanTypeDataByArea || !allowedCheckIn) return Promise.resolve();

      postBody = {
        entityType: scanTypeDataByArea.entityType,
        entityId: scanTypeDataByArea.entityId,
        eventId: scanTypeDataByArea.eventId,
      };
    }
    const selectedPrinter = getLastSelectedCheckInData(postBody.eventId, 'printer');

    contact.ticketId ? (postBody.ticketId = contact.ticketId) : (postBody.participantId = contact.id);
    postBody.printerLabel = selectedPrinter?.label;
    postBody.checkIn = checkInStatus;

    return checkIn(postBody)
      .then(res => {
        if (
          res === checkInConfig.requestStatuses.alreadyCheckedIn ||
          res === checkInConfig.requestStatuses.alreadyCheckedOut
        ) {
          NotificationAlert.warning(
            <FormattedMessage
              id={`checkIn.container.${res}.error`}
              values={{
                name: contactFullName(contact),
              }}
            />,
          );
        } else if (
          res === checkInConfig.requestStatuses.fully ||
          res === checkInConfig.requestStatuses.wrongTicketType
        ) {
          NotificationAlert.warning(<FormattedMessage id={`checkIn.container.${res}.error`} />);
        } else {
          NotificationAlert.success(
            <FormattedMessage
              id={`checkIn.container.${res}.success`}
              values={{
                name: contactFullName(contact),
              }}
            />,
          );
        }
        Object.values(checkInConfig.requestStatuses).includes(res) && setStatus(res);
      })
      .catch(() => {
        NotificationAlert.error(<FormattedMessage id="checkIn.container.checkIn.error" />);
      });
  };

  const areaSelectOptions = areas => {
    return areas.map(a => {
      return {
        key: a.id,
        flag: <span className="check-in-form__field--area-name">{a.name}</span>,
        text:
          a.capacity === a.capacityUsed &&
          a.id !== 'allEvent' &&
          intl.formatMessage({id: 'checkIn.form.area.fully.label'}),
        value: a.id,
      };
    });
  };

  const areaListField =
    checkInFromAttendee && areaList.length ? (
      <Form.Field className="check-in-form__field--area">
        <label htmlFor="area">
          <FormattedMessage id="checkIn.form.chooseArea.label" />
        </label>
        <Dropdown
          selection
          placeholder={intl.formatMessage({id: 'checkIn.form.chooseEvent.placeholder'})}
          options={areaSelectOptions(areaList)}
          id="area"
          onChange={(e, {value}) => {
            setSelectedArea(value);
          }}
          value={selectedArea}
        />
      </Form.Field>
    ) : null;

  const buttons = (
    <div className="check-in-result-form__btns">
      <Button
        color="blue"
        onClick={() => {
          if (checkInFromAttendee) {
            modalStore.close('checkInQrModal');
          } else {
            checkInStore.setMode(scanTypeData.scanMethod === 'camera' ? 'scan' : 'handheldScan');
          }
        }}
      >
        <FormattedMessage id="checkIn.scan.resultForm.scanNext.btn" />
      </Button>
      {showPrintBtn ? (
        <Button
          className="check-in-result-form__btns--print"
          color="blue"
          basic
          onClick={() => checkInStore.setMode('printBadge')}
        >
          <FormattedMessage id="checkIn.scan.resultForm.print.btn" />
        </Button>
      ) : null}
      <ButtonLoading color="blue" disabled={!!status} onClick={() => handleCheckIn()}>
        {checkInStatus ? (
          <FormattedMessage id="checkIn.scan.resultForm.checkIn.btn" />
        ) : (
          <FormattedMessage id="checkIn.scan.resultForm.checkOut.btn" />
        )}
      </ButtonLoading>
    </div>
  );

  const checkInSelector =
    scanTypeData.scanType !== 'fast' ? (
      <Form.Field>
        <CheckinStatusSelector onChangeStatus={setCheckInStatus} status={checkInStatus} />
      </Form.Field>
    ) : null;

  const printerSelector = showPrinterSelector ? (
    <Form.Field>
      <CheckinPrinterSelector
        value={selectedPrinter}
        onChange={setSelectedPrinter}
        printers={filteredPrinterList}
        eventId={eventDetails.id}
      />
    </Form.Field>
  ) : null;

  return contact ? (
    <div className="check-in-result-form">
      <h3>
        <FormattedMessage id="checkIn.scan.resultForm.title" />
      </h3>
      <CheckInContact contact={contact} checkInStatus={status} showPhoto />
      <Form>
        {areaListField}
        {printerSelector}
        {checkInSelector}
      </Form>
      {buttons}
    </div>
  ) : null;
});

export default CheckInScanResultForm;
