import React from 'react';
import {observer} from 'mobx-react-lite';
import Modal from '../../../../ui/Modal';
import {AppContext} from '../../../../store';
import Announcement from '../../../announcement/Announcement/Announcement';
import './AnnouncementInfoModal.scss';

const AnnouncementInfoModal = observer(() => {
  const {modalStore, announcementStore} = React.useContext(AppContext);
  const {open, announcement} = modalStore.modals.announcementInfo;

  return (
    <Modal className="announcement-info-modal" open={open} onClose={() => modalStore.close('announcementInfo')}>
      <Announcement
        announcement={announcement}
        participants={announcementStore.participant}
        announcementModal
        isModal
      />
    </Modal>
  );
});

export default AnnouncementInfoModal;
