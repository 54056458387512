import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Dimmer, Loader, Message, Segment} from 'semantic-ui-react';
import UserEmails from '../../components/account/UserEmails';
import {AppContext} from '../../store';
import UserEmailConnectForm from '../../components/account/UserEmailConnectForm';
import UserEmailVerificationForm from '../../components/account/UserEmailVerificationForm';
import './ConnectedEmails.scss';

const ConnectedEmails = observer(() => {
  const {userStore} = React.useContext(AppContext);
  const {connectedEmails, isSendToAddNewEmail} = userStore;
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    userStore.getConnectedEmails().then(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Segment className="connected-emails__loading">
        <Dimmer active inverted>
          <Loader inverted>
            <FormattedMessage id="global.loading" />
          </Loader>
        </Dimmer>
      </Segment>
    );
  }

  return (
    <div className="connected-emails">
      {connectedEmails.length > 0 ? (
        <h1 className="connected-emails__title">
          <FormattedMessage id="user.email.title" />
        </h1>
      ) : (
        <Message info>
          <FormattedMessage id="user.emails.no.connected.emails" />
        </Message>
      )}
      {isSendToAddNewEmail ? <UserEmailVerificationForm /> : <UserEmailConnectForm />}
      <UserEmails />
    </div>
  );
});

export default injectIntl(ConnectedEmails);
