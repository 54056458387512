import React from 'react';
import {observer} from 'mobx-react-lite';
import {Dropdown, Form, Message, Card, Icon, Button} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {useForm, FormProvider} from 'react-hook-form';
import {FormattedMessage, injectIntl} from 'react-intl';
import {AppContext} from '../../../store';
import generateQR from '../../../utils/QRgenerate';
import './QrGenerateForm.scss';
import {ReactComponent as ScanImg} from '../../../assets/images/icons/header/scan.svg';

const QrGenerateForm = observer(() => {
  const {eventStore, userStore, modalStore} = React.useContext(AppContext);
  const {eventDetails, activeEvents} = eventStore;
  const {user} = userStore;
  const methods = useForm();
  const qrElRef = React.useRef(null);
  const [selectedEventId, setSelectedEventId] = React.useState('');
  const [meDetails, setMeDetails] = React.useState(null);

  React.useEffect(() => {
    if (meDetails?.ticketId && qrElRef.current) {
      generateQR(meDetails.ticketId, {}, qrElRef.current);
    }
  }, [meDetails]);

  React.useEffect(() => {
    if (!selectedEventId) {
      if (eventDetails) {
        setSelectedEventId(eventDetails.id);
      } else {
        Object.keys(activeEvents).length && setSelectedEventId(activeEvents[0].id);
      }
    }

    return () => {
      setSelectedEventId(null);
    };
  }, [eventDetails, activeEvents]);

  React.useEffect(() => {
    if (selectedEventId) {
      if (eventDetails) {
        setMeDetails(eventDetails.me);
      } else {
        eventStore.getEventDetails(selectedEventId, true).then(event => {
          if (event.me && event.me.ticketId) {
            setMeDetails(event.me);
          } else {
            setMeDetails(null);
          }
        });
      }
    }

    return () => {
      setMeDetails(null);
    };
  }, [selectedEventId]);

  const options = events => {
    return events.map(event => {
      return {
        key: event.id,
        text: event.name,
        value: event.id,
      };
    });
  };

  const eventSelector = !eventDetails?.id && (
    <Dropdown
      selection
      placeholder="Please select event"
      options={options(activeEvents)}
      onChange={(e, {value}) => {
        setSelectedEventId(value);
      }}
      defaultValue={selectedEventId}
    />
  );

  const qrGenerator =
    meDetails && meDetails.ticketId ? (
      <div className="qr-generate-form__qr-code">
        <div ref={qrElRef} className="qr-generate-form__qr-code" />
        <strong>{meDetails.ticketId}</strong>
      </div>
    ) : (
      <Message info>
        <FormattedMessage id="qr.formGenerator.ticketId.error" />
      </Message>
    );

  const scanQrButton = (
    <Button color="blue" className="qr-generate-form__scan-qr" onClick={() => modalStore.open('addContactByQrModal')}>
      <ScanImg /> <FormattedMessage id="qr.formGenerator.ScanQR.button" />
    </Button>
  );

  const contactCard = meDetails && (
    <Card>
      <Card.Content>
        <Card.Header className="ui">
          {meDetails.surname ? `${meDetails.name} ${meDetails.surname}` : meDetails.name}
        </Card.Header>
        <Card.Meta>
          {meDetails.jobTitle}
          {meDetails.jobTitle && meDetails.company ? ` | ${meDetails.company}` : meDetails.company}
        </Card.Meta>
        <Card.Description>
          {meDetails.phone && (
            <div>
              <Icon name="phone" /> {meDetails.phone}
            </div>
          )}
          {user.username && (
            <div>
              <Icon name="mail" /> {user.username}
            </div>
          )}
          {meDetails.website && (
            <div>
              <Icon name="globe" /> {meDetails.website}
            </div>
          )}
        </Card.Description>
      </Card.Content>
    </Card>
  );

  return selectedEventId ? (
    <FormProvider {...methods}>
      <Form className="qr-generate-form" error={!isEmpty(methods.errors)}>
        <div className="qr-generate-form__wrapper">
          {eventSelector}
          <div className="qr-generate-form__result">
            {qrGenerator}
            {scanQrButton}
            {contactCard}
          </div>
        </div>
      </Form>
    </FormProvider>
  ) : null;
});

export default injectIntl(QrGenerateForm);
