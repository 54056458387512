import React from 'react';
import PropTypes from 'prop-types';
import {Button as ButtonSemantic} from 'semantic-ui-react';
import classNames from 'classnames';
import NotificationAlert from '../../components/notifications-alert/NotificationAlert';

const ButtonLoading = props => {
  const {children, className, onClick, color, disabled, basic, size} = props;
  const [isLoading, setLoading] = React.useState(false);

  const classes = classNames(className, {
    'btn-loading': isLoading,
  });

  React.useEffect(() => {
    let isMounted = true;
    if (isLoading) {
      onClick()
        .then(data => {
          if (isMounted) {
            setLoading(false);
          }
          return data;
        })
        .catch(error => {
          setLoading(false);
          if (typeof error === 'string') {
            NotificationAlert.error(error, {
              autoClose: 8000,
            });
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [isLoading]);

  const handleClick = e => {
    e.preventDefault();
    setLoading(true);
  };

  return (
    <ButtonSemantic
      className={classes}
      onClick={!isLoading ? handleClick : null}
      disabled={disabled || isLoading}
      loading={isLoading}
      color={color}
      basic={basic}
      size={size}
    >
      {children}
    </ButtonSemantic>
  );
};

ButtonLoading.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  basic: PropTypes.bool,
  size: PropTypes.string,
};

ButtonLoading.defaultProps = {
  children: '',
  onClick: () => {},
  className: '',
  color: 'teal',
  disabled: false,
  basic: false,
  size: null,
};

export default ButtonLoading;
