import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button} from 'semantic-ui-react';
import {useLocation, useHistory} from 'react-router-dom';
import {AppContext} from '../../../store';
import useMedia from '../../../hooks/useMedia';
import './AgendaCardSession.scss';
import ButtonLoading from '../../../ui/ButtonLoading';
import MeetingOfApi from '../../../services/api/meetingOfApi';

const AgendaCardSession = observer(props => {
  const {session, attendee} = props;
  const {uiStore, userStore} = React.useContext(AppContext);
  const {pageTitle} = uiStore;
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMedia('(max-width: 768px)');
  const {user} = userStore;

  return (
    <div className="agenda-details agenda-session">
      <div className="agenda-item__details">
        <div className="agenda-item__name">
          <FormattedMessage
            id="agenda.item.waitingConfirmation"
            values={{
              session: session.name,
            }}
          />
        </div>
      </div>
      <div className="agenda-item__action">
        <Button
          className="agenda-btn__details link"
          color="blue"
          basic
          onClick={() => {
            if (isMobile) {
              history.push({
                pathname: `/my-agenda/session/${session.eventId}/${session.rsesId || session.sdefId}`,
                state: {
                  background: location,
                  page: 'agenda',
                  title: session.name,
                  prevPage: pageTitle,
                },
              });
            } else {
              history.push({
                pathname: `/event/${session.eventId}/sessions/${session.rsesId || session.sdefId}`,
                state: {
                  background: location,
                  page: 'sessions',
                  title: session.name,
                },
              });
            }
          }}
        >
          <FormattedMessage id="agenda.item.viewDetails.btn" />
        </Button>
        <ButtonLoading
          className="agenda-btn__calendar-remove"
          basic
          color="red"
          onClick={() => MeetingOfApi.deleteOffTime(user.id, session.eventId, session, attendee?.id)}
        >
          <FormattedMessage id="agenda.item.removeFromAgenda.btn" />
        </ButtonLoading>
      </div>
    </div>
  );
});

AgendaCardSession.propTypes = {
  session: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default injectIntl(AgendaCardSession);
