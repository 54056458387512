import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {Popup} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import AnnouncementApi from '../../../services/api/announcementApi';
import ImageLazy from '../../../ui/ImageLazy';
import userImg from '../../../assets/images/user.jpg';
import ParserText from '../../../ui/ParserText';
import './AnnouncementComment.scss';

const AnnouncementComment = observer(props => {
  const {comment} = props;
  const {participantStore, eventStore} = React.useContext(AppContext);
  const {participantsMap} = participantStore;
  const {meParticipant} = eventStore;
  const [openEditPopup, setOpenEditPopup] = React.useState(false);
  const [participant, setParticipant] = useState({});
  const isAdmin = meParticipant.organizer;

  useEffect(() => {
    const participantFromStore = participantsMap[comment.participantId];
    if (!participantFromStore) {
      participantStore.getParticipantDetails(comment.participantId).then(data => {
        setParticipant(data);
      });
    } else {
      setParticipant(participantFromStore);
    }
  }, [comment.participantId]);

  const deleteComment = comment => {
    AnnouncementApi.deleteComment(comment)
      .then(() => {
        NotificationAlert.success(<FormattedMessage id="announcement.comment.delete.success" />);
      })
      .catch(() => {
        NotificationAlert.error(<FormattedMessage id="announcement.comment.delete.error" />);
      });
  };

  return (
    <div className="comment">
      <div className="comment__author">
        <div className="comment__img">
          <ImageLazy src={participant.profilePhoto || userImg} errorSrc={userImg} alt={participant.fullName} />
        </div>
        <div className="comment__details">
          <span className="comment__name">{participant.fullName || '...'}</span>
          <span className="comment__position">{participant.jobTitle}</span>
          {participant.company ? ', ' : null}
          <span className="comment__company">{participant.company}</span>
        </div>
      </div>
      <div className="comment__message">
        <ParserText>{comment.text}</ParserText>
      </div>
      {meParticipant.id === comment.participantId || isAdmin ? (
        <div className="comment__edit">
          <Popup
            trigger={<i className="ellipsis horizontal icon" />}
            content={
              <div role="list" className="comment-edit">
                <div aria-hidden className="comment-edit__remove" onClick={() => deleteComment(comment)}>
                  <FormattedMessage id="announcement.comment.btn.remove" />
                </div>
              </div>
            }
            offset={[3, 0]}
            position="bottom right"
            on="click"
            className="comment__popup"
            onClose={() => setOpenEditPopup(false)}
            onOpen={event => {
              event.stopPropagation();
              setOpenEditPopup(true);
            }}
            open={openEditPopup}
            hideOnScroll
          />
        </div>
      ) : null}
    </div>
  );
});

AnnouncementComment.propTypes = {
  comment: PropTypes.oneOfType([PropTypes.object]),
};

export default injectIntl(AnnouncementComment);
