import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {LocalStore} from '../../../../services/core/storage-service';
import {AppContext} from '../../../../store';
import Modal from '../../../../ui/Modal';
import PresenceConfirmationForm from '../../../lobby/PresenceConfirmationForm';
import NotificationAlert from '../../../notifications-alert/NotificationAlert';

const PresenceConfirmationModal = observer(() => {
  const {eventStore, modalStore} = React.useContext(AppContext);
  const onSubmitFunction = React.useRef(() => {});
  const {eventDetails, meParticipant, isParticipantUpdated} = eventStore;
  const {open} = modalStore.modals.presenceConfirmationModal;
  const {eventType, isFinished} = !!eventDetails && eventDetails;
  const eventProfileIsFilled =
    eventDetails &&
    Object.keys(LocalStore.getObject('filledEventProfileForEvents')).some(eventId => eventId === eventDetails.id);
  const isActualEvent = eventType === 'hybrid' && !isFinished;
  const history = useHistory();

  React.useEffect(() => {
    eventDetails &&
      isActualEvent &&
      !history.location.pathname.endsWith('/event-profile') &&
      Math.floor(meParticipant.lastPresenceConfirmTime + 86400000) <= Date.now() &&
      eventProfileIsFilled &&
      modalStore.open('presenceConfirmationModal');
  }, [eventDetails]);

  const handleClose = () => {
    const presenceIsEmpty = isActualEvent && !meParticipant?.present && !isParticipantUpdated;

    if (presenceIsEmpty) {
      history.push('/event/list');
      NotificationAlert.error(<FormattedMessage id="participant.form.presenceStatus.error" />, {
        autoClose: 8000,
      });
      modalStore.close('presenceConfirmationModal');
    } else {
      onSubmitFunction.current({present: meParticipant.present});
      isParticipantUpdated && modalStore.close('presenceConfirmationModal');
    }
  };

  return open ? (
    <Modal className="presence-confirmation-modal" open={true} onClose={() => handleClose()}>
      <PresenceConfirmationForm onSubmitFunction={onSubmitFunction} />
    </Modal>
  ) : null;
});

export default PresenceConfirmationModal;
