import React from 'react';
import {FormattedMessage} from 'react-intl';
import NotificationAlert from '../../components/notifications-alert/NotificationAlert';
import messages from '../../components/session/SessionBtns/SessionBtns.messages';
import {stores} from '../../store';

export async function showNotificationByAgenda(session) {
  if (session.offType === 'break') {
    return null;
  } else if (session.type === 'dealroom') {
    if (session.participants.length > 0) {
      NotificationAlert.success(
        <FormattedMessage
          {...messages.sessionMeetingOpponentSuccess}
          values={{
            me: `${session.me.name} ${session.me.surname}`,
            opponent: session.participants[0].fullName,
          }}
        />,
      );
    }
  } else {
    const message = session.blocking ? messages.sessionTextSuccessRequested : messages.sessionTextSuccess;
    NotificationAlert.success(
      <FormattedMessage
        {...message}
        values={{
          name: (
            <span
              className="session__success close-icon"
              aria-hidden
              onClick={() => {
                stores.agendaStore.data.showAgenda(true);
              }}
            >
              {session.name}
            </span>
          ),
          time: session.sdts.hour,
          date: session.sdts.date,
        }}
      />,
    );
  }
}
