import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage} from 'react-intl';
import Modal from '../../../../ui/Modal';
import {AppContext} from '../../../../store';
import CompanyAddMemberToCompanyForm from '../../../company/CompanyAddMemberToCompanyForm';
import './AddMembersToCompanyModal.scss';

const AddMembersToCompanyModal = observer(() => {
  const {modalStore, participantStore, eventStore, userStore} = React.useContext(AppContext);
  const {open} = modalStore.modals.addMembersToCompanyModal;
  const {eventDetails} = eventStore;

  React.useEffect(() => {
    if (open) {
      eventStore.getEventDetails(eventDetails.id).then(eDetails =>
        userStore.setQueryUser(eDetails, eDetails.me).then(() => {
          const query = {query: '(NOT(_exists_:boothId) AND organizer:false) AND (*:*)', initialLength: 10, length: 10};
          participantStore.setQuery(query, true);
          return participantStore.getParticipants(eDetails, true, true);
        }),
      );
    }
  }, [open]);

  return (
    <Modal
      className="add-member-to-company-modal"
      open={open}
      onClose={() => modalStore.close('addMembersToCompanyModal')}
      size="tiny"
    >
      <h3>
        <FormattedMessage id="add.members.to.company.modal.title" />
      </h3>
      <CompanyAddMemberToCompanyForm />
    </Modal>
  );
});

export default AddMembersToCompanyModal;
