import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Icon} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import ContactsHeader from '../../contact/ContactsHeader';
import ContactList from '../../contact/ContactList';
import Drawer from '../../shared/Drawer';
import {ReactComponent as BackImg} from '../../../assets/images/icons/arrow.svg';
import {ReactComponent as ContactsImg} from '../../../assets/images/icons/header/contacts.svg';
import ContactForm from '../../contact/ContactForm';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import './ContactsSidebar.scss';

const ContactsSidebar = observer(() => {
  const {contactStore, userStore, uiStore} = React.useContext(AppContext);
  const {scannedContacts, filteredContacts, filters, matchingContacts, mode} = contactStore;
  const {user} = userStore;
  const {isMenuCollapsed} = uiStore;
  const contactList = !isEmpty(filters) ? filteredContacts : scannedContacts;
  const [showContacts, setShowContacts] = React.useState(false);
  const [contact, setContact] = React.useState(null);
  const [step, setStep] = React.useState(1);

  React.useEffect(() => {
    contactStore.list(user.id);
  }, []);

  React.useEffect(() => {
    if (matchingContacts.length && mode === 'foundContact') {
      setStep(1);
      NotificationAlert.info(<FormattedMessage id="contact.sidebar.matchingContacts" />);
      contactStore.showDetails(matchingContacts[0]);
      setContact(matchingContacts[0]);
      setTimeout(() => setStep(2));
    }
  }, [matchingContacts]);

  const handleClose = async () => {
    setShowContacts(false);
  };

  const handleOpenContact = async c => {
    setContact(c);
    setStep(2);
  };

  return (
    <div className={classNames('nav-menu__item nav-menu__item--contacts', {active: showContacts})}>
      <div className="nav-menu__button" role="button" aria-hidden="true" onClick={() => setShowContacts(true)}>
        <div className="nav-menu__item-icon">
          <ContactsImg />
        </div>
        <p className="nav-menu__item-text">
          <FormattedMessage id="contact.sidebar.menuTitle" />
        </p>
        <Icon name="angle right" />
      </div>
      <Drawer show={showContacts} close={handleClose} leftOffset={isMenuCollapsed} classes="contacts-drawer">
        <div
          className={classNames('contacts-scanned', {
            'contacts-scanned--step1': step === 1,
            'contacts-scanned--step2': step === 2,
          })}
        >
          <ContactsHeader />
          {step === 1 ? <ContactList contacts={contactList} openContact={handleOpenContact} /> : null}
          {step === 2 ? (
            <div className="contacts-scanned__select">
              <Button className="contacts-scanned__back" onClick={() => setStep(1)}>
                <BackImg /> Back to the list
              </Button>
              <ContactForm contact={contact} />
            </div>
          ) : null}
        </div>
      </Drawer>
    </div>
  );
});

export default injectIntl(ContactsSidebar);
