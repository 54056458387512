import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Dimmer, Loader, Segment} from 'semantic-ui-react';
import NotificationList from '../NotificationList';
import {AppContext} from '../../../store';
import {ReactComponent as NotificationsImg} from '../../../assets/images/icons/header/notificationsFull.svg';
import './NotificationContent.scss';

const NotificationContent = observer(() => {
  const {activityStore, eventStore, userStore} = React.useContext(AppContext);
  const {notifications, countNotificationUnRead, tags, isLoading} = activityStore;
  const {eventList} = eventStore;

  const content = () => {
    if (!eventList || isLoading) {
      return (
        <Segment className="notifications__loader">
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        </Segment>
      );
    }

    if (notifications && notifications.length > 0) {
      return <NotificationList notifications={notifications} tags={tags} />;
    }

    return (
      <div className="notifications__empty">
        <div className="notifications__empty-img">
          <NotificationsImg />
        </div>
        <div className="notifications__empty-text">
          <FormattedMessage id="notification.content.no.notifications" />
        </div>
      </div>
    );
  };

  return (
    <div className="notifications">
      <div className="notifications__wrapper">
        <h1 className="notifications__title">Notifications</h1>
        <div
          className="notifications__all-read"
          onClick={() => (countNotificationUnRead ? activityStore.markAllRead(userStore.user.id) : null)}
          aria-hidden
        >
          {notifications ? (
            <span>
              <FormattedMessage id="notification.content.mark.as.read" values={{value: countNotificationUnRead || 0}} />
            </span>
          ) : null}
        </div>
      </div>
      {content()}
    </div>
  );
});

export default injectIntl(NotificationContent);
