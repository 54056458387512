import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Dimmer, Loader, Segment, Message} from 'semantic-ui-react';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import throttle from 'lodash/throttle';
import {Html5Qrcode, Html5QrcodeSupportedFormats} from 'html5-qrcode';
import UtilsService from '../../../services/core/utils-service';
import './QrScanner.scss';

const QrScanner = observer(props => {
  const {windowSizeStore} = React.useContext(AppContext);
  const {
    config,
    qrCodeSuccessCallback,
    qrCodeErrorCallback,
    qrAreaId,
    showScanResult,
    scanThrottling,
    className,
    scanFormats,
  } = props;
  const isMobile = windowSizeStore.windowSizes === 'mobile';
  const [error, setError] = useState(null);
  const [qrScanner, setQrScanner] = useState(null);
  const [scanStatus, setScanStatus] = useState('scanNotStarted');
  const [scanResult, setScanResult] = useState(null);
  const containerRef = useRef();

  const findHeight = (ratio = '', width = 1) => {
    const [w, h] = ratio.split(':').map(Number);
    const height = (width * h) / w;
    return Math.round(height);
  };

  const containerSize = {
    width: '100%',
    minHeight: containerRef.current ? findHeight('4:3', containerRef.current.offsetWidth) + 'px' : 'auto',
  };

  useEffect(() => {
    initScan();

    return () => {
      if (qrScanner?.isScanning) {
        qrScanner.stop();
      }
    };
  }, [qrScanner]);

  const errorHandle = e => {
    console.log(e, 'e');
    setError(typeof e !== 'object' ? e : null);
    qrCodeErrorCallback(e);
  };

  const initScan = () => {
    if (!qrScanner) {
      setScanStatus('scanInit');
      const scanConfig = {};
      const filteredFormats = scanFormats.map(format => Html5QrcodeSupportedFormats[format]);

      if (filteredFormats.length) {
        scanConfig.formatsToSupport = filteredFormats;
      }

      setQrScanner(new Html5Qrcode(qrAreaId, scanConfig));
    } else {
      setError(null);
      Html5Qrcode.getCameras()
        .then(devices => {
          const cameras = [];
          devices.forEach(camera => {
            let option = {};
            option.value = camera.id;
            option.text = camera.label;
            cameras.push(option);
          });
          if (cameras.length) {
            startScan();
          }
        })
        .catch(e => {
          errorHandle(e);
        });
    }
  };

  const setSuccessCallback = throttle(
    (result, type) => {
      qrCodeSuccessCallback(result, type);
    },
    scanThrottling,
    {leading: true, trailing: false},
  );

  const startScan = () => {
    setScanStatus('scanStarted');
    qrScanner
      .start({facingMode: isMobile ? 'environment' : 'user'}, config, result => {
        const next = UtilsService.parseVCard(result);
        try {
          if (next.jCardContact) {
            setScanResult(next.jCardContact);
            setSuccessCallback(result, 'vCard');
          } else if (!/\s/g.test(result)) {
            setScanResult(result);
            setSuccessCallback(result, 'string');
          } else {
            setScanResult(result);
            setSuccessCallback(result, 'text');
          }
        } catch (e) {
          errorHandle(e);
        }
      })
      .catch(e => {
        errorHandle(e);
      });
  };

  const loader =
    scanStatus !== 'scanStarted' && !error ? (
      <Segment style={containerSize} className="contact-scan-window__loader">
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      </Segment>
    ) : null;

  const scannedData =
    scanResult && showScanResult ? (
      <div className="qr-scanner__result">
        Detected QR / Barcode code: <span>{scanResult}</span>
      </div>
    ) : null;

  const errorText = error ? (
    <Message negative>
      <p>{error}</p>
    </Message>
  ) : null;

  return (
    <div ref={containerRef} style={containerSize} className={classNames('qr-scanner', className)}>
      {loader}
      <div id={qrAreaId} />
      {errorText}
      {scannedData}
    </div>
  );
});

QrScanner.propTypes = {
  qrCodeSuccessCallback: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  qrCodeErrorCallback: PropTypes.func,
  qrAreaId: PropTypes.string,
  showScanResult: PropTypes.bool,
  scanThrottling: PropTypes.number,
  className: PropTypes.string,
  scanFormats: PropTypes.array,
};

QrScanner.defaultProps = {
  qrCodeErrorCallback: () => {},
  qrAreaId: 'reader',
  showScanResult: false,
  scanThrottling: 0,
  scanFormats: [],
};

export default injectIntl(QrScanner);
