import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Loader, Dimmer, Button} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';
import ImageLazy from '../../../ui/ImageLazy';
import {AppContext} from '../../../store';
import createVideo from '../../../utils/embedVideo';
import {HostUrl} from '../../../services/core/axios-service';
import getDomain from '../../../utils/getDomain';
import InvitationButton from '../../invitations/InvitationButton';
import PopupTables from '../../shared/Table';
import PartnerQuestions from '../PartnerQuestions';
import companyImg from '../../../assets/images/defaultImages/company-logo.png';
import attendeeImg from '../../../assets/images/defaultImages/attendee.png';
import './PartnerDetails.scss';

const PartnerDetails = props => {
  const {partnerDetails, members, owner, membersLimit} = props;
  const {images, documents, website, name, description, some, popupMeetings} = partnerDetails;
  const {eventStore, uiStore, agendaStore} = React.useContext(AppContext);
  const {meParticipant} = eventStore;
  const {pageTitle} = uiStore;
  const {myAgendaList} = agendaStore.data;
  const liveAgenda = myAgendaList?.filter(s => !s.expired || s.upcomingState !== 'expired');
  const {eventId} = useParams();
  const location = useLocation();
  const history = useHistory();
  const [fullListView, setFullListView] = useState(true);
  const [attendeeState, setAttendee] = useState(null);

  React.useEffect(() => {
    if (Object.keys(members).length > membersLimit) {
      setFullListView(false);
    }
  }, [members, owner, membersLimit]);

  React.useEffect(() => {
    Object.keys(members).forEach(member => {
      const memberDetails = members[member];
      if (liveAgenda.some(a => a.prts.includes(memberDetails.id))) {
        setAttendee(memberDetails);
      }
    });
  }, [members, liveAgenda]);

  const parseHtml = htmlParser({
    isValidNode: node => node.type !== 'script',
  });

  return partnerDetails && members && owner ? (
    <div className="partner-details">
      <div className="partner-details__banner">
        {documents && documents.slice()[0]?.type === 'bvideo' ? (
          <Fragment>
            {/* eslint-disable react/no-danger */}
            <div
              dangerouslySetInnerHTML={{
                __html: createVideo(documents[0].url, '100%', '139px', true, true),
              }}
            />
            {/* eslint-enable */}
          </Fragment>
        ) : (
          <Fragment>
            {images && images.banner ? (
              <ImageLazy
                src={images.banner}
                errorSrc="https://console.dealroomevents.com/assets/images/company-banner1.png"
                alt={name}
                width={220}
                height={257}
              />
            ) : (
              <img
                src="https://console.dealroomevents.com/assets/images/company-banner1.png"
                alt={name}
                width={220}
                height={257}
              />
            )}
          </Fragment>
        )}
      </div>
      <div className="partner-details__content">
        <div className="partner-details__info">
          <div className="partner-details__img">
            {images && images.logo ? (
              <img aria-hidden src={images.logo} alt={name} />
            ) : (
              <img aria-hidden src={companyImg} alt={name} />
            )}
          </div>
          <div className="partner-details__user">
            <div className="partner-details__user-details">
              <p className="partner-details__label">
                <FormattedMessage id={`partner.${partnerDetails.boothType}`} />
              </p>
              <h2 className="partner-details__title">{partnerDetails && partnerDetails.boothName}</h2>
              <p>{partnerDetails.seek}</p>
              {partnerDetails.location ? (
                <p className="partner-details__location">
                  <FormattedMessage
                    id="partner.location"
                    values={{
                      span: chunks => <span>{chunks}</span>,
                      location: partnerDetails.location,
                    }}
                  />
                </p>
              ) : null}
              <a href={website} rel="noopener noreferrer" target="_blank" className="partner-card__web-site">
                {getDomain(partnerDetails.website)}
              </a>
            </div>
            {some && (
              <div className="partner-details__socials">
                {some
                  ? Object.keys(some).map(social => (
                      <a
                        key={social}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={some[social]}
                        className={`ui ${social} circular icon button`}
                      >
                        <i className={`${social} icon`} />
                      </a>
                    ))
                  : null}
              </div>
            )}
          </div>
          <div className="partner-details__meet">
            {meParticipant?.boothId !== partnerDetails.id ? (
              <InvitationButton attendee={attendeeState || owner} type="partner" />
            ) : null}
          </div>
          {meParticipant?.boothId === partnerDetails.id ? (
            <Button
              className="partner-details__edit"
              onClick={() => {
                history.push({
                  pathname: `/event/${eventId}/company/profile`,
                });
              }}
            >
              <FormattedMessage id="partner.details.edit" />
            </Button>
          ) : null}
        </div>
        {description ? (
          <div className="partner-details__description">
            <ReactMarkdown allowDangerousHtml astPlugins={[parseHtml]}>
              {description || null}
            </ReactMarkdown>
          </div>
        ) : null}
      </div>
      {members && Object.keys(members).length > 0 ? (
        <div className="partner-details__members">
          <h4 className="partner-details__members-title">Members:</h4>
          <div className="partner-details__members-list">
            {Object.keys(members)
              .slice(0, !fullListView ? membersLimit : Object.keys(members).length)
              .map(member => {
                const memberInfo = members[member];

                return (
                  <div key={memberInfo.id} className="partner-details__member">
                    <Link
                      to={{
                        pathname: `/event/${eventId}/attendees/${memberInfo.id}`,
                        state: {
                          background: location.state.background,
                          page: 'attendees',
                          title: memberInfo.name,
                          prevPage: pageTitle,
                        },
                      }}
                    >
                      <div className="partner-details__member-img">
                        {memberInfo.profilePhoto ? (
                          <ImageLazy
                            src={`${HostUrl}/${memberInfo.profilePhoto}`}
                            errorSrc={attendeeImg}
                            alt={memberInfo.name}
                          />
                        ) : (
                          <img aria-hidden src={attendeeImg} alt={owner.name} />
                        )}
                      </div>
                    </Link>
                    <div className="partner-details__member-info">
                      <p className="partner-details__member-name">
                        {memberInfo.name} {memberInfo.surname}
                      </p>
                      <p className="partner-details__member-position">{memberInfo.jobTitle}</p>
                    </div>
                  </div>
                );
              })}
          </div>
          {Object.keys(members).length > membersLimit ? (
            <div className="partner-details__members-switcher">
              {!fullListView ? (
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    setFullListView(true);
                  }}
                >
                  Show all
                </a>
              ) : (
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    setFullListView(false);
                  }}
                >
                  Hide
                </a>
              )}
            </div>
          ) : null}
        </div>
      ) : null}
      <PartnerQuestions questionAnswers={partnerDetails.categories} />
      {popupMeetings ? (
        <div className="partner-card__popup_meetings">
          <PopupTables popupMeetings={popupMeetings} />
        </div>
      ) : null}
    </div>
  ) : (
    <div className="partner-details__loading">
      <Dimmer active inverted>
        <Loader size="medium">
          <FormattedMessage id="global.loading" />
        </Loader>
      </Dimmer>
    </div>
  );
};

PartnerDetails.propTypes = {
  partnerDetails: PropTypes.oneOfType([PropTypes.object]),
  members: PropTypes.oneOfType([PropTypes.object]),
  owner: PropTypes.oneOfType([PropTypes.object]),
  membersLimit: PropTypes.number,
  textLimit: PropTypes.number,
};

PartnerDetails.defaultProps = {
  partnerDetails: {},
  members: {},
  owner: {},
  membersLimit: 9,
  textLimit: 500,
};

export default PartnerDetails;
