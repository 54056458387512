import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Button, Dropdown, Form, Icon, Loader, Radio} from 'semantic-ui-react';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import Modal from '../../../ui/Modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {AppContext} from '../../../store';
import ButtonLoading from '../../../ui/ButtonLoading';
import './PreBookedMeeting.scss';
import isEmpty from "lodash/isEmpty";
import {
  ParticipantStaticFields
} from "../../lobby/ParticipantDetailsForm/ParticipantFields";
import UniversalField from "../../shared/UniversalField/UniversalField";
import NotificationAlert from "../../notifications-alert/NotificationAlert";
import EventApi from "../../../services/api/eventApi";

const InviteParticipantModal = observer(props => {
  const {eventStore, agendaStore, userStore, participantStore, modalStore} = React.useContext(AppContext);
  const {open} = modalStore.modals.inviteAParticipantModal;
  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const [participantType, setParticipantType] = React.useState('');
  const {
    categoriesList,
    selectedWizardStep,
    stepsForWizard,
    preselectSelectWizardSteps,
    selectedParticipantType,
    disabledToChangeParticipantType,
  } = participantStore;
  const {eventDetails, eventList} = eventStore;
  const onSubmit = value => {
    console.log(value)
    setLoading(true);
    EventApi
    .inviteParticipant(eventDetails, value)
    .then(() => {
      NotificationAlert.success('Successfully invited participant.');
      methods.reset()
      methods.reset({categories:{}})
      setLoading(false);
      closeModal()
    })
    .catch(err => {
      console.log('er', {err});
      NotificationAlert.error('Could not invited participant. ' + err.message);
      setLoading(false);
    });
  };
  const intl = useIntl();
  const {user} = userStore;
  const [meParticipant, setMeParticipant] = useState({categories:[]})
  const participantTypes =
    eventDetails && eventDetails.participantTypes
      ? Object.keys(eventDetails.participantTypes)
      .map(type => {
        const pType = eventDetails.participantTypes[type];
        return {
          label: pType.texts.en,
          value: pType.id,
          checked: false,
        };
      })
      : [];
  const  closeModal = ()=>{
    modalStore.close('inviteAParticipantModal')
  }
  return (
    <Modal
      className="pre-booked-meeting-modal"
      open={open}
      onClose={() => closeModal()}
    >
      <h3>
        <FormattedMessage id="inviteParticipant.modal.header" />
      </h3>


      <div className="pre-booked-meeting">
        {
          eventDetails?<FormProvider {...methods}>
            <Form
              className="participant-details-form"
              error={!isEmpty(methods.errors)}
              onSubmit={methods.handleSubmit(() => {})}
            >
              <div className="participant-details-form__personal">
                <div className="participant-details-form__fields equal width fields">
                  {ParticipantStaticFields.participant.map(field => {
                    return <UniversalField
                      key={field.fieldName}
                      label={field.fieldName}
                      name={field.fieldName}
                      type={field.type}
                      defaultValue={meParticipant[field.fieldName]}
                      required={field.required}
                      dataList={field.dataList}
                      className={field.className}
                      fields={field.fields}
                      placeholder={field.fieldName}
                      error={methods.errors[field.fieldName]}
                    />
                  })
                  }
                </div>
              </div>

              <div className="participant-details-form__company">
                <div className="participant-details-form__fields equal width fields">
                  {ParticipantStaticFields.company.map(field => {
                    // TODO: Remove in the future, custom feature for the event
                    const showOptionalLabelForEvent = !(field.fieldName === 'phone' && eventDetails.id);
                    // eslint-disable-next-line no-nested-ternary
                    return field.fieldName !== 'present' ? (
                      <UniversalField
                        key={field.fieldName}
                        label={field.fieldName}
                        name={field.fieldName}
                        type={field.type}
                        defaultValue={meParticipant[field.fieldName] || ''}
                        required={field.required}
                        dataList={field.dataList}
                        className={field.className}
                        fields={field.fields}
                        disabled={eventDetails.isFinished}
                        placeholder={field.fieldName}
                        error={methods.errors[field.fieldName]}
                        requiredLabel={showOptionalLabelForEvent}
                      />
                    ) : eventDetails?.eventType === 'hybrid' ? (
                      <UniversalField
                        key={field.fieldName}
                        name={field.fieldName}
                        label={field.fieldName}
                        type={field.type}
                        placeholder={field.fieldName}
                        defaultValue={meParticipant.present || ''}
                        required={field.required}
                        disabled={eventDetails.isFinished}
                        options={field.options}
                        error={methods.errors[field.fieldName]}
                        requiredLabel={showOptionalLabelForEvent}
                      />
                    ) : null;
                  })}
                </div>
              </div>
              <div className="participant-details-form__participantType">
                <div className="participant-details-form__desc">
                  <FormattedMessage id="participant.form.participant.text" />
                </div>
                <div className="participant-details-form__fields equal width fields">
                  {
                    participantTypes.map(field => (
                      <Form.Field key={field.value} className="field__checkbox poll__question-option">
                        <Controller
                          name="typeId"
                          control={methods.control}
                          defaultValue={meParticipant.typeId}
                          render={() => (
                            <Radio
                              label={field.label}
                              value={field.value}
                              checked={
                                participantType === field.value || participantType
                                  ? participantType === field.value
                                  : meParticipant.typeId === field.value
                              }
                              onChange={() => {
                                setParticipantType(field.value);
                                methods.setValue('typeId', field.value);
                                participantStore.setParticipantType(field.value);
                                participantStore.generateStepsForWizard(eventDetails);
                              }}
                            />
                          )}
                        />
                      </Form.Field>
                    )
                  )}
                </div>
              </div>

              <div className="participant-details-form__categories">
                <div className="participant-details-form__desc">
                  <FormattedMessage id="participant.form.categories.text" />
                </div>
                <div className="participant-details-form__fields equal width fields">
                  {categoriesList.map((field, k) => {
                    let defaultValue = null;

                    if (preselectSelectWizardSteps && meParticipant.categories) {
                      meParticipant.categories.forEach(item => {
                        if (item.type === 'text' && field.category.categoryId === item.categoryId) {
                          defaultValue = item.value;
                          setTimeout(() => {
                            methods.setValue(`categories[${k}]`, {
                              categoryId: field.category.categoryId,
                              type: 'text',
                              value: defaultValue,
                            });
                          });
                        } else if (item.type === 'select' && field.category.categoryId === item.categoryId) {
                          defaultValue = field.category.isMulti ? item.selectedCategoryIds : item.selectedCategoryIds[0];
                          setTimeout(() => {
                            methods.setValue(`categories[${k}]`, {
                              categoryId: field.category.categoryId,
                              type: 'select',
                              selectedCategoryIds: field.category.isMulti ? defaultValue : [defaultValue],
                              maxSelectableOptionCount: field.category.maxSelectableOptionCount,
                            });
                          });
                        }
                      });
                    }

                    return (
                      <UniversalField
                        key={field.category.categoryId}
                        label={`${field.category.texts.title?.name} ${
                          field.category.isMulti &&
                          field.category.type === 'select' &&
                          field.category.subCategoriesArray.length > field.category.maxSelectableOptionCount
                            ? `(Max ${field.category.maxSelectableOptionCount})`
                            : ''
                        }`}
                        name={`categories[${k}]`}
                        type={field.category.type}
                        defaultValue={defaultValue}
                        dataList={field.category.subCategoriesArray}
                        multiSelect={field.category.isMulti}
                        hidden={!field.visible}
                        required={field.category.required}
                        maxSelectableOptionCount={field.category.maxSelectableOptionCount}
                        requiredLabel
                        error={
                          methods.errors?.categories?.[k] ||
                          (field.category.isMulti &&
                            methods.getValues(`categories[${k}]`)?.selectedCategoryIds?.length >
                            field.category.maxSelectableOptionCount)
                        }
                        disabled={eventDetails.isFinished}
                        onChange={(e, data) => {
                          if (field.category.type === 'text') {
                            if (e.target.value) {
                              methods.setValue(`categories[${k}]`, {
                                categoryId: field.category.categoryId,
                                type: 'text',
                                value: e.target.value,
                              });
                            } else {
                              methods.setValue(`categories[${k}]`, '');
                            }
                          }
                          if (field.category.type === 'select') {
                            if (data.value.length > 0) {
                              methods.setValue(`categories[${k}]`, {
                                categoryId: field.category.categoryId,
                                type: 'select',
                                selectedCategoryIds: field.category.isMulti ? data.value : [data.value],
                                maxSelectableOptionCount: field.category.maxSelectableOptionCount,
                              });
                            } else {
                              methods.setValue(`categories[${k}]`, '');
                            }
                          }
                          methods.trigger(`categories[${k}]`);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </Form>
          </FormProvider>:<Loader/>
        }

        <div className="pre-booked-meeting__action">
          <Button color="red" basic className="btn-link__back" onClick={()=>{
            closeModal()
          }
          }>
            <FormattedMessage id="attendee.form.btn.cancel" />
          </Button>
          <ButtonLoading color="blue"  onClick={methods.handleSubmit(onSubmit)} loading={loading} disabled={loading}>
            <FormattedMessage id="attendee.form.btn.invite" />
          </ButtonLoading>
        </div>
      </div>
    </Modal>
  );
});

InviteParticipantModal.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
  invitation: PropTypes.oneOfType([PropTypes.object]),
};

export default InviteParticipantModal;
