import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import NotificationAlert from '../../../notifications-alert/NotificationAlert';
import Modal from '../../../../ui/Modal';
import {AppContext} from '../../../../store';
import ExpoApi from '../../../../services/api/expoApi';
import ButtonLoading from '../../../../ui/ButtonLoading';
import './BoothConfirmation.scss';

const BoothConfirmationModal = observer(() => {
  const {modalStore, userStore} = React.useContext(AppContext);
  const {open, booth} = modalStore.modals.boothConfirmation;

  const onSubmit = isAccept =>
    ExpoApi.confirmInvitation(userStore.user.id, booth.eventId, booth.id, isAccept).then(() => {
      modalStore.close('boothConfirmation');
      if (isAccept) {
        userStore.setParticipantBooth(booth);
        NotificationAlert.success(`You have been added to the ${booth?.boothName} team`);
      }
    });

  return (
    <Modal open={open} onClose={() => modalStore.close('boothConfirmation')} className="booth-confirmation">
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <div className="booth-confirmation__text">You are invited as a member for team "{booth?.boothName}"</div>
      <div className="booth-confirmation__action">
        <ButtonLoading onClick={() => onSubmit(true)}>
          <FormattedMessage id="booth.confirmation.btn.accept" />
        </ButtonLoading>
        <ButtonLoading color="red" onClick={() => onSubmit(false)}>
          <FormattedMessage id="booth.confirmation.btn.decline" />
        </ButtonLoading>
      </div>
    </Modal>
  );
});

export default injectIntl(BoothConfirmationModal);
