export const ParticipantStaticFields = {
  participant: [
    {
      type: 'input',
      fieldName: 'name',
      placeholder: '+11111111',
      required: true,
    },
    {
      type: 'input',
      fieldName: 'surname',
      placeholder: 'Surname',
      required: true,
    },
    {
      type: 'input',
      fieldName: 'email',
      placeholder: 'Email',
      required: true,
    }
  ],
  company: [
    {
      type: 'select',
      fieldName: 'present',
      required: true,
      options: [
        {
          key: 0,
          text: 'On-site',
          value: 'venue',
        },
        {
          key: 1,
          text: 'Remote',
          value: 'online',
        },
      ],
    },
    {
      type: 'input',
      fieldName: 'phone',
      placeholder: '+11111111',
      required: false,
    },
    {
      type: 'input',
      fieldName: 'company',
      placeholder: 'Company',
      required: false,
    },
    {
      type: 'input',
      fieldName: 'jobTitle',
      placeholder: 'Job',
      required: false,
    },
    {
      type: 'input',
      fieldName: 'website',
      placeholder: 'Job',
      required: false,
    },
  ],
  offerSeek: [
    {
      type: 'textarea',
      fieldName: 'offer',
      placeholder: 'Jon',
      required: false,
    },
    {
      type: 'textarea',
      fieldName: 'seek',
      placeholder: 'Seek',
      required: false,
    },
    {
      type: 'textarea',
      fieldName: 'biography',
      placeholder: 'Biography',
      required: false,
    },
    {
      type: 'inputGroup',
      fieldName: 'some',
      className: 'participant-social',
      fields: [
        {
          fieldName: 'instagram',
          placeholder: 'instagram',
          icon: 'instagram',
          required: false,
        },
        {
          fieldName: 'linkedin',
          placeholder: 'linkedin',
          icon: 'linkedin square',
          required: false,
        },
        {
          fieldName: 'facebook',
          placeholder: 'facebook',
          icon: 'facebook square',
          required: false,
        },
        {
          fieldName: 'twitter',
          placeholder: 'twitter',
          icon: 'twitter',
          required: false,
        },
      ],
    },
  ],
};
