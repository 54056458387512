import React from 'react';
import {Tab} from 'semantic-ui-react';
import {observer} from 'mobx-react-lite';
import Modal from '../../../../ui/Modal';
import PhotoUpload from '../../../account/PhotoUpload';
import PhotoByCamera from '../../../account/PhotoByCamera';
import {AppContext} from '../../../../store';

const UpdatePhotoModal = observer(() => {
  const {modalStore} = React.useContext(AppContext);
  const {open, maxWidth, maxHeight} = modalStore.modals.updatePhoto;

  const panes = [
    {
      menuItem: 'Upload',
      render: () => (
        <Tab.Pane>
          <PhotoUpload maxWidth={maxWidth} maxHeight={maxHeight} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Snap',
      render: () => (
        <Tab.Pane>
          <PhotoByCamera maxWidth={maxWidth} maxHeight={maxHeight} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Modal open={open} onClose={() => modalStore.close('updatePhoto')} size="small">
      <Tab panes={panes} />
    </Modal>
  );
});

export default UpdatePhotoModal;
