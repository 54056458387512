import {action, computed, extendObservable, runInAction} from 'mobx';
import {DateTime, Interval} from 'luxon';
import TimeService from '../../../services/core/time-service';
import {CommandExecutor} from '../../../services/core/command-executor';
import ServiceCacheService from '../../../services/core/cache-service';
import MeetingOffApi from '../../../services/api/meetingOfApi';

function* days(interval) {
  let cursor = interval.start.startOf('day');
  while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus({days: 1});
  }
}

export class AgendaOffTimeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.commandExecutor = new CommandExecutor();
    this.meetingCache = ServiceCacheService.create();

    extendObservable(this, {
      selectedDate: null,
      minDate: null,
      maxDate: null,
      minTime: DateTime.fromObject({hour: new Date().getHours(), minute: '00'}).toFormat('yyyy-LL-dd HH:mm'),
      maxTime: DateTime.fromObject({hour: new Date().getHours() + 1, minute: '00'}).toFormat('yyyy-LL-dd HH:mm'),
      rangeDays: [],
      participants: [],
      availableDayIndex: 0,
    });
  }

  @action
  initAgendaOfTimeForm(eventId) {
    this.rootStore.eventStore.getEventDetails(eventId).then(eventDets => {
      if (!eventDets || !eventDets.startDate) return;
      runInAction(() => {
        const s = TimeService.getMomentUserOrEvent(eventDets, eventDets.realSdt);
        const e = TimeService.getMomentUserOrEvent(eventDets, eventDets.realEdt).plus({day: 1});
        this.minDate = s.valueOf();
        this.maxDate = e.valueOf();
        const range = Interval.fromDateTimes(s, e);
        this.rangeDays = Array.from(days(range)).map(m => m.toFormat('yyyy-LL-dd'));
        // eslint-disable-next-line prefer-destructuring
        this.selectedDate = this.availableDateList[0];
      });
    });
  }

  @computed
  get availableDateList() {
    return this.rangeDays.filter(date => {
      const dateNow = TimeService.dateTime.fromMillis(TimeService.now()).startOf('day');
      return TimeService.dateTime.fromISO(date).ts >= dateNow;
    });
  }

  @action
  addAvailableDay(incr) {
    this.availableDayIndex += incr;
    const selectDay = this.availableDateList[this.availableDayIndex];
    this.selectOffTimeDay(selectDay);
  }

  @action
  selectOffTimeDay(date) {
    this.selectedDate = date;
  }

  @action
  selectOffTime(time, type) {
    if (type === 'max') {
      this.maxTime = DateTime.fromObject({hour: time.hour, minute: time.minute}).toFormat('yyyy-LL-dd HH:mm');
    } else {
      this.minTime = DateTime.fromObject({hour: time.hour, minute: time.minute}).toFormat('yyyy-LL-dd HH:mm');
    }
  }

  @computed
  get isValidaAdOffTimeForm() {
    return this.selectedDate && this.minTime && this.maxTime && this.minTime < this.maxTime;
  }

  @action
  saveOffTimeSession(userId, eventId, timezone, onBehalfOfParticipantId=null) {
    return this.rootStore.eventStore
      .getEventDetails(eventId)
      .then(() => {
        const data = {eventId};
        data.startTime = DateTime.fromSQL(this.minTime).toFormat('HH:mm');
        data.endTime = DateTime.fromSQL(this.maxTime).toFormat('HH:mm');
        data.date = this.selectedDate;
        data.timezone = timezone || TimeService.getCurrentUserTimezone();
        if(onBehalfOfParticipantId){
          data.asParticipantId = onBehalfOfParticipantId;
        }
        return MeetingOffApi.addOffTimeSession(userId, eventId, data);
      })
      .catch(error => {
        console.log('error', error);
        return Promise.reject(error);
      });
  }
}
