import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import last from 'lodash/last';
import {AppContext} from '../../../store';
import ImageLazy from '../../../ui/ImageLazy';
import AttendeePreview from '../../attendee/AttendeePreview/AttendeePreview';
import InvitationDateSection from '../InvitationDateSection/';
import attendeeImg from '../../../assets/images/defaultImages/attendee.png';
import './InvitationCardMeetingCanceled.scss';

const InvitationCardMeetingCanceled = observer(props => {
  const {invitation} = props;
  const {eventStore} = React.useContext(AppContext);
  const {eventList} = eventStore;
  const eventDetails = eventList?.filter(event => event.id === invitation.eid)[0];

  return (
    <div className="invitation-card meeting-cancel">
      <div className="invitation-header invitation-header--decline">
        <div className="invitation-header__label">
          {last(invitation.meetingDetails)?.executorId === eventDetails.me.id
            ? 'You cancelled the meeting'
            : `${invitation.opponentParticipant?.fullName} cancelled the meeting`}
        </div>
        <div className="invitation-header__time">{invitation.cdtStr}</div>
      </div>
      <InvitationDateSection invitation={invitation} />
      <div className="invitation-card__details">
        {last(invitation.meetingDetails)?.executorId === eventDetails.me.id ? (
          <AttendeePreview attendee={invitation.opponentParticipant} />
        ) : null}
        <div className="invitation-card__details-body">
          <div className="invitation-card__img">
            {last(invitation.meetingDetails)?.executorId === eventDetails.me.id ? (
              <ImageLazy
                src={`${invitation.opponentParticipantReceived.profilePhoto}`}
                errorSrc={attendeeImg}
                alt={invitation.opponentParticipantReceived?.name}
              />
            ) : (
              <ImageLazy
                src={`${invitation.opponentParticipant.profilePhoto}`}
                errorSrc={attendeeImg}
                alt={invitation.opponentParticipant?.name}
              />
            )}
          </div>
          <div className="invitation-card__message">{last(invitation.meetingDetails)?.message}</div>
        </div>
      </div>
    </div>
  );
});

InvitationCardMeetingCanceled.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default injectIntl(InvitationCardMeetingCanceled);
