import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import {ReactComponent as ContactsImg} from '../../../assets/images/icons/header/contacts.svg';
import './ContactList.scss';

const ContactList = observer(props => {
  const {contacts} = props;
  const {contactStore} = React.useContext(AppContext);

  return (
    <div className="contact-list">
      {contacts.length > 0 ? (
        contacts.map(contact => (
          <div key={contact.id} className="contact-item">
            <div className="contact-item__wrapper">
              <div className="contact-item__details">
                <div className="contact-item__fullName">{contact.fullName}</div>
                <div className="contact-item__company">{contact.company}</div>
              </div>
              <div className="contact-item__action">
                <Button
                  className="contact-item__edit"
                  onClick={() => {
                    contactStore.showDetails(contact);
                    props.openContact(contact);
                  }}
                  size="mini"
                >
                  More
                </Button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="contact-list__empty">
          <div className="contact-list__empty-img">
            <ContactsImg />
          </div>
          <div className="contact-list__empty-text">
            <FormattedMessage id="contact.content.empty" />
          </div>
          <div className="contact-list__empty-info">
            <FormattedMessage id="contact.content.empty.info" />
          </div>
        </div>
      )}
    </div>
  );
});

export default injectIntl(ContactList);
