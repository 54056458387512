import React from 'react';
import {observer} from 'mobx-react-lite';
import {runInAction} from 'mobx';
import {Button} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {AppContext} from '../../../../store';
import NotificationAlert from '../../../notifications-alert/NotificationAlert';
import Modal from '../../../../ui/Modal';
import ButtonLoading from '../../../../ui/ButtonLoading';
import './RemoveEmailConfirmationModal.scss';

const RemoveEmailConfirmationModal = observer(() => {
  const {modalStore, userStore} = React.useContext(AppContext);
  const {open, email} = modalStore.modals.removeEmailConfirmationModal;

  const onSubmit = () => {
    return userStore
      .deleteEmail(email)
      .then(() => {
        NotificationAlert.success(<FormattedMessage id="remove.email.confirmation.modal.success" />);
        onClose();
      })
      .catch(err => {
        NotificationAlert.error(err.message);
        onClose();
      });
  };

  const onClose = () => {
    runInAction(() => {
      modalStore.modals.announcementCreate.email = null;
    });
    modalStore.close('removeEmailConfirmationModal');
  };

  return (
    <Modal
      open={open}
      onClose={() => modalStore.close('removeEmailConfirmationModal')}
      className="remove-email-confirmation"
    >
      <div className="remove-email-confirmation__text">
        <FormattedMessage id="remove.email.confirmation.modal.title" values={{email: <strong>{email}</strong>}} />
      </div>
      <div className="remove-email-confirmation__action">
        <ButtonLoading onClick={() => onSubmit()} className="right floated">
          <FormattedMessage id="remove.email.confirmation.modal.accept" />
        </ButtonLoading>
        <Button color="red" onClick={() => onClose()} className="right floated">
          <FormattedMessage id="remove.email.confirmation.modal.decline" />
        </Button>
      </div>
    </Modal>
  );
});

export default injectIntl(RemoveEmailConfirmationModal);
