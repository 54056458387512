import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';
import InvitationsSearch from '../InvitationsSearch';
import DropdownFilter from '../../../ui/DropdownFilter';
import {AppContext} from '../../../store';
import InvitationsFilterByStatus from '../InvitationsFilterByStatus';
import './InvitationsFilterMobile.scss';

const InvitationsFilterMobile = observer(props => {
  const {invitations, isOpen} = props;
  const {invitationStore} = React.useContext(AppContext);
  const {filterOptions, loadedInvitations} = invitationStore;

  React.useEffect(() => {
    if (loadedInvitations) {
      invitationStore.setOptionsForFilters(invitations);
    }
  }, [invitations, loadedInvitations]);

  return (
    <div className="invitations-filter-mobile">
      <InvitationsSearch collapse={props.collapse} isOpen={isOpen} />
      <div
        className={classNames('invitations-filter-mobile__full', {
          active: isOpen,
        })}
      >
        {filterOptions?.eventName ? (
          <DropdownFilter
            classNames="invitations-filter-mobile__event"
            label={<FormattedMessage id="invitations.filter.events" />}
            options={filterOptions.eventName}
            onChange={(e, {value}) => invitationStore.filterExact('eventName', value === 'All events' ? '' : value)}
            defaultValue="All events"
            clearable={false}
          />
        ) : null}
        <InvitationsFilterByStatus />
      </div>
    </div>
  );
});

InvitationsFilterMobile.propTypes = {
  invitations: PropTypes.oneOfType([PropTypes.array]),
};

export default injectIntl(InvitationsFilterMobile);
