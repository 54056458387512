import Api from '../core/axios-service';
import {stores} from '../../store';

const ContactApi = {
  addContact(contact) {
    return Api.post('contact', contact).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  saveNote(noteObj) {
    return Api.post('note', noteObj).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  deleteNote(note) {
    return Api.delete('note', {params: {id: note.id}}).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  getBadges() {
    return Api.get('digest').then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  getBadge(id) {
    return Api.get('digest', {params: {id: id.id}}).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  getContactDetails(ticketId, eventId) {
    return Api.get('event/participant/badge', {params: {ticketId, eventId}}).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
};

export default ContactApi;
