import {action, extendObservable, runInAction} from 'mobx';
import firebase from 'firebase/app';
import 'firebase/database';
import escapeRegExp from 'lodash/escapeRegExp';
import filter from 'lodash/filter';
import ServiceCacheService from '../../../services/core/cache-service';

export class SpeakerStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.speakersCache = ServiceCacheService.create();

    extendObservable(this, {
      speakers: null,
      allSpeakers: null,
      speakerDetails: {},
      speakersByFilter: null,
      isLoading: false,
      enableFilterSpeakers: false,
      filterSpeakers: {
        search: {
          filter: false,
          value: '',
        },
      },
    });
  }

  @action
  getSpeakersFromFirebase(eventId) {
    this.isLoading = true;
    const sort = (a, b) => (a.name > b.name ? 1 : -1);

    return new Promise(resolve => {
      firebase
        .database()
        .ref(`/newEvents/${eventId}/speakers/`)
        .on('value', snapshot => {
          if (snapshot.val()) {
            runInAction(() => {
              const speakersVisible = {};
              snapshot.forEach(childSnapshot => {
                if (childSnapshot.val().showSpeaker !== false) {
                  speakersVisible[childSnapshot.val().id] = childSnapshot.val();
                }
              });
              const sortSpeakers = [];
              Object.keys(speakersVisible)
                .map(key => ({key, ...speakersVisible[key]}))
                .sort((a, b) => sort(a, b))
                .every((speaker, rank) => {
                  sortSpeakers.push(speaker);
                  return rank < Object.keys(speakersVisible).length;
                });
              this.speakers = sortSpeakers;
              this.isLoading = false;
              return resolve(this.speakers);
            });
          } else {
            runInAction(() => {
              this.speakers = [];
              this.isLoading = false;
            });
          }
          return resolve(this.speakers);
        });
    });
  }

  getSpeakers(eventId) {
    return this.speakersCache.getOrCreate(eventId, (key, subject) =>
      this.getSpeakersFromFirebase(eventId).then(data => {
        runInAction(() => subject.set(data));
        return data;
      }),
    );
  }

  @action
  getSpeakerDetails(eventId, speakerId) {
    firebase
      .database()
      .ref(`/newEvents/${eventId}/speakers/${speakerId}`)
      .once('value')
      .then(snapshot => {
        runInAction(() => {
          if (snapshot.val()) {
            this.speakerDetails[snapshot.val().id] = snapshot.val();
          }
        });
      });
  }

  @action
  searchSpeaker(value) {
    const re = new RegExp(escapeRegExp(value), 'i');
    const isMatch = result =>
      re.test(`${result.name} ${result.surname}`) || re.test(result.company) || re.test(result.jobTitle);
    this.filterSpeakers.search.filter = !!value;
    this.filterSpeakers.search.value = value;

    if (value) {
      this.enableFilterSpeakers = true;
      this.speakersByFilter = filter(this.speakers, isMatch);
    } else {
      this.enableFilterSpeakers = false;
    }
  }

  @action
  resetFilter() {
    this.enableFilterSpeakers = false;
    this.filterSpeakers = {
      search: {
        filter: false,
        value: '',
      },
    };
  }

  @action
  sortSpeaker(value) {
    if (value) {
      const scores = this.enableFilterSpeakers ? this.speakersByFilter : this.speakers;
      const sortSpeakers = [];
      const sort = (a, b) => {
        if (value === 'abc') {
          return a.name > b.name ? 1 : -1;
        }

        return b.name > a.name ? 1 : -1;
      };
      this.enableFilterSpeakers = true;
      Object.keys(scores)
        .map(key => ({key, ...scores[key]}))
        .sort((a, b) => sort(a, b))
        .every((speaker, rank) => {
          sortSpeakers.push(speaker);
          return rank < Object.keys(scores).length;
        });

      this.speakersByFilter = sortSpeakers;
    } else if (!this.filterSpeakers.search.filter) {
      this.enableFilterSpeakers = false;
    }
  }

  @action
  clean() {
    this.speakers = null;
  }
}
