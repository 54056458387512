export const ContactFields = [
  {
    type: 'input',
    fieldName: 'firstName',
    placeholder: 'Jon',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'lastName',
    placeholder: 'Doe',
    required: false,
  },
  {
    type: 'input',
    fieldName: 'emails',
    placeholder: 'user@email.com',
    required: true,
  },
  {
    type: 'input',
    fieldName: 'phones',
    placeholder: '+11111111',
    required: false,
  },
  {
    type: 'input',
    fieldName: 'company',
    placeholder: 'Company',
    required: false,
  },
  {
    type: 'input',
    fieldName: 'jobTitle',
    placeholder: 'Job title',
    required: false,
  },
  {
    type: 'input',
    fieldName: 'website',
    placeholder: 'Website',
    required: false,
  },
  {
    type: 'textarea',
    fieldName: 'note',
    placeholder: 'Note',
    required: false,
  },
];
