// eslint-disable-next-line import/no-extraneous-dependencies
import 'semantic-ui-less/semantic.less';
import './styles/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import {syncHistoryWithStore} from 'mobx-react-router';
import {createBrowserHistory as createHistory} from 'history';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {AppContext, stores} from './store';
import AppRoot from './layouts';
import * as serviceWorker from './serviceWorker';
import userApi from './services/api/userApi';
import searchParse from './utils/searchParse';

const browserHistory = createHistory();
const history = syncHistoryWithStore(browserHistory, stores.routerStore);

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications', // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
      'ResizeObserver loop limit exceeded',
      'Non-Error promise rejection captured', // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/14
      'Non-Error exception captured',
    ],
  });
}

// Only for native app
const {appNative} = searchParse(window.location.search);

if (appNative) {
  window.DR = (() => {
    let onUserChangeCallback;

    return {
      onUserChange: function (callback) {
        onUserChangeCallback = callback;
      },
      dispatchUserChange: function (userId, isLogged) {
        if (typeof onUserChangeCallback == 'function') {
          onUserChangeCallback(userId, isLogged);
        }
      },
      setPushToken: function (userId, deviceUID, token) {
        if (deviceUID && token) {
          userApi.saveTokenDevice(deviceUID, token);
        } else {
          console.error('Error, not found deviceUID or token');
        }
      },
    };
  })();
}

ReactDOM.render(
  <AppContext.Provider value={stores}>
    <AppRoot history={history} />
  </AppContext.Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
