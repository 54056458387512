import isEmpty from 'lodash/isEmpty';
import TimeService from '../core/time-service';
import UtilsService from '../core/utils-service';

export function findMatchingCategories(categories, searchedCategoryId) {
  const foundCategories = [];
  const searchedCategory = categories[searchedCategoryId];
  for (const targetCategoryId in categories) {
    if (targetCategoryId !== searchedCategoryId) {
      const category = categories[targetCategoryId];
      if (
        searchedCategory != null &&
        category != null &&
        (category.matchingCategoryId === searchedCategory.categoryId ||
          searchedCategory.matchingCategoryId === category.categoryId)
      ) {
        foundCategories.push(category);
      }
    }
  }
  return foundCategories;
}

export function processEvent(event, partDetails) {
  // date
  TimeService.eventDatePopulating(event, partDetails);
  let imagesToDisplay = {};
  if (event.images != null) {
    for (const key in event.images) {
      event.images[key] = `${UtilsService.fixPublicBucketUrl(event.images[key])}?rnd=${event.updatedAt}`;
    }
    imagesToDisplay = event.images;
  }
  if (event.pages != null) {
    for (const key in event.pages) {
      event.pages[key] = UtilsService.fixPublicBucketUrl(event.pages[key]);
    }
  }
  event.imagesToDisplay = imagesToDisplay;
  buildAllowedTabs(event, partDetails);
}

export function processEventForDetails(data, partDetails, refreshImages = false) {
  if (data == null) return null;
  if (data.eventType == null) data.eventtype = 'normal';
  if (data.categories == null) data.categories = {};
  if (data.categoriesForBooth == null) data.categoriesForBooth = {};
  if (data.participantTypes == null) data.participantTypes = {};
  if (data.pages != null) {
    for (const key in data.pages) {
      data.pages[key] = UtilsService.fixPublicBucketUrl(data.pages[key]);
    }
  }

  // categories
  for (const catId in data.categories) {
    const category = data.categories[catId];
    if (category.index == null) {
      try {
        category.index = parseInt(category.categoryId.substring(3), 10);
      } catch (e) {
        console.log('e');
      }
    }
  }

  // categories for booth categories
  for (const catId in data.categoriesForBooth) {
    const category = data.categoriesForBooth[catId];
    if (category.index == null) {
      try {
        category.index = parseInt(category.categoryId.substring(3), 10);
      } catch (e) {
        console.log('e');
      }
    }
  }

  // participantTypes
  for (const typeId in data.participantTypes) {
    const ptype = data.participantTypes[typeId];
    if (ptype.index == null) {
      try {
        ptype.index = parseInt(ptype.id.substring(4), 10);
      } catch (e) {
        console.log('e');
      }
    }
  }

  // date
  TimeService.eventDatePopulating(data, partDetails);

  if (data.categories != null) {
    data.origCategories = data.categories;
  }
  if (data.categoriesForBooth != null) {
    data.origCategoriesForBooth = data.categoriesForBooth;
  }
  if (data.participantTypes != null) {
    data.origParticipantTypes = data.participantTypes;
  }
  const clonedImages = {};

  if (data.images != null) {
    for (const imageType in data.images) {
      data.images[imageType] = UtilsService.fixPublicBucketUrl(data.images[imageType]);
    }
    for (const key in data.images) {
      const imagePath = data.images[key];
      clonedImages[key] = `${imagePath}?r=${refreshImages ? TimeService.now() : data.updatedAt}`;
    }
  }
  data.imagesToDisplay = clonedImages;
  if (data.imagesToDisplay.side_banner) {
    data.side_banner = data.imagesToDisplay.side_banner;
  } else if (data.imagesToDisplay.banner) {
    data.side_banner = data.imagesToDisplay.banner;
  } else {
    data.side_banner = 'assets/images/default_banner.png';
  }

  const partTypeArray = [];
  data.participantTypesArray = partTypeArray;
  if (data.colorsJson != null) {
    data.colors = JSON.parse(data.colorsJson);
  }
  if (data.participantTypes != null) {
    for (const pId in data.participantTypes) {
      const participantType = data.participantTypes[pId];
      if (participantType != null) {
        participantType.name = participantType.texts[data.defaultLanguage];
        if (participantType.name == null) {
          // eslint-disable-next-line prefer-destructuring
          participantType.name = Object.values(participantType.texts)[0];
        }
        if (participantType.categories == null || isEmpty(participantType.categories)) {
          participantType.categories = [];
        } else {
          const categoriesMap = {};
          participantType.categories.forEach(cat => {
            categoriesMap[cat.id] = cat;
          });
          participantType.categories = categoriesMap;
        }
        if (
          data.colors != null &&
          data.colors.participantTypes != null &&
          data.colors.participantTypes[participantType.name] != null
        ) {
          const colorCOnf = data.colors.participantTypes[participantType.name];
          participantType.backgroundColor = colorCOnf.backgroundColor;
          participantType.color = colorCOnf.color;
          if (!participantType.color) {
            participantType.color = '#fff';
          }
        }
        partTypeArray.push(participantType);
      }
    }
  }

  data.categoriesArray = processCategories(data, 'categories');
  data.categoriesForBoothArray = processCategories(data, 'categoriesForBooth');

  buildAllowedTabs(data, partDetails);

  return data;
}

function processCategories(data, categoriesTypeName) {
  const categories = [];
  let oldDataIndexCalc = 1000;

  for (const cId in data[categoriesTypeName]) {
    const category = data[categoriesTypeName][cId];
    if (category != null) {
      if (category.index == null) {
        try {
          category.index = parseInt(category.categoryId.substring('cat'.length), 10);
        } catch (e) {
          // eslint-disable-next-line no-plusplus
          category.index = oldDataIndexCalc++;
        }
      }
      if (category.texts == null) {
        category.texts = {};
      }
      for (const catTextItype in category.texts) {
        const catText = category.texts[catTextItype];
        catText.name = catText[data.defaultLanguage];
        if (catText.name == null) {
          // eslint-disable-next-line prefer-destructuring
          catText.name = Object.values(catText)[0];
        }
      }

      if (category.texts.placeholder == null) {
        category.texts.placeholder = {};
      }
      if (category.texts.title == null) {
        category.texts.title = {};
      }
      if (category.texts.label == null) {
        category.texts.label = {};
      }
      if (category.matchingCategoryId != null) {
        category.subCategories = data[categoriesTypeName][category.matchingCategoryId].subCategories;
      }
      const subCatArr = [];
      category.subCategoriesArray = subCatArr;
      if (category.visibleParticipants == null) {
        category.visibleParticipants = {};
      }

      if (category.subCategories) {
        for (const subCatId in category.subCategories) {
          const subCat = category.subCategories[subCatId];
          subCat.value = subCat.id;
          subCat.name = subCat.texts[data.defaultLanguage];
          subCat.text = subCat.texts[data.defaultLanguage];
          if (subCat.name == null) {
            // eslint-disable-next-line prefer-destructuring
            subCat.name = Object.values(subCat.texts)[0];
            // eslint-disable-next-line prefer-destructuring
            subCat.text = Object.values(subCat.texts)[0];
          }
          subCatArr.push(subCat);
        }
        category.subCategoriesArray.sort((o1, o2) => {
          if (o1.name < o2.name) return -1;
          return 1;
        });
      }
      categories.push(category);
    }
  }

  return categories.sort((o1, o2) => {
    if (o1.order == null) return -1;
    if (o2.order == null) return 1;
    if (o1.order > o2.order) return 1;
    return -1;
  });
}

export function memoizedProcessEventForDetails() {
  const cache = {};

  return n => {
    if (n.id in cache) {
      return cache[n.id];
    }
    const result = processEventForDetails(n, n.me);
    cache[n.id] = result;
    return result;
  };
}

function buildAllowedTabs(data, participantDetails) {
  let allowedTabs = ['participant', 'agenda', 'venueMap', 'speakers', 'sponsor', 'partner', 'booth', 'lobby'];
  if (data.limitTabs && (participantDetails == null || !participantDetails.organizer)) {
    allowedTabs = [];
    if (data.tabs) {
      data.tabs.forEach(tab => {
        if (
          tab.participantTypes == null ||
          tab.participantTypes.indexOf(
            participantDetails == null || participantDetails.typeId == null ? 'undefined' : participantDetails.typeId,
          ) >= 0
        ) {
          allowedTabs.push(tab.name);
        }
      });
    }
  }
  data.allowedTabs = allowedTabs;
  // eslint-disable-next-line prefer-destructuring
  if(data.tabsConfig){
    data.indexPagePath = data.tabsConfig[0].id;
  }
  else{
    if(window.innerWidth < 767){
      data.indexPagePath = "lobby";  
    }
    else {
      data.indexPagePath = "participant";
    }
  }
  if (data.indexPagePath === 'agenda') {
    data.indexPagePath = 'sessions';
  } else if (data.indexPagePath === 'participant') {
    data.indexPagePath = 'attendees';
  } else if (data.indexPagePath === 'booth' || data.indexPagePath === 'partner' || data.indexPagePath === 'sponsor') {
    data.indexPagePath = 'expo';
  }
}
