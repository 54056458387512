import React from 'react';
import {observer} from 'mobx-react-lite';
import {Dimmer, Segment, Loader} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SpeakerCard from '../SpeakerCard/SpeakerCard';
import SessionsAvailableForSpeaker from '../SessionsAvailableForSpeaker';
import './SpeakerDetails.scss';

const SpeakerDetails = observer(props => {
  const {speaker, sessionsAvailable} = props;

  return speaker ? (
    <React.Fragment>
      <h1 className="speaker-details__title">About speaker</h1>
      <div className="speaker-details__wrapper">
        <div className="speaker-details__details">
          <SpeakerCard speaker={speaker} sessionsAvailable={sessionsAvailable} />
        </div>
        <div className="speaker-details__content">
          {speaker?.biography ? (
            <div className="speaker-details__bio">
              <h3>biography</h3>
              <p>{speaker?.biography ? speaker.biography : 'No info'}</p>
            </div>
          ) : null}
          <SessionsAvailableForSpeaker speaker={speaker} sessions={sessionsAvailable} />
        </div>
      </div>
    </React.Fragment>
  ) : (
    <Segment className="speaker-details__loader">
      <Dimmer active inverted>
        <Loader />
      </Dimmer>
    </Segment>
  );
});

SpeakerDetails.propTypes = {
  speaker: PropTypes.oneOfType([PropTypes.object]),
  sessionsAvailable: PropTypes.oneOfType([PropTypes.array]),
};

export default SpeakerDetails;
