import React, {Fragment} from 'react';
import {observer} from 'mobx-react-lite';
import isEmpty from 'lodash/isEmpty';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import {Button, Form} from 'semantic-ui-react';
import {useForm} from 'react-hook-form';
import NotificationAlert from '../../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../../store';
import Modal from '../../../../ui/Modal';
import ButtonLoading from '../../../../ui/ButtonLoading';
import closeImg from '../../../../assets/images/icons/X.svg';
import './DeleteAccountModal.scss';

const DeleteAccountModal = observer(() => {
  const {userStore, modalStore} = React.useContext(AppContext);
  const [confirmation, setConfirmation] = React.useState(false);
  const {isLoading, user} = userStore;
  const {handleSubmit, register, errors} = useForm();
  const {open} = modalStore.modals.deleteAccount;
  const intl = useIntl();

  const onSubmit = () => {
    setConfirmation(true);
  };

  return (
    <Modal
      className="delete-account-modal"
      open={open}
      onClose={() => {
        modalStore.close('deleteAccount');
        setConfirmation(false);
      }}
      centered
      closeIcon={<img className="close-icon" src={closeImg} alt="closeIcon" />}
    >
      {!confirmation ? (
        <Fragment>
          <p>
            <FormattedMessage
              id="reject.account.modal.badge"
              values={{span: chunks => <span className="delete-account-form__badge">{chunks}</span>}}
            />
          </p>
          <Form
            error={!isEmpty(errors) || !!userStore.error}
            onSubmit={handleSubmit(onSubmit)}
            className="delete-account-form__body"
          >
            <Form.Field error={!!errors.deleteAccount}>
              <label>
                <FormattedMessage id="delete.account.modal.label" />
              </label>
              <input
                name="deleteAccount"
                ref={register({
                  required: intl.formatMessage({id: 'validation.error.delete'}),
                  pattern: {
                    value: /delete/,
                    message: intl.formatMessage({id: 'validation.error.delete'}),
                  },
                })}
              />
              {errors.deleteAccount ? <div className="error">{errors.deleteAccount.message}</div> : null}
            </Form.Field>
            <Button type="submit" disabled={isLoading} loading={isLoading} color="red">
              <FormattedMessage id="delete.account.modal.button" />
            </Button>
          </Form>
        </Fragment>
      ) : (
        <Fragment>
          <p>
            <FormattedMessage id="reject.account.modal.sure" />
          </p>
          <ButtonLoading
            color="red"
            onClick={() =>
              userStore
                .deleteAccount(user.id)
                .then(() => modalStore.close('deleteAccount'))
                .catch(err => {
                  NotificationAlert.error(err.message);
                })
            }
          >
            <FormattedMessage id="delete.account.modal.button" />
          </ButtonLoading>
          <Button
            disabled={isLoading}
            loading={isLoading}
            color="green"
            onClick={() => {
              modalStore.close('deleteAccount');
              setConfirmation(false);
            }}
          >
            <FormattedMessage id="reject.account.modal.button" />
          </Button>
        </Fragment>
      )}
    </Modal>
  );
});

export default injectIntl(DeleteAccountModal);
