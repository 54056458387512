import React from 'react';
import {observer} from 'mobx-react-lite';
import {runInAction} from 'mobx';
import {FormattedMessage} from 'react-intl';
import {AppContext} from '../../../../store';
import Modal from '../../../../ui/Modal';
import AnnouncementAddForm from '../../../announcement/AnnouncementAddForm';
import './AnnouncementCreateModal.scss';

const AnnouncementCreateModal = observer(() => {
  const {modalStore, eventStore} = React.useContext(AppContext);
  const {open, announcement} = modalStore.modals.announcementCreate;

  return (
    <Modal
      className="announcement-create-modal"
      open={open}
      onClose={() => {
        runInAction(() => {
          modalStore.modals.announcementCreate.announcement = null;
        });
        modalStore.close('announcementCreate');
      }}
    >
      <h3>
        <FormattedMessage id="announcement.create.modal.title" />
      </h3>
      <AnnouncementAddForm eventDetails={eventStore.eventDetails} announcement={announcement} />
    </Modal>
  );
});

export default AnnouncementCreateModal;
