import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router-dom';
import {Dimmer, Loader} from 'semantic-ui-react';
import {AppContext} from '../../../../store';
import MobileInsideLayout from '../../../../layouts/MobileInsideLayout';
import PartnerDetails from '../../../partners/PartnerDetails';
import Title from '../../../shared/Title';
import './PartnerModalPage.scss';

const PartnerModalPage = observer(() => {
  const {exhibitorStore, participantStore, userStore} = React.useContext(AppContext);
  const {exhibitors, exhibitorsMap, exhibitorMembers} = exhibitorStore.view;
  const userId = userStore.user.id;
  const {eventId, partnerId} = useParams();
  const [attendee, setAttendee] = useState({});
  const [loading, setLoading] = React.useState(false);
  const [booth, setBooth] = useState({});

  React.useEffect(() => {
    if (exhibitorsMap && exhibitorsMap[partnerId]) {
      setLoading(true);
      exhibitorsMap[partnerId].members
        .filter(memberId => memberId !== partnerId)
        .forEach(memberId => {
          exhibitorStore.view.getMembers(partnerId, memberId);
          participantStore
            .getParticipantDetails(exhibitorsMap[partnerId].ownerParticipantId)
            .then(r => {
              setAttendee(r);
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        });
      participantStore.markAsSeen(exhibitorsMap[partnerId].eventId, userId, exhibitorsMap[partnerId].id);
    } else {
      exhibitorStore.view.getExhibitorDetail(eventId, partnerId).then(booth => {
        booth.members
          .filter(memberId => {
            return memberId !== partnerId;
          })
          .forEach(memberId => {
            exhibitorStore.view.getMembers(partnerId, memberId);
            participantStore.getParticipantDetails(booth.ownerParticipantId).then(r => setAttendee(r));
          });
        setBooth(booth);
      });
    }

    return () => {
      setAttendee(null);
    };
  }, [exhibitors, exhibitorStore, partnerId]);

  const partner = (exhibitors && exhibitors.find(el => el.id === partnerId)) || booth;

  return loading || partner ? (
    <MobileInsideLayout>
      <Title id="partner-details" />
      <PartnerDetails
        partnerDetails={partner}
        owner={attendee}
        members={exhibitorMembers[partnerId] || {}}
        membersLimit={6}
        textLimit={200}
      />
    </MobileInsideLayout>
  ) : (
    <Dimmer active inverted>
      <Loader />
    </Dimmer>
  );
});

export default PartnerModalPage;
