import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {observer} from 'mobx-react-lite';
import {Button} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import './AccountDeleteForm.scss';

const AccountDeleteForm = observer(() => {
  const {modalStore} = React.useContext(AppContext);

  return (
    <div className="delete-account-form">
      <h2 className="delete-account-form__title">
        <FormattedMessage id="delete.account.form.title" />
      </h2>
      <Button onClick={() => modalStore.open('deleteAccount')}>
        <FormattedMessage id="delete.account.form.btn" />
      </Button>
    </div>
  );
});

export default injectIntl(AccountDeleteForm);
