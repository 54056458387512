import {action, extendObservable, runInAction} from 'mobx';
import firebase from 'firebase/app';
import 'firebase/database';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import differenceBy from 'lodash/differenceBy';
import UtilsService from '../../../services/core/utils-service';
import ExpoApi from '../../../services/api/expoApi';
import {CommandExecutor} from '../../../services/core/command-executor';
import {processBooth, openCompanyInvite} from '../../../services/helpers/expoHelper';
import {processMeeting} from '../../../services/helpers/meetingHelper';
import ServiceCacheService from '../../../services/core/cache-service';

export class ExhibitorsViewStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.commandExecutor = new CommandExecutor();
    this.exhibitorCache = ServiceCacheService.create();

    extendObservable(this, {
      exhibitors: [],
      exhibitorsLoadedForEvent: {},
      exhibitorsMap: {},
      exhibitorMembers: {},
      exhibitorsTypes: new Set([]),
      loading: false,
      images: {},
      teamMember: [],
      participants: [],
      isLoadingDelete: false,
    });
  }

  getExhibitors(eventId, update) {
    return this.exhibitorCache.getOrCreate(
      eventId,
      (key, subject) =>
        this.getExhibitorFromFirebase(eventId, update).then(data => {
          runInAction(() => subject.set(data));
          return data;
        }),
      false,
    );
  }

  getExhibitorDetail(eventId, boothId) {
    const ref = firebase.database().ref(`/newEvents/${eventId}/booths/${boothId}`);
    return ref.once('value').then(data => {
      if (data.val()?.details) {
        return processBooth(eventId, data.val()?.details, false);
      }
      return {};
    });
  }

  @action
  getExhibitorFromFirebase(eventId, update) {
    const ref = firebase.database().ref(`/newEvents/${eventId}/booths/`);
    this.loading = !update;

    return new Promise((resolve, reject) => {
      ref
        .once('value')
        .then(snapshot => {
          runInAction(() => {
            if (snapshot.val()) {
              const booths = [];
              for (const boothId in snapshot.val()) {
                const boothDetails = snapshot.val()[boothId].details;
                const boothDetailsProcess = processBooth(eventId, boothDetails);
                booths.push(boothDetailsProcess);
                this.exhibitorsMap[boothDetails.id] = boothDetailsProcess;
                this.exhibitorsTypes.add(boothDetails.boothType === 'exhibitor' ? 'booth' : boothDetails.boothType);
              }

              const {meParticipant} = this.rootStore.eventStore;
              openCompanyInvite(booths, meParticipant);

              if (meParticipant.boothId) {
                this.rootStore.userStore.setParticipantBooth(booths.find(b => b.id === meParticipant.boothId));
              }
              this.exhibitors = booths;
            }
            this.loading = false;
            this.exhibitorsLoadedForEvent[eventId] = true;
          });
          return resolve(this.exhibitors);
        })
        .catch(error => {
          runInAction(() => {
            this.loading = false;
          });
          return reject(error);
        });

      ref.on('child_added', newExhibitor => {
        if (!some(this.exhibitors, ['id', newExhibitor.val().details.id]) && this.exhibitorsLoadedForEvent[eventId]) {
          const boothDetails = newExhibitor.val().details;
          const exhibitor = processBooth(eventId, boothDetails);
          if (!isEmpty(exhibitor) && exhibitor.eventId === eventId) {
            runInAction(() => {
              this.exhibitors.push(exhibitor);
              this.exhibitorsMap[boothDetails.id] = boothDetails;
              this.exhibitorsTypes.add(boothDetails.boothType === 'exhibitor' ? 'booth' : boothDetails.boothType);
            });
          }
        }
      });

      ref.on('child_removed', res => {
        runInAction(() => {
          this.exhibitors = differenceBy(this.exhibitors, [res.val().details], 'id');
          delete this.exhibitorsMap[res.val().details.id];
        });
      });

      ref.on('child_changed', res => {
        this.rootStore.eventStore.getEventDetails(eventId).then(eventDetails => {
          const exhibitor = processBooth(eventId, res.val().details);
          runInAction(() => {
            this.exhibitors = differenceBy(this.exhibitors, [res.val().details], 'id');
            this.exhibitors.push(exhibitor);
            this.rootStore.userStore.setParticipantBooth(this.exhibitors.find(b => b.id === eventDetails.me.boothId));
          });
        });
      });
    });
  }

  @action
  getMembers(exhibitorId, participantId) {
    firebase
      .database()
      .ref(`/profiles/${participantId}/public`)
      .once('value')
      .then(snapshot => {
        const attendee = snapshot.val();
        if (attendee.state !== 'deleted') {
          runInAction(() => {
            if (!this.exhibitorMembers[exhibitorId]) {
              this.exhibitorMembers[exhibitorId] = {[participantId]: attendee};
            } else {
              Object.assign(this.exhibitorMembers[exhibitorId], {[participantId]: attendee});
            }
          });
        }
      });
  }

  @action
  setBanner(type, image) {
    this.images[type] = image;
  }

  @action
  updateBoothDetails(boothDetails) {
    return ExpoApi.updateBoothDetails(boothDetails)
      .then(resp => {
        runInAction(() => {
          this.user = resp.data;
        });
        this.getExhibitors(boothDetails.eventId, true);
        return Promise.resolve(resp.data);
      })
      .catch(error => Promise.reject(error.response.data));
  }

  @action
  getTeamMembers(eventId) {
    return ExpoApi.getTeamMembers(eventId).then(resp => {
      const next = resp.data;
      const members = [];
      next.results.forEach(result => {
        if (!result.organizer && result.boothId == null) {
          result.organizerRole = 'notConfirmed';
        }
        result.profilePhoto = UtilsService.fixPublicBucketUrl(result.profilePhoto);
        if (result.organizerRole !== 'owner') {
          members.push(result);
        }
      });
      runInAction(() => {
        this.teamMember = members;
      });
      return members;
    });
  }

  getBoothMembersPrivateSessions(eventId, participantId) {
    const ref = firebase.database().ref(`/newEvents/${eventId}/participants/${participantId}/sessions`);
    return new Promise((resolve, reject) => {
      ref.once('value').then(
        res =>
          this.rootStore.eventStore.getEventDetails(eventId).then(eventDetails => {
            const sessions = res.val();
            const sessionObservables = [];

            for (const sessionId in sessions) {
              const session = sessions[sessionId];

              sessionObservables.push(processMeeting(session, eventDetails, eventDetails.me).then(s => s));
            }

            return Promise.all([Promise.all(sessionObservables)]).then(results => resolve(results[0]));
          }),
        errorObject => {
          console.log('e', errorObject);
          return reject(errorObject);
        },
      );
    });
  }

  @action
  getBoothMembersAgenda(eventDetails) {
    const sessionsObservables = [];
    return this.rootStore.exhibitorStore.view.getTeamMembers(eventDetails.id).then(members => {
      const teamMembers = members.filter(member => eventDetails.me.id !== member.id);

      teamMembers.forEach(member => {
        sessionsObservables.push(
          this.getExhibitorDetail(eventDetails.id, member.boothId).then(boothDetails => {
            member.boothDetails = boothDetails;

            return this.getBoothMembersPrivateSessions(eventDetails.id, member.id).then(sessions => {
              return sessions.filter(session => {
                if (session.type === 'dealroom') {
                  session.teamMemberDetails = member;
                  return session;
                }
              });
            });
          }),
        );
      });

      return Promise.all([Promise.all(sessionsObservables)])
        .then(sessionsData => {
          return sessionsData[0].map(sessions => sessions);
        })
        .catch(() => {
          return [];
        });
    });
  }

  @action
  deleteMember(member, eId) {
    this.isLoadingDelete = true;
    return ExpoApi.deleteMember(member.id, eId)
      .then(() => {
        runInAction(() => {
          this.isLoadingDelete = false;
        });
        return this.getTeamMembers(eId);
      })
      .catch(err => {
        runInAction(() => {
          this.isLoadingDelete = false;
        });
        return err;
      });
  }

  @action
  clean() {
    this.exhibitors = [];
    this.exhibitorsLoadedForEvent = {};
    this.exhibitorsMap = {};
    this.exhibitorMembers = {};
    this.exhibitorsTypes = new Set([]);
  }
}
