import PropTypes from 'prop-types';
import {FormattedMessage, useIntl} from 'react-intl';
import {Dropdown} from 'semantic-ui-react';
import {setLastSelectedCheckInData} from '../../../services/helpers/checkInHelper';

export const ZPL_PRINT_TYPE_OPTIONS = [
  {
    key: 0,
    text: 'checkIn.zplPrintType.type.directly',
    value: 'directly',
  },
  {
    key: 1,
    text: 'checkIn.zplPrintType.type.printQueue',
    value: 'printQueue',
  },
];

export const CheckInZplPrintTypeSelector = ({value, onChange, printer, eventId, placeholder, className, label}) => {
  const intl = useIntl();

  const translatedOptions = ZPL_PRINT_TYPE_OPTIONS.map(item => {
    if (intl.messages[item.text]) {
      item.text = intl.formatMessage({id: item.text});
    }

    return item;
  });

  const handleDropdownChange = (_, {value}) => {
    delete printer.zplPrintType;
    setLastSelectedCheckInData(eventId, 'printer', {zplPrintType: value, ...printer});

    return onChange(value);
  };

  return (
    <>
      <label htmlFor="zpl-print-type">{label ?? <FormattedMessage id="checkIn.zplPrintType.label" />}</label>
      <Dropdown
        selection
        className={className}
        placeholder={placeholder ?? intl.formatMessage({id: 'checkIn.zplPrintType.placeholder'})}
        options={translatedOptions}
        id="zpl-print-type"
        onChange={handleDropdownChange}
        value={value}
      />
    </>
  );
};

CheckInZplPrintTypeSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  printer: PropTypes.object,
  eventId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};

CheckInZplPrintTypeSelector.defaultProps = {
  className: '',
  value: '',
};
