import React from 'react';
import PropTypes from 'prop-types';
import JoditReact from 'jodit-react';
import {AppContext} from '../../store';
import './HtmlEditor.scss';

const HtmlEditor = props => {
  const {value, onChange, placeholder} = props;
  const {languageStore} = React.useContext(AppContext);

  // fix bug with missing focus when value changed
  const [savedConfig] = React.useState({
    editorCssClass: 'html-editor',
    language: languageStore.language || 'en',
    placeholder: placeholder,
    resizer: {
      min_height: 249,
    },
    allowResizeTags: ['img', 'jodit'],
    hidePoweredByJodit: true,
    link: {
      processVideoLink: false,
      target_checkbox: true,
    },
    image: {
      useImageEditor: false,
    },
    buttons: [
      {
        group: 'font-style',
        buttons: [],
      },
      {
        group: 'list',
        buttons: [],
      },
      {
        group: 'indent',
        buttons: [],
      },
      '---',
      {
        group: 'fontCustom',
        buttons: ['font', 'fontsize', 'paragraph'],
      },
      {
        group: 'color',
        buttons: [],
      },
      '\n',
      {
        group: 'mediaCustom',
        buttons: ['link', 'video'],
      },
      {
        group: 'insertCustom',
        buttons: ['hr', 'table'],
      },
      {
        group: 'clipboardCustom',
        buttons: ['cut', 'copy', 'paste', 'selectall'],
      },
      '---',
      {
        group: 'script',
        buttons: [],
      },
      {
        group: 'history',
        buttons: [],
      },
      {
        group: 'source',
        buttons: [],
      },
      'preview',
    ],
    buttonsMD: [
      {
        group: 'font-style',
        buttons: [],
      },
      {
        group: 'list',
        buttons: [],
      },
      {
        group: 'indent',
        buttons: [],
      },
      '---',
      {
        group: 'fontCustom',
        buttons: ['font', 'fontsize', 'paragraph'],
      },
      {
        group: 'color',
        buttons: [],
      },
      '\n',
      {
        group: 'mediaCustom',
        buttons: ['link', 'video'],
      },
      {
        group: 'insertCustom',
        buttons: ['hr', 'table'],
      },
      {
        group: 'clipboardCustom',
        buttons: ['cut', 'copy', 'paste', 'selectall'],
      },
      '---',
      {
        group: 'script',
        buttons: [],
      },
      {
        group: 'history',
        buttons: [],
      },
      {
        group: 'source',
        buttons: [],
      },
      'preview',
    ],
    buttonsSM: [
      {
        group: 'font-style',
        buttons: [],
      },
      '---',
      {
        group: 'history',
        buttons: [],
      },
      '\n',
      {
        group: 'list',
        buttons: [],
      },
      '---',
      {
        group: 'indent',
        buttons: [],
      },
      '\n',
      {
        group: 'mediaCustom',
        buttons: ['link', 'video'],
      },
      {
        group: 'clipboardCustom',
        buttons: ['selectall'],
      },
      '---',
      {
        group: 'source',
        buttons: [],
      },
      'dots',
      'preview',
    ],
    buttonsXS: [
      {
        group: 'font-style',
        buttons: [],
      },
      '---',
      {
        group: 'history',
        buttons: [],
      },
      '\n',
      {
        group: 'list',
        buttons: [],
      },
      {
        group: 'clipboardCustom',
        buttons: ['selectall'],
      },
      '---',
      {
        group: 'source',
        buttons: [],
      },
      'dots',
      'preview',
    ],
  });

  return <JoditReact value={value} onChange={onChange} config={savedConfig} />;
};

HtmlEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default HtmlEditor;
