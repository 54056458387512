import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router-dom';
import {Dimmer, Segment, Loader} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {AppContext} from '../../../../store';
import ModalUrl from '../../../../ui/ModalUrl';
import PartnerDetails from '../../../partners/PartnerDetails';
import './PartnerModal.scss';

const PartnerModal = observer(() => {
  const {exhibitorStore, participantStore, userStore} = React.useContext(AppContext);
  const {exhibitors, exhibitorsMap, exhibitorMembers} = exhibitorStore.view;
  const userId = userStore.user.id;
  const {eventId, partnerId} = useParams();
  const [attendee, setAttendee] = useState({});
  const [booth, setBooth] = useState({});

  React.useEffect(() => {
    if (exhibitorsMap && exhibitorsMap[partnerId]) {
      exhibitorsMap[partnerId].members
        .filter(memberId => {
          return memberId !== partnerId;
        })
        .forEach(memberId => {
          exhibitorStore.view.getMembers(partnerId, memberId);
          participantStore.getParticipantDetails(exhibitorsMap[partnerId].ownerParticipantId).then(r => setAttendee(r));
        });
      participantStore.markAsSeen(exhibitorsMap[partnerId].eventId, userId, exhibitorsMap[partnerId].id);
    } else {
      exhibitorStore.view.getExhibitorDetail(eventId, partnerId).then(booth => {
        booth.members
          .filter(memberId => {
            return memberId !== partnerId;
          })
          .forEach(memberId => {
            exhibitorStore.view.getMembers(partnerId, memberId);
            participantStore.getParticipantDetails(booth.ownerParticipantId).then(r => setAttendee(r));
          });
        setBooth(booth);
      });
    }
  }, [exhibitors, exhibitorStore, partnerId]);

  const partner = (exhibitors && exhibitors.find(el => el.id === partnerId)) || booth;

  return (
    <ModalUrl className="partner-modal" open={!!partnerId} size="large">
      {!isEmpty(partner) ? (
        <PartnerDetails
          partnerDetails={partner}
          owner={attendee}
          members={exhibitorMembers[partnerId] || {}}
          membersLimit={6}
          textLimit={200}
        />
      ) : (
        <Segment className="partner-modal__loader">
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        </Segment>
      )}
    </ModalUrl>
  );
});

export default PartnerModal;
