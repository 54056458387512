import React, {Fragment} from 'react';
import {Route} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import SpeakerModal from '../../components/modals/modalsUrl/SpeakerModal';
import SessionModal from '../../components/modals/modalsUrl/SessionModal';
import PartnerModal from '../../components/modals/modalsUrl/PartnerModal';
import AttendeeModal from '../../components/modals/modalsUrl/AttendeeModal';
import InvitationModalPage from '../../components/modals/modalsMobilePage/InvitationModalPage';
import SessionModalPage from '../../components/modals/modalsMobilePage/SessionModalPage';
import AttendeeModalPage from '../../components/modals/modalsMobilePage/AttendeeModalPage';
import PartnerModalPage from '../../components/modals/modalsMobilePage/PartnerModalPage';
import SpeakerModalPage from '../../components/modals/modalsMobilePage/SpeakerModalPage';

const ModalRoute = observer(props => {
  const {location, background, isMobile} = props;

  return (
    <Fragment>
      {background && location && location.state.page === 'speakers' ? (
        <Route path="/event/:eventId/speakers/:speakerId" component={isMobile ? SpeakerModalPage : SpeakerModal} />
      ) : null}
      {background && location && location.state.page === 'sessions' ? (
        <Route path="/event/:eventId/sessions/:sessionId" component={isMobile ? SessionModalPage : SessionModal} />
      ) : null}
      {background && location && location.state.page === 'expo' ? (
        <Route path="/event/:eventId/expo/:partnerId" component={isMobile ? PartnerModalPage : PartnerModal} />
      ) : null}
      {background && location && location.state.page === 'attendees' ? (
        <Route path="/event/:eventId/attendees/:attendeeId" component={isMobile ? AttendeeModalPage : AttendeeModal} />
      ) : null}
      {background && location && (location.state.page === 'invitation' || location.state.page === 'agenda') ? (
        <>
          <Route
            exact
            path="/event/:eventId/invitation/:attendeeId/requestReschedule"
            render={() => <InvitationModalPage action="requestReschedule" />}
          />
          <Route
            exact
            path="/event/:eventId/invitation/:attendeeId/cancelMeeting"
            render={() => <InvitationModalPage action="cancelMeeting" />}
          />
          <Route
            path="/event/:eventId/invitation/:attendeeId/decline"
            render={() => <InvitationModalPage action="decline" />}
          />
          <Route
            path="/event/:eventId/invitation/:attendeeId/accept"
            render={() => <InvitationModalPage action="accept" />}
          />
          <Route
            exact
            path="/event/:eventId/invitation/:attendeeId/reschedulePending"
            render={() => <InvitationModalPage action="reschedulePending" />}
          />
          <Route exact path="/event/:eventId/invitation/:attendeeId" component={InvitationModalPage} />
        </>
      ) : null}
      <Route path="/my-agenda/session/:eventId/:sessionId" render={() => <SessionModalPage />} />
    </Fragment>
  );
});

export default ModalRoute;
