import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {injectIntl, FormattedMessage} from 'react-intl';
import AttendeePreview from '../../attendee/AttendeePreview';
import InvitationDateSection from '../InvitationDateSection';
import './InvitationCardDecline.scss';

const InvitationCardDecline = observer(props => {
  const {invitation} = props;
  const name = invitation && invitation.oppId === invitation.tpid ? invitation.attendeeFullName : 'You';

  return (
    <div className="invitation-card invitation-card--decline">
      <div className="invitation-header invitation-header--decline">
        <div className="invitation-header__label">{name} declined meeting invitation</div>
        <div className="invitation-header__time">{invitation.cdtStr}</div>
      </div>
      <InvitationDateSection invitation={invitation} />
      <AttendeePreview attendee={invitation.opponentParticipant} />
      <div className="invite-decline__message">
        {invitation?.message ? (
          <p dangerouslySetInnerHTML={{__html: invitation?.message.replaceAll('\n', '<br>')}}></p>
        ) : (
          <FormattedMessage id="invite.card.decline.text" />
        )}
      </div>
    </div>
  );
});

InvitationCardDecline.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.object]),
};

export default injectIntl(InvitationCardDecline);
