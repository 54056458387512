import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Virtuoso} from 'react-virtuoso';
import {AppContext} from '../../../store';
import NotificationCard from '../NotificationCard';
import './NotificationList.scss';

const NotificationList = observer(props => {
  const {notifications, tags} = props;
  const allNotifications = notifications.slice();
  const {activityStore} = React.useContext(AppContext);
  const {hasMore} = activityStore;

  /*
  function loadMoreRows() {
    if (!hasMore) return;
    activityStore.loadNextNotifications();
  }
  */

  return (
    <div className="notifications-list">
      <Virtuoso
        overscan={10}
        data={allNotifications}
        itemContent={(index, notification) => (
          <NotificationCard
            notification={notification}
            type={notification.typeNotification}
            tags={tags[notification.id]}
          />
        )}
        components={{
          Footer: () =>
            hasMore ? (
              <div
                style={{
                  padding: '2rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <FormattedMessage id="global.loading" />
                ...
              </div>
            ) : null,
        }}
      />
    </div>
  );
});

NotificationList.propTypes = {
  notifications: PropTypes.oneOfType([PropTypes.array]),
  tags: PropTypes.oneOfType([PropTypes.object]),
};

export default injectIntl(NotificationList);
