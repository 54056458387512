import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import {Dropdown, Form} from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import {AppContext} from '../../../store';
import PersonItem from '../../../ui/PersonItem';
import ButtonLoading from '../../../ui/ButtonLoading';
import ExpoApi from '../../../services/api/expoApi';
import './CompanyAddMemberToCompanyForm.scss';

const CompanyAddMemberToCompanyForm = observer(() => {
  const intl = useIntl();
  const {participantStore, agendaStore, eventStore, modalStore} = React.useContext(AppContext);
  const {participants} = agendaStore.data;
  const {eventDetails} = eventStore;
  const [selectedParticipantId, setSelectedParticipantId] = React.useState(null);
  const [selectedRole, setSelectedRole] = React.useState('user');
  const [loading, setLoading] = React.useState(false);

  const debouncedSave = React.useCallback(
    debounce(newValue => {
      setLoading(true);
      return participantStore
        .search({queryText: newValue, filters: []}, eventDetails.id, true)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }, 400),
    [],
  );

  const updateValue = newValue => {
    debouncedSave(newValue);
  };

  const options = [
    {
      key: 0,
      text: 'User',
      value: 'user',
    },
    {
      key: 1,
      text: 'Admin',
      value: 'admin',
    },
  ];

  return (
    <Form className="company-add-member">
      <div className="company-add-member__fields equal width fields">
        <div className="field">
          <label>
            <FormattedMessage id="company.add.member.to.company.form.label.role" />
          </label>
          <Dropdown
            fluid
            clearable={false}
            disabled={!options}
            selection
            options={options}
            onChange={(e, {value}) => setSelectedRole(value)}
            defaultValue={options[0].value}
          />
        </div>
        <div className="field">
          <label>
            <FormattedMessage id="company.add.member.to.company.form.label.member" />
          </label>
          <Dropdown
            fluid
            search
            selection
            clearable
            loading={loading}
            onSearchChange={(event, value) => updateValue(value.searchQuery)}
            placeholder={intl.formatMessage({id: 'company.add.member.to.company.form.placeholder'})}
            onChange={(event, data) => {
              if (data.value.length) {
                setSelectedParticipantId(data.value);
              } else {
                setSelectedParticipantId(null);
              }
            }}
            options={participants.map(participant => ({
              key: participant.id,
              content: <PersonItem person={participant} />,
              text: `${participant.name} ${participant.surname}`,
              value: participant.id,
              className: 'company-add-member___attendee',
            }))}
          />
        </div>
      </div>
      <div className="company-add-member__action">
        <ButtonLoading
          className="company-add-member__add-to-meeting"
          disabled={!selectedParticipantId}
          onClick={() =>
            ExpoApi.addMember(eventDetails.id, selectedParticipantId, selectedRole).then(() =>
              modalStore.close('addMembersToCompanyModal'),
            )
          }
        >
          <FormattedMessage id="company.add.member.to.company.form.btn" />
        </ButtonLoading>
      </div>
    </Form>
  );
});

export default injectIntl(CompanyAddMemberToCompanyForm);
