import {action, extendObservable} from 'mobx';

export class ModalStore {
  constructor() {
    extendObservable(this, {
      modals: {
        announcementCreate: {
          open: false,
          announcement: null,
        },
        announcementInfo: {
          open: false,
          announcement: null,
        },
        poll: {
          open: false,
        },
        updatePhoto: {
          open: false,
          maxWidth: null,
          maxHeight: null,
        },
        eventInfo: {
          open: false,
          event: null,
        },
        deleteAccount: {
          open: false,
        },
        attendeeMeet: {
          open: false,
          attendee: null,
          invitationId:null,
          meeting: null,
          newMeeting: false,
          isBooth: false,
        },
        agendaOffTimeModal: {
          open: false,
          attendee:null
        },
        inviteAParticipantModal:{
          open:false
        },
        showAgendaModal:{
          open: false,
          attendee:null
        },
        privacyModal: {
          open: false,
        },
        termsModal: {
          open: false,
        },
        addGuestToMeetingModal: {
          open: false,
          meeting: null,
        },
        preBookedMeetingModal: {
          open: false,
          attendee: null,
        },
        reserveATableModal: {
          open: false,
          attendee: null,
        },
        addSessionToAgendaModal: {
          open: false,
          session: null,
        },
        imageUpload: {
          open: false,
          type: null,
          maxWidth: null,
          maxHeight: null,
        },
        boothConfirmation: {
          open: false,
          booth: null,
        },
        addMembersToCompanyModal: {
          open: false,
        },
        exportAgendaModal: {
          open: false,
        },
        timezoneModal: {
          open: false,
          registeredNewUser: false,
        },
        addContactByQrModal: {
          open: false,
          contact: null,
        },
        presenceConfirmationModal: {
          open: false,
        },
        addContactModal: {
          open: false,
        },
        qrGenerate: {
          open: false,
        },
        removeEmailConfirmationModal: {
          open: false,
          email: null,
        },
        checkInQrModal: {
          open: false,
        },
        messageActionConfirmationModal: {
          open: false,
          messageInfo: null,
          type: null,
        },
      },
    });
  }

  @action.bound
  close(type) {
    document.getElementsByTagName('body')[0].style.paddingRight = null;
    this.modals[type].open = false;
    return this.modals[type].open;
  }

  @action.bound
  open(type, args) {
    const documentWidth = parseInt(document.documentElement.clientWidth);
    const windowsWidth = parseInt(window.innerWidth);
    document.getElementsByTagName('body')[0].style.paddingRight = `${windowsWidth - documentWidth}px`;
    const modal = this.modals[type];
    modal.open = true;

    if (args) {
      // eslint-disable-next-line guard-for-in
      for (const key in args) {
        modal[key] = args[key];
      }
    }
    return modal.open;
  }
}
