import Api from '../core/axios-service';
import {stores} from '../../store';
import UtilsService from '../core/utils-service';

const EventApi = {
  getEventList() {
    return Api.get('event', {
      skipAuthRefresh: true,
      params: {query: '*:*'},
    }).then(
      resp => Promise.resolve(resp),
      err => {
        return Promise.reject(err);
      },
    );
  },
  getEventDetails(id, useCache) {
    return Api.get('event', {params: {id}, useCache: useCache}).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  getEventDetailsByDomain(eventName) {
    return Api.get(UtilsService.fixPublicBucketUrl(`public/domaininf/${eventName}.json`), {}).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  updateParticipantDetails(participantDetails) {
    return Api.put('event/participant', participantDetails).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  inviteParticipant(eventDetails, participantDetails) {
    return Api.post('event/participant', { participants: [participantDetails], eventId: eventDetails.id }).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  eventRegister(userData) {
    return Api.post('/event/register', userData)
      .then(resp => {
        const data = resp.data;
        if (data.success) {
          return Promise.resolve(data);
        } else {
          return Promise.reject(data);
        }
      })
      .catch(err => {
        stores.errorStore.addError(err);
        return Promise.reject(err?.response?.data);
      });
  },
  getParticipantDetailsForOrganizer(eventId, participantId) {
    return Api.get('event/participant/adminDetails', {params: {id: participantId, eventId}}).then(
      resp => Promise.resolve(resp.data.participant),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
};

export default EventApi;
