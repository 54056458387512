import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import preval from 'preval.macro';
import packageJson from '../../../../package.json';
import dealroomImg from '../../../assets/images/dealroom_logo.svg';
import './Footer.scss';

const Footer = () => {
  const aboutLinks = [
    {
      title: <FormattedMessage id="app.footer.about.aboutUs" />,
      url: 'https://www.dealroomevents.com/about-us',
      external: true,
    },
    {
      title: <FormattedMessage id="app.footer.about.platform" />,
      url: 'https://www.dealroomevents.com',
      external: true,
    },
    {
      title: <FormattedMessage id="app.footer.about.contact" />,
      url: 'https://www.dealroomevents.com/help-center',
      external: true,
    },
  ];

  const resourcesLinks = [
    {
      title: <FormattedMessage id="app.footer.resources.support" />,
      url: 'https://www.dealroomevents.com/faq',
      external: true,
    },
    {
      title: <FormattedMessage id="app.footer.resources.blog" />,
      url: 'https://www.dealroomevents.com/blog',
      external: true,
    },
  ];

  const legalLinks = [
    {title: <FormattedMessage id="app.footer.legal.privacy" />, url: '/privacy-notice'},
    {title: <FormattedMessage id="app.footer.legal.terms" />, url: '/terms'},
    {title: <FormattedMessage id="app.footer.legal.cookies" />, url: '/cookies'},
  ];

  const socialLinks = [
    {type: 'instagram', url: 'https://www.instagram.com/dealroomevents/'},
    {type: 'facebook', url: 'https://www.facebook.com/dealroomevents/'},
    {type: 'twitter', url: 'https://twitter.com/dealroomevents'},
    {type: 'linkedin', url: 'https://www.linkedin.com/company/dealroomevents/'},
    {type: 'youtube', url: 'https://www.youtube.com/channel/UCicqW74CPUaxDAMW1IPm2zg'},
  ];

  return (
    <footer className="footer">
      <div className="footer__body">
        <div className="col-4">
          <FooterLinks title={<FormattedMessage id="app.footer.about.title" />} items={aboutLinks} />
        </div>
        <div className="col-4">
          <FooterLinks title={<FormattedMessage id="app.footer.resources.title" />} items={resourcesLinks} />
        </div>
        <div className="col-4">
          <FooterLinks title={<FormattedMessage id="app.footer.legal.title" />} items={legalLinks} />
        </div>
        <div className="col-4 dealroom__info">
          <div className="dealroom__logo">
            <a rel="noopener noreferrer" target="_blank" href="https://www.dealroomevents.com">
              <img src={dealroomImg} alt="logo" />
            </a>
          </div>
          <div className="dealroom__socials">
            {socialLinks.map(social => (
              <a
                key={social.type}
                rel="noopener noreferrer"
                target="_blank"
                href={social.url}
                className={`ui ${social.type} circular icon button`}
              >
                <i className={`${social.type} icon`} />
              </a>
            ))}
          </div>
          <div className="dealroom__email">
            <a data-auto-recognition="true" href="mailto:info@dealroomevents.com">
              info@dealroomevents.com
            </a>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="footer__copyright">
          <FormattedMessage id="app.footer.copyright" />
        </div>
      </div>
      <div className="build-timestamp">
        <FormattedMessage
          id="app.footer.build"
          values={{
            version: packageJson.version,
            time: preval`module.exports = new Date().toLocaleString();`,
          }}
        />
      </div>
    </footer>
  );
};

export default injectIntl(Footer);

function FooterLinks({title, items = []}) {
  const linksList = items.map(item => (
    <li key={item.url} className="footer-links__item">
      {item.external ? (
        <a target="_blank" rel="noopener noreferrer" href={item.url} className="footer-links__link">
          {item.title}
        </a>
      ) : (
        <Link to={item.url} className="footer-links__link">
          {item.title}
        </Link>
      )}
    </li>
  ));

  return (
    <div className="site-footer__widget footer-links">
      <h3 className="footer-links__title">{title}</h3>
      <ul className="footer-links__list">{linksList}</ul>
    </div>
  );
}

FooterLinks.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  items: PropTypes.oneOfType([PropTypes.array]).isRequired,
};
