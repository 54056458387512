import React from 'react';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {Dimmer, Loader} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import {AppContext} from '../../../store';
import ChatUser from '../ChatUser';
import {ReactComponent as ChatImg} from '../../../assets/images/icons/header/chat.svg';
import './ChatUsers.scss';

const ChatUsers = observer(
  ({floating}) => {
    const {chatStore} = React.useContext(AppContext);
    const {roomsDetails, loading, filteredRoom, filters, floatingSelectedRoom, isFloatingChat} = chatStore;

    const sortedUsers = () => {
      const sortByDate = [];
      const notMessages = [];
      const firstMessage = [];

      Object.values(roomsDetails).forEach(el => {
        if (el.firstMessage) {
          return firstMessage.push(el);
        }
        if (el.messages) {
          return sortByDate.push(el);
        }
        if (!el.messages && el.searchName) {
          return notMessages.push(el);
        }
        return null;
      });

      sortByDate.sort((a, b) => {
        const keysB = Object.keys(b.messages);
        const keysA = Object.keys(a.messages);
        return new Date(b.messages[keysB[keysB.length - 1]].dt) - new Date(a.messages[keysA[keysA.length - 1]].dt);
      });

      return [...firstMessage, ...sortByDate, ...notMessages];
    };

    // eslint-disable-next-line no-nested-ternary
    const sortRooms = Object.values(sortedUsers()).sort((a, b) => (a.pin === b.pin ? 0 : a.pin ? -1 : 1));
    const chatRoomsList = (!isEmpty(filters) ? filteredRoom : sortRooms).filter(room => room.details?.id);

    const refs = React.useMemo(
      () =>
        chatRoomsList.reduce((refsObj, room) => {
          refsObj[room.details.id] = React.createRef();
          return refsObj;
        }, {}),
      [chatRoomsList],
    );

    React.useEffect(() => {
      if (refs && floatingSelectedRoom && isFloatingChat && refs[floatingSelectedRoom]?.current) {
        refs[floatingSelectedRoom].current.scrollIntoView({
          block: 'start',
        });
        chatStore.cleanFloatingChat();
      }
    }, [floatingSelectedRoom]);

    if (loading) {
      return (
        <div className="chat-users__loading">
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
        </div>
      );
    }

    return (
      <div className="chat-users">
        <div className="chat-users__wrapper">
          <div className="chat-users__list">
            {chatRoomsList.length > 0 ? (
              chatRoomsList.map(room => (
                <ChatUser
                  key={room.details.id}
                  ref={refs[room.details.id]}
                  oppositeMember={room.oppositeMember}
                  room={roomsDetails[room.details.id]}
                  floating={floating}
                />
              ))
            ) : (
              <div className="chat-users__empty">
                <div className="chat-users__empty-img">
                  <ChatImg />
                </div>
                <div className="chat-users__empty-text">
                  <FormattedMessage id="chat.room.content.empty" />
                </div>
                <div className="chat-users__empty-info">
                  <FormattedMessage id="chat.room.content.empty.info" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
  {
    forwardRef: true,
  },
);

ChatUsers.defaultProps = {
  floating: PropTypes.bool,
};

ChatUsers.defaultProps = {
  floating: false,
};

export default injectIntl(ChatUsers);
