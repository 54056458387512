import {action, computed, extendObservable} from 'mobx';
import filter from 'lodash/filter';
import flattenDeep from 'lodash/flattenDeep';
import uniqBy from 'lodash/uniqBy';
import escapeRegExp from 'lodash/escapeRegExp';
import intersectionBy from 'lodash/intersectionBy';
import values from 'lodash/values';
import UtilsService from '../../../services/core/utils-service';
import {conformsOne} from '../../../utils/conformsOne';
import isEmpty from 'lodash/isEmpty';

export class ExhibitorsFilterStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    extendObservable(this, {
      searchFilters: {},
      filteredBySearch: [],
      selectedFiltersByCategories: {},
      filteredByCategories: [],
    });
  }

  @computed
  get selectedFiltersCount() {
    return flattenDeep(values(this.selectedFiltersByCategories)).length;
  }

  @computed
  get hasActiveFilters() {
    return !isEmpty(this.selectedFiltersByCategories) || !isEmpty(this.searchFilters);
  }

  @computed
  get filteredExhibitors() {
    if (this.filteredBySearch.length && isEmpty(this.selectedFiltersByCategories)) {
      return this.filteredBySearch;
    }
    if (this.filteredByCategories.length && isEmpty(this.searchFilters)) {
      return this.filteredByCategories;
    }
    if (this.filteredByCategories.length && this.filteredBySearch.length) {
      const uniqueArrays = [this.filteredByCategories, this.filteredBySearch].map(arr => uniqBy(arr ?? [], 'id'));
      return intersectionBy(...uniqueArrays, 'id');
    }

    return [];
  }

  @action
  setFiltersByCategories(name, value) {
    if (value?.length) {
      this.selectedFiltersByCategories[name] = value;
    } else {
      delete this.selectedFiltersByCategories[name];
    }

    this.filterByCategory();
  }

  @action
  filterSearch(properties, rule) {
    const list = UtilsService.mergeAsObject(this.rootStore.exhibitorStore.view.exhibitors, 'id');
    const re = new RegExp(escapeRegExp(rule), 'i');

    if (!rule) {
      this.removeSearchFilter(properties);
      return;
    }

    for (const property of properties) {
      if (property === 'categories') {
        this.searchFilters['categories'] = categories =>
          categories?.some(
            selectedCategory => selectedCategory.type === 'text' && re.test(selectedCategory.inputValue),
          );
        continue;
      }
      this.searchFilters[property] = val => re.test(val);
    }

    this.filteredBySearch = filter(list, item => conformsOne(item, this.searchFilters));
  }

  @action
  filterByCategory() {
    const list = UtilsService.mergeAsObject(this.rootStore.exhibitorStore.view.exhibitors, 'id');

    this.filteredByCategories = filter(list, listItem => {
      return Object.keys(this.selectedFiltersByCategories).every(key =>
        this.selectedFiltersByCategories[key].some(value =>
          listItem?.categories?.some(
            category =>
              category?.type === 'select' &&
              category?.selectedCategoryIds?.some(subCategory => value.includes(subCategory)),
          ),
        ),
      );
    });
  }

  @action
  removeSearchFilter(properties) {
    properties.forEach(property => {
      delete this.searchFilters[property];
      this[property] = null;
    });
  }

  @action
  removeFilterByCategory() {
    this.selectedFiltersByCategories = {};
    this.filteredByCategories = [];
  }

  @action
  cleanFilter() {
    this.searchFilters = {};
    this.filteredBySearch = [];
    this.selectedFiltersByCategories = {};
    this.filteredByCategories = [];
  }
}
