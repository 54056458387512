import React from 'react';
import PropTypes from 'prop-types';
import {Modal as ModalSemantic, Transition} from 'semantic-ui-react';
import {injectIntl} from 'react-intl';
import closeImg from '../../assets/images/icons/X.svg';
import './Modal.scss';

const Modal = props => {
  const {open, onClose, children, size} = props;

  return (
    <Transition visible={open} animation="fade down" duration={400}>
      <ModalSemantic
        closeIcon={<img className="close-icon" src={closeImg} alt="closeIcon" />}
        open={open}
        onClose={onClose}
        size={size}
        centered
        {...props}
      >
        <ModalSemantic.Content>{children}</ModalSemantic.Content>
      </ModalSemantic>
    </Transition>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.string,
};

Modal.defaultProps = {
  children: '',
  size: 'tiny',
};

export default injectIntl(Modal);
