import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';
import TimeService from '../../../services/core/time-service';
import {AppContext} from '../../../store';
import ImageLazy from '../../../ui/ImageLazy';
import bannerImg from '../../../assets/images/defaultImages/banner.jpg';
import {ReactComponent as LocationImg} from '../../../assets/images/icons/location.svg';
import './AgendaHeaderCard.scss';

const AgendaHeaderCard = observer(props => {
  const {session, preloadLazyImages} = props;
  const {eventStore} = React.useContext(AppContext);
  const {eventList} = eventStore;
  const eventDetails = eventList?.filter(event => event.id === session.eventId)[0];
  const imageUrl = eventDetails?.images.logo || bannerImg;
  const sessionLocation = session?.sessionDefinition?.mlocname;

  const classes = classNames('agenda-item agenda-item--session', {
    'agenda-item--past': session.expired || session.upcomingState === 'expired',
    'agenda-item--upcoming':
      !session.expired && session.startsAt > TimeService.now() && session.upcomingState !== 'expired',
    'agenda-item--live': session.upcomingState === 'started',
  });

  return (
    <div className={classes} onClick={props.onClick} aria-hidden>
      <div className="agenda-item__info">
        <div className="agenda-item__details">
          {sessionLocation ? (
            <span className="agenda-item__location">
              <LocationImg />
              {sessionLocation}
            </span>
          ) : null}
          {session.upcomingState === 'started' ? (
            <div className="agenda-item__label">
              <FormattedMessage id="agenda.item.live.label" />
            </div>
          ) : null}
          <div className="agenda-item__date">
            {session.sdts.monthFull}, {session.sdts.hour} - {session.edts.hour}
            {eventDetails?.type === 'normal' ? (
              <>
                {' '}
                <FormattedMessage id="meeting.date.eventTime" />
              </>
            ) : null}
          </div>
          {session.sdtsReal ? (
            <div className="agenda-item__date--event-datetime">
              {session.sdtsReal.monthFull}, {session.sdtsReal.hour} - {session.edtsReal.hour}{' '}
              <FormattedMessage id="meeting.date.yourTime" />
            </div>
          ) : null}
          <div className="agenda-item__event">{eventDetails ? eventDetails.name : null}</div>
        </div>
        <div className="agenda-item__event-logo">
          <ImageLazy src={imageUrl} errorSrc={bannerImg} alt={eventDetails.name} visibleByDefault={preloadLazyImages} />
        </div>
      </div>
      {props.children}
    </div>
  );
});

AgendaHeaderCard.propTypes = {
  session: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default injectIntl(AgendaHeaderCard);
