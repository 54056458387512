import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Search, Form} from 'semantic-ui-react';
import './Search.scss';

const SearchInput = props => {
  const {onSearchChange, className, label, value, placeholder, defaultValue} = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSearchChange = (e, {value}) => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 100);

    onSearchChange(value);
  };

  return (
    <Form.Field className={className}>
      {label ? <label>{label}</label> : null}
      {value !== undefined ? (
        <Search loading={isLoading} onSearchChange={handleSearchChange} placeholder={placeholder} value={value} />
      ) : (
        <Search
          loading={isLoading}
          onSearchChange={handleSearchChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
      )}
    </Form.Field>
  );
};

SearchInput.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
};

SearchInput.defaultProps = {
  label: '',
  className: '',
  placeholder: '',
  defaultValue: '',
};

export default SearchInput;
