import React from 'react';
import {observer} from 'mobx-react-lite';
import {Icon} from 'semantic-ui-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import ChatUsers from '../ChatUsers';
import ChatSearchUsers from '../ChatSearchUsers/ChatSearchUsers';
import FloatingChatWindow from '../FloatingChatWindow';
import './FloatingChat.scss';

const FloatingChat = observer(() => {
  const {chatStore, windowSizeStore} = React.useContext(AppContext);
  const {floatingSelectedRoom, getUnSeenMessages, errorLoad, loading} = chatStore;
  const isMobile = windowSizeStore.windowSizes === 'mobile';
  const [showChats, setShowChats] = React.useState(false);

  React.useEffect(() => {
    if (floatingSelectedRoom) {
      setShowChats(true);
    }
  }, [chatStore, floatingSelectedRoom]);

  const classes = classNames('floating-chat', {
    'floating-chat__open': showChats,
  });

  if (loading || errorLoad) {
    return null;
  }

  const chatFloatingAccordion = (
    <div
      aria-hidden
      className="floating-chat__accordion-title"
      onClick={() => {
        setShowChats(!showChats);
        chatStore.selectChat(null, true);
      }}
    >
      <div className="floating-chat__left">
        <div className="floating-chat__text">
          <FormattedMessage id="floating.chat.title" />
        </div>
        {getUnSeenMessages > 0 && (
          <div className="floating-chat__amount ui red label mini circular">{getUnSeenMessages}</div>
        )}
      </div>
      <div className="floating-chat__right">
        <div className="floating-chat__trigger">
          <Icon
            name="angle up"
            className={`${showChats ? 'open' : ''}`}
            onClick={() => {
              setShowChats(!showChats);
              chatStore.selectChat(null, true);
            }}
          />
        </div>
      </div>
    </div>
  );

  return !isMobile ? (
    <div className={classes}>
      {floatingSelectedRoom && showChats ? <FloatingChatWindow openChat={setShowChats} isOpen={showChats} /> : null}
      <div className="floating-chat__list">
        {chatFloatingAccordion}
        <div className="floating-chat__body">
          <ChatSearchUsers />
          <ChatUsers floating />
        </div>
      </div>
    </div>
  ) : null;
});

export default injectIntl(FloatingChat);
