import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import isEmpty from 'lodash/isEmpty';
import {useLocation} from 'react-router-dom';
import {AppContext} from '../../../store';
import AttendeeOpenChatBtn from '../AttendeeOpenChatBtn';
import AttendeeBtnsMore from '../AttendeeBtnsMore';
import InvitationButton from '../../invitations/InvitationButton';
import './AttendeeButtons.scss';

const AttendeeButtons = observer(props => {
  const {attendee, width} = props;
  const {userStore} = React.useContext(AppContext);
  const {user} = userStore;
  const location = useLocation();
  const openedAttendeeFromChat = location.state ? location.state.openedFromChat : null;

  return !isEmpty(attendee) ? (
    <div className="attendee-card__action">
      <AttendeeOpenChatBtn color="blue" basic={!width || width > 320} attendee={attendee} isShort={width <= 320} />
      {!openedAttendeeFromChat ? <InvitationButton attendee={attendee} containerWidth={width} /> : null}
        <div className="attendee-card__btn-more">
          <AttendeeBtnsMore attendee={attendee} user={user}/>
        </div>
    </div>
  ) : null;
});

AttendeeButtons.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
};

AttendeeButtons.defaultProps = {
  attendee: {},
};

export default AttendeeButtons;
