import React from 'react';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import {Checkbox} from 'semantic-ui-react';
import classNames from 'classnames';
import AgendaSearch from '../AgendaSearch';
import PreCallTestButton from '../../../layouts/PreCallTestButton/PreCallTestButton';
import {AppContext} from '../../../store';
import './AgendaFilter.scss';

const AgendaFilter = observer(props => {
  const {agendaStore, eventStore, sessionsNewStore} = React.useContext(AppContext);
  const {sessions} = sessionsNewStore.data;
  const {intl, isOpen} = props;
  const {eventDetails, activeEvents} = eventStore;
  const isActiveHybridOrOnline = activeEvents.filter(el => el?.type === 'hybrid' || el?.type === 'online').length > 0;
  const isCurrentEventHybridOrOnline = eventDetails?.eventType === 'hybrid' || eventDetails?.eventType === 'online';
  const hasSessions = sessions.length > 0;
  const typeHybridOrOnline = (isCurrentEventHybridOrOnline || isActiveHybridOrOnline) && hasSessions;

  return (
    <div className={classNames('agenda-filter', {'agenda-filter--pre-call-button': typeHybridOrOnline})}>
      <AgendaSearch collapseFilter={props.collapse} isOpen={isOpen} />
      {typeHybridOrOnline ? (
        <div>
          <PreCallTestButton type="agenda" />
        </div>
      ) : null}
      <div className={classNames('agenda-filter__full', {active: isOpen})}>
        <h2>Search in</h2>
        <div className="agenda-filter__checkbox">
          <Checkbox
            className="agenda-filter__item agenda-filter__item--sessions"
            label={intl.formatMessage({id: 'agenda.filter.label.session'})}
            defaultChecked
            onChange={(e, data) => {
              agendaStore.data.setVisible('sessions', data.checked);
            }}
          />
          <Checkbox
            className="agenda-filter__item agenda-filter__item--meetings"
            label={intl.formatMessage({id: 'agenda.filter.label.meetings'})}
            defaultChecked
            onChange={(e, data) => {
              agendaStore.data.setVisible('meetings', data.checked);
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default injectIntl(AgendaFilter);
