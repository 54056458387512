import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button} from 'semantic-ui-react';
import {runInAction} from 'mobx';
import {AppContext} from '../../../store';
import Search from '../../../ui/Search/Search';
import ContactsMyQrButton from '../ContactsMyQrButton';
import './ContactsHeader.scss';

const ContactsHeader = observer(props => {
  const {intl} = props;
  const {contactStore, modalStore} = React.useContext(AppContext);
  const {exportLoading} = contactStore;

  return (
    <div className="contacts-header">
      <div className="contacts-header__top">
        <h1>
          <FormattedMessage id="contacts.header.title" />
        </h1>
        <div className="contacts-header__action">
          <Button
            className="contacts-header__export link"
            onClick={() => contactStore.exportContacts()}
            loading={exportLoading}
          >
            <FormattedMessage id="contacts.header.export.btn" />
          </Button>
          <ContactsMyQrButton color="teal" />
          <Button
            className="contacts-header__scan"
            onClick={() => {
              runInAction(() => {
                contactStore.selectedContactNotes = [];
              });
              modalStore.open('addContactByQrModal');
            }}
            color="teal"
          >
            <FormattedMessage id="contacts.header.scanQr.btn" />
          </Button>
        </div>
      </div>
      <Search
        className="contacts-header__search"
        placeholder={intl.formatMessage({id: 'contacts.header.search'})}
        onSearchChange={value => contactStore.filterSearch('fullName', value)}
      />
    </div>
  );
});

export default injectIntl(ContactsHeader);
