import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Button, Form} from 'semantic-ui-react';
import {useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {AppContext} from '../../../store';
import InvitationApi from '../../../services/api/invitationApi';
import AttendeePreview from '../../attendee/AttendeePreview';
import ButtonLoading from '../../../ui/ButtonLoading';
import Title from '../../shared/Title';
import './InvitationFormDecline.scss';

const InvitationFormDecline = observer(props => {
  const intl = useIntl();
  const {invitation} = props;
  const {eventStore, userStore} = React.useContext(AppContext);
  const {eventList} = eventStore;
  const eventDetails = eventList.filter(e => e.id === invitation.eid)[0];
  const {user} = userStore;
  const {register, errors, getValues} = useForm();

  function onSubmit() {
    const message = getValues().message || intl.formatMessage({id: 'invite.form.decline.placeholder'});
    return InvitationApi.rejectInvitation(user.id, eventDetails.id, invitation.id, message, false).then(() => {
      props.onBack();
    });
  }

  return (
    <>
      <Title id="invitation-decline" />
      <Form className="invitation-decline">
        <AttendeePreview attendee={invitation.opponentParticipant} big />
        <div className="invitation-decline__message">{invitation.message}</div>
        <div className="invitation-decline__textarea">
          <Form.Field className="field__textarea" error={!!errors.username}>
            <label>Enter your message</label>
            <div className="ui left input">
              <textarea
                rows="4"
                name="message"
                placeholder={intl.formatMessage({id: 'invite.form.decline.placeholder'})}
                ref={register()}
              />
            </div>
          </Form.Field>
          <div className="invitation-decline__info">
            <FormattedMessage id="invite.form.decline.text.note" />
          </div>
        </div>
        <div className="invitation-decline__action">
          <Button basic color="red" onClick={() => props.onBack()}>
            <FormattedMessage id="invite.form.decline.btn.back" />
          </Button>
          <ButtonLoading color="red" onClick={() => onSubmit()}>
            <FormattedMessage id="invite.form.decline.btn.decline" />
          </ButtonLoading>
        </div>
      </Form>
    </>
  );
});

InvitationFormDecline.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.object]),
  onBack: PropTypes.func,
};

InvitationFormDecline.defaultProps = {
  onBack: () => {},
};

export default InvitationFormDecline;
