import Api from '../core/axios-service';
import {stores} from '../../store';
import {CommandExecutor} from '../core/command-executor';

const commandExecutor = new CommandExecutor();

const ExpoApi = {
  updateBoothDetails(boothDetails) {
    return Api.put('event/booth', boothDetails)
      .then(resp => {
        return Promise.resolve(resp);
      })
      .catch(error => Promise.reject(error.response.data));
  },
  getTeamMembers(eventId) {
    return Api.get('event/member', {params: {eventId}}).then(resp => {
      return resp;
    });
  },
  confirmInvitation(executorUserId, eventId, boothId, accepted) {
    return commandExecutor.execute(
      executorUserId,
      Api.post('/event/member/confirm', {eventId, boothId, accepted})
        .then(resp => resp.data)
        .catch(err => {
          stores.errorStore.addError(err);
          return err;
        }),
    );
  },
  addMember(eId, pId, userRole) {
    const member = {eventId: eId, id: pId, role: userRole};
    return Api.post('event/member', member)
      .then(() => stores.exhibitorStore.view.getTeamMembers(eId))
      .catch(err => err);
  },
  deleteMember(member, eId) {
    return Api.delete('event/member', {params: {memberId: member.id, eventId: eId}});
  },
  updateRole(pId, eId, userRole) {
    const member = {eventId: eId, id: pId, role: userRole};
    return Api.put('event/member', member)
      .then(() => stores.exhibitorStore.view.getTeamMembers(eId))
      .catch(err => err);
  },
};

export default ExpoApi;
