import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';
import {useHistory, useLocation} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';
import {AppContext} from '../../../store';
import TimeService from '../../../services/core/time-service';
import ImageLazy from '../../../ui/ImageLazy';
import ParserText from '../../../ui/ParserText';
import attendeeImg from '../../../assets/images/defaultImages/attendee.png';
import systemImg from '../../../assets/images/icons/systems-notification.png';
import './NotificationCard.scss';

const NotificationCard = observer(props => {
  const {notification, type, tags, intl} = props;
  const {activityStore, userStore} = React.useContext(AppContext);
  const {user} = userStore;
  const location = useLocation();
  const history = useHistory();

  const parseHtml = htmlParser({
    isValidNode: node => node.type !== 'script',
  });

  const classes = classNames('notification-card', {
    'notification-card--user': type === 'user',
    'notification-card--event': type === 'event',
    'notification-card--system': type === 'system',
    'notification-card--unread': !tags?.seen,
  });

  const msg = msgType => {
    if (msgType === 'invited_to_event') {
      return <FormattedMessage id="notification.card.invited-to-event" />;
    }
    if (msgType === 'confirmed') {
      return (
        <FormattedMessage
          id="notification.card.confirmed"
          values={{
            time: '09:30 22 Oct 2020',
          }}
        />
      );
    }
    if (msgType === 'session_cancelled') {
      return (
        <FormattedMessage
          id="notification.card.session-cancelled"
          values={{
            time: '09:30 22 Oct 2020',
          }}
        />
      );
    }
    if (msgType === 'rescheduled_session') {
      return (
        <FormattedMessage
          id="notification.card.rescheduled-session"
          values={{
            time: '09:30 22 Oct 2020',
          }}
        />
      );
    }
    if (msgType === 'announcement') {
      return <ParserText type={'html'}>{notification.det.text}</ParserText>;
    }
    if (msgType === 'poll') {
      return (
        <ReactMarkdown astPlugins={[parseHtml]}>
          {intl.formatMessage({id: 'notification.card.poll'}, {eventId: notification.det.eventId})}
        </ReactMarkdown>
      );
    }
    if (msgType === 'poll_finished') {
      return (
        <ReactMarkdown>
          {intl.formatMessage({id: 'notification.card.poll-finished'}, {eventId: notification.det.eventId})}
        </ReactMarkdown>
      );
    }
    return null;
  };

  return notification ? (
    <div className={classes} style={props.style}>
      <div className="notification-card__wrapper">
        <div className="notification-card__action">
          <div className="notification-card__time">
            {TimeService.dateTime.fromMillis(notification.dt).toFormat('ff')}
          </div>
          {!notification.seen ? (
            <div
              aria-hidden
              className="notification-card__read"
              onClick={() => activityStore.markRead(user.id, notification.id)}
            >
              <FormattedMessage id="notification.card.mark.as.read" />
            </div>
          ) : null}
        </div>
        <div className="notification-card__event">
          <div className="notification-card__img">
            {notification.event ? (
              <ImageLazy src={notification.event.images.logo} errorSrc={attendeeImg} alt={notification.event?.name} />
            ) : (
              <img aria-hidden src={systemImg} alt="notification" />
            )}
          </div>
          <div className="notification-card__details">
            <div className="notification-card__info">
              <div className="notification-card__title">
                {notification.type === 'invited_to_event' ? (
                  <h4 className="notification-card__name">New Event:</h4>
                ) : (
                  <h4
                    className="notification-card__name"
                    aria-hidden
                    onClick={() => {
                      history.push({
                        pathname: `/event/${notification.event.id}/sessions/${notification.sesId}`,
                        state: {
                          background: location,
                          page: 'sessions',
                          title: notification.session.name,
                        },
                      });
                    }}
                  >
                    {notification.session?.name}
                  </h4>
                )}
                <span
                  aria-hidden
                  className="notification-card__event"
                  onClick={() => {
                    history.push({
                      pathname: `/event/${notification.event.id}/` + notification.event.indexPagePath,
                    });
                  }}
                >
                  {notification.event?.name}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="notification-card__text">{msg(notification.type)}</div>
      </div>
    </div>
  ) : null;
});

NotificationCard.propTypes = {
  notification: PropTypes.oneOfType([PropTypes.object]),
  type: PropTypes.oneOf(['event', 'user', 'system']),
  tags: PropTypes.oneOfType([PropTypes.object]),
};

export default injectIntl(NotificationCard);
