import * as React from 'react';
import {configure} from 'mobx';
import {RouterStore} from 'mobx-react-router';
import {FirebaseStore} from './appStore/FirebaseStore';
import {UserStore} from './appStore/UserStore';
import {EventStore} from './appStore/EventStore';
import {ModalStore} from './uiStore/ModalStore';
import {LanguageStore} from './uiStore/LanguageStore';
import {UiStore} from './uiStore/UiStore';
import {WindowSizeStore} from './uiStore/WindowSizeStore';
import {ErrorStore} from './uiStore/ErrorStore';
import {AnnouncementStore} from './domainStore/announcementStore';
import {SpeakerStore} from './domainStore/speakerStore';
import {SessionsNewStore} from './domainStore/sessionStore';
import {AgendaStore} from './domainStore/agendaStore';
import {ParticipantStore} from './domainStore/participantStore';
import {PollStore} from './domainStore/pollStore';
import {ExhibitorStore} from './domainStore/exhibitorStore';
import {InvitationStore} from './domainStore/invitationStore';
import {ChatStore} from './domainStore/chatStore';
import {ActivityStore} from './domainStore/activityStore';
import {ContactStore} from './domainStore/contactStore';
import {CheckInStore} from './domainStore/checkInStore';

configure({enforceActions: 'always'});

export class RootStore {
  constructor() {
    // app store
    this.firebaseStore = new FirebaseStore(this);
    this.userStore = new UserStore(this);
    this.eventStore = new EventStore(this);

    // ui store
    this.windowSizeStore = new WindowSizeStore(this);
    this.modalStore = new ModalStore(this);
    this.languageStore = new LanguageStore(this);
    this.uiStore = new UiStore(this);
    this.routerStore = new RouterStore(this);
    this.errorStore = new ErrorStore(this);

    // domain store
    this.participantStore = new ParticipantStore(this);
    this.sessionsNewStore = new SessionsNewStore(this);
    this.agendaStore = new AgendaStore(this);
    this.speakerStore = new SpeakerStore(this);
    this.exhibitorStore = new ExhibitorStore(this);
    this.announcementStore = new AnnouncementStore(this);
    this.pollStore = new PollStore(this);
    this.invitationStore = new InvitationStore(this);
    this.chatStore = new ChatStore(this);
    this.activityStore = new ActivityStore(this);
    this.contactStore = new ContactStore(this);
    this.checkInStore = new CheckInStore(this);
  }
}

const stores = new RootStore();

const AppContext = React.createContext(stores);

export {stores, AppContext};
