import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'semantic-ui-react';
import {Controller} from 'react-hook-form';
import {injectIntl, useIntl} from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PhoneNumberField.scss';

const PhoneNumberField = props => {
  const {name, required, label, error, disabled, defaultValue, requiredLabel} = props;
  const intl = useIntl();
  const fieldOptional = req => {
    if (req && requiredLabel) {
      return <span className="optional">(required)</span>;
    }
    if ((!req && requiredLabel) || (req && !requiredLabel)) {
      return '';
    }
    return <span className="optional">(optional)</span>;
  };

  return (
    <Form.Field className="field__phone" error={!!error} disabled={disabled}>
      {label ? (
        <label htmlFor={name}>
          {intl.formatMessage({id: 'app.field.phone'})}
          {fieldOptional(required)}
        </label>
      ) : null}
      <Controller
        render={({onChange: onValueChange}) => (
          <PhoneInput
            containerClass="ui input"
            value={props.defaultValue}
            onChange={phone => onValueChange(phone)}
            enableSearch
            disableSearchIcon
            areaCodes={{kz: ['777']}}
            inputProps={{
              name: 'phone',
              required,
            }}
          />
        )}
        rules={{
          required: {
            value: required,
            message: intl.formatMessage({id: 'validation.error.required'}, {name}),
          },
        }}
        name={name}
        defaultValue={defaultValue}
      />
      {error ? <div className="error">{error.message}</div> : null}
    </Form.Field>
  );
};

PhoneNumberField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object]),
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  requiredLabel: PropTypes.bool,
};

PhoneNumberField.defaultProps = {
  name: '',
  required: false,
  label: '',
  error: null,
  disabled: false,
  defaultValue: '',
  requiredLabel: false,
};

export default injectIntl(PhoneNumberField);
