import React from 'react';
import {observer} from 'mobx-react-lite';
import Modal from '../../../../ui/Modal';
import {AppContext} from '../../../../store';
import TimezoneForm from '../../../shared/TimezoneForm';
import TimeService from '../../../../services/core/time-service';
import './TimezoneModal.scss';

const TimezoneModal = observer(() => {
  const {modalStore, userStore} = React.useContext(AppContext);
  const {open, registeredNewUser} = modalStore.modals.timezoneModal;
  const {user} = userStore;

  const onClose = () => {
    modalStore.close('timezoneModal');
    userStore.setSkippedTimezone(user.timezone, TimeService.getMyLocalTimezone());
  };

  return (
    <Modal className="timezone-modal" open={open} onClose={onClose} size="mini">
      <TimezoneForm registeredNewUser={registeredNewUser} />
    </Modal>
  );
});

export default TimezoneModal;
