import {action, extendObservable, runInAction} from 'mobx';
import firebase from 'firebase/app';
import {Html5Qrcode} from 'html5-qrcode';
import {ExportToCsv} from 'export-to-csv';
import filter from 'lodash/filter';
import conforms from 'lodash/conforms';
import escapeRegExp from 'lodash/escapeRegExp';
import throttle from 'lodash/throttle';
import UtilsService from '../../../services/core/utils-service';
import TimeService from '../../../services/core/time-service';
import ContactApi from '../../../services/api/contactApi';

const config = {fps: 10, qrbox: {width: 250, height: 250}};

export class ContactStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    extendObservable(this, {
      qrScanner: null,
      camQrResult: null,
      listCameras: [],
      matchingContacts: [],
      scannedContacts: [],
      mode: 'notStarted',
      contactForm: {},
      selectedContact: null,
      selectedContactNotes: [],
      showAddNote: false,
      selectedNote: null,
      exportLoading: false,
      initScanning: false,
      notFoundContact: false,
      error: null,
      filteredContacts: [],
      filterCriteria: ['fullName'],
      filterOptions: {},
      filters: {},
    });
  }

  @action
  list(userId) {
    firebase
      .database()
      .ref(`/scannedContacts/${userId}`)
      .on('value', data => {
        const next = data.val();
        if (next == null) {
          return [];
        }
        const contacts = [];
        for (const contactId in next) {
          const contact = next[contactId];
          contact.fullName = contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.firstName;
          contacts.push(contact);
        }
        runInAction(() => {
          this.scannedContacts = contacts.sort((o1, o2) => (o1.fullName > o2.fullName ? 1 : -1));
        });
        this.setOptionsForFilters(this.scannedContacts);
      });
  }

  @action
  initScan() {
    this.initScanning = true;
    this.error = null;

    this.qrScanner = new Html5Qrcode('reader');
    Html5Qrcode.getCameras()
      .then(devices => {
        devices.forEach(camera => {
          let option = {};
          option.value = camera.id;
          option.text = camera.label;
          runInAction(() => {
            this.listCameras.push(option);
          });
        });
        if (this.listCameras.length) {
          this.startScan();
        }
      })
      .catch(e => {
        runInAction(() => {
          this.error = typeof e !== 'object' ? e : null;
          this.initScanning = false;
        });
      });
  }

  @action
  addContact(value) {
    const event = this.rootStore.eventStore.eventDetails;

    let val = UtilsService.cloneData(value);
    if (event && event.id) {
      val.events = [event.id];
    }
    if (val.emails) {
      val.emails = [val.emails];
    }
    if (val.phone) {
      val.phones = [val.phone];
      delete val.phone;
    }
    return ContactApi.addContact(val);
  }

  @action
  processScannedData() {
    const email = this.contactForm.email == null ? null : this.contactForm.email.trim().toLowerCase();
    let phone = null;
    if (this.contactForm.phone != null) {
      phone = this.contactForm.phone.trim().toLowerCase();
      if (phone.charAt(0) === '+') phone = `00${phone.substring(1)}`;
    }
    this.matchingContacts = [];
    this.scannedContacts.forEach(scannedContact => {
      if (
        (scannedContact.emails != null && scannedContact.emails.indexOf(email) >= 0) ||
        (scannedContact.phones != null && scannedContact.phones.indexOf(phone) >= 0)
      ) {
        this.matchingContacts.push(scannedContact);
      }
    });

    if (this.matchingContacts.length > 0) {
      this.setMode('foundContact');
    } else {
      this.setMode('addContact');
    }
  }

  @action
  setContactForm(contactForm) {
    this.contactForm = contactForm;
  }

  @action
  setMode(mode) {
    this.mode = mode;
  }

  startScan() {
    const isMobile = this.rootStore.windowSizeStore.windowSizes === 'mobile';
    this.setMode('scanning');

    const getScannedContactDetails = throttle(
      (nextText, result) => {
        const {eventDetails, activeEvents, pastEvents} = this.rootStore.eventStore;
        const currentEventIsPast = pastEvents.some(event => event.id === eventDetails?.id);
        let events = activeEvents;
        let contactsObservables = [];
        if (eventDetails && currentEventIsPast) {
          events = [eventDetails];
        }
        events.forEach(event =>
          contactsObservables.push(
            this.getScannedContactDetails(event.id, nextText).then(null, err => {
              console.log('err', err);
            }),
          ),
        );
        Promise.all(contactsObservables).then(contacts => {
          const foundContacts = contacts.filter(contacts => !!contacts);
          if (foundContacts.length) {
            this.stopScan();
            this.setContactForm({
              firstName: foundContacts[0].firstName,
              lastName: foundContacts[0].lastName,
              email: foundContacts[0].email,
              company: foundContacts[0].company,
              phone: foundContacts[0].phone,
              jobTitle: foundContacts[0].jobTitle,
            });
            this.processScannedData();
          } else {
            runInAction(() => {
              if (result) {
                this.notFoundContact = true;
              }
            });
          }
        });
      },
      2000,
      {leading: true, trailing: false},
    );

    this.qrScanner
      .start({facingMode: isMobile ? 'environment' : 'user'}, config, result => {
        runInAction(() => {
          this.camQrResult = result;
        });
        if (result == null || result.trim().length === 0) return;

        const next = UtilsService.parseVCard(result);
        try {
          if (next.jCardContact) {
            this.stopScan();
            this.setContactForm(next.jCardContact);
            this.processScannedData();
          } else if (Object.keys(this.contactForm).length <= 0) {
            getScannedContactDetails(next.text, result);
          } else {
            return null;
          }
        } catch (err) {
          console.log('err', err);
        }
      })
      .then(() => {
        runInAction(() => {
          this.initScanning = false;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.initScanning = false;
        });
      });

    // TODO Quick fix for small qr codes. Need to improve!
    setTimeout(() => {
      this.qrScanner
        .applyVideoConstraints({
          focusMode: 'continuous',
          advanced: [{zoom: 3.0}],
        })
        .then(r => {
          console.log(r, 'r');
        })
        .catch(e => {
          console.log(e, 'e');
        });
    }, 2000);
  }

  setCamera(value) {
    this.qrScanner.setCamera(value);
  }

  @action
  stopScan() {
    if (this.qrScanner?.isScanning) {
      this.qrScanner.stop();
      this.qrScanner = null;
    }
    this.camQrResult = null;
    this.notFoundContact = false;
    this.listCameras = [];
    this.setMode('notStarted');
    this.setContactForm({});
  }

  @action
  exportContacts() {
    this.exportLoading = true;
    let contacts = JSON.parse(JSON.stringify(this.scannedContacts));
    const notesObservables = [];
    contacts.forEach(contact => {
      notesObservables.push(this.listNotes(contact.id));
    });
    return Promise.all([Promise.all(notesObservables)])
      .then(allNotes => {
        allNotes[0].forEach(contactNotes => {
          if (contactNotes) {
            let contactId = null;
            const notes = [];
            for (const noteId in contactNotes) {
              const contactNote = contactNotes[noteId];
              contactId = contactNote.contactId;
              notes.push(`${contactNote.note}\n${contactNote.insertedAtStr}`);
            }

            const notesStr = notes.join('\n==========\n');
            const founCOntact = contacts.find(contact => {
              return contact.id === contactId;
            });
            if (founCOntact) {
              founCOntact.notes = notesStr;
            }
          }
        });

        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'Scanned Contact List',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          filename: 'scanned_contacts.csv',
        };

        const csvExporter = new ExportToCsv(options);
        contacts = contacts.map(contact => {
          return {
            FirstName: contact.firstName ? contact.firstName : '',
            LastName: contact.lastName ? contact.lastName : '',
            Company: contact.company ? contact.company : '',
            JobTitle: contact.jobTitle ? contact.jobTitle : '',
            Website: contact.website ? contact.website : '',
            Emails: contact.emails ? contact.emails.join(', ') : '',
            Phones: contact.phones ? contact.phones.join(', ') : '',
            Notes: contact.notes ? contact.notes : '',
          };
        });
        csvExporter.generateCsv(contacts);
        runInAction(() => {
          this.exportLoading = false;
        });
        return contacts;
      })
      .catch(() => {
        runInAction(() => {
          this.exportLoading = false;
        });
      });
  }

  @action
  showDetails(contact) {
    if (contact.events) {
      const events = [];
      contact.events.forEach(eventId => {
        events.push(this.rootStore.eventStore.eventList?.filter(e => e.id === eventId)[0]);
      });
      contact.eventObjects = events;
    }
    this.selectedContact = contact;
    this.listNotes(contact.id);
  }

  @action
  listNotes(contactId) {
    const userId = this.rootStore.userStore.user.id;
    const ref = firebase.database().ref(`notes/${userId}/${contactId}`);

    return new Promise(resolve =>
      ref.on('value', data => {
        const next = data.val();
        const notes = [];
        for (const noteId in next) {
          const note = next[noteId];
          note.insertedAtStr = TimeService.dateTime
            .fromMillis(note.insertedAt / 1000000)
            .toFormat('yyyy-LL-dd HH:mm:ss');
          notes.push(note);
        }
        runInAction(() => {
          this.selectedContactNotes = notes.sort((o1, o2) => (o2.insertedAt > o1.insertedAt ? 1 : -1));
        });

        return resolve(notes);
      }),
    );
  }

  @action
  addNote() {
    this.showAddNote = true;
    this.selectedNote = null;
  }

  @action
  updateNote(note) {
    this.showAddNote = true;
    this.selectedNote = note;
  }

  @action
  saveNote(val) {
    let noteObj = {};
    noteObj.note = val;
    noteObj.contactId = this.selectedContact.id;
    noteObj.contactType = 'scan';
    if (this.selectedNote) {
      noteObj.id = this.selectedNote.id;
    }

    return ContactApi.saveNote(noteObj).then(next => {
      runInAction(() => {
        this.showAddNote = false;
        this.selectedNote = null;
      });
      return next;
    });
  }

  @action
  cancelNote() {
    this.showAddNote = false;
    this.selectedNote = null;
  }

  @action
  getScannedContactDetails(eventId, ticketId) {
    // eslint-disable-next-line no-useless-escape
    if (/[#$\[\]'?]/g.test(ticketId)) {
      return new Error("Paths must be non-empty strings and can't contain '.', '#', '$', '[', or ']'");
    }

    return ContactApi.getContactDetails(ticketId, eventId)
      .then(resp => {
        if (resp?.data && !Object.keys(resp.data).includes('errorMessage')) {
          return resp.data;
        }
        return null;
      })
      .catch(err => {
        console.log('err', err);
      });
  }

  @action
  setOptionsForFilters(contacts) {
    if (Object.keys(contacts).length > 0) {
      Object.keys(contacts).forEach(id => {
        const contact = contacts[id];

        this.filterCriteria.forEach(criteria => {
          if (contact[criteria]) {
            if (!this.filterOptions[criteria]) {
              this.filterOptions[criteria] = [contact[criteria]];
            } else if (!this.filterOptions[criteria].includes(contact[criteria])) {
              this.filterOptions[criteria].push(contact[criteria]);
            }
          }
        });
      });
    }
  }

  @action
  applyFilter() {
    this.filteredContacts = filter(this.scannedContacts, conforms(this.filters));
  }

  @action
  filterSearch(property, rule) {
    const re = new RegExp(escapeRegExp(rule), 'i');
    if (!rule) {
      this.removeFilter(property);
      return;
    }
    this.filters[property] = val => re.test(val);
    this.applyFilter();
  }

  @action
  removeFilter(property) {
    delete this.filters[property];
    this[property] = null;
    this.applyFilter();
  }

  @action
  cleanFilter() {
    this.filters = {};
    this.filterOptions = {};
  }
}
