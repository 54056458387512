import React from 'react';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import {Slide, ToastContainer} from 'react-toastify';
// import {AppContext} from '../../../store';
import 'react-toastify/dist/ReactToastify.min.css';

const NotificationsPopup = observer(() => (
  // const {errorStore} = React.useContext(AppContext);
  // const {errors} = errorStore;

  /*
  if (errors.length > 0) {
    toast.error(errorStore.errors.join(` \n`), {
      onClose: () => errorStore.clear(),
    });
  }
  */

  <ToastContainer
    position="top-right"
    transition={Slide}
    autoClose={10000}
    hideProgressBar
    newestOnTop
    rtl={false}
    pauseOnVisibilityChange
    draggable
    pauseOnHover
  />
));

export default injectIntl(NotificationsPopup);
