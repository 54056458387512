import React from 'react';
import {useHistory} from 'react-router-dom';

const useChangeHistoryCallbackCallbackAction = (backCallbackAction = () => {}, forwardCallbackAction = () => {}) => {
  const [locationKeys, setLocationKeys] = React.useState([]);
  const history = useHistory();

  React.useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
          forwardCallbackAction();
        } else {
          setLocationKeys(keys => [location.key, ...keys]);

          // Handle back event
          backCallbackAction();
        }
      }
    });
  }, [locationKeys]);
};

export default useChangeHistoryCallbackCallbackAction;
