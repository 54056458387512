import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Form} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {useForm, FormProvider} from 'react-hook-form';
import {FormattedMessage, injectIntl} from 'react-intl';
import {AppContext} from '../../../store';
import UniversalField from '../../shared/UniversalField';
import {ContactFields} from './ContactFields';
import ContactNote from '../ContactNote';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import './ContactForm.scss';

const ContactForm = observer(props => {
  const {contact} = props;
  const {contactStore, modalStore} = React.useContext(AppContext);
  const {contactForm, selectedContact} = contactStore;
  const methods = useForm();
  const [addContact, setAddContact] = React.useState(false);
  const fieldList = ContactFields;

  const onSubmit = value => {
    value.phones = value.phones ? [value.phones] : [];

    setAddContact(true);
    contactStore
      .addContact(value)
      .then(() => {
        NotificationAlert.success('Successfully added contact.');
        modalStore.close('addContactModal');
        modalStore.close('addContactByQrModal');
        setAddContact(false);
      })
      .catch(err => {
        console.log('er', {err});
        NotificationAlert.error('Could not added contact. ' + err.message);
        setAddContact(false);
      });
  };

  return (
    <FormProvider {...methods}>
      <Form className="contact-form" error={!isEmpty(methods.errors)}>
        <div className="contact-form__fields">
          {fieldList.map(field => {
            return (
              <UniversalField
                key={field.fieldName}
                label={field.fieldName}
                name={field.fieldName === 'phone' ? 'phones' : field.fieldName}
                type={field.type}
                defaultValue={
                  contactForm[field.fieldName === 'emails' ? 'email' : field.fieldName] ||
                  (field.fieldName === 'emails'
                    ? contact && contact['emails'] && contact['emails'][0]
                    : contact && contact[field.fieldName]) ||
                  contactForm[field.fieldName === 'phones' ? 'phone' : field.fieldName] ||
                  (field.fieldName === 'phones'
                    ? contact && contact['phones'] && contact['phones'][0]
                    : contact && contact[field.fieldName]) ||
                  ''
                }
                required={field.required}
                disabled={field.disabled || !!contact}
                dataList={field.dataList}
                placeholder={field.fieldName}
                error={methods.errors[field.fieldName]}
              />
            );
          })}
          {contact ? (
            <>
              <ContactNote />
              <div className="contact-form__events">
                <label>Encountered Events</label>
                {selectedContact.eventObjects && selectedContact.eventObjects.length > 0 ? (
                  selectedContact.eventObjects.map(eventObject => <div key={eventObject.id}>{eventObject?.name}</div>)
                ) : (
                  <div>No encountered events are found</div>
                )}
              </div>
            </>
          ) : null}
        </div>
        {!contact ? (
          <Button color="teal" onClick={methods.handleSubmit(onSubmit)} loading={addContact} disabled={addContact}>
            <FormattedMessage id="contact.form.btn" />
          </Button>
        ) : null}
      </Form>
    </FormProvider>
  );
});

export default injectIntl(ContactForm);
