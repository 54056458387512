import React from 'react';
import {observer} from 'mobx-react-lite';
import {Form} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {useForm, FormProvider} from 'react-hook-form';
import {FormattedMessage, injectIntl} from 'react-intl';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import UniversalField from '../../shared/UniversalField';
import {UserFields} from './UserFields';
import ButtonLoading from '../../../ui/ButtonLoading';
import './UserDetailsForm.scss';

const UserDetailsForm = observer(() => {
  const {userStore} = React.useContext(AppContext);
  const {user, error} = userStore;
  const methods = useForm();

  const onSubmit = value =>
    userStore
      .updateUserDetails(
        user.id,
        {
          action: 'profile',
          channels: {sms: user.channels.sms},
          name: value.name,
          surname: value.surname,
          timezone: value.timezone,
        },
        true,
      )
      .then(() => {
        NotificationAlert.success(<FormattedMessage id="account.form.success" />);
      })
      .catch(err => {
        NotificationAlert.error(err.message);
      });

  return (
    <FormProvider {...methods}>
      <Form className="user-details-form" error={!isEmpty(methods.errors) || !!error}>
        <div className="user-details-form__fields equal width fields">
          {UserFields.map(field => {
            return (
              <UniversalField
                key={field.fieldName}
                label={field.fieldName}
                name={field.fieldName}
                type={field.type}
                defaultValue={user[field.fieldName] || ''}
                required={field.required}
                disabled={field.disabled}
                dataList={field.dataList}
                placeholder={field.placeholder}
                error={methods.errors[field.fieldName]}
              />
            );
          })}
        </div>
        <ButtonLoading onClick={methods.handleSubmit(onSubmit)} color="teal">
          <FormattedMessage id="account.form.btn" />
        </ButtonLoading>
      </Form>
    </FormProvider>
  );
});

export default injectIntl(UserDetailsForm);
