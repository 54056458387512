import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Button, Dropdown, Form} from 'semantic-ui-react';
import {useForm} from 'react-hook-form';
import Modal from '../../../ui/Modal';
import {FormattedMessage, useIntl} from 'react-intl';
import {AppContext} from '../../../store';
import AttendeePreview from '../../attendee/AttendeePreview/AttendeePreview';
import InvitationApi from '../../../services/api/invitationApi';
import ButtonLoading from '../../../ui/ButtonLoading';
import MeetingTimeSlots from '../../meeting/MeetingTimeSlots';
import {redirectBack} from '../../../services/helpers/redirectHelper';
import useMedia from '../../../hooks/useMedia';
import './PreBookedMeeting.scss';
import PersonItem from "../../../ui/PersonItem";
import debounce from "lodash/debounce";
import MeetingApi from "../../../services/api/meetingApi";

const PreBookedMeeting = observer(props => {
  const {eventStore, agendaStore, userStore, participantStore, modalStore} = React.useContext(AppContext);
  const {open, attendee} = modalStore.modals.preBookedMeetingModal;
  const intl = useIntl();
  // const {eventList} = eventStore;
  // const eventDetails = eventList.filter(e => e.id === attendee.eventId)[0];
  const {user} = userStore;
  const {selectedSessionId, availableSessions, availableSessionDayIndex} = participantStore;
  const {register, errors, getValues} = useForm();
  const isMobile = useMedia('(max-width: 768px)');
  const [loading, setLoading] = React.useState(false);
  const [selectedParticipant, setSelectedParticipant] = React.useState(null);
  const {participants} = agendaStore.data;
  React.useEffect(() => {
    if(selectedParticipant && attendee) {
      participantStore.availableSessionsForParticipants(attendee,
        selectedParticipant);
    }
  }, [attendee?.id, selectedParticipant?.id]);


  const debouncedSave = React.useCallback(
    debounce(newValue => {
      setLoading(true);
      return participantStore
      .search({queryText: newValue, filters: []}, attendee.eventId, true)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    }, 400),
    [attendee?.id],
  );

  const updateValue = newValue => {
    return debouncedSave(newValue);
  };
  function onSubmit() {
    const slots = Array.from(availableSessions.availableSessions[availableSessionDayIndex]?.slots || []);
    const selectedSlot = slots.find(slot => slot.id === selectedSessionId);
    const messageText = getValues().message || intl.formatMessage({id: 'invite.form.respond.default.text'});

    const message = selectedSlot.sdtStr
      ? intl.formatMessage(
          {id: 'invite.form.respond.message'},
          {
            message: messageText,
            startTime: selectedSlot.sdtStr,
            span: (...chunks) => `<span>${chunks.join('')}</span>`,
          },
        )
      : messageText;

    return MeetingApi.preBookMeeting(
      user.id,
      attendee.eventId,
      selectedSessionId,
      attendee.id,
      selectedParticipant.id,
      message
    ).then(() => {
      if (isMobile) {
        redirectBack(location);
      } else {
        closeModal()
      }
    }, err=>{
      console.log(err)
    });
  }
  const  closeModal = ()=>{
    setSelectedParticipant(null);
    modalStore.close('preBookedMeetingModal')
  }
  return (
    <Modal
      className="pre-booked-meeting-modal"
      open={open}
      onClose={() => closeModal()}
    >
      <h3>
        <FormattedMessage id="prebook.modal.header" />
      </h3>


      <div className="pre-booked-meeting">
        <div className="pre-booked-meeting__select">
          <Dropdown
            fluid
            search
            selection
            multiple={false}
            clearable
            loading={loading}
            onSearchChange={(event, value) => updateValue(value.searchQuery)}
            placeholder={intl.formatMessage({id: 'meeting.form.add.guest.placeholder'})}
            onChange={(event, data) => {
              if (data.value) {
                setSelectedParticipant(data.value);
              } else {
                setSelectedParticipant(null);
              }
            }}
            options={participants
            .filter(el => el.id)
            .map(participant => ({
              key: participant.id,
              content: <PersonItem person={participant} fullImageLink />,
              text: `${participant.name} ${participant.surname}`,
              value: participant,
              className: 'meeting-add-guest__attendee',
            }))}
          />
        </div>
        {
          selectedParticipant?
            <>
              <AttendeePreview attendee={selectedParticipant} big />
              <MeetingTimeSlots attendee={selectedParticipant} />
              <Form.Field className="field__textarea" error={!!errors.username}>
                <div className="ui left input">
                <textarea
                  rows="4"
                  name="message"
                  placeholder={intl.formatMessage({id: 'invite.form.respond.default.text'})}
                  ref={register()}
                />
                </div>
              </Form.Field>
            </>:""
        }
        <div className="pre-booked-meeting__action">
          <Button color="red" basic className="btn-link__back" onClick={()=>{
            closeModal()
          }
          }>
            <FormattedMessage id="invite.form.respond.btn.back" />
          </Button>
          <ButtonLoading color="blue" disabled={selectedSessionId == null} onClick={() => onSubmit()}>
            <FormattedMessage id="prebook.form.btn.book" />
          </ButtonLoading>
        </div>
      </div>
    </Modal>
  );
});

PreBookedMeeting.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
  invitation: PropTypes.oneOfType([PropTypes.object]),
};

export default PreBookedMeeting;
