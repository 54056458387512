import {useState, useEffect} from 'react';
import {useFetch} from './useFetch';
import ContactApi from '../services/api/contactApi';
import {badgeImage} from '../services/helpers/printHelper';

const useBadges = (defaultBadge, contact) => {
  const [badge, setBadge] = useState(null);
  const [badges, setBadges] = useState(null);
  const [previewImg, setPreviewImg] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const getBadges = useFetch({
    fetchFn: ContactApi.getBadges,
    immediate: false,
  });

  const getBadge = useFetch({
    fetchFn: ContactApi.getBadge,
    immediate: false,
  });

  useEffect(() => {
    if (!badge) {
      getBadges
        .makeRequest()
        .then(resp => {
          const badgesLoaded = Array.isArray(resp.data) && resp.data.length;
          badgesLoaded && setBadges(resp.data);
          const badgeId = defaultBadge || (badgesLoaded ? resp.data[0].id : null);

          if (badgeId) {
            getBadge
              .makeRequest({id: badgeId})
              .then(resp => {
                if (resp.data) {
                  const badgeObj = resp.data;
                  setBadge(badgeObj);
                  if (badgeObj.type === 'badge' && contact) {
                    const badgeTpl = JSON.parse(badgeObj.templates['BadgeFrontCanvas.template']) || {};
                    badgeImage(badgeTpl, contact).then(img => setPreviewImg(img));
                  }
                }
              })
              .finally(() => setIsLoading(false));
          } else {
            setBadge({});
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [defaultBadge, badge, contact]);

  return {badge, badges, previewImg, isLoading};
};

export default useBadges;
