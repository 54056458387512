import React from 'react';
import {observer} from 'mobx-react-lite';
import Modal from '../../../../ui/Modal';
import ImageUpload from '../../../shared/ImageUpload';
import {AppContext} from '../../../../store';

const ImageUploadModal = observer(() => {
  const {modalStore} = React.useContext(AppContext);
  const {open, type, maxWidth, maxHeight} = modalStore.modals.imageUpload;

  return (
    <Modal className="image-upload-modal" open={open} onClose={() => modalStore.close('imageUpload')} size="small">
      <ImageUpload type={type} maxWidth={maxWidth} maxHeight={maxHeight} />
    </Modal>
  );
});

export default ImageUploadModal;
