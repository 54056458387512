import React from 'react';
import {observer} from 'mobx-react-lite';
import {runInAction} from 'mobx';
import {FormattedMessage} from 'react-intl';
import Modal from '../../../../ui/Modal';
import {AppContext} from '../../../../store';
import ContactScanWindow from '../../../contact/ContactScanWindow';
import ContactForm from '../../../contact/ContactForm';
import './AddContactByQrModal.scss';

const AddContactByQrModal = observer(() => {
  const {modalStore, contactStore} = React.useContext(AppContext);
  const {open, contact} = modalStore.modals.addContactByQrModal;
  const {mode} = contactStore;

  React.useEffect(() => {
    if (mode === 'foundContact') {
      modalStore.close('addContactByQrModal');
    }
  }, [mode]);

  return (
    <Modal
      className="add-contact-qr-modal"
      open={open}
      onClose={() => {
        modalStore.close('addContactByQrModal');
      }}
      onUnmount={() => {
        runInAction(() => {
          modalStore.modals.addContactByQrModal.contact = null;
        });
        contactStore.stopScan();
      }}
      size="tiny"
    >
      <div className="add-contact-qr-modal__wrapper">
        <h3>
          <FormattedMessage id="add.Contact.byQr.modal" />
        </h3>
        {!contact && (mode === 'notStarted' || mode === 'scanning') ? <ContactScanWindow /> : null}
        {contact || mode === 'addContact' ? <ContactForm contact={contact} /> : null}
      </div>
    </Modal>
  );
});

export default AddContactByQrModal;
