import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Form} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {useForm, FormProvider} from 'react-hook-form';
import {FormattedMessage, injectIntl} from 'react-intl';
import {AppContext} from '../../../store';
import UniversalField from '../../shared/UniversalField';
import {PresenceConfirmationFields} from './PresenceConfirmationFields';
import './PresenceConfirmationForm.scss';

const PresenceConfirmationForm = observer(props => {
  const {onSubmitFunction} = props;
  const {userStore, eventStore, participantStore, modalStore} = React.useContext(AppContext);
  const {error, user} = userStore;
  const {meParticipant, eventDetails} = eventStore;
  const methods = useForm();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    onSubmitFunction.current = onSubmit;
  }, []);

  const onSubmit = value => {
    setLoading(true);
    const pProfileData = {
      action: 'all',
      eventId: eventDetails.id,
      id: meParticipant.id,
      company: meParticipant.company || '',
      jobTitle: meParticipant.jobTitle || '',
      name: user.name,
      surname: user.surname,
      timezone: meParticipant.timezone,
      offer: meParticipant.offer || '',
      seek: meParticipant.seek || '',
      biography: meParticipant.biography || '',
      some: meParticipant.some || {
        facebook: meParticipant.some?.facebook || '',
        twitter: meParticipant.some?.twitter || '',
        instagram: meParticipant.some?.instagram || '',
        linkedin: meParticipant.some?.linkedin || '',
      },
      typeId: meParticipant.typeId,
      categories: meParticipant.categories,
      website: meParticipant.website || '',
      present: value.present,
    };

    participantStore.saveAll(true, pProfileData).then(() => {
      setLoading(false);
      modalStore.close('presenceConfirmationModal');
      methods.reset();
      eventStore.resetParticipantUpdated();
    });
  };

  // specified inputs and return their values
  methods.watch();

  return meParticipant && eventDetails ? (
    <FormProvider {...methods}>
      <Form
        className="participant-details-form"
        error={!isEmpty(methods.errors) || !!error}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <h2>
          <FormattedMessage id="presence.confirmation.form.title" />
        </h2>
        <div className="participant-details-form__fields equal width fields">
          <UniversalField
            key={PresenceConfirmationFields.fieldName}
            name={PresenceConfirmationFields.fieldName}
            label={PresenceConfirmationFields.fieldName}
            type={PresenceConfirmationFields.type}
            placeholder={PresenceConfirmationFields.fieldName}
            defaultValue={meParticipant?.present || ''}
            required={PresenceConfirmationFields.required}
            options={PresenceConfirmationFields.options}
            error={methods.errors[PresenceConfirmationFields.fieldName]}
            requiredLabel
          />
        </div>
        <Button color="blue" size="medium" loading={loading} type="submit" disabled={loading}>
          <FormattedMessage id="presence.confirmation.form.btn" />
        </Button>
      </Form>
    </FormProvider>
  ) : null;
});

export default injectIntl(PresenceConfirmationForm);
