import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Button, Form} from 'semantic-ui-react';
import {useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {AppContext} from '../../../store';
import AttendeePreview from '../../attendee/AttendeePreview/AttendeePreview';
import InvitationApi from '../../../services/api/invitationApi';
import ButtonLoading from '../../../ui/ButtonLoading';
import MeetingTimeSlots from '../../meeting/MeetingTimeSlots';
import Title from '../../shared/Title';
import {redirectBack} from '../../../services/helpers/redirectHelper';
import useMedia from '../../../hooks/useMedia';
import './InvitationFormRespond.scss';

const InvitationFormRespond = observer(props => {
  const intl = useIntl();
  const {attendee, invitation} = props;
  const {eventStore, userStore, participantStore, modalStore} = React.useContext(AppContext);
  const {eventList} = eventStore;
  const eventDetails = eventList.filter(e => e.id === invitation.eid)[0];
  const {user} = userStore;
  const {selectedSessionId, availableSessions, availableSessionDayIndex} = participantStore;
  const {register, errors, getValues} = useForm();
  const isMobile = useMedia('(max-width: 768px)');

  React.useEffect(() => {
    participantStore.availableSessionsForParticipants(eventDetails.me, attendee);
  }, []);

  function onSubmit() {
    const slots = Array.from(availableSessions.availableSessions[availableSessionDayIndex]?.slots || []);
    const selectedSlot = slots.find(slot => slot.id === selectedSessionId);
    const messageText = getValues().message || intl.formatMessage({id: 'invite.form.respond.default.text'});

    const message = selectedSlot.sdtStr
      ? intl.formatMessage(
          {id: 'invite.form.respond.message'},
          {
            message: messageText,
            startTime: selectedSlot.sdtStr,
            span: (...chunks) => `<span>${chunks.join('')}</span>`,
          },
        )
      : messageText;

    return InvitationApi.acceptMeetingRequest(
      user.id,
      eventDetails.id,
      invitation.id,
      message,
      selectedSessionId,
      '',
    ).then(() => {
      if (isMobile) {
        redirectBack(location);
      } else {
        modalStore.close('attendeeMeet');
      }
    });
  }

  return (
    <>
      <Title id="invitation-respond" />
      <div className="invitation-respond">
        <AttendeePreview attendee={invitation.opponentParticipant} big />
        <MeetingTimeSlots attendee={attendee} />
        <Form.Field className="field__textarea" error={!!errors.username}>
          <div className="ui left input">
            <textarea
              rows="4"
              name="message"
              placeholder={intl.formatMessage({id: 'invite.form.respond.default.text'})}
              ref={register()}
            />
          </div>
        </Form.Field>
        <div className="invitation-respond__action">
          <Button color="red" basic className="btn-link__back" onClick={() => props.onBack()}>
            <FormattedMessage id="invite.form.respond.btn.back" />
          </Button>
          <ButtonLoading color="blue" disabled={selectedSessionId == null} onClick={() => onSubmit()}>
            <FormattedMessage id="invite.form.respond.btn.accept-meet" />
          </ButtonLoading>
        </div>
      </div>
    </>
  );
});

InvitationFormRespond.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
  invitation: PropTypes.oneOfType([PropTypes.object]),
};

export default InvitationFormRespond;
