import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {AppContext} from '../../../../store';
import Modal from '../../../../ui/Modal';
import AgendaOffTime from '../../../agenda/AgendaFormOffTime';
import './AgendaOffTimeModal.scss';

const AgendaOffTimeModal = observer(() => {
  const {modalStore} = React.useContext(AppContext);
  const {open, attendee} = modalStore.modals.agendaOffTimeModal;

  return (
    <Modal
      className="agenda-offTime-modal"
      open={open}
      onClose={() => modalStore.close('agendaOffTimeModal')}
      size="mini"
    >
      <h3>
        <FormattedMessage id="agenda.off.time.modal.title" />
      </h3>
      <AgendaOffTime attendee={attendee}/>
    </Modal>
  );
});

export default injectIntl(AgendaOffTimeModal);
