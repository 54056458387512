import React from 'react';
import {observer} from 'mobx-react-lite';
import {runInAction} from 'mobx';
import {Dimmer, Loader} from 'semantic-ui-react';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import Title from '../../shared/Title/Title';
import {AppContext} from '../../../store';
import InvitationCardSent from '../InvitationCardSent';
import InvitationCardReceived from '../InvitationCardReceived';
import InvitationCardDecline from '../InvitationCardDecline';
import InvitationFormLimit from '../InvitationFormLimit';
import InvitationFormSent from '../InvitationFormSent';
import MeetingFormRescheduleRespond from '../../meeting/MeetingFormRescheduleRespond';
import MeetingFormReschedulePending from '../../meeting/MeetingFormReschedulePending';
import MeetingDetails from '../../meeting/MeetingDetails';
import useUserInvitation from '../../../hooks/useUserInvitation';
import {meetingStatus} from '../../../constants/meetingStatus';
import './InvitationBoxMapByAttendee.scss';

const InvitationBoxMapByAttendee = observer(props => {
  const {action, isBooth, invitationId} = props;
  const {eventStore, participantStore} = React.useContext(AppContext);
  const {filterList} = participantStore;
  const {attendeeId, eventId} = useParams();
  const [attendeeFromState, setAttendee] = React.useState(null);
  let attendee = attendeeFromState || props.attendee;
  const {invitation, meeting, status, state} = useUserInvitation(attendee, isBooth, invitationId);

  React.useEffect(() => {
    if (attendeeId && !attendee) {
      const findAttendee = filterList.find(a => a.id === attendeeId);
      if (findAttendee) {
        setAttendee(findAttendee);
      } else {
        eventStore.getEventDetails(eventId, true).then(() => {
          participantStore.getParticipantDetails(attendeeId).then(participant => {
            setAttendee(participant);
          });
        });
      }
    }

    return () => {
      setAttendee(null);
    };
  }, [attendeeId]);

  React.useEffect(
    () => () => {
      runInAction(() => {
        participantStore.participantData = null;
      });
    },
    [attendee, participantStore],
  );

  const content = () => {
    if (action === 'cancelMeeting' || action === 'requestReschedule') {
      return (
        <div className="invitation-details--meeting-details">
          <Title id="meeting-details" />
          <MeetingDetails meeting={meeting || invitation?.meeting} actionType={action} state={state} />
        </div>
      );
    }
    if (status === meetingStatus.INVITE_PENDING) {
      return (
        <div className="invitation-details--pending">
          <Title id="invitation-sent" />
          <InvitationCardSent invitation={invitation} />
        </div>
      );
    }
    if (status === meetingStatus.MEETING_DETAILS) {
      return (
        <div className="invitation-details--meeting-details">
          <Title id="meeting-details" />
          <MeetingDetails meeting={meeting || invitation?.meeting} actionType={action} />
        </div>
      );
    }
    if (status === meetingStatus.INVITE_LIMIT) {
      return (
        <div className="invitation-details--limit">
          <Title id="invitation-limit" />
          <InvitationFormLimit invitation={invitation} />
        </div>
      );
    }
    if (status === meetingStatus.INVITE_RECEIVED) {
      return (
        <div className="invitation-details--received">
          <Title id="invitation-received" />
          <InvitationCardReceived attendee={attendee} invitation={invitation} actionType={action} />
        </div>
      );
    }
    if (status === meetingStatus.INVITE_NEW) {
      return (
        <div className="invitation-details--new">
          <Title id="invitation-new" />
          <InvitationFormSent attendee={attendee} invitation={invitation} eventId={eventId} />
        </div>
      );
    }
    if (status === meetingStatus.MEETING_RESCHEDULE_PENDING) {
      return (
        <div className="invitation-details--reschedule-pending">
          <Title id="meeting.reschedule-pending" />
          <MeetingFormReschedulePending invitation={invitation} />
        </div>
      );
    }
    if (status === meetingStatus.MEETING_RESCHEDULE) {
      return (
        <div className="invitation-details--reschedule-respond">
          <Title id="meeting.reschedule-respond" />
          <MeetingFormRescheduleRespond attendee={attendee} invitation={invitation} actionType={action} />
        </div>
      );
    }
    if (status === meetingStatus.INVITE_REJECTED) {
      return (
        <div className="invitation-details--declined">
          <Title id="invitation-rejected" />
          <InvitationCardDecline invitation={invitation} />
        </div>
      );
    }
    return (
      <div className="invitation-details__loading">
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      </div>
    );
  };

  if (!attendee) {
    return (
      <div className="invitation-details__loading">
        <Dimmer active inverted>
          <Loader />
        </Dimmer>
      </div>
    );
  }

  return content();
});

InvitationBoxMapByAttendee.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
};

export default InvitationBoxMapByAttendee;
