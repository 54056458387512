import React from 'react';
import {observer} from 'mobx-react-lite';
import LoginForm from '../../components/auth/LoginForm';
import Title from '../../components/shared/Title';
import {AppContext} from '../../store';

const Login = observer(() => {
  const {eventStore} = React.useContext(AppContext);
  const {eventDetails} = eventStore;

  return (
    <React.Fragment>
      <Title id="login" event={eventDetails?.id} />
      <div className="login-page">
        <LoginForm />
      </div>
    </React.Fragment>
  );
});

export default Login;
