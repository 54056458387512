import Api from '../core/axios-service';
import {CommandExecutor} from '../core/command-executor';
import TimeService from '../../services/core/time-service';

const commandExecutor = new CommandExecutor();

const MeetingOffApi = {
  addOffTimeSession(executorId, eventId, data) {
    return commandExecutor.execute(
      executorId,
      Api.post('/event/schedule/participantOff', data)
        .then(resp => resp.data)
        .catch(err => Promise.reject(err.response.data)),
    );
  },
  deleteOffTime(executorId, eventId, data, asParticipantId=null) {
    return commandExecutor.execute(
      executorId,
      Api.delete('/event/schedule/participantOff', {
        params: {
          eventId,
          id: data.id,
          asParticipantId:asParticipantId,
          startTime: TimeService.dateTime.fromMillis(data.sdt).setZone('utc').toFormat('yyyy-LL-dd HH:mm'),
        },
      })
        .then(resp => resp.data)
        .catch(err => Promise.reject(err.response.data)),
    );
  },
};

export default MeetingOffApi;
