import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import ImageLazy from '../../../ui/ImageLazy';
import {HostUrl} from '../../../services/core/axios-service';
import userImg from '../../../assets/images/user.jpg';
import './UserUpdateAvatar.scss';

const UserUpdateAvatar = observer(() => {
  const {userStore, modalStore} = React.useContext(AppContext);
  const {user} = userStore;

  return userStore.user ? (
    <div className="user-update-avatar">
      <div className="user-item">
        <div className="user-item__img">
          {userStore.user.avatar ? (
            <img className="ui circular image" src={user.avatar} alt="alt" width={100} height={100} />
          ) : (
            <ImageLazy
              src={`${HostUrl}/${user.profilePhotoPath}`}
              errorSrc={userImg}
              alt={user.name}
              width={100}
              height={100}
            />
          )}
        </div>
        <div className="user-item__info">
          <Button
            basic
            color="teal"
            className="user-update-avatar__btn"
            onClick={() => modalStore.open('updatePhoto', {maxWidth: 700, maxHeight: 700})}
          >
            <FormattedMessage id="user.update.avatar.add.photo" />
          </Button>
        </div>
      </div>
    </div>
  ) : null;
});

export default injectIntl(UserUpdateAvatar);
