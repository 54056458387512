import React from 'react';
import {observer} from 'mobx-react-lite';
import {injectIntl} from 'react-intl';
import classNames from 'classnames';
import {NavLink, matchPath, withRouter} from 'react-router-dom';
import compose from 'recompose/compose';
import './StaticPageNavbar.scss';

const StaticPageNavbar = observer(props => {
  const {location, intl} = props;

  const links = [
    {title: intl.formatMessage({id: 'static.nav.link.2'}), url: '/privacy-notice'},
    {title: intl.formatMessage({id: 'static.nav.link.4'}), url: '/terms'},
    {title: intl.formatMessage({id: 'static.nav.link.1'}), url: '/cookies'},
  ].map(link => {
    const isActive = matchPath(location.pathname, {path: link.url});
    const classes = classNames('static-nav__item', {
      'static-nav__item--active': isActive,
    });

    return (
      <NavLink key={link.url} className={classes} to={link.url}>
        {link.title}
      </NavLink>
    );
  });

  return (
    <div className="static-nav">
      <div className="static-nav__vertical-menu">{links}</div>
    </div>
  );
});

export default compose(injectIntl, withRouter)(StaticPageNavbar);
