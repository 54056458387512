import React from 'react';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router-dom';
import {AppContext} from '../../../../store';
import ModalUrl from '../../../../ui/ModalUrl';
import SpeakerCard from '../../../speaker/SpeakerCard';
import SessionsAvailableForSpeaker from '../../../speaker/SessionsAvailableForSpeaker';
import './SpeakerModal.scss';

const SpeakerModal = observer(() => {
  const {speakerStore, sessionsNewStore} = React.useContext(AppContext);
  const {speakers} = speakerStore;
  const {sessions} = sessionsNewStore.data;
  const {speakerId} = useParams();

  const speaker = speakers ? speakers.filter(s => s.id === speakerId)[0] : null;
  const sessionsAvailable =
    sessions && speaker ? sessions.filter(s => s.speakers && s.speakers.includes(speaker.id)) : [];

  return speaker ? (
    <ModalUrl className="modal-speaker" open={!!speaker} size="small">
      <h1 className="modal-speaker__title">About speaker</h1>
      <div className="modal-speaker__wrapper">
        <div className="modal-speaker__details">
          <SpeakerCard speaker={speaker} sessionsAvailable={sessionsAvailable} />
        </div>
        <div className="modal-speaker__content">
          {speaker?.biography ? (
            <div className="modal-speaker__bio">
              <h3>biography</h3>
              <p>{speaker?.biography ? speaker.biography : 'No info'}</p>
            </div>
          ) : null}
          <SessionsAvailableForSpeaker speaker={speaker} sessions={sessionsAvailable} />
        </div>
      </div>
    </ModalUrl>
  ) : null;
});

export default SpeakerModal;
