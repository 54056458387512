import {useContext, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {AppContext} from '../../../../store';
import Modal from '../../../../ui/Modal';
import CheckInContainer from '../../../checkIn/CheckInContainer';
import {getLastSelectedCheckInData} from '../../../../services/helpers/checkInHelper';
import './CheckInModal.scss';

const CheckInModal = observer(() => {
  const {modalStore, checkInStore} = useContext(AppContext);
  const {scanTypeData, mode} = checkInStore;
  const {open} = modalStore.modals.checkInQrModal;

  useEffect(() => {
    // Only for TXT printing
    if (open && scanTypeData.scanType === 'fast') {
      const selectedPrinter = getLastSelectedCheckInData(scanTypeData.eventId, 'printer');
      const modalElement = window.document.querySelector('.ui.dimmer');
      const bgClass = 'bg-modal';

      if (selectedPrinter) {
        mode === 'scan' || mode === 'handheldScan'
          ? modalElement.classList.add(bgClass)
          : modalElement.classList.remove(bgClass);
      }
    }
  }, [scanTypeData, open, mode]);

  return (
    <Modal
      className="check-in-qr-modal"
      open={open}
      onClose={() => {
        modalStore.close('checkInQrModal');
      }}
      size="tiny"
    >
      <div className="check-in-qr-modal__wrapper">
        <CheckInContainer />
      </div>
    </Modal>
  );
});

export default CheckInModal;
