import {defineMessages} from 'react-intl';

export default defineMessages({
  sessionBtnStart: {
    id: 'session.btn.start',
    defaultMessage: 'Start Streaming',
  },
  sessionBtnStartOnSite: {
    id: 'session.btn.start.onSite',
    defaultMessage: 'On-site session',
  },
  sessionBtnJoin: {
    id: 'session.btn.join',
    defaultMessage: 'Join now',
  },
  sessionBtnJoinAsAttendee: {
    id: 'session.btn.joinAsAttendee',
    defaultMessage: 'Join as attendee',
  },
  sessionBtnFinish: {
    id: 'session.btn.finish',
    defaultMessage: 'Finished',
  },
  sessionBtnAddToCalendar: {
    id: 'session.btn.add.to.calendar',
    defaultMessage: 'Add to agenda',
  },
  sessionBtnLeaveSession: {
    id: 'session.btn.leave.session',
    defaultMessage: 'Remove from agenda',
  },
  sessionBtnRehearsal: {
    id: 'session.btn.rehearsal',
    defaultMessage: 'Join Rehearsal',
  },
  sessionBtnBusy: {
    id: 'session.btn.busy',
    defaultMessage: 'You are busy',
  },
  sessionBtnWaitingConfirm: {
    id: 'session.btn.sessionBtnWaitingConfirm',
    defaultMessage: 'Waiting Confirmation',
  },
  sessionBtnDuration: {
    id: 'session.btn.duration',
    defaultMessage: 'Duration',
  },
  sessionBtnModerate: {
    id: 'session.btn.moderate',
    defaultMessage: 'Join as moderator',
  },
  sessionBtnWatch: {
    id: 'session.btn.watch',
    defaultMessage: 'View recorded video',
  },
  sessionTextSuccess: {
    id: 'session.text.success',
    defaultMessage: `"{name}" - session added to agenda`,
  },
  sessionMeetingOpponentSuccess: {
    id: 'session.meeting.opponent.success',
    defaultMessage: `1:1 meeting for {me} and {opponent} has been scheduled. You can access the meeting via Agenda tab.`,
  },
  sessionTextSuccessRequested: {
    id: 'session.text.success.requested',
    defaultMessage:
      'You requested access to "{name}" at {time} on {date}. ' +
      'The event organizer team will inform you via email if your request is approved',
  },
  sessionBtnRequestDeclined: {
    id: 'session.text.requestDeclined',
    defaultMessage: 'Request declined',
  },
  sessionTextRequestDeclined: {
    id: 'session.text.RequestDeclined',
    defaultMessage: 'The event organizers declined your request.',
  },
  sessionBtnFullyBooked: {
    id: 'session.text.fullyBooked',
    defaultMessage: 'Fully booked',
  },
});
