import React from 'react';
import {observer} from 'mobx-react-lite';
import ForgotPasswordForm from '../../components/auth/ForgotPasswordForm';
import Title from '../../components/shared/Title';
import {AppContext} from '../../store';

const ForgotPassword = observer(() => {
  const {eventStore} = React.useContext(AppContext);
  const {eventDetails} = eventStore;

  return (
    <React.Fragment>
      <Title id="forgot-password" event={eventDetails?.id} />
      <div className="forgot-password-page">
        <ForgotPasswordForm />
      </div>
    </React.Fragment>
  );
});

export default ForgotPassword;
