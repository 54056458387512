import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Dropdown, Form, Button, Icon} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import ButtonLoading from '../../../ui/ButtonLoading';
import TimeService from '../../../services/core/time-service';
import './AgendaFormOffTime.scss';
import 'react-datepicker/dist/react-datepicker.css';

const AgendaFormOffTime = observer(({attendee}) => {
  const {agendaStore, eventStore, userStore, modalStore} = React.useContext(AppContext);
  const {rangeDays, isValidaAdOffTimeForm, selectedDate} = agendaStore.ofTime;
  const {eventDetails, activeEvents} = eventStore;
  const {user} = userStore;
  const startDate = new Date();
  const endDate = new Date();
  startDate.setHours(new Date().getHours(), 0);
  endDate.setHours(new Date().getHours() + 1, 0)
  const [startTime, setStartTime] = React.useState(startDate);
  const [endTime, setEndTime] = React.useState(endDate);
  const [eventId, setEventId] = React.useState(null);

  React.useEffect(() => {
    if (eventDetails?.id) {
      agendaStore.ofTime.initAgendaOfTimeForm(eventDetails.id);
    }
  }, []);

  const options = activeEvents.map(event => {
    const obj = {};
    obj.key = event.id;
    obj.text = event.name;
    obj.value = event.id;
    return obj;
  });

  const onStartTimeChange = newTime => {
    setStartTime(new Date(newTime));
    agendaStore.ofTime.selectOffTime({hour: new Date(newTime).getHours(), minute: new Date(newTime).getMinutes()});
  };

  const onEndTimeChange = newTime => {
    setEndTime(new Date(newTime));
    agendaStore.ofTime.selectOffTime(
      {hour: new Date(newTime).getHours(), minute: new Date(newTime).getMinutes()},
      'max',
    );
  };

  const handleSubmit = () => {
    const timeZone = eventDetails?.eventType === 'normal' ? eventDetails.timezone : null;

    return agendaStore.ofTime
      .saveOffTimeSession(user.id, eventId || eventDetails?.id || options[0].value, timeZone, attendee?.id)
      .then(data => {
        if (data.error) {
          NotificationAlert.error(data.error);
        } else {
          NotificationAlert.success('Success');
          agendaStore.ofTime.selectOffTimeDay(null);
          modalStore.close('agendaOffTimeModal');
        }
      })
      .catch(error => NotificationAlert.error(error));
  };

  const availableDateList = rangeDays.filter(date => {
    const dateNow = TimeService.dateTime.fromMillis(TimeService.now()).startOf('day');
    return TimeService.dateTime.fromISO(date).ts >= dateNow;
  });

  return (
    <div className="agenda-offTime">
      <div className="form-group input-floating" id="start">
        <div className="agenda-offTime__select">
          <Form.Field>
            <label>
              <FormattedMessage id="agenda.off.time.select.name" />
            </label>
            <Dropdown
              selection
              options={options}
              defaultValue={eventDetails?.id}
              onChange={(e, {value}) => {
                setEventId(value);
                agendaStore.ofTime.initAgendaOfTimeForm(value);
              }}
            />
          </Form.Field>
          <div className="agenda-offTime__datepicker field">
            <label className="agenda-offTime__label">
              <FormattedMessage id="agenda.off.time.select.day" />
            </label>
            <div className="agenda-offTime__days">
              <Button
                onClick={() => agendaStore.ofTime.addAvailableDay(-1)}
                disabled={agendaStore.ofTime.availableDayIndex <= 0}
              >
                <Icon name="angle left" />
              </Button>
              <span>{selectedDate}</span>
              <Button
                onClick={() => agendaStore.ofTime.addAvailableDay(1)}
                disabled={agendaStore.ofTime.availableDayIndex >= availableDateList.length - 1}
              >
                <Icon name="angle right" />
              </Button>
            </div>
          </div>
        </div>
        <div className="agenda-offTime__timePicker">
          <div className="agenda-offTime__timePicker-start field">
            <label>
              <FormattedMessage id="agenda.off.time.start" />
            </label>
            <DatePicker
              selected={startTime}
              onChange={date => onStartTimeChange(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
          </div>
          <div className="agenda-offTime__timePicker-end field">
            <label>
              <FormattedMessage id="agenda.off.time.end" />
            </label>
            <DatePicker
              selected={endTime}
              onChange={date => onEndTimeChange(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
          </div>
          {eventDetails?.eventType === 'normal' ? (
            <div className="agenda-offTime__timezone-label">
              <FormattedMessage id="agenda.off.timezone.label" />
            </div>
          ) : null}
        </div>
        <div className="agenda-offTime__action">
          <ButtonLoading onClick={() => handleSubmit()} disabled={!isValidaAdOffTimeForm}>
            <FormattedMessage id="agenda.off.time.btn.confirm" />
          </ButtonLoading>
        </div>
      </div>
    </div>
  );
});

export default injectIntl(AgendaFormOffTime);
