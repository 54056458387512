import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {AppContext} from '../../../store';
import ImageLazy from '../../../ui/ImageLazy';
import participantImg from '../../../assets/images/icons/header/participant-card.svg';
import './ParticipantProfile.scss';

const ParticipantProfile = observer(props => {
  const {isMobile} = props;
  const {eventStore, userStore, uiStore} = React.useContext(AppContext);
  const {meParticipant, eventDetails} = eventStore;
  const {myParticipantBooth} = userStore;
  const eventId = eventDetails?.id;
  const boothLogo =
    myParticipantBooth && myParticipantBooth.images && myParticipantBooth.images.logo
      ? myParticipantBooth.images.logo.indexOf('base64') >= 0
        ? myParticipantBooth.images.logo
        : `${myParticipantBooth.images.logo}?rand=${Math.random()}`
      : 'undefined';
  const mainLinkClassName = isMobile
    ? `mobile-sidebar__group mobile-sidebar__group--participant`
    : `nav-menu__item nav-menu__item--participant`;

  const compositeClassName = part => {
    return (isMobile ? 'mobile-sidebar__' : 'nav-menu__') + part;
  };

  const imageProfile = isMobile ? (
    <ImageLazy src={boothLogo} alt="My event profile" width={22} height={22} errorSrc={participantImg} />
  ) : (
    <div className="nav-menu__item-icon">
      <ImageLazy src={boothLogo} alt="My event profile" width={22} height={22} errorSrc={participantImg} />
    </div>
  );

  return meParticipant && eventId ? (
    <div className={mainLinkClassName}>
      <NavLink
        className={isMobile ? 'mobile-sidebar__item' : 'nav-menu__button'}
        to={`/event/${eventId}/event-profile`}
        onClick={() => uiStore.triggerMobileMenu()}
      >
        {imageProfile}
        <p className={compositeClassName('item-text')}>
          <FormattedMessage id="participant.profile.title" />
        </p>
      </NavLink>
    </div>
  ) : null;
});

export default injectIntl(ParticipantProfile);
