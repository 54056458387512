import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Form, Icon, Button} from 'semantic-ui-react';
import {useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {AppContext} from '../../../store';
import ButtonLoading from '../../../ui/ButtonLoading';
import InvitationApi from '../../../services/api/invitationApi';
import MeetingFormRescheduleDecline from '../MeetingFormRescheduleDecline';
import MeetingTimeSlots from '../MeetingTimeSlots';
import AttendeePreview from '../../attendee/AttendeePreview';
import Title from '../../shared/Title';
import {redirectBack} from '../../../services/helpers/redirectHelper';
import useMedia from '../../../hooks/useMedia';
import './MeetingFormRescheduleRespond.scss';

const MeetingFormRescheduleRespond = observer(props => {
  const intl = useIntl();
  const {attendee, invitation, actionType} = props;
  const {eventStore, userStore, participantStore, modalStore} = React.useContext(AppContext);
  const {eventList} = eventStore;
  const eventDetails = eventList.filter(e => e.id === invitation.eid)[0];
  const {user} = userStore;
  const {selectedSessionId, availableSessions, availableSessionDayIndex} = participantStore;
  const {register, errors, getValues} = useForm();
  const [step, setStep] = React.useState(actionType === 'decline' ? 2 : 1);
  const location = useLocation();
  const isMobile = useMedia('(max-width: 768px)');

  React.useEffect(() => {
    participantStore.availableSessionsForParticipants(eventDetails.me, attendee);
  }, []);

  const onSubmit = () => {
    const slots = Array.from(availableSessions.availableSessions[availableSessionDayIndex]?.slots || []);
    const selectedSlot = slots.find(slot => slot.id === selectedSessionId) || {};
    const messageText = getValues().message || intl.formatMessage({id: 'meeting.form.reschedule.default.text'});

    const message = selectedSlot.sdtStr
      ? intl.formatMessage(
          {id: 'meeting.form.reschedule.respond.message'},
          {
            message: messageText,
            startTime: selectedSlot.sdtStr,
            span: (...chunks) => `<span>${chunks.join('')}</span>`,
          },
        )
      : messageText;

    return InvitationApi.acceptRescheduleRequest(
      user.id,
      eventDetails.id,
      invitation.lastInvitationDetails.id,
      message,
      selectedSessionId,
    ).then(() => {
      if (isMobile) {
        redirectBack(location);
      } else {
        modalStore.close('attendeeMeet');
      }
    });
  };

  return invitation ? (
    <div className="meeting-reschedule">
      <AttendeePreview attendee={invitation.opponentParticipant} big />
      <div className="meeting-reschedule__time">
        <Icon name="check" />
        <span>{invitation.cdtStr}</span>
      </div>
      <p className="meeting-reschedule__message">{invitation.message}</p>
      {step === 1 || actionType === 'respond' ? (
        <React.Fragment>
          <Title id="meeting.reschedule-respond" />
          <MeetingTimeSlots attendee={attendee} />
          <Form.Field className="field__textarea" error={!!errors.username}>
            <div className="ui left input">
              <textarea
                rows="4"
                name="message"
                placeholder={intl.formatMessage({id: 'meeting.form.reschedule.respond.placeholder'})}
                ref={register()}
              />
            </div>
          </Form.Field>
          <div className="meeting-reschedule__action">
            {isMobile ? (
              <Button
                color="blue"
                basic
                className="meeting-reschedule__btn-cancel"
                onClick={() => redirectBack(location)}
              >
                <FormattedMessage id="meeting.form.reschedule.respond.btn.back" />
              </Button>
            ) : (
              <Button color="red" basic className="meeting-reschedule__btn-cancel" onClick={() => setStep(2)}>
                <FormattedMessage id="meeting.form.reschedule.respond.btn.decline" />
              </Button>
            )}
            <ButtonLoading
              color="blue"
              disabled={selectedSessionId == null}
              onClick={() => onSubmit('acceptReschedule')}
            >
              <FormattedMessage id="meeting.form.reschedule.respond.btn.accept" />
            </ButtonLoading>
          </div>
        </React.Fragment>
      ) : null}
      {step === 2 || actionType === 'decline' ? (
        <MeetingFormRescheduleDecline invitation={invitation} onBack={() => setStep(1)} />
      ) : null}
    </div>
  ) : null;
});

MeetingFormRescheduleRespond.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
  invitation: PropTypes.oneOfType([PropTypes.object]),
  reschedule: PropTypes.bool,
};

MeetingFormRescheduleRespond.defaultProps = {
  pending: false,
};

export default MeetingFormRescheduleRespond;
