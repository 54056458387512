import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Popup} from 'semantic-ui-react';
import {useLocation, useHistory} from 'react-router-dom';
import {AppContext} from '../../../store';
import ButtonLoading from '../../../ui/ButtonLoading';
import MeetingApi from '../../../services/api/meetingApi';
import {startMeeting} from '../../../services/helpers/meetingHelper';
import useMedia from '../../../hooks/useMedia';
import './AgendaCardAttendeeRole.scss';

const AgendaCardAttendeeRole = observer(props => {
  const {session} = props;
  const {eventStore, userStore, uiStore} = React.useContext(AppContext);
  const {eventList} = eventStore;
  const {user} = userStore;
  const {pageTitle} = uiStore;
  const location = useLocation();
  const history = useHistory();
  const eventDetails = eventList?.filter(event => event.id === session.eventId)[0];
  const isMobile = useMedia('(max-width: 768px)');
  const [openPopup, setOpenPopup] = React.useState(false);
  return (
    <div className="agenda-details attendee-role">
      <div className="agenda-item__name">{session.name}</div>
      {!session.expired &&
      session.upcomingState !== 'expired' &&
      session.onSite &&
      eventDetails &&
      eventDetails.type === 'hybrid' ? (
        <Popup
          content="This session is available only on-site. To take part in it, please proceed to the session location."
          trigger={
            <Button className="session-btn__start-stream" basic color="teal">
              <FormattedMessage id="agenda.item.startStreaming.btn.onSite" />
            </Button>
          }
        />
      ) : null}
      <div className="agenda-item__action">
        <Button
          className="agenda-btn__details"
          basic
          color="blue"
          onClick={() => {
            if (isMobile) {
              history.push({
                pathname: `/my-agenda/session/${session.eventId}/${session.rsesId || session.sdefId}`,
                state: {
                  background: location,
                  page: 'agenda',
                  title: session.name,
                  prevPage: pageTitle,
                },
              });
            } else {
              history.push({
                pathname: `/event/${session.eventId}/sessions/${session.rsesId || session.sdefId}`,
                state: {
                  background: location,
                  page: 'sessions',
                  title: session.name,
                },
              });
            }
          }}
        >
          <FormattedMessage id="agenda.item.viewDetails.btn" />
        </Button>
        {!session.expired &&
        session.upcomingState !== 'expired' &&
        session.buttonShow &&
        eventDetails &&
        !(session.onSite || (eventDetails && eventDetails.type === 'normal')) ? (
          <Button className="agenda-btn__start-stream" color="blue" onClick={() => startMeeting(session)}>
            <FormattedMessage id="agenda.item.joinNow.btn" />
          </Button>
        ) : null}
        {!session.expired && session.upcomingState !== 'expired' && session.upcomingState !== 'started' ? (
          <ButtonLoading
            className="agenda-btn__calendar-remove"
            basic
            color="red"
            onClick={() => MeetingApi.leaveSession(user.id, session.eventId, session.id)}
          >
            <FormattedMessage id="agenda.item.removeFromAgenda.btn" />
          </ButtonLoading>
        ) : null}
        {session.upcomingState === 'started' ? (
          <Popup
            trigger={<i className="meeting-details__btn-more ellipsis horizontal icon button" />}
            content={
              <div role="list" className="meeting-details__more-list">
                <div
                  aria-hidden
                  className="item"
                  onClick={() => {
                    MeetingApi.leaveSession(user.id, session.eventId, session.id);
                  }}
                >
                  <div className="content">
                    <FormattedMessage id="agenda.item.removeFromAgenda.btn" />
                  </div>
                </div>
              </div>
            }
            className="meeting-details__popup"
            basic
            position="bottom right"
            on="click"
            onClose={() => setOpenPopup(false)}
            onOpen={() => setOpenPopup(true)}
            open={openPopup}
          />
        ) : null}
      </div>
    </div>
  );
});

AgendaCardAttendeeRole.propTypes = {
  session: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default injectIntl(AgendaCardAttendeeRole);
