import React from 'react';
import {FormattedMessage} from 'react-intl';
import CheckInApi from '../api/checkInApi';
import checkInConfig from '../../components/checkIn/checkInConfig';
import NotificationAlert from '../../components/notifications-alert/NotificationAlert';
import isValidUrl from '../../utils/isValidUrl';
import {stores} from '../../store';
import {LocalStore} from '../core/storage-service';

export function checkIn(checkInPostBody) {
  return CheckInApi.checkIn(checkInPostBody)
    .then(res => {
      if (res?.status === 200) {
        if (checkInPostBody.checkIn) {
          return Promise.resolve(checkInConfig.requestStatuses.checkedIn);
        }
        return Promise.resolve(checkInConfig.requestStatuses.checkedOut);
      }
      return Promise.resolve(res);
    })
    .catch(err => {
      if (err?.errorMessage?.includes('DEAL-00113')) {
        return Promise.resolve(checkInConfig.requestStatuses.alreadyCheckedIn);
      }
      if (err?.errorMessage?.includes('DEAL-00120')) {
        return Promise.resolve(checkInConfig.requestStatuses.alreadyCheckedOut);
      }
      if (err?.errorMessage?.includes('DEAL-00114')) {
        return Promise.resolve(checkInConfig.requestStatuses.fully);
      }
      if (err?.errorMessage?.includes('DEAL-00116')) {
        return Promise.resolve(checkInConfig.requestStatuses.wrongTicketType);
      }
      return Promise.reject();
    });
}

export function getLastSelectedCheckInData(eventId, type = 'printer' || 'scanMethod') {
  const lastSelectedData = LocalStore.getObject('lastSelectedCheckInData') || {};
  const eventData = lastSelectedData[eventId] || {};

  return eventData[type];
}

export function setLastSelectedCheckInData(eventId, type, data) {
  if (!eventId || !type) return;
  const savedData = LocalStore.getObject('lastSelectedCheckInData') || {};
  const eventData = savedData[eventId] || {};

  LocalStore.addToObject('lastSelectedCheckInData', {[eventId]: {...eventData, ...{[type]: data}}});
}

export function handleScan(scannedData, checkInStatus) {
  const {eventDetails} = stores.eventStore;
  const {scanTypeData} = stores.checkInStore;
  return new Promise((resolve, reject) => {
    if (scannedData && Object.keys(scanTypeData).length) {
      if (isValidUrl(scannedData)) {
        const splitURL = scannedData.toString().split('/').filter(Boolean);
        const ticketIdKey = splitURL.indexOf('tickets');
        if (ticketIdKey > 0 && ticketIdKey < splitURL.length - 1) {
          scannedData = splitURL[ticketIdKey + 1];
        } else {
          NotificationAlert.error(<FormattedMessage id="checkIn.container.scan.data.error" />);
          return resolve();
        }
      }
      stores.contactStore.getScannedContactDetails(scanTypeData.eventId, scannedData).then(contact => {
        if (!contact) {
          if (scanTypeData.scanType !== 'fast') {
            stores.checkInStore.backToInitStep(scanTypeData);
            resolve();
          }
          NotificationAlert.error(<FormattedMessage id="checkIn.container.ticket.notFound.error" />);
          stores.checkInStore.setScannedContact({});
          return resolve();
        }
        if (contact.typeId) {
          const participantTypes = eventDetails?.participantTypes || {};
          const contactParticipantType = participantTypes[contact.typeId];
          if (contactParticipantType) {
            contact.participantType = {typeId: contact.typeId, typeText: contactParticipantType.texts.en};
          }
          delete contact.typeId;
        }

        if (contact.ticketType) {
          const ticketTypes = eventDetails?.ticketTypes || [];
          const contactTicketType = ticketTypes.find(type => type.typeId === contact.ticketType);

          if (contactTicketType) {
            contact.ticketType = {typeId: contact.ticketType, typeText: contactTicketType.name};
          }
        }

        stores.checkInStore.setScannedContact(contact);
        if (!checkAllowedCheckIn(scanTypeData, contact)) return;

        if (scanTypeData.scanType !== 'fast') {
          stores.checkInStore.setMode('scanResult');
        } else {
          const selectedPrinter = getLastSelectedCheckInData(eventDetails.id, 'printer');

          const postBody = {
            entityType: scanTypeData.entityType,
            entityId: scanTypeData.entityId,
            checkIn: checkInStatus,
            ticketId: scannedData,
            eventId: scanTypeData.eventId,
            printerLabel: selectedPrinter?.label,
          };
          checkIn(postBody)
            .then(res => {
              if (
                res === checkInConfig.requestStatuses.alreadyCheckedIn ||
                res === checkInConfig.requestStatuses.alreadyCheckedOut
              ) {
                NotificationAlert.warning(
                  <FormattedMessage
                    id={`checkIn.container.${res}.error`}
                    values={{
                      name: contactFullName(contact),
                    }}
                  />,
                );
              } else if (
                res === checkInConfig.requestStatuses.fully ||
                res === checkInConfig.requestStatuses.wrongTicketType
              ) {
                NotificationAlert.warning(<FormattedMessage id={`checkIn.container.${res}.error`} />);
                return reject(res);
              } else if (
                res === checkInConfig.requestStatuses.checkedIn ||
                res === checkInConfig.requestStatuses.checkedOut
              ) {
                NotificationAlert.success(
                  <FormattedMessage
                    id={`checkIn.container.${res}.success`}
                    values={{
                      name: contactFullName(contact),
                    }}
                  />,
                );
              }
              resolve(res);
            })
            .catch(err => {
              NotificationAlert.error(<FormattedMessage id="checkIn.container.checkIn.error" />);
              stores.checkInStore.setScannedContact({});
              reject(err);
            });
        }
      });
    }
  });
}

export function getTXTTemplate(id, eventId, type) {
  return new Promise((resolve, reject) => {
    CheckInApi.getTXTTemplate(id, eventId, type)
      .then(resp => {
        if (resp?.data?.content) {
          return resolve(resp.data.content);
        }
        reject();
      })
      .catch(err => {
        if (err?.errorMessage?.includes('DEAL-00061')) {
          NotificationAlert.error(<FormattedMessage id="checkIn.container.printTXT.notFound.error" />);
        }
        reject(err);
      });
  });
}

export function checkAllowedCheckIn(scanTypeData, contact, clearContact = true) {
  const {areaList} = stores.checkInStore;
  const areaInfo = areaList.find(area => scanTypeData.entityType === 'area' && area.id === scanTypeData.entityId);
  const allowedPart = areaInfo?.allowedPart;
  const allowedCheckInByType =
    (allowedPart && allowedPart.includes(contact?.participantType?.typeId)) ||
    !allowedPart ||
    scanTypeData.entityType === 'event';

  if (!allowedCheckInByType) {
    NotificationAlert.error(<FormattedMessage id="checkIn.container.checkIn.type.error" />);
    clearContact && stores.checkInStore.setScannedContact({});
    return false;
  }
  return true;
}

export function handleCheckInFromAttendees(attendeeItem, eventId, mode = 'scanResult') {
  const {eventDetails} = stores.eventStore;
  const contact = {
    company: attendeeItem.company,
    email: attendeeItem.userEmail || attendeeItem.invitationEmail,
    firstName: attendeeItem.name,
    id: attendeeItem.id,
    jobTitle: attendeeItem.jobTitle,
    lastName: attendeeItem.surname,
    profilePhotoPath: attendeeItem.profilePhoto,
    website: attendeeItem.website,
    city: attendeeItem.city,
  };
  if (mode === 'printBadgeForced') {
    contact.ticketId = attendeeItem.ticketId;
  }
  if (attendeeItem.typeId) {
    const participantTypes = eventDetails?.participantTypes || {};
    const contactParticipantType = participantTypes[attendeeItem.typeId];

    if (contactParticipantType) {
      contact.participantType = {typeId: attendeeItem.typeId, typeText: contactParticipantType.texts.en};
    }
  }

  if (attendeeItem.ticketType) {
    const ticketTypes = eventDetails?.ticketTypes || [];
    const contactTicketType = ticketTypes.find(type => type.typeId === attendeeItem.ticketType);

    if (contactTicketType) {
      contact.ticketType = {typeId: attendeeItem.ticketType, typeText: contactTicketType.name};
    }
  }

  stores.checkInStore.setScanTypeData({
    eventId: eventId,
    checkInFromAttendee: true,
  });
  stores.checkInStore.getAreaList(attendeeItem.eventId);
  stores.checkInStore.setScannedContact(contact);
  stores.checkInStore.setMode(mode);
  stores.modalStore.open('checkInQrModal');
}

export function checkAreaOccupation(selectedArea) {
  const {areaList} = stores.checkInStore;
  const allEventArea = selectedArea === 'allEvent';
  const selectedAreaIsFull = areaList.find(area => area.id === selectedArea && area.capacity === area.capacityUsed);
  if (!allEventArea && selectedAreaIsFull) {
    NotificationAlert.warning(
      <FormattedMessage id={`checkIn.container.${checkInConfig.requestStatuses.fully}.error`} />,
    );
    return false;
  }
  return true;
}

export function setScanTypeDataByArea(selectedArea, eventId, scanType = 'individual', scanMethod = 'camera') {
  const allEventArea = selectedArea === 'allEvent';

  if (!checkAreaOccupation(selectedArea)) {
    return null;
  }

  const entityData = !allEventArea
    ? {
        entityId: selectedArea,
        entityType: 'area',
      }
    : {
        entityId: eventId,
        entityType: 'event',
      };

  return {
    ...entityData,
    eventId: eventId,
    scanType: scanType,
    scanMethod: scanMethod,
  };
}

export function contactFullName(contact) {
  return (contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.firstName) || '';
}
