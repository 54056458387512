import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {Link, useLocation, useParams} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import sortBy from 'lodash/sortBy';
import SessionBtns from '../../session/SessionBtns';
import {AppContext} from '../../../store';
import TimeService from '../../../services/core/time-service';
import './SessionsAvailableForSpeaker.scss';

const SessionsAvailableForSpeaker = observer(props => {
  const {sessions, speaker} = props;
  const {agendaStore} = React.useContext(AppContext);
  const {meetings} = agendaStore.data;
  const location = useLocation();
  const {eventId} = useParams();
  const sessionsLive = [];
  const sessionsPast = [];
  const limit = 4;
  const [showLive, setShowLive] = React.useState(false);
  const [showPast, setShowPast] = React.useState(false);

  if (sessions.length > 0) {
    sessions.forEach(el => {
      if (TimeService.now() > new Date(el.edt)) {
        return sessionsPast.push(el);
      }
      return sessionsLive.push(el);
    });
  }

  const sessionAvailable = (s, live) => (
    <div key={s.id} className="session-available">
      <div className="session-available__details">
        <Link
          to={{
            pathname: `/event/${eventId}/sessions/${s.id}`,
            state: {
              background: location.state.background,
              page: 'sessions',
              title: s.name,
            },
          }}
        >
          <p className="session-available__name">{s.name}</p>
        </Link>
        <div className="session-available__time">
          {TimeService.now() > s.sdt && TimeService.now() < s.edt ? (
            <div className="session-available__live">
              <FormattedMessage id="sessions.available.live" />
            </div>
          ) : (
            <div className="session-available__time-date">
              {s.sdts.monthFull}, {s.sdts.hour} - {s.edts.hour}
            </div>
          )}
        </div>
      </div>
      {live ? (
        <div className="session-available__action">
          <SessionBtns session={s} meetings={meetings} />
        </div>
      ) : null}
    </div>
  );

  return sessions.length > 0 ? (
    <div className="sessions-available">
      <h3 className="sessions-available__title">
        <FormattedMessage id="sessions.available.speaker" /> {speaker?.name}:
      </h3>
      <div className="sessions-available__list">
        {sessionsLive.length > 0 ? (
          <div className="sessions-available__group">
            <h4 className="sessions-available__group-title">
              <FormattedMessage id="sessions.available.upcoming" />:
            </h4>
            {sortBy(sessionsLive, ['sdt'])
              .slice(0, showLive ? Infinity : limit)
              .map(session => sessionAvailable(session, true))}
            {sessionsLive.length > limit ? (
              <div aria-hidden className="sessions-available__load" onClick={() => setShowLive(true)}>
                <FormattedMessage id="sessions.available.loadMore" />
              </div>
            ) : null}
          </div>
        ) : null}
        {sessionsPast.length > 0 ? (
          <div className="sessions-available__group">
            <h4 className="sessions-available__group-title">
              <FormattedMessage id="sessions.available.past" />:
            </h4>
            {sessionsPast.slice(0, showPast ? Infinity : limit).map(session => sessionAvailable(session))}
            {sessionsPast.length > limit ? (
              <div aria-hidden className="sessions-available__load" onClick={() => setShowPast(!showPast)}>
                {showPast ? (
                  <FormattedMessage id="sessions.available.collapse" />
                ) : (
                  <FormattedMessage id="sessions.available.loadMore" />
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
});

SessionsAvailableForSpeaker.propTypes = {
  sessions: PropTypes.oneOfType([PropTypes.array]),
  speaker: PropTypes.oneOfType([PropTypes.object]),
};

SessionsAvailableForSpeaker.defaultProps = {
  sessions: [],
  speaker: {},
};

export default SessionsAvailableForSpeaker;
