import React from 'react';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import {AppContext} from '../../../store';
import AnnouncementComment from '../AnnouncementComment';
import AnnouncementCommentAdd from '../AnnouncementCommentAdd';
import './AnnouncementCommentList.scss';

const AnnouncementCommentList = observer(props => {
  const {announcement} = props;
  const {announcementStore, userStore} = React.useContext(AppContext);
  const comments = announcementStore.comments ? announcementStore.comments[announcement.id] : [];

  return (
    <div className="announcement-item__comments">
      {userStore.user ? (
        <>
          <div className="comments">
            <div className="comments-header">
              <h3 className="comments-header__title">
                <FormattedMessage id="announcement.comment.title" />
              </h3>
            </div>
            {!isEmpty(comments)
              ? map(comments, (value, key) => {
                  const c = comments[key];
                  return <AnnouncementComment key={key} comment={c} />;
                })
              : null}
          </div>
          <AnnouncementCommentAdd announcement={announcement} />
        </>
      ) : null}
    </div>
  );
});

AnnouncementCommentList.propTypes = {
  announcement: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  participants: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default injectIntl(AnnouncementCommentList);
