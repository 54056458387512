import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Form, Icon, Message} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {FormProvider, useForm} from 'react-hook-form';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import ReactMarkdown from 'react-markdown';
import {useLocation} from 'react-router-dom';
import generateQR from '../../../utils/QRgenerate';
import {AppContext} from '../../../store';
import UniversalField from '../../shared/UniversalField';
import {PreRegistrationField} from './PreRegistrationField';
import EventApi from '../../../services/api/eventApi';
import searchParse from '../../../utils/searchParse';
import './PreRegistrationForm.scss';

const PreRegistrationForm = observer(() => {
  const {eventStore} = React.useContext(AppContext);
  const {domainDetails} = eventStore;
  const methods = useForm();
  const intl = useIntl();
  const location = useLocation();
  const {eventId, type: regType} = searchParse(location.search);
  let fields = PreRegistrationField;
  const qrElRef = React.useRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const [registrationData, setRegistrationData] = React.useState(null);
  const [error, setError] = React.useState(null);

  useEffect(() => {
    if (registrationData?.ticketId && qrElRef.current) {
      generateQR(registrationData.ticketId, {}, qrElRef.current);
    }
  }, [registrationData]);

  if (regType === 'simplereg') {
    fields = PreRegistrationField.map(field => {
      if (field?.fieldName === 'jobTitle') {
        return {
          type: 'input',
          fieldName: 'city',
          placeholder: 'City',
          required: true,
        };
      }

      return field;
    });
  }

  const onSubmit = value => {
    setIsLoading(true);
    value.eventId = eventId || domainDetails?.id;

    if (regType === 'simplereg' || regType === 'prereg') {
      value.regType = regType;
    }

    EventApi.eventRegister(value)
      .then(data => setRegistrationData(data))
      .catch(err => setError(err))
      .finally(() => setIsLoading(false));
  };

  const parseError = errorMessage => {
    const {code = 'UNKNOWN', msg = 'Error'} = errorMessage;

    if (code === 'DEAL-00082' || code === 'DEAL-00021')
      return <ReactMarkdown>{intl.formatMessage({id: 'registration.form.error'})}</ReactMarkdown>;

    return `${msg} (${code})`;
  };

  const ticketType = ticketType =>
    ticketType ? (
      <ReactMarkdown>
        {intl.formatMessage(
          {
            id: 'preregistration.form.simpleReg.ticketType',
          },
          {ticketType},
        )}
      </ReactMarkdown>
    ) : null;

  if (registrationData && registrationData.ticketId) {
    return (
      <div className="pre-registration-success">
        <div className="pre-registration-success__qr-code-container">
          <div ref={qrElRef} />
          <strong>{registrationData.ticketId}</strong>
        </div>
        {ticketType(registrationData.ticketType)}
        <div className="pre-registration-success__text-container">
          <Icon name="check circle outline" size="huge" className="pre-registration-success__icon" />
          <ReactMarkdown>
            {intl.formatMessage({
              id: `preregistration.form.simpleReg.success.${registrationData.hasUserId ? 'hasUser' : 'hasNotUser'}`,
            })}
          </ReactMarkdown>
        </div>
      </div>
    );
  }

  if (registrationData) {
    return (
      <div className="pre-registration-success">
        <div className="pre-registration-success__text-container">
          <Icon name="check circle outline" size="huge" className="pre-registration-success__icon" />
          <p>
            <FormattedMessage id="preregistration.form.success" />
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="pre-registration-form">
      <div className="pre-registration-form__header">
        <h2 className="pre-registration-form__title">
          <FormattedMessage id="preregistration.form.title" />
        </h2>
        {domainDetails ? (
          <p className="pre-registration-form__desc">
            <FormattedMessage id="preregistration.form.info" />
          </p>
        ) : null}
      </div>
      <FormProvider {...methods}>
        <Form
          className="pre-registration-form__body"
          error={!isEmpty(methods.errors) || !!error}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {fields.map(field => (
            <UniversalField
              key={field.fieldName}
              label={field.fieldName}
              name={field.fieldName}
              type={field.type}
              required={field.required}
              dataList={field.dataList}
              placeholder={field.placeholder}
              error={methods.errors[field.fieldName]}
            />
          ))}
          <Button fluid disabled={isLoading} loading={isLoading}>
            <FormattedMessage id="preregistration.form.btn" />
          </Button>
          <Message error>{error ? parseError(error) : null}</Message>
        </Form>
      </FormProvider>
    </div>
  );
});

export default injectIntl(PreRegistrationForm);
