import {action, extendObservable, runInAction} from 'mobx';
import {subscribeAnswerByUser, subscribePollList} from '../../../services/firebase/pollFbApi';

export class PollStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    extendObservable(this, {
      questions: [],
      answerUsers: {},
      isLoadingQuestions: false,
      isUpdateQuestion: false,
    });
  }

  @action
  getPollList(eventId, isModerator, participantId) {
    this.isLoadingQuestions = true;

    subscribePollList(eventId, isModerator, snapshot => {
      runInAction(() => {
        const questions = snapshot.val();
        if (questions !== null) {
          this.questions = Object.values(questions);
          this.getAnswerByUser(eventId, this.questions, participantId);
        } else {
          this.questions = [];
        }
        this.isLoadingQuestions = false;
      });
    });
  }

  @action
  getAnswerByUser(eventId, questions, participantId) {
    questions.forEach(question => {
      if (question.details) {
        subscribeAnswerByUser(eventId, question.details.id, participantId, snapshot => {
          runInAction(() => {
            /*  TODO: why there is not data here ?  {console.log(snapshot.val())} */
            this.answerUsers[question.details.id] = snapshot.val();
          });
        });
      }
    });
  }

  @action
  setQuestionForUpdate(question, value) {
    this.question = question;
    this.isUpdateQuestion = value;
  }

  @action
  clear() {
    this.questions = [];
  }
}
