import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {runInAction} from 'mobx';
import {injectIntl} from 'react-intl';
import {AppContext} from '../../../store';
import ChatMessagesList from '../ChatMessagesList';
import ChatPost from '../ChatPost';
import ChatActiveUser from '../ChatActiveUser';
import {ReactComponent as CloseImg} from '../../../assets/images/icons/close.svg';
import './FloatingChatWindow.scss';

const FloatingChatWindow = observer(props => {
  const {isOpen, openChat} = props;
  const {chatStore} = React.useContext(AppContext);
  const {floatingSelectedRoom, roomsDetails} = chatStore;
  const room = roomsDetails[floatingSelectedRoom];

  return room && room.members ? (
    <div className="floating-chat-window">
      <div
        aria-hidden
        className="floating-chat-window__top"
        onClick={() => {
          if (!isOpen) {
            openChat(!isOpen);
          }
        }}
      >
        <ChatActiveUser floating isFloatingOpen={isOpen} floatingTrigger={openChat} />
        {isOpen ? (
          <div
            className="floating-chat-window__close"
            aria-hidden
            onClick={() => {
              runInAction(() => {
                chatStore.floatingSelectedRoom = null;
              });
            }}
          >
            <CloseImg />
          </div>
        ) : null}
      </div>
      <div className="floating-chat-window__messages">
        <ChatMessagesList isFloating />
      </div>
      <ChatPost floating />
    </div>
  ) : null;
});

FloatingChatWindow.propTypes = {
  isOpen: PropTypes.bool,
  openChat: PropTypes.func,
};

FloatingChatWindow.defaultProps = {
  isOpen: false,
  openChat: () => {},
};

export default injectIntl(FloatingChatWindow);
