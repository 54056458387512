import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage} from 'react-intl';
import {Button} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import ButtonLoading from '../../../ui/ButtonLoading';
import {updateMessage} from '../../../services/firebase/chatFbApi';
import './ChatRemoveMessageForm.scss';

const CheckInPrintBadgeForm = observer(() => {
  const {modalStore} = React.useContext(AppContext);
  const {messageInfo} = modalStore.modals.messageActionConfirmationModal;

  const deleteMessage = () => {
    return updateMessage(messageInfo.roomId, messageInfo.messageId).then(() => {
      modalStore.close('messageActionConfirmationModal');
    });
  };

  const buttons = (
    <div className="chat-remove-message-form__btns">
      <Button
        color="blue"
        basic
        onClick={() => {
          modalStore.close('messageActionConfirmationModal');
        }}
      >
        <FormattedMessage id="chatRemoveMessage.form.cancel.btn" />
      </Button>
      <ButtonLoading color="red" disabled={!messageInfo} onClick={() => deleteMessage()}>
        <FormattedMessage id="chatRemoveMessage.form.delete.btn" />
      </ButtonLoading>
    </div>
  );

  return (
    <div className="chat-remove-message-form">
      <FormattedMessage id="chatRemoveMessage.form.text" />
      {buttons}
    </div>
  );
});

export default CheckInPrintBadgeForm;
