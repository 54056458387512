import Api from '../core/axios-service';
import {stores} from '../../store';

const ChatApi = {
  createRoom(members, type, eventId, name) {
    return Api.post('/chat/room', {
      members,
      type,
      eventId,
      name,
    }).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  addMembers(roomId, members) {
    return Api.post('/chat/room/member', {
      roomId,
      members,
    }).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  removeMembers(roomId, members) {
    return Api.delete('/chat/room/member', {
      data: {
        roomId,
        members,
      },
    }).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
};

export default ChatApi;
