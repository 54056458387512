import Api from '../core/axios-service';
import {stores} from '../../store';

const UserApi = {
  getProfileDetails() {
    return Api.get('user/profile').then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  getToken(token, isUpdate = false) {
    return Api.get('user/token', {
      params: {provider: isUpdate ? 'cognitointernal' : 'cognito', accessToken: token},
    }).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  signup(user) {
    return Api.post('user/signup', user).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  deleteAccount() {
    return Api.delete('user').then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  updateUserDetails(userDetails) {
    return Api.put('user/profile', userDetails).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  getConnectedEmails() {
    return Api.get('user/email').then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  connectNewEmail(email) {
    return Api.post('user/email', email).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  deleteEmail(email) {
    return Api.delete('user/email', {params: {email}}).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  updateUsername(email) {
    return Api.post('user/username', {username: email}).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
  verifyCodeForAddingEmail(code) {
    return Api.post('url/action', {...code, ...{action: 'connectEmail'}})
      .then(resp => {
        return Promise.resolve(resp);
      })
      .catch(error => Promise.reject(error));
  },
  updateNotificationSettings(notificationSettings) {
    return Api.put('user/profile', notificationSettings)
      .then(resp => {
        return Promise.resolve(resp);
      })
      .catch(error => Promise.reject(error));
  },
  saveTokenDevice(deviceId, token) {
    return Api.put('user/profile', {
      firebaseDeviceTokens: {
        [deviceId]: token,
      },
      action: 'update_device_token',
    }).then(
      resp => Promise.resolve(resp),
      err => {
        stores.errorStore.addError(err);
        return Promise.reject(err);
      },
    );
  },
};

export default UserApi;
