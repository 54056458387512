import React from 'react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import {Button} from 'semantic-ui-react';
import classNames from 'classnames';
import {AppContext} from '../../../store';

const ContactsMyQrButton = props => {
  const {className, icon, ...restProps} = props;
  const {modalStore} = React.useContext(AppContext);

  return (
    <Button
      {...restProps}
      className={classNames('contacts-my-qr-button', className)}
      onClick={() => {
        modalStore.open('qrGenerate');
      }}
    >
      {icon}
      <FormattedMessage id="contacts.myQr.btn" />
    </Button>
  );
};

ContactsMyQrButton.propTypes = {
  icon: PropTypes.node,
  className: PropTypes.string,
};

ContactsMyQrButton.defaultProps = {
  icon: null,
};

export default ContactsMyQrButton;
