import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Form} from 'semantic-ui-react';

const DropdownFilter = props => {
  const types = props.options
    ? props.options.map((value, i) => {
        if (props.dateFormat) {
          return {
            key: i,
            text: value,
            value: props.session ? value : value,
          };
        }
        return {
          key: i,
          text: value,
          value,
        };
      })
    : null;

  return (
    <Form.Field className={props.classNames}>
      <label>{props.label}</label>
      <Dropdown
        clearable={props.clearable}
        fluid
        loading={props.options ? props.options.length === 0 : false}
        disabled={!props.options}
        selection
        options={types}
        onChange={props.onChange}
        defaultValue={props.defaultValue || ''}
      />
    </Form.Field>
  );
};

DropdownFilter.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  session: PropTypes.bool,
  dateFormat: PropTypes.bool,
  clearable: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classNames: PropTypes.string,
};

DropdownFilter.defaultProps = {
  options: null,
  label: null,
  session: false,
  dateFormat: false,
  clearable: true,
  defaultValue: '',
  classNames: '',
};

export default DropdownFilter;
