import * as React from 'react';
import {observer} from 'mobx-react-lite';
import AnnouncementCreateModal from './modalsState/AnnouncementCreateModal';
import AnnouncementInfoModal from './modalsState/AnnouncementInfoModal';
import PollModal from './modalsState/PollModal';
import UpdatePhotoModal from './modalsState/UpdatePhotoModal';
import EventInfoModal from './modalsState/EventInfoModal';
import InvitationModal from './modalsState/InvitationModal';
import AgendaOffTimeModal from './modalsState/AgendaOffTimeModal';
import PrivacyModal from './modalsState/PrivacyModal';
import TermsModal from './modalsState/TermsModal';
import AddGuestToMeetingModal from './modalsState/AddGuestToMeetingModal';
import AddSessionToAgendaModal from './modalsState/AddSessionToAgendaModal';
import ImageUploadModal from './modalsState/ImageUploadModal';
import BoothConfirmationModal from './modalsState/BoothConfirmationModal';
import AddMembersToCompanyModal from './modalsState/AddMembersToCompanyModal';
import ExportAgendaModal from './modalsState/ExportAgendaModal';
import TimezoneModal from './modalsState/TimezoneModal';
import DeleteAccountModal from './modalsState/DeleteAccountModal';
import AddContactByQrModal from './modalsState/AddContactByQrModal';
import AddContactModal from './modalsState/AddContactModal';
import QrGenerateModal from './modalsState/QrGenerateModal';
import RemoveEmailConfirmationModal from './modalsState/RemoveEmailConfirmationModal';
import PresenceConfirmationModal from './modalsUrl/PresenceConfirmationModal';
import CheckInModal from './modalsState/CheckInModal';
import MessageActionConfirmationModal from './modalsState/MessageActionConfirmationModal';
import './Modals.scss';
import PreBookedMeeting
  from "../invitations/InvitationFormRespond/PreBookedMeeting";
import ReserveATable from "../invitations/InvitationFormRespond/ReserveATable";
import ShowAgenda from "../invitations/InvitationFormRespond/ShowAgenda";
import InviteParticipantModal
  from "../invitations/InvitationFormRespond/InviteParticipant";

const Modals = observer(() => (
  <React.Fragment>
    <AnnouncementCreateModal />
    <AnnouncementInfoModal />
    <PollModal />
    <UpdatePhotoModal />
    <EventInfoModal />
    <InvitationModal />
    <AgendaOffTimeModal />
    <ShowAgenda/>
    <PrivacyModal />
    <TermsModal />
    <AddGuestToMeetingModal />
    <AddSessionToAgendaModal />
    <ImageUploadModal />
    <BoothConfirmationModal />
    <AddMembersToCompanyModal />
    <ExportAgendaModal />
    <TimezoneModal />
    <DeleteAccountModal />
    <AddContactByQrModal />
    <AddContactModal />
    <PresenceConfirmationModal />
    <RemoveEmailConfirmationModal />
    <QrGenerateModal />
    <CheckInModal />
    <MessageActionConfirmationModal />
    <PreBookedMeeting/>
    <ReserveATable/>
    <InviteParticipantModal/>
  </React.Fragment>
));

export default Modals;
