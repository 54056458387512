import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';
import {Icon} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {AppContext} from '../../../store';
import ProfileSidebar from '../ProfileSidebar';
import EventsSidebar from '../EventsSidebar';
import NotificationsSidebar from '../NotificationsSidebar';
import ParticipantProfile from '../ParticipantProfile';
import AgendaSidebar from '../AgendaSidebar';
import InvitationsSidebar from '../InvitationsSidebar';
import ContactsSidebar from '../ContactsSidebar';
import LastVisitedEventBtn from '../LastVisitedEventBtn';
import {ReactComponent as ChatImg} from '../../../assets/images/icons/header/chat.svg';
import {ReactComponent as SignOutImg} from '../../../assets/images/icons/header/sign-out.svg';
import {ReactComponent as CheckInImg} from '../../../assets/images/icons/header/checkIn.svg';
import {ReactComponent as InviteImg} from '../../../assets/images/icons/header/invite.svg';
import './NavBar.scss';

const NavBar = observer(() => {
  const {chatStore, userStore, eventStore, uiStore, modalStore} = React.useContext(AppContext);
  const {getUnSeenMessages} = chatStore;
  const {isMenuCollapsed} = uiStore;
  const {eventDetails} = eventStore;
  const canCheckIn = eventDetails && eventDetails.me.checkInAdmin && !eventDetails.isFinished;
  const canInvite = eventDetails?.me?.rights?.inviteparticipant;

  const classes = classNames('nav-bar', {
    'nav-bar--close': isMenuCollapsed,
    'nav-bar--open': !isMenuCollapsed,
  });

  return (
    <nav className={classes}>
      <div className="nav-bar__btn-collapse">
        <div
          className="nav-bar__btn-collapse-wrapper"
          aria-hidden
          onClick={() => uiStore.triggerMainMenu(!isMenuCollapsed)}
        >
          <Icon name="angle right" />
        </div>
      </div>
      <ProfileSidebar />
      <div className="nav-menu nav-menu--group divided">
        <NotificationsSidebar />
        <AgendaSidebar />
        <InvitationsSidebar />
        <ContactsSidebar />
      </div>
      <div className="nav-menu nav-menu--group">
        <div className="nav-menu__item nav-menu__item--chat">
          <div className="nav-menu__button" onClick={() => chatStore.openFloatChat()}>
            <div className="nav-menu__item-icon">
              <ChatImg />
            </div>
            <p className="nav-menu__item-text">
              <FormattedMessage id="header.link.messenger" />
            </p>
            {getUnSeenMessages > 0 && <div className="ui red label mini circular">{getUnSeenMessages}</div>}
          </div>
        </div>
        <EventsSidebar />
        {canCheckIn && (
          <div className="nav-menu__item nav-menu__item--check-in">
            <div className="nav-menu__button" onClick={() => modalStore.open('checkInQrModal')}>
              <div className="nav-menu__item-icon">
                <CheckInImg />
              </div>
              <p className="nav-menu__item-text">
                <FormattedMessage id="header.link.checkIn" />
              </p>
            </div>
          </div>
        )}
        {canInvite && (
          <div className="nav-menu__item nav-menu__item--check-in">
            <div className="nav-menu__button" onClick={() => modalStore.open('inviteAParticipantModal')}>
              <div className="nav-menu__item-icon">
                <InviteImg />
              </div>
              <p className="nav-menu__item-text">
                <FormattedMessage id="header.link.inviteParticipant" />
              </p>
            </div>
          </div>
        )}
        {!isEmpty(userStore.user) && eventDetails ? <ParticipantProfile /> : null}
      </div>
      <LastVisitedEventBtn eventDetails={eventDetails} />
      <div className="nav-menu__item nav-menu__item--logout">
        <div className="nav-menu__button" role="button" aria-hidden="true" onClick={() => userStore.logout()}>
          <SignOutImg />
          <p className="nav-menu__item-text">
            <FormattedMessage id="header.link.signOut" />
          </p>
        </div>
      </div>
    </nav>
  );
});

export default injectIntl(NavBar);
