import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Popup} from 'semantic-ui-react';
import {useLocation, useHistory} from 'react-router-dom';
import {AppContext} from '../../../store';
import {startMeeting} from '../../../services/helpers/meetingHelper';
import './AgendaCardModeratorRole.scss';

const AgendaCardModeratorRole = observer(props => {
  const {session} = props;
  const {eventStore} = React.useContext(AppContext);
  const {eventList} = eventStore;
  const location = useLocation();
  const history = useHistory();
  const eventDetails = eventList?.filter(event => event.id === session.eventId)[0];

  return (
    <div className="agenda-details moderator-role">
      <div className="agenda-item__name">{session.name}</div>
      <div className="agenda-item__role">You are moderator</div>
      {!session.expired &&
      session.upcomingState !== 'expired' &&
      session.onSite &&
      eventDetails &&
      eventDetails.type === 'hybrid' ? (
        <Popup
          content="This session is available only on-site. To take part in it, please proceed to the session location."
          trigger={
            <Button className="session-btn__start-stream" basic color="teal" size="mini">
              <FormattedMessage id="agenda.item.startStreaming.btn.onSite" />
            </Button>
          }
        />
      ) : null}
      <div className="agenda-item__action">
        {!session.expired &&
        session.upcomingState !== 'expired' &&
        !(session.onSite || (eventDetails && eventDetails.type === 'normal')) ? (
          <Button className="session-btn__start-stream" basic color="teal" onClick={() => startMeeting(session, true)}>
            <FormattedMessage id="agenda.item.joinNow.btn" />
          </Button>
        ) : null}
        <Button
          className="agenda-btn__details"
          color="blue"
          basic
          onClick={() => {
            history.push({
              pathname: `/event/${session.eventId}/sessions/${session.rsesId || session.sdefId}`,
              state: {
                background: location,
                page: 'sessions',
                title: session.name,
              },
            });
          }}
        >
          <FormattedMessage id="agenda.item.viewDetails.btn" />
        </Button>
      </div>
    </div>
  );
});

AgendaCardModeratorRole.propTypes = {
  session: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default injectIntl(AgendaCardModeratorRole);
