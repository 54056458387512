import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Form, Button} from 'semantic-ui-react';
import {useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import AttendeePreview from '../../attendee/AttendeePreview/AttendeePreview';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import ButtonLoading from '../../../ui/ButtonLoading';
import InvitationApi from '../../../services/api/invitationApi';
import useMedia from '../../../hooks/useMedia';
import './InvitationFormSent.scss';

const InvitationFormSent = observer(props => {
  const {attendee} = props;
  const {userStore, modalStore, invitationStore, routerStore} = React.useContext(AppContext);
  const {user} = userStore;
  const {getValues, register, errors} = useForm();
  const intl = useIntl();
  const isMobile = useMedia('(max-width: 768px)');

  const onSubmit = () => {
    const message = getValues().message || 'I would like to meet you!';
    invitationStore.setTypeCommandByUser(attendee.id, 'pending');
    return InvitationApi.sendMeetingRequest(user.id, attendee.eventId, attendee.id, message, false).catch(err => {
      invitationStore.setTypeCommandByUser(attendee.id, null);
      NotificationAlert.error(err);
    });
  };

  return (
    <Form className="invite-form-sent">
      <AttendeePreview attendee={attendee} big />
      <Form.Field className="field__textarea" error={!!errors.username}>
        <label>Enter your greeting message</label>
        <div className="ui left input">
          <textarea
            rows="6"
            name="message"
            placeholder={intl.formatMessage({id: 'invite.form.sent.placeholder'})}
            ref={register()}
          />
        </div>
      </Form.Field>
      <div className="invite-form-sent__info">
        <FormattedMessage
          id="invite.form.sent.text.note"
          values={{
            b: chunks => <b>{chunks}</b>,
          }}
        />
      </div>
      <div className="invite-form-sent__action">
        <Button
          color="red"
          basic
          onClick={() => {
            if (isMobile) {
              routerStore.goBack();
            } else {
              modalStore.close('attendeeMeet');
            }
          }}
        >
          <FormattedMessage id="invite.form.sent.btn.cancel" />
        </Button>
        <ButtonLoading color="blue" onClick={() => onSubmit()}>
          <FormattedMessage id="invite.form.sent.btn.send" />
        </ButtonLoading>
      </div>
    </Form>
  );
});

InvitationFormSent.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
};

export default InvitationFormSent;
