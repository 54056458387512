import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Confirm, Icon, Transition} from 'semantic-ui-react';
import {injectIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import {AppContext} from '../../../store';
import ContactApi from '../../../services/api/contactApi';
import './ContactNote.scss';

const ContactNote = observer(props => {
  const {intl} = props;
  const {contactStore} = React.useContext(AppContext);
  const {selectedContactNotes, showAddNote, selectedNote} = contactStore;
  const {register, errors, getValues, trigger} = useForm();
  const [confirmRemove, setConfirmRemove] = React.useState(false);
  const [selectNote, setSelectNote] = React.useState(null);
  const [addNote, setAddNote] = React.useState(false);
  const [removeNote, setRemoveNote] = React.useState(false);

  return (
    <>
      <div className="note-box">
        <label htmlFor="">Notes</label>
        {!showAddNote ? (
          <Button icon color="teal" size="mini" className="note-add__btn" onClick={() => contactStore.addNote()}>
            <Icon name="plus" />
          </Button>
        ) : null}
        {!showAddNote ? (
          <div className="note__list">
            {selectedContactNotes.length > 0
              ? selectedContactNotes.map(note => (
                  <div className="note" key={note.id}>
                    <div className="note__text">{note.note}</div>
                    <div className="note__footer">
                      <div className="note__action">
                        <Button icon onClick={() => contactStore.updateNote(note)} size="mini">
                          <Icon name="edit" />
                        </Button>
                        <Button
                          icon
                          onClick={() => {
                            setSelectNote(note);
                            setConfirmRemove(true);
                          }}
                          size="mini"
                        >
                          <Icon name="remove" />
                        </Button>
                      </div>
                      <p className="note__date">{note.insertedAtStr}</p>
                    </div>
                  </div>
                ))
              : 'No notes exists. Click  to add a note.'}
          </div>
        ) : null}
      </div>
      {showAddNote ? (
        <div className="note-box note-box__add">
          <textarea
            name="note"
            rows="2"
            defaultValue={selectedNote?.note || ''}
            ref={register({
              required: intl.formatMessage({id: 'validation.error.comment'}),
            })}
          />
          {errors.note && <span className="error">{errors.note.message}</span>}
          <div className="note-box__action">
            <Button onClick={() => contactStore.cancelNote()}>Cancel</Button>
            <Button
              onClick={async () => {
                const result = await trigger('note');
                if (result) {
                  console.log('valid input');
                  setAddNote(true);
                  contactStore.saveNote(getValues('note')).finally(() => setAddNote(false));
                }
              }}
              color="teal"
              loading={addNote}
            >
              {selectedNote ? 'Update note' : 'Add note'}
            </Button>
          </div>
        </div>
      ) : null}
      <Transition visible={confirmRemove} animation="fade down" duration={400}>
        <Confirm
          className="note-modal__remove"
          open={confirmRemove}
          cancelButton={{content: 'No'}}
          confirmButton={{
            loading: removeNote,
            disabled: removeNote,
            content: 'Yes',
          }}
          onCancel={() => setConfirmRemove(false)}
          onConfirm={() => {
            setRemoveNote(true);
            ContactApi.deleteNote(selectNote)
              .then(() => {
                setConfirmRemove(false);
              })
              .finally(() => setRemoveNote(false));
          }}
        />
      </Transition>
    </>
  );
});

export default injectIntl(ContactNote);
