import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {AppContext} from '../../../store';
import ButtonLoading from '../../../ui/ButtonLoading';
import MeetingOffService from '../../../services/api/meetingOfApi';
import './AgendaCardOffTime.scss';
const AgendaCardOffTime = observer(props => {
  const {session, attendee} = props;
  const {userStore} = React.useContext(AppContext);
  const {user} = userStore;

  return (
    <div className="agenda-details agenda-off">
      <div className="agenda-item__name">Break</div>
      <div className="agenda-item__action">
        {!session.expired ? (
          <ButtonLoading
            className="agenda-btn__calendar-remove"
            color="red"
            onClick={() => MeetingOffService.deleteOffTime(user.id, session.eventId, session, attendee?.id)}
          >
            <FormattedMessage id="agenda.item.removeOffTime.btn" />
          </ButtonLoading>
        ) : null}
      </div>
    </div>
  );
});

AgendaCardOffTime.propTypes = {
  session: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default injectIntl(AgendaCardOffTime);
