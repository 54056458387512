import last from 'lodash/last';
import UtilsService from '../core/utils-service';
import TimeService from '../core/time-service';
import {stores} from '../../store';

async function processArrayParticipants(array) {
  const pDetails = [];
  for (const item of array) {
    // eslint-disable-next-line no-await-in-loop
    await stores.participantStore.getParticipantDetails(item).then(p => {
      pDetails.push(p);
    });
  }
  return pDetails;
}

export async function processInvitation(eventDetails, invitations, invitation, participantDetails) {
  const participantIds = [];
  const sessionIds = {};
  const sessionObservables = [];
  let meetingObservable;
  participantIds.push(invitation.tpid, invitation.fpid);

  if (invitation.sessionId) {
    sessionIds[invitation.sessionId] = invitation.eid;
  }

  const participantsObservables = processArrayParticipants(participantIds);

  for (const sessionId in sessionIds) {
    sessionObservables.push(stores.sessionsNewStore.data.getPublicSession(sessionIds[sessionId], sessionId));
  }

  if (last(invitation.meetingDetails)) {
    meetingObservable = stores.agendaStore.data.getPrivateMeeting(
      eventDetails.id,
      participantDetails.id,
      last(invitation.meetingDetails).sessionId,
    );
  }

  return Promise.all([Promise.all(sessionObservables), participantsObservables, meetingObservable]).then(results => {
    const sessions = UtilsService.asMap(results[0], 'id');
    const participants = UtilsService.asMap(results[1], 'id');
    const meeting = results[2];
    invitation.event = eventDetails;
    if (
      invitation.tpid === participantDetails.id ||
      invitation.fpid === participantDetails.id ||
      invitation.oppId === participantDetails.id
    ) {
      invitation.myInvitation = true;
    }
    invitation.myId = invitation.tpid;
    invitation.sent = invitation.fpid === participantDetails.id;
    invitation.message = invitation.messsage;
    TimeService.invitationDatePopulate(invitation);
    if (invitation.sessionId != null) {
      invitation.session = sessions[invitation.sessionId];
    }
    if (invitation.meetingDetails) {
      invitation.meeting = meeting;
    }
    invitation.opponentParticipant = participants[invitation.fpid];
    invitation.opponentParticipantReceived = participants[invitation.myId];

    invitation.myDetails = participants[invitation.myId];

    invitation.eventName = eventDetails?.name;
    invitation.me = eventDetails.me;
    invitation.me.participantTypes = eventDetails.participantTypes;
    invitation.attendeeFullName = invitation.opponentParticipant?.fullName;
    let lastMeetingDetails = last(invitation.meetingDetails);
    if (lastMeetingDetails != null) {
      lastMeetingDetails = invitations.find(inv => inv.id === lastMeetingDetails.invitationId);
    }
    if (lastMeetingDetails == null) {
      lastMeetingDetails = invitation;
    }
    invitation.sent = lastMeetingDetails.sent;
    invitation.lastInvitationDetails = lastMeetingDetails;
    if (
      (lastMeetingDetails.type === 'join' && lastMeetingDetails.sesId == null) ||
      lastMeetingDetails.type === 'reschedule'
    ) {
      if (!lastMeetingDetails.sent) {
        if (
          (eventDetails.me?.boothId && lastMeetingDetails.tpid !== participantDetails.id) ||
          !invitation.myInvitation
        ) {
          invitation.segment = 'company';
        } else {
          invitation.segment = 'received';
        }
      } else {
        invitation.segment = 'sent';
      }
    }
    if (
      (lastMeetingDetails.state == null || lastMeetingDetails.state === 'waiting') &&
      lastMeetingDetails.fpid != null &&
      lastMeetingDetails.tpid != null &&
      (lastMeetingDetails.edt == null || lastMeetingDetails.edt + 24 * 3600 * 1000 > TimeService.now())
    ) {
      if (
        lastMeetingDetails.meeting &&
        (lastMeetingDetails.meeting.expired || lastMeetingDetails.meeting.upcomingState === 'expired')
      ) {
        invitation.status = 'past';
      } else {
        invitation.status = 'live';
      }
    } else {
      invitation.status = 'past';
    }

    return invitation;
  });
}

export function filterInvitation(el, segment, status) {
  if (
    // Hide reschedule invitation (show only original invitation)
    (el.type === 'reschedule' && el.state !== 'waiting') ||
    (el.type === 'join' && el.state === 'accepted' && status === 'live' && el.meeting) ||
    // Hide original invitation that accepted (show reschedule invitation)
    (el.type === 'join' && el.status === 'past' && el.meeting && el.lastMeetingDetails?.type !== 'rescheduled') ||
    // Hide invitation, if my teammate is invitation sender
    (el.fpid === el.opponentParticipant.id &&
      el.fpid !== el.me.id &&
      el.me.boothId &&
      el.opponentParticipant.boothId === el.me.boothId)
  ) {
    return false;
  }
  return el.segment === segment && el.status === status;
}
