import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage} from 'react-intl';
import {AppContext} from '../../../store';
import './InvitationFormLimit.scss';

const InvitationFormLimit = observer(props => {
  const {invitation} = props;
  const {eventStore} = React.useContext(AppContext);
  const {eventDetails, meParticipant} = eventStore;
  const meParticipantTypesConf = invitation
    ? invitation.me.participantTypes[invitation.me.typeId]
    : meParticipant?.participantTypeConf;

  return (
    <div className="attendee-invite-limit">
      <FormattedMessage
        id="invite.form.limit.text"
        values={{
          eventName: invitation ? invitation.event.name : eventDetails.name,
          countLimit: <span>{meParticipantTypesConf?.maxMeetingCount}</span>,
        }}
      />
    </div>
  );
});

export default InvitationFormLimit;
