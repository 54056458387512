import React from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Icon} from 'semantic-ui-react';
import {AppContext} from '../../../store';
import CrispService from '../../../services/core/crisp-service';
import packageJson from '../../../../package.json';
import './LiveSupportBtn.scss';

const LiveSupportBtn = observer(() => {
  const {windowSizeStore} = React.useContext(AppContext);
  const [init, setInit] = React.useState(false);
  const isMobile = windowSizeStore.windowSizes === 'mobile';

  React.useEffect(() => {
    if (!init) {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = '50a066e9-4218-4c28-a2a5-1fd41bfbac5d';
      (() => {
        const d = document;
        const s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1;
        d.getElementsByTagName('body')[0].appendChild(s);
      })();
      window.$crisp.push(['config', 'hide:vacation', true]);
      window.CRISP_READY_TRIGGER = () => {
        setInit(true);
        CrispService.setVersion(`${packageJson.version}`);
      };
    }
  });

  if (window.$crisp) {
    if (isMobile) {
      window.$crisp.push(['config', 'hide:vacation', false]);
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          window.$crisp.push(['config', 'hide:vacation', false]);
        },
      ]);
    }
  }

  function openSupport() {
    if (window.$crisp) {
      window.$crisp.push(['config', 'hide:vacation', false]);
      window.$crisp.push(['do', 'chat:open']);
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          window.$crisp.push(['config', 'hide:vacation', true]);
        },
      ]);
    }
  }

  return init ? (
    <div className="live-support" aria-hidden onClick={() => openSupport()}>
      <div className="live-support__inner">
        <div className="live-support__rotate">
          <span>
            <FormattedMessage id="live.support.btn" />
          </span>
          <Icon name="users" />
        </div>
      </div>
    </div>
  ) : null;
});

export default injectIntl(LiveSupportBtn);
