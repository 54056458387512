import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button} from 'semantic-ui-react';
import {useHistory, useLocation} from 'react-router-dom';
import {AppContext} from '../../../store';
import useMedia from '../../../hooks/useMedia';
import ParserText from '../../../ui/ParserText';
import './ChatMessageByInvitation.scss';

const ChatMessageByInvitation = observer(props => {
  const {message, room, nextMessage} = props;
  const {userStore, modalStore, invitationStore, uiStore} = React.useContext(AppContext);
  const userId = userStore.user.id;
  const {invitations} = invitationStore;
  const {pageTitle} = uiStore;
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMedia('(max-width: 768px)');

  const invitation = invitations.find(i => {
    if (message.det?.invitationIds) {
      return i.id === message.det?.invitationIds[0];
    } else {
      return i.id === message.det?.id || i.id === message.det?.iid;
    }
  });

  const interlocutorsArray = Object.keys(room.members).filter(member => member !== message.sndr);
  const interlocutor = interlocutorsArray.length === 1 ? interlocutorsArray[0] : '';

  const userName = interlocutor !== userId ? 'You' : room.members[message.sndr].name;

  return (
    <div className="chat-msg__inv">
      <div className="chat-msg__inv-body">
        <div className="chat-msg__inv-img">
          <i className="ellipsis video camera icon" />
        </div>
        <div className="chat-msg__inv-info">
          <div className="chat-msg__status-text">
            {message.type === 'cmreq' && (
              <FormattedMessage
                id="chat.invitation.confirmed.text"
                values={{
                  span: chunks => <span>{chunks}</span>,
                  user: userName,
                }}
              />
            )}
            {message.type === 'mreq' && (
              <FormattedMessage
                id="chat.invitation.request.text"
                values={{
                  span: chunks => <span>{chunks}</span>,
                  user: userName,
                }}
              />
            )}
            {message.type === 'revmreq' && (
              <FormattedMessage
                id="chat.invitation.revoked.text"
                values={{
                  span: chunks => <span>{chunks}</span>,
                  user: userName,
                }}
              />
            )}
            {message.type === 'rmreq' && (
              <FormattedMessage
                id="chat.invitation.declined.text"
                values={{
                  span: chunks => <span>{chunks}</span>,
                  user: userName,
                }}
              />
            )}
            {message.type === 'rreq' && (
              <FormattedMessage
                id="chat.invitation.reschedule.text"
                values={{
                  span: chunks => <span>{chunks}</span>,
                  user: userName,
                }}
              />
            )}
            {message.type === 'rres' && (
              <FormattedMessage
                id="chat.invitation.reschedule.decline.text"
                values={{
                  span: chunks => <span>{chunks}</span>,
                  user: userName,
                }}
              />
            )}
            {message.type === 'cres' && (
              <FormattedMessage
                id="chat.invitation.reschedule.accept.text"
                values={{
                  span: chunks => <span>{chunks}</span>,
                  user: userName,
                }}
              />
            )}
            {message.type === 'lses' && (
              <FormattedMessage
                id="chat.invitation.canceled.text"
                values={{
                  span: chunks => <span>{chunks}</span>,
                  user: userName,
                }}
              />
            )}
          </div>
          {invitation ? <div className="chat-msg__inv-event">{invitation.eventName}</div> : null}
        </div>
      </div>
      <div className="chat-msg__inv-msg">
        <ParserText type="html">{message.msg}</ParserText>
      </div>
      <div className="chat-msg__inv-action">
        {invitation &&
        !nextMessage &&
        ['mreq', 'rreq', 'rres', 'cmreq', 'cres'].includes(message.type) &&
        invitation.status !== 'past' ? (
          <Button
            basic
            onClick={() => {
              if (invitation) {
                if (isMobile) {
                  history.push({
                    pathname: `/event/${invitation.opponentParticipant.eventId}/invitation/${invitation.opponentParticipant.id}`,
                    state: {
                      background: location,
                      page: 'invitation',
                      prevPage: pageTitle,
                      title: 's.name',
                    },
                  });
                } else {
                  modalStore.open('attendeeMeet', {attendee: invitation.opponentParticipant});
                }
              }
            }}
          >
            <FormattedMessage id="chat.message.btn.open" />
          </Button>
        ) : null}
      </div>
    </div>
  );
});

ChatMessageByInvitation.propTypes = {
  message: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

ChatMessageByInvitation.defaultProps = {
  messageIndex: 0,
};

export default injectIntl(ChatMessageByInvitation);
