import React from 'react';
import {IntlProvider} from 'react-intl';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {AppContext} from '../store';
import tr from '../translations/languages/tr.json';
import en from '../translations/languages/en.json';
import ru from '../translations/languages/ru.json';
import es from '../translations/languages/es.json';

const messages = {
  tr,
  en,
  ru,
  es,
};

const Localizer = observer(({children, locale}) => {
  const {languageStore} = React.useContext(AppContext);
  const {messagesFromEvent} = languageStore;
  const messagesByLocale = messages[locale];
  let messagesDefaultWithEvent = null;

  if (messagesFromEvent) {
    messagesDefaultWithEvent = {...messagesByLocale, ...messagesFromEvent};
  }

  return (
    <IntlProvider
      defaultLocale="en"
      locale={locale}
      messages={messagesFromEvent ? messagesDefaultWithEvent : messages[locale]}
    >
      {children}
    </IntlProvider>
  );
});

Localizer.propTypes = {
  children: PropTypes.element.isRequired,
  locale: PropTypes.string.isRequired,
};

export default Localizer;
