import React, {useRef, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Portal} from 'semantic-ui-react';
import htmlClass from '../../../utils/htmlClass';
import {useEventOutsideElement} from '../../../hooks/useEventOutsideElement';
import {ReactComponent as CloseImg} from '../../../assets/images/icons/close.svg';
import './Drawer.scss';

const Drawer = props => {
  const {show, children, close, leftOffset} = props;
  const [closeClass, setCloseClass] = React.useState(false);
  const [openClass, setOpenClass] = React.useState(false);
  const scrollBarWidth = window.innerWidth - document.body.clientWidth;

  const drawerClasses = classNames('side-drawer', {
    open: openClass,
    'open--offset': leftOffset,
    close: closeClass,
    [`${props.classes}`]: props.classes,
  });

  const closeFunction = () => {
    enableScroll();
    setCloseClass(true);
    htmlClass.remove(document.body, 'drawer--open');
    setTimeout(() => {
      close();
      setCloseClass(false);
    }, 500);
  };

  const escFunction = useCallback(
    event => {
      if (event.keyCode === 27) {
        // Do whatever when esc is pressed
        closeFunction();
      }
    },
    [close],
  );

  const reference = useRef();
  useEventOutsideElement(
    reference,
    closeFunction,
    ['mousedown', 'touchstart'],
    event =>
      !reference.current ||
      reference.current.contains(event.target) ||
      event.target.closest('div.modals') ||
      event.target.closest('div.popup') ||
      event.target.classList.contains('close-icon'),
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
      enableScroll();
    };
  }, [escFunction]);

  function disableScroll() {
    document.body.style.marginRight = `${scrollBarWidth}px`;
    document.body.style.overflowY = 'hidden';
  }

  function enableScroll() {
    document.body.style.marginRight = null;
    document.body.style.overflowY = null;
  }

  return (
    <Portal
      onClose={closeFunction}
      closeOnDocumentClick={false}
      open={show}
      onMount={() =>
        setTimeout(() => {
          setOpenClass(true);
          htmlClass.add(document.body, 'drawer--open');
          disableScroll();
        })
      }
      onUnmount={() => {
        setOpenClass(false);
      }}
    >
      <div className={drawerClasses} ref={reference}>
        <div className="side-drawer__bg" aria-hidden onClick={closeFunction} />
        <div className="side-drawer__wrapper">
          <div className="side-drawer__body">{children}</div>
          <div className="side-drawer__close" aria-hidden onClick={closeFunction}>
            <CloseImg />
          </div>
        </div>
      </div>
    </Portal>
  );
};

Drawer.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  classes: PropTypes.string,
};

Drawer.defaultProps = {
  classes: '',
};

export default Drawer;
