import {useState, useEffect} from 'react';
import {getLastSelectedCheckInData} from '../services/helpers/checkInHelper';
import {useIntl} from 'react-intl';

const useBadgePrinters = (printers, eventId, printType, addNonePrinter) => {
  const intl = useIntl();
  const [filteredPrinterList, setFilteredPrinterList] = useState([]);
  const [currentPrinter, setCurrentPrinter] = useState({});
  const selectedPrinterForEvent = getLastSelectedCheckInData(eventId, 'printer') || {};
  const checkChangedPrinter =
    selectedPrinterForEvent.ip !== currentPrinter.ip || !Object.keys(selectedPrinterForEvent).length;

  // Filter printers by type
  useEffect(() => {
    if (Array.isArray(printers) && !filteredPrinterList.length) {
      let filteredPrinters;

      if (printType === 'txt') {
        filteredPrinters = printers.filter(printer => printer.printType === 'TXT');
      } else {
        filteredPrinters = printers;
      }

      if (printers.length && addNonePrinter) {
        setFilteredPrinterList(addTypePrinterNone(filteredPrinters, intl));
      }

      if (printers.length && !addNonePrinter) {
        setFilteredPrinterList(filteredPrinters);
      }
    }
  }, [printers]);

  // Set current printer or first printer from printer list
  useEffect(() => {
    if (filteredPrinterList.length && checkChangedPrinter) {
      if (
        filteredPrinterList.some(
          printer => printer.ip === selectedPrinterForEvent.ip && printer.label === selectedPrinterForEvent.label,
        )
      ) {
        setCurrentPrinter(selectedPrinterForEvent);
      } else {
        setCurrentPrinter(filteredPrinterList[0]);
      }
    }
  }, [filteredPrinterList, selectedPrinterForEvent]);

  return {filteredPrinterList, currentPrinter};
};

function addTypePrinterNone(printers, intl) {
  const printerList = printers.map(printers => printers);

  printerList.unshift({
    label: intl.formatMessage({id: 'checkIn.printerSelector.nonePrinter.label'}),
    printType: 'none',
    ip: 'none',
  });

  return printerList;
}

export default useBadgePrinters;
