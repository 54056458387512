import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Form} from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';
import {useForm} from 'react-hook-form';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {AppContext} from '../../../store';
import classNames from 'classnames';
import './UpdatePasswordForm.scss';

const UpdatePasswordForm = observer(() => {
  const intl = useIntl();
  const {userStore} = React.useContext(AppContext);
  const {isLoading} = userStore;
  const {handleSubmit, register, errors} = useForm();
  const [showPasswordNew, setShowPasswordNew] = React.useState(false);
  const [showPasswordOld, setShowPasswordOld] = React.useState(false);

  const onTogglePassword = type => {
    switch (type) {
      case 'new':
        setShowPasswordNew(!showPasswordNew);
        break;
      case 'old':
        setShowPasswordOld(!showPasswordOld);
        break;
      default:
        break;
    }
  };
  const classesPasswordNew = classNames('link icon', {
    eye: showPasswordNew,
    'eye slash': !showPasswordNew,
  });
  const classesPasswordOld = classNames('link icon', {
    eye: showPasswordOld,
    'eye slash': !showPasswordOld,
  });

  const onSubmit = value => {
    userStore
      .updatePassword(userStore.user.username, value.oldPassword, value.newPassword)
      .then(() => {
        NotificationAlert.success(intl.formatMessage({id: 'update.password.success'}));
      })
      .catch(err => {
        const {code, message} = err;

        switch (code) {
          case 'NotAuthorizedException':
            NotificationAlert.error(intl.formatMessage({id: 'update.password.error.NotAuthorizedException'}));
            break;
          default:
            NotificationAlert.error(message);
        }
      });
  };

  return (
    <div className="update-password-form">
      <h2 className="update-password-form__title">
        <FormattedMessage id="update.password.title" />
      </h2>
      <Form
        className="update-password-form__body"
        error={!isEmpty(errors) || !!userStore.error}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form.Field error={!!errors.oldPassword}>
          <label>
            <FormattedMessage id="app.field.old-password" />
          </label>
          <div className="ui icon input">
            <input
              name="oldPassword"
              placeholder={intl.formatMessage({id: 'update.password.placeholder.old'})}
              type={showPasswordNew ? 'text' : 'password'}
              ref={register({
                required: intl.formatMessage({id: 'validation.error.required'}, {name: 'E-mail'}),
              })}
            />
            <i
              aria-hidden="true"
              className={classesPasswordNew}
              onClick={() => {
                onTogglePassword('new');
              }}
            />
          </div>
          {errors.oldPassword ? <div className="error">{errors.oldPassword.message}</div> : null}
        </Form.Field>
        <Form.Field error={!!errors.newPassword}>
          <label>
            <FormattedMessage id="app.field.new-password" />
          </label>
          <div className="ui icon input">
            <input
              name="newPassword"
              placeholder={intl.formatMessage({id: 'update.password.placeholder.new'})}
              type={showPasswordOld ? 'text' : 'password'}
              ref={register({
                required: intl.formatMessage({id: 'validation.error.required'}, {name: 'E-mail'}),
              })}
            />
            <i
              aria-hidden="true"
              className={classesPasswordOld}
              onClick={() => {
                onTogglePassword('old');
              }}
            />
          </div>
          {errors.newPassword ? <div className="error">{errors.newPassword.message}</div> : null}
        </Form.Field>
        <Button disabled={isLoading} loading={isLoading} color="teal">
          <FormattedMessage id="update.password.btn.reset" />
        </Button>
      </Form>
    </div>
  );
});

export default injectIntl(UpdatePasswordForm);
